<div class="content-container" fxFlexFill fxLayout="column" fxLayoutAlign="start center">

    <h1 class="mat-h1" i18n="Refrees Reservations|Refrees Reservations header@@">Refrees Reservations</h1>

    <div [hidden]="!this.dataSource" fxFlexFill fxLayoutAlign="center start" >
        <div style="margin: 2vh;" fxFlex.gt-md="95" fxFlex="100" class="mat-elevation-z8" fxLayout="column">
            
            <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap.xs="1vh">
                <div fxFlex="100" fxFlex.xs fxFlexOrder="1">
                    
                    <mat-form-field  fxLayoutAlign="center center" fxLayoutAlign.xs="center center">
                        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
                    </mat-form-field>
            
                </div>
            
                <div fxFlex="100" fxFlex.xs fxFlexOrder="3">
            
                    <mat-paginator [length]="this.dataSource.data.length"
                                    [pageSize]="5"
                                    showFirstLastButtons
                                    (page)="pageEvent = $event">
                    </mat-paginator>
                    
                </div>
            
    
            <table *ngIf="!this.UIservice.isMobileXS; else cards" 
                    class="mat-elevation-z8"
                    fxFlexOrder="2" 
                    fxFlex="100" 
                    mat-table
                    multiTemplateDataRows
                    [dataSource]="dataSource">
                
                    <ng-container matColumnDef="Name">
                        <th fxFlex="25" fxLayoutAlign="center center" mat-header-cell *matHeaderCellDef i18n="@@5f4504ebcc1baf8c9919de13a7ccf043a821e8ca">Name</th>
                        <td fxFlex="25" fxLayoutAlign="center center" mat-cell *matCellDef="let element"> {{element.userData.name}} </td>
                      </ng-container>

                      <ng-container matColumnDef="Surname">
                        <th fxFlex="25" fxLayoutAlign="center center" mat-header-cell *matHeaderCellDef i18n="@@fcb6ff7ea6d7e88ade21b6df268f0c71f0d7ae02">Surname</th>
                        <td fxFlex="25" fxLayoutAlign="center center" mat-cell *matCellDef="let element">{{element.userData.surname}}</td>
                      </ng-container>

                      <ng-container matColumnDef="Phone">
                        <th fxFlex="25" fxLayoutAlign="center center" mat-header-cell *matHeaderCellDef i18n="@@9267be8781930e8c0c72709bdd17b1f670dd8252">Phone</th>
                        <td fxFlex="25" fxLayoutAlign="center center" mat-cell *matCellDef="let element">{{element.phone}}</td>
                      </ng-container>

                      <ng-container matColumnDef="Actions">
                        <th fxFlex="25" fxLayoutAlign="center center" mat-header-cell *matHeaderCellDef i18n="@@Actions">Actions</th>
                        <td fxFlex="25" fxLayoutAlign="center center" mat-cell *matCellDef="let element">
                            <button mat-button color="accent" (click)="onRefereeConfirm(element.id)" i18n="@@Confirm">Confirm</button>
                            <button mat-button color="warn" (click)="onRefereeReservationDelete(element.id)" i18n="Delete button| Delete Button@@">Delete</button>
                        </td>
                      </ng-container>
                    
                    
                      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                      <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
                          class="example-element-row"
                          >
                      </tr>

            </table>
            
            <ng-template #cards>
        
                <div fxFlexFill fxLayout="column" fxLayoutGap="1vh" fxFlexOrder.xs="2">
                    <mat-accordion *ngFor="let element of this.cardData | async">
                        <mat-expansion-panel>
                          <mat-expansion-panel-header fxLayout="row">  
                            
                            <mat-panel-title fxFlex="30">
                                                 
                                <label fxFlex="50">{{element.userData.name}}</label>
                                <label fxFlex="50">{{element.userData.surname}}</label>
                
                            </mat-panel-title>
                            
                          </mat-expansion-panel-header>
             
                          <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="2vh">
                          
                            <div fxLayout="row wrap" fxLayoutAlign="start center">

                                    <div fxFlex="100" fxLayout="column" fxLayoutAlign="space-between center"  fxLayoutGap="2vh">
                                
                                        <div fxLayout="row nowrap" fxLayoutAlign="center start">
                                            <mat-icon>phone</mat-icon>  
                                            <a class="cardLink" [attr.href]="'tel:' + element.phone">{{element.phone}}</a>
                                        </div>
                                        <div fxLayout="row nowrap" fxLayoutAlign="center start">
                                            <mat-icon>email</mat-icon>  
                                            <a class="cardLink" [attr.href]="'mailto:' + element.email">{{element.email}}</a>
                                        </div>
                                        
                                        <!-- <div fxLayoutAlign="center end">
                                            <button mat-stroked-button color="primary" (click)="onRefereeEdit(element.id)">Edit</button>
                                            <button mat-stroked-button color="warn" (click)="onRefereeDelete(element.id)">Delete</button>
                                        </div>   -->
            
                                    </div>
                            </div>
                            
                                    <div class="mat-h4" style="font-weight: bold;" i18n="@@d494be449864c2a49e7eedf6895ae54e5727ac2b">Peg</div>

                          <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="2vh">
                            

                            <!-- <div style="width: 100%;" fxLayout="row wrap" fxLayoutAlign="space-between center" *ngFor="let peg of element.anglers; let i = index;" >
                                
                                <div fxFlex="30" style="font-weight: bold;">
                                    {{angler.teamRole}}
                                </div>
                                <div fxFlex="60">
                                    {{angler.name}}&nbsp;{{angler.surname}}
                                </div>
                                <div fxFlex="10">
                                    {{angler.country}}
                                </div>
                                <div fxFlex="100" fxLayoutAlign="end center">
                                    <button mat-stroked-button color="primary" (click)="onAnglerEdit(element.id,i)" i18n="Edit button| Edit Button@@">Edit</button>
                                    <button mat-stroked-button color="warn" (click)="onAnglerDelete(element.id,i)" i18n="Delete button| Delete Button@@">Delete</button>
                                </div>  
    
                            </div> -->

                          </div>

            
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
            </ng-template>
    
            </div>
            
        </div>
    
    
    </div>

</div>