
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, Subscription } from 'rxjs';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Fish } from 'src/app/data/models/Manage/fish.model';
import { ContestDataService } from '../../services/contest-data.service';
import { UIService } from 'src/app/shared/service/ui.service';
import { ManageContestDataService } from '../../services/manage-contest-data';
import { AuthService } from 'src/app/auth-module/service/auth.service';

@Component({
  selector: 'app-catches',
  templateUrl: './catches.component.html',
  styleUrls: ['./catches.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class CatchesComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  dataSubscription: Subscription
  catches = []
  catchesById = []

  allcatches = 0;
  wholeWeight = 0;

  columnsToDisplay= ['station', 'TotalFish', 'TotalWeight' ];
  dataSource = new MatTableDataSource<Fish>()
  expandedElement: Fish | null;
  pageEvent: PageEvent;
  cardData: Observable<Fish[]>

  constructor(private contestData: ContestDataService,
              private UIservice: UIService,
              private manageContest: ManageContestDataService,
              private authService: AuthService) {
    this.contestData.getCatches()
  }


  ngOnInit(): void {
    this.dataSubscription = this.contestData.catches$.subscribe(array => {
      if(array != null)
      {
        this.catchesById = array
        let tempCatches = []
        array.forEach((fish) => {
          tempCatches[fish.station] = tempCatches[fish.station] || {station: fish.station, totalWeight: 0, totalFish: 0, catches: []}
          tempCatches[fish.station].totalWeight += fish.weight
          tempCatches[fish.station].totalFish += 1
          tempCatches[fish.station].catches.push(fish)
          this.allcatches ++;
          this.wholeWeight += fish.weight;
        })
        this.catches = []
        for (let index = 0; index < tempCatches.length; index++) {
            if(tempCatches[index])
            {
              this.catches.push(tempCatches[index])
            }
          }
        this.dataSource.paginator = this.paginator
        this.dataSource.data = this.catches
        this.cardData = this.dataSource.connect()
      }
    })
  }

  applyFilter(filterValue: string) {

    // let searchResults: Team[] = []

    // let searchValue = filterValue.trim().toLowerCase()

    // if(this.allTeams && searchValue != ''){

    //   searchResults =  this.allTeams.filter(object => {
    //     let values: string = ''
    //       values += object.privateData.leaderEmail ? object.privateData.leaderEmail.toLocaleLowerCase() : ''
    //       values += object.privateData.leaderPhone ? object.privateData.leaderPhone.toLocaleLowerCase() : ''
    //       values += object.promoTeam ? object.promoTeam.toLocaleLowerCase() : ''

    //     object.anglers.forEach(angler => {
    //       for (const property in angler)
    //     {
    //       values += angler[property].toLocaleLowerCase()
    //     }
    //     })

    //     return values.indexOf(searchValue) != -1

    //   })


    // }
    //   else
    //   {
    //     searchResults = this.allTeams
    //   }

    //   this.assignDataToDataSource(searchResults)

  }

  openFishPhoto(photoUrl){

    if(!!photoUrl)
    {
      this.UIservice.openFishPhotoDialog(photoUrl)
    }

  }

  onFishDelete(fishID: string){
    let title = $localize`Fish Delete`
    let content = $localize`Are you sure to delete selected Fish ?`

    this.UIservice.openConfirmationDialog(title, content).subscribe(async result => {
    if(result)
    {
      const spinner = this.UIservice.openSpinnerDialog()
      await this.manageContest.deleteFish(fishID)
            .finally(() => { spinner.close()})
            .catch((e) => {
              let data = {
                title: $localize`Fish Delete Error`,
                content: e.message
            }
              this.UIservice.openInfoDialog(data.title, data.content,false)
              spinner.close()
            })
    }
  })

  }

  onFishEdit(fishID: string){

    let editFish = {...this.catchesById.filter(x => x.id === fishID)[0]}
    this.UIservice.openEditFishDialog(editFish, this.contestData.signedTeams$).subscribe(async result => {
      if (result)
      {
        const spinner = this.UIservice.openSpinnerDialog()
        const sendFish = {... editFish}
        sendFish.refereeID = this.authService.getCurrentUserUID()
        if(result.photoFile)
        {
          await this.contestData.uploadFishPhoto(result.photoFile)
          .then((photoLink) => {sendFish.photoUrl = photoLink})
          .finally(() => { sendFish.photoFile = null})

        }
        await this.contestData.editFish(sendFish)
              .then(() => { spinner.close() })
              .catch((e) => {
                let data = {
                  title: $localize`Add Fish Error`,
                  content: e.message
              }
                this.UIservice.openInfoDialog(data.title, data.content,false)
                spinner.close()
              })

      }
    })

  }
  onInstagramPublish(fishID: string){

    let title = $localize`Fish Instagram Publish`
    let content = $localize`Are you sure to publish selected Fish on Instagram ?`

    this.UIservice.openConfirmationDialog(title, content).subscribe(async result => {
      if(result)
      {
        const spinner = this.UIservice.openSpinnerDialog()
        await this.manageContest.instagramPublishPhoto(fishID).subscribe({
          error: (e) => {
            let data = {
              title: $localize`Fish Publish Error`,
              content: e.message
          }
            this.UIservice.openInfoDialog(data.title, data.content,false)
            spinner.close()
          },
          complete: () => spinner.close()
      })
      }
    })



  }

  ngOnDestroy(): void {
   this.dataSubscription.unsubscribe()
  }

}
