<div *ngIf="!modeEdit && !modeAdd; else editModeForm">
<mat-card class="contest-card">
    <mat-card-header>
    
      <mat-card-title>{{partner.name}}</mat-card-title>
      <mat-card-subtitle>
         <a style="text-decoration: none; color: inherit" target="_blank" [attr.href]="partner.link">{{partner.link}}</a>
      </mat-card-subtitle>
    </mat-card-header>
    <img mat-card-image [attr.src]="partner.logoLink" alt="{{partner.name}} Logo">
    <mat-card-content>
      <p>{{partner.description}}</p>
      <p>Priority: {{partner.priority}}</p>
    </mat-card-content>
    <mat-card-actions fxLayoutAlign="space-around center">
      <button mat-flat-button 
                        color="accent" 
                        i18n="Edit button| Edit Button@@"
                        (click)="onEdit()">Edit</button>
      <button mat-flat-button 
                        color="warn" 
                        i18n="Delete button| Delete Button@@"
                        (click)="onDelete()">Delete</button>
    </mat-card-actions>
  </mat-card> 

</div>


<ng-template #editModeForm>

    <mat-card class="contest-card">

        <form #p="ngForm">

        <mat-card-header>

            <mat-card-title style="width: 100%; text-align: center;">
                <label *ngIf="modeEdit" i18n="Partner Edit Header| Partner Edit Header in edit form@@">Edit Partner</label>
                <label *ngIf="modeAdd" i18n="Partner Add Header| Partner Add Header in add form@@">Add Partner</label>
            </mat-card-title>

          <mat-card-subtitle>

            <mat-form-field fxFlexFill appearance="outline">
                <mat-label i18n="Partner Name Label| Partner Name Label in edit form@@">Partner Name</mat-label>
                <input  matInput
                    name="PartnerName"
                    [(ngModel)]="formPartner.name"
                    required
                 >
                </mat-form-field>
            
            <mat-form-field fxFlexFill appearance="outline">
                <mat-label i18n="Partner Site Label| Partner Site Label in edit form@@">Partner Site</mat-label>
                <input  matInput
                    name="PartnerSite"
                    [(ngModel)]="formPartner.link"
                    required
                 >
                </mat-form-field>

          </mat-card-subtitle>

        </mat-card-header>

        <img *ngIf="formPartner.logoLink.length > 3" mat-card-image [attr.src]="formPartner.logoLink" alt="{{formPartner.name}} Logo">
        <img *ngIf="selectedLogo" mat-card-image [attr.src]="selectedLogo" alt="Selected Logo">
        <input hidden (change)="onFileSelected($event)" #logoInput type="file" accept="image/png, image/jpeg" id="logoFile">
        <mat-card-content>

            <mat-form-field fxFlexFill appearance="outline">
                <mat-label i18n="Partner Description Label| Partner Description Label in edit form@@">Description:</mat-label>
                <textarea matInput
                        type="text"
                        [(ngModel)]="formPartner.description"
                        name="Description"
                        cdkTextareaAutosize
                        cdkAutosizeMinRows="1"
                        cdkAutosizeMaxRows="10"
                ></textarea>
            </mat-form-field>

            <mat-form-field fxFlexFill appearance="outline">
                <mat-label i18n="Partner Priority Label| Partner Priority Label in edit form@@">Priority</mat-label>
                <input matInput
                        type="number"
                        [(ngModel)]="formPartner.priority"
                        name="PartnerPriority"
                        required
                >
            </mat-form-field>

        </mat-card-content>

    </form>
        <mat-card-actions fxLayoutAlign="space-around center">
           <button mat-flat-button 
                            color="accent"
                            i18n="Import Logo button| Import Logo button@@"
                            (click)="logoInput.click()">Import Logo</button>
          <button mat-flat-button 
                            *ngIf="(modeEdit && p.touched) || (modeAdd && p.touched && p.valid && fileSelected)" 
                            style="background-color:#23c268;" 
                            i18n="Save button| Save Button@@"
                            (click)="onSave()">Save</button>
          <button mat-flat-button
                            *ngIf="modeEdit"
                            color="warn"
                            i18n="cancel| cancel button@@"
                            (click)="onCancel()">Cancel</button>
        </mat-card-actions>
      </mat-card> 

</ng-template>
