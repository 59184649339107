<section fxFlex class="tarrif-form-section-free" fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="3vw">

    <div fxLayout="column">

        <h2 class="mat-h2" i18n="Premium Premium Info Header| Header for Premium Info@@">Premium Functions</h2>

        <ul fxLayout="column">

            <li>
                <p i18n="Benefits Charts| Paragraph Benefits Charts@@">
                    Charts
                </p>
            </li>

            <li>
                <p i18n="@@36886110102b6c509f0751cc5af36ba21e86dfce">
                    Reservations
                </p>
            </li>

            <li>
                <p i18n="Benefits Referees| Paragraph Benefits Referees@@">
                    Referees
                </p>
            </li>

            <li>
                <p i18n="Benefits Live Scores| Paragraph Benefits Live Scores@@">
                    Live Scores
                </p>
            </li>

            <li>
                <p i18n="Benefits Fish Photos| Paragraph Benefits Fish Photos@@">
                    Fish Photos
                </p>
            </li>
 
            <li>
                <p i18n="Benefits Advanced Facebook| Paragraph Benefits Facebook@@">
                    Advanced Facebook & Instagram posts
                </p>
            </li>

            <li>
                <p i18n="Benefits web support| Paragraph Benefits web support@@">
                    Carp Contest web support
                </p>
            </li>

        </ul>
<!-- 
        <h4 class="mat-h4" i18n="Premium Tariffs Info Header| Header for Tariffs Info@@">Tariffs:</h4>

        <a href="tarifs" i18n="Premium Tariffs document| Header for Tariffs document@@">Regulations and tariffs document</a> -->

    </div>

</section>