import { Component, OnInit } from '@angular/core';
import { UIService } from 'src/app/shared/service/ui.service';

@Component({
  selector: 'app-referee-how-it-works',
  templateUrl: './referee-how-it-works.component.html',
  styleUrls: ['./referee-how-it-works.component.css']
})
export class RefereeHowItWorksComponent implements OnInit {

  constructor(public UIservice: UIService) { }

  ngOnInit() {
  }

}
