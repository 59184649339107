import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { FirebaseService } from 'src/app/core-module/base/firebase.service';
import { Angler } from 'src/app/data/models/Manage/angler.model';


@Injectable()

export class AnglerService {


    private anglerCollection: string;
    teamsPath: string
    reservationPath:string

    constructor(private firebaseService: FirebaseService){
        // this.anglerCollection = challengeCollection + '/Anglers';
    }

    getAnglers(){
        return this.firebaseService.getCollection(this.anglerCollection).valueChanges()
    }

    updateTeamAngler(angler: Angler, teamID: string, anglerID: string){
        let anglersCollection = this.teamsPath + '/' + teamID + '/anglers/'

        return this.firebaseService.updateObjectByKey(anglersCollection, angler, anglerID)
    }

    updateReservationAngler(angler: Angler, teamID: string, anglerID: string){
        let anglersCollection = this.reservationPath + '/' + teamID + '/team/anglers/'

        return this.firebaseService.updateObjectByKey(anglersCollection, angler, anglerID)
    }

    deleteTeamAngler(teamKey: string, anglerKey: string, contestKey: string): Observable<any>{
        const deleteAction = this.firebaseService.callFirebaseFunction('deleteTeamMember')

        return deleteAction({teamKey: teamKey, teamMemberKey: anglerKey, contestKey: contestKey})
    }

    deleteReservationAngler(teamKey: string, anglerKey: string, contestKey: string){
        const deleteAction = this.firebaseService.callFirebaseFunction('deleteReservationMember')

        return deleteAction({teamKey: teamKey, teamMemberKey: anglerKey, contestKey: contestKey})
    }
}
