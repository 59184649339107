import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UIService } from 'src/app/shared/service/ui.service';
import * as fromRoot from 'src/app/presentation/app.reducer'
import * as App from 'src/app/shared/service/app.actions'
import * as UI from 'src/app/shared/service/ui.actions'
import { Store } from '@ngrx/store'

@Injectable()
export class ContestGuard implements CanActivate {
    
    constructor(private store: Store<fromRoot.State>,
                private router: Router,
                private uiService: UIService){

                }


   canActivate(route: ActivatedRouteSnapshot, 
                router: RouterStateSnapshot
   ): boolean | Promise<boolean> | Observable<boolean> {
    this.store.dispatch(new UI.StopLoadingChild)
    this.store.dispatch(new UI.StopLoadingMain)
    this.store.dispatch(new App.LoadContestState)
    return true
   }
}