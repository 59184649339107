<div style="margin-top: 2vh;" fxFlex="100%" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="2vh">

        
        <div style="width: 100% !important; min-height: 200px;" 
  
            fxLayoutAlign="center center"
            fxLayoutGap.lt-md="2vh"
            fxLayout="row wrap" 
            fxLayoutAlign.gt-md="center end" class="mat-title">
    
            <div style="min-height: 200px;"  fxFlex="1 0 100%" fxFlex.gt-md="50" fxLayout="column" fxLayoutAlign="center center">
                <div class="mat-h1" i18n="@@FishPerDay">Fish Per Day</div>
                
                <app-chart-bar-horizontal *ngIf="UIservice.isMobileXS; else fishPerDayPieChart" 
                                            style="width: 100%;"
                                        [chartData]="fishPerDay"
                                        [chartData$]="fishPerDayData.asObservable()">
                </app-chart-bar-horizontal>

                <ng-template #fishPerDayPieChart>
                
                <app-chart-pie-advanced style="width: 100%;"
                                        [chartData]="fishPerDay"
                                        [chartData$]="fishPerDayData.asObservable()">
                </app-chart-pie-advanced>

                </ng-template>
   
            </div>
               
        </div>
    
        <div style="width: 100% !important; min-height: 200px;" 
            fxLayout="column grow" 
            fxLayoutAlign="center center"
            fxLayoutGap="3vh"
            fxLayout.gt-md="row" 
            fxLayoutAlign.gt-md="center end" class="mat-title">

            <div style="width: 100% !important; min-height: 200px;" fxFlex.gt-md="50" fxLayout="column" fxLayoutAlign="center center">
                <div class="mat-h1" i18n="@@FishPerDay&Night">Fish Per Day & Night</div>

                <app-chart-bar-horizontal *ngIf="UIservice.isMobileXS; else fishPerDayNightPieChart"
                                    style="width: 100%; "
                                    [chartData]="fishPerDayNight"
                                    [chartData$]="fishPerDayNightData.asObservable()">
                </app-chart-bar-horizontal>

                <ng-template #fishPerDayNightPieChart>
                
                <app-chart-pie-advanced  style="width: 100%;"
                                        [chartData]="fishPerDayNight"
                                        [chartData$]="fishPerDayNightData.asObservable()">
                </app-chart-pie-advanced>

                </ng-template>
            </div>
            
            <div style="width: 100% !important; min-height: 200px;" fxFlex.gt-md="50" fxLayout="column" fxLayoutAlign="center center">
                <div class="mat-h1" i18n="@@FishByTime">Fish By Time</div>
                
                <app-chart-bar-horizontal *ngIf="UIservice.isMobileXS; else fishPerTimePieChart" 
                                        style="width: 100%;"
                                        [chartData]="fishPerTime"
                                        [chartData$]="fishPerTimeData.asObservable()">
                </app-chart-bar-horizontal>

                <ng-template #fishPerTimePieChart>

                <app-chart-pie-advanced  style="width: 100%;"
                                        [chartData]="fishPerTime"
                                        [chartData$]="fishPerTimeData.asObservable()">
                </app-chart-pie-advanced>

                </ng-template>
            </div>


        </div>
    
        <div style="width: 100% !important; min-height: 200px;" 
            fxLayout="column grow" 
            fxLayoutAlign="center center"
            fxLayoutGap.lt-md="2vh"
            fxLayout.gt-md="row" 
            fxLayoutAlign.gt-md="center end" class="mat-title">

            <div style="width: 100% !important; min-height: 200px;" fxFlex.gt-md="50" fxLayout="column" fxLayoutAlign="center center">
                <div class="mat-h1" i18n="@@FishByWeight">Fish By Weight</div>
                
                <app-chart-bar-horizontal *ngIf="UIservice.isMobileXS; else fishPerWeightCards"
                                         style="width: 100%;"
                                         [chartData]="fishPerWeight"
                                         [chartData$]="fishPerWeightData.asObservable()">
                </app-chart-bar-horizontal>

                <ng-template #fishPerWeightCards>
                
                     <app-number-cards  style="width: 100%;"
                                    [chartData]="fishPerWeight"
                                    [chartData$]="fishPerWeightData.asObservable()">
                     </app-number-cards>

                </ng-template>
            </div>
    

            <div style="width: 100% !important; min-height: 200px;" fxFlex.gt-md="50" fxLayout="column" fxLayoutAlign="center center">
                <div class="mat-h1" i18n="@@FishPerPeg">Fish Amount Per Peg</div>
                
                <app-chart-bar-horizontal *ngIf="UIservice.isMobileXS; else fishPerStationCards"
                                         style="width: 100%;"
                                         [chartData]="fishPerStation"
                                        [chartData$]="fishPerStationData.asObservable()">
                </app-chart-bar-horizontal>

                <ng-template #fishPerStationCards>
                
                    <app-number-cards style="width: 100%;"
                                        [chartData]="fishPerStation"
                                        [chartData$]="fishPerStationData.asObservable()">
                    </app-number-cards>
                </ng-template>
            </div>
            </div> 

            <div style="width: 100% !important; min-height: 200px;" 
            fxLayout="column grow" 
            fxLayoutAlign="center center"
            fxLayoutGap.lt-md="2vh"
            fxLayout.gt-md="row wrap" 
            fxLayoutAlign.gt-md="center end" class="mat-title">
            
            <div style="width: 100% !important; min-height: 300px;" fxFlexFill fxLayout="column" fxLayoutAlign="center center">
                <div class="mat-h1" i18n="@@FishPerPegsGroup">Fish/Weight by Pegs group</div>

               
                <div    *ngIf="UIservice.isMobileXS; else fishPerPegsCards"
                        [hidden]="!this.kgPerConcurentStations"  
                        style="width: 100%;">
                    <div fxFlexFill fxLayout="column" fxLayoutGap="1vh" >
                        <mat-accordion *ngFor="let element of this.kgPerConcurentStations; let i = index" style="width: 100%;">
                            <mat-expansion-panel>
                              <mat-expansion-panel-header fxLayout="row">  
                                <mat-panel-title fxFlex="40" >
                                 
                                 <label>Group: &nbsp;</label>
                                    {{i + 1}}

                                </mat-panel-title>
                                <mat-panel-description fxFlex="60">
 
                                {{element.value}}
  
                </mat-panel-description>
                              </mat-expansion-panel-header>

                              <div style="margin-top: 1vh">
                                <label style="font-weight: bold;">Pegs:&nbsp;</label>
                                {{element.name}}
                            </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                </div>

                <ng-template #fishPerPegsCards>
               
                    <app-number-cards style="width: 100%;"
                                    [chartData]="kgPerConcurentStations"
                                    [chartData$]="kgPerConcurentStationsData.asObservable()">
                    </app-number-cards>
    
                </ng-template>
            </div>
    </div>