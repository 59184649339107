import { Component, OnInit, Input } from '@angular/core';
import { Fee } from 'src/app/data/models/Manage/challenge.model';

@Component({
  selector: 'app-join-start',
  templateUrl: './join-start.component.html',
  styleUrls: ['./join-start.component.css']
})
export class JoinStartComponent implements OnInit {

  @Input() fee: Fee
  constructor() { }

  ngOnInit() {
  }

}
