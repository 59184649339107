import { Component, OnInit, Output, EventEmitter} from '@angular/core';

import { AuthService } from 'src/app/auth-module/service/auth.service';
import { ServeUserService } from 'src/app/auth-module/service/serveUser.service';


@Component({
  selector: 'app-sidebar-manage-profile-links',
  template: ` <mat-expansion-panel>
                
                <mat-expansion-panel-header>
                    
                    <mat-panel-title style="color:rgb(255,255,255, 0.8);">
                        <mat-icon style="padding: 0 2vw 0 0;">account_box</mat-icon>
                        <span i18n="Account| Account in Navigation@@">Account</span>
                    </mat-panel-title>
                
                </mat-expansion-panel-header>

                <a *ngIf="this.serveUser.isAdmin || this.serveUser.isOrganiser; else refereeData"
                    mat-list-item
                    role="navigation" 
                    routerLink="organiser/account"
                    (click)="onClose()"
                >
                    
                    <mat-icon class="expansion-panel-item-icon">build</mat-icon>
                    <span class="expansion-panel-item-caption" i18n="ProfileData| Profile Data in Navigation@@">Profile Data</span> 
                </a>

            <ng-template #refereeData>

                <a *ngIf="this.serveUser.isReferee"
                    mat-list-item
                    role="navigation" 
                    routerLink="referee/account"
                    (click)="onClose()"
                 >
                
                <mat-icon class="expansion-panel-item-icon">build</mat-icon>
                <span class="expansion-panel-item-caption" i18n="ProfileData| Profile Data in Navigation@@">Profile Data</span> 
                </a>

            </ng-template>

                <a *ngIf="this.serveUser.isAdmin || this.serveUser.isOrganiser"
                    mat-list-item
                    role="navigation"  
                    routerLink="organiser/balance"
                    (click)="onClose()"
                >

                    <mat-icon class="expansion-panel-item-icon">payment</mat-icon>
                    <span class="expansion-panel-item-caption" i18n="AccountBalance| Account Balance in Header@@">Account Balance</span>
                </a>
                
            </mat-expansion-panel>`,
    styleUrls: ['./sidenav-list.component.css']
})
export class SideBarManageProfileLinksComponent implements OnInit {
 
@Output() closeSidenav = new EventEmitter<void>()

  constructor(public authService: AuthService, public serveUser: ServeUserService) { 
  }

  ngOnInit() {
  }

  onClose(){
      this.closeSidenav.emit()
  }


}