<article  class="full-description-panel" fxFill fxLayout="column"  fxLayoutAlign="start center">

    <div fxLayout="column" fxLayoutAlign="start center">
        
        <h1 class="mat-display-2" i18n="@@Future"><strong>Future</strong></h1>
        
    </div>

    <div [ngClass.gt-xs]="'mat-title'" [ngClass.lt-md]="'mobile-font-style'" fxFill fxLayout.gt-xs-="row" fxLayoutAlign.gt-xs="center center" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="start center">

    <div class="description-panel" fxFlex fxLayout="column" fxLayoutAlign="start center" fxLayoutGap.lt-sm="5vh">

        <h2 class="mat-display-1" style="text-align: center;" i18n="@@May2021"><strong>May, 2021</strong></h2>

        <div fxLayout.lt-sm="column" fxLayoutAlign="start center">
         
            <div>
                <p i18n="@@SectorsChallenge">
                    New Contest type - SECTORS with Live Ranking calculation       
                </p>
            </div>
        </div>

        <div class="description-element" fxLayout.lt-sm="column" fxLayoutAlign="start center">
            <div>
                <p i18n="@@InstagramPost">
                    Instagram posts with fish catches, decorated with organiser Tags     
                </p>
            </div>
        </div>

        <h2 class="mat-display-1" style="text-align: center;" i18n="@@june2021"><strong>June, 2021</strong></h2>

        <div fxLayout.lt-sm="column" fxLayoutAlign="start center">

            <div>
                <p i18n="@@TeamWarningCards">
                      Team warning Cards - Referee will be able to set Yellow or Red card to the team
            </div>
        </div>

        <div class="description-element" fxLayout.lt-sm="column" fxLayoutAlign="start center">
        
            <div>
                <p i18n="@@teamCaptionPhoto">
                      Teams captions photo - Referee will be able to set Team Photo</p>
            </div>
        </div>

        <h2 class="mat-display-1" style="text-align: center;" i18n="@@july2021"><strong>July, 2021</strong></h2>

        <div class="description-element" fxLayout.lt-sm="column" fxLayoutAlign="start center">
     
            <div>
                <p i18n="@@teamsDocuments">
                    Organiser will be able to generate and print signed Teams list and Team catches document.       
                </p>
            </div>
        </div>

        <h2 class="mat-display-1" style="text-align: center;"><strong>2022</strong></h2>

        <div class="description-element" fxLayout.lt-sm="column" fxLayoutAlign="start center">
     
            <div>
                <p i18n="@@CarpContestPayments">
                   Carp Contest portal - fast web payments and Reservation payment confirmation.       
                </p>
            </div>
        </div>

    </div>

</div>


</article>