import { Component, OnInit, Input } from '@angular/core';
import { Challenge } from 'src/app/data/models/Manage/challenge.model';

@Component({
  selector: 'app-team-details',
  templateUrl: './team-details.component.html',
  styleUrls: ['./team-details.component.css']
})
export class TeamDetailsComponent implements OnInit {

  @Input() challenge: Challenge

  constructor() { }

  ngOnInit() {
  }

}
