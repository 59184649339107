import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ContestService } from '../services/contest.service';
import { ContestInfo } from '../model/contestInfo.model';
import { Subscription, Subject } from 'rxjs';
import { BaseLandingComponent } from '../baseLanding.component';
import { UIService } from 'src/app/shared/service/ui.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { AppDateAdapter, APP_DATE_FORMATS } from 'src/app/shared/helpers/format-datepicker';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-contest-lists',
  templateUrl: './contest-lists.component.html',
  styleUrls: ['./contest-lists.component.css'],
  providers: [ContestService,
    {provide: DateAdapter, useClass: AppDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS}]
})
export class ContestListsComponent extends BaseLandingComponent implements OnInit,OnDestroy {

  @ViewChild('dateForm') dateForm: NgForm
  @ViewChild('searchForm') searchForm: NgForm

  showRegions: boolean

  changeCountry: Subject<string> = new Subject()
  commingContests: Subject<ContestInfo[]> = new Subject()
  pedningContests: Subject<ContestInfo[]> = new Subject()
  finishedContests: Subject<ContestInfo[]> = new Subject()

  selectedCountry = 'all'
  isSelectedDate = false;

  allContests: ContestInfo[]
  searchResults: ContestInfo[]

  filters = []
  searchValue: string 

  commingCount: string
  pendingCount: string
  finishedCount: string

  

  contestsSubscription: Subscription

  constructor(public UIservice: UIService,
              private contestService: ContestService,
              private route: ActivatedRoute) { 
    super(UIservice);
    
    let _search = route.snapshot.params['searchValue'];
    

    this.contestsSubscription = this.contestService.challenges$
        .subscribe(data => 
          {
            this.allContests = data.filter(x => x.alias != 'Test')
            if(_search)
            {
              this.applySearch(_search)
              this.searchForm.controls['searchValue'].setValue(_search)
              this.searchForm.controls['searchValue'].markAsDirty()
            }
            else
            {
              this.passFilteredContestsByStatus(this.allContests)
            }
            
          })
    
  
    
  }
      
  ngOnInit() {
  }

  onClearFilters(){
    this.dateForm.reset()
    this.searchForm.reset()
    this.selectedCountry = 'all'
    this.showRegions = false
    this.filters = []
    if(this.allContests.length > 0)
    {
      this.passFilteredContestsByStatus(this.allContests)
    }
    
  }

  applySearch(search: string){
      
    this.searchResults = []
    let searchValue = search.trim().toLowerCase()

    if(this.allContests && searchValue != ''){

      this.searchResults =  this.allContests.filter(object => {
        
        let values: string = ''
        for (const property in object)
        {
          values += object[property]
        }

        return values.indexOf(searchValue) != -1

      })

      this.passFilteredContestsByStatus(this.searchResults)
    }

  }

  onSelectedCountry(event:MatCheckboxChange, _country: string): void{
      this.showRegions = event.source.value === 'all' ? false : true
      this.selectedCountry = event.source.value
      this.changeCountry.next(event.source.value)

      if(event.source.value && event.source.value != 'all' && this.allContests)
      {
        this.filters = this.filters.filter(f => f.name != 'country')
        let filter ={name: 'country', markers: []}
        filter.markers.push(_country)
        this.filters.push(filter)
        let filtered = this.applyFilters()

        this.passFilteredContestsByStatus(filtered)
      }
      else if(this.allContests){

        this.filters = this.filters.filter(f => f.name != 'country')
        this.passFilteredContestsByStatus(this.allContests)
      }
        
  }

  onSelectedRegions(_regions: string[]): void {

    const filtered: ContestInfo[] = []
    if(_regions.length > 0){
      
      let filter ={name: 'regions', markers: _regions}
      this.filters = this.filters.filter(f => f.name != 'regions')
      this.filters.push(filter)

      let filtered = this.applyFilters()

      this.passFilteredContestsByStatus(filtered)
    }
    else{
      this.filters = this.filters.filter(f => f.name != 'regions')
      let filtered = this.applyFilters()
      this.passFilteredContestsByStatus(filtered)
    }
    
  }

  onDateInput(range: string, event: MatDatepickerInputEvent<Date>): void{
    let choosenDate = event.value.getTime()
    let dateFilter = this.filters.filter(f => f.name === 'date')

    if(range === 'start'){
     
        if(dateFilter.length > 0){
          dateFilter.forEach(d => d.markers.startDate = choosenDate)
        }
        else
        {
          let filter ={name: 'date', markers: {startDate: choosenDate, endDate: 999999999999999999}}
          this.filters.push(filter)
        }
    }
    else
    {
 
         if(dateFilter.length > 0){
           dateFilter.forEach(d => d.markers.endDate = choosenDate)
         }
         else
         {
           let filter ={name: 'date', markers: {startDate: 1, endDate: choosenDate}}
           this.filters.push(filter)
         }
    }
    let filtered = this.applyFilters()

    this.passFilteredContestsByStatus(filtered)
  }

  applyFilters(): ContestInfo[]  {

    let _filtered: ContestInfo[] = []
    let byPlace: ContestInfo[] = []
    let byPlaceAndDate: ContestInfo[] = []
    let byDate: ContestInfo[] = []

    let regionFilter = this.filters.filter(a => a.name === 'regions')
    let countryFilter = this.filters.filter(a => a.name === 'country')
    let dateFilter = this.filters.filter(a => a.name === 'date')

    if(countryFilter.length > 0 && regionFilter.length > 0){
      
      let country: ContestInfo[] = []
      let regions: ContestInfo[] = []

      countryFilter.forEach(c => {
        
        c.markers.forEach(marker => {
            
          let filter = this.allContests.filter(a => a.country === marker)  
            
            if(filter.length > 0){
              filter.forEach(a => country.push(a))
          }
        })
      })

      regionFilter.forEach(r => {
        
        r.markers.forEach(marker => {
          let filter = country.filter(a => a.region === marker)
          
          if(filter.length > 0){
            filter.forEach(a => regions.push(a))
          }
        })
      })
      
      byPlace = regions
    }

    else if(countryFilter.length){

      let country: ContestInfo[] = []
      countryFilter.forEach(c => {
        
        c.markers.forEach(marker => {
            
          let filter = this.allContests.filter(a => a.country === marker)  
            
            if(filter.length > 0){
              filter.forEach(a => country.push(a))
          }
        })
      })
      byPlace = country
    }

    _filtered = byPlace

    if(countryFilter.length > 0 && dateFilter.length > 0){
      
      
      dateFilter.forEach(a => {
          
              let startDate = a.markers.startDate
              let endDate = a.markers.endDate

              let startFrom = byPlace.filter(a => {
                let _start = Date.parse(a.Data.startDate)
                return (_start >= startDate)
              })

              byPlaceAndDate = startFrom.filter(a => {
                let _end = Date.parse(a.Data.startDate)
                return (_end <= endDate)
              })
          })
      _filtered = byPlaceAndDate
    }
    else if(dateFilter.length > 0){
    
      dateFilter.forEach(a => {
          
        let startDate = a.markers.startDate
        let endDate = a.markers.endDate

        let startFrom = this.allContests.filter(a => {
          let _start = Date.parse(a.Data.startDate)
          return (_start >= startDate)
        })

        byDate = startFrom.filter(a => {
          let _end = Date.parse(a.Data.startDate)
          return (_end <= endDate)
        })
      })
      _filtered = byDate
    }
  
    return _filtered
  }

  passFilteredContestsByStatus(_data: ContestInfo[]): void {
    let comming = _data.filter(a => a.isComming === true)
    let pending = _data.filter(a => a.isLive === true)
    let archived = _data.filter(a => a.isArchived === true)

    this.commingCount= comming.length.toString()
    this.pendingCount = pending.length.toString()
    this.finishedCount = archived.length.toString()

    this.commingContests.next(comming) 
    this.pedningContests.next(pending)
    this.finishedContests.next(archived)
  }

  ngOnDestroy(){
    this.contestsSubscription.unsubscribe()
  }
}
