import { Component, OnInit,EventEmitter,Output } from '@angular/core';
import { UIService } from 'src/app/shared/service/ui.service';
import { AuthService } from 'src/app/auth-module/service/auth.service';
import { ServeUserService } from 'src/app/auth-module/service/serveUser.service';

declare var require: any;

@Component({
  selector: 'app-sidenav-list',
  templateUrl: './sidenav-list.component.html',
  styleUrls: ['./sidenav-list.component.css']
})
export class SidenavListComponent implements OnInit {
 @Output() closeSidenav = new EventEmitter<void>()
 
 public logo = "assets/logos/rsz_cucy21v3-min.png"
 

  constructor(public UIservice: UIService,
              public authService: AuthService,
              public serveUser: ServeUserService) { 
  }

  ngOnInit() {
  }

  onClose(){
    this.closeSidenav.emit();
  }

}
