<div class="content-container" fxLayout="column" fxLayoutAlign="start center" fxLayoutAlign.gt-sm="center center" fxLayoutGap="2vh">

    <h1 class="mat-h1" i18n="@@StartInThreeSteps">Start in three steps !</h1>
    
    <div fxLayout="column" fxFlex.lt-md fxLayoutGap="2vh">


        <div [ngClass]="{'positionCard': !UIservice.isMobileSM, 'smallPositionCard': UIservice.isMobileSM}">
            <div fxFlexFill fxLayoutAlign="center center">
                <button [disabled]="this.hasDataFilled"
                        fxFlexFill
                        mat-button
                        [routerLink]="[ 'account']"
                        i18n="@@FillRequiredData"
                >
                Fill required data
                </button>
            </div>
        </div>

        <div [ngClass]="{'positionCard': !UIservice.isMobileSM, 'smallPositionCard': UIservice.isMobileSM}">
            <div fxFlexFill fxLayoutAlign="center center">
                <button [disabled]="!this.hasDataFilled"
                        fxFlexFill 
                        mat-button
                        [routerLink]="[ 'add-contest']"
                        i18n="@@AddNewContest"
                >Add new contest</button>
            </div>
        </div>

        <div [ngClass]="{'positionCard': !UIservice.isMobileSM, 'smallPositionCard': UIservice.isMobileSM}">
            <div fxFlexFill fxLayoutAlign="center center">
                <button [disabled]="true"
                        fxFlexFill 
                        mat-button
                        i18n="@@InviteNewReferees"
                >
                Invite Referees
                </button>
            </div>
        </div>

    </div>

</div>