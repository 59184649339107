import { trigger, state, transition, animate, style } from "@angular/animations";

export const fullScreenImage = trigger('openClose', [
    state('void', 
        style({
        display: 'block', 
        transform: 'translateY(-100%)'})
        ),
    transition(':enter, :leave', [
        animate(500)
    ])
])