import { Component, OnInit } from '@angular/core';
import { BaseLandingComponent } from '../../../baseLanding.component';
import { UIService } from 'src/app/shared/service/ui.service';

@Component({
  selector: 'app-future',
  templateUrl: './future.component.html',
  styleUrls: ['./future.component.css']
})
export class FutureComponent extends BaseLandingComponent implements OnInit {

  constructor(public UIservice: UIService) {
    super(UIservice);
  }

  ngOnInit() {
  }

}
