import { Injectable } from "@angular/core";
import { FirebaseService } from 'src/app/core-module/base/firebase.service';
import { FishEntity } from '../entity/fish.model';
import { AngularFireList } from '@angular/fire/compat/database';
import { Fish } from "src/app/data/models/Manage/fish.model";


@Injectable()
export class FishService {


    basePathReference: string;

    constructor(private firebaseService: FirebaseService){
    }

    getFish(): AngularFireList<Fish | any>{
        return this.firebaseService.getCollection(this.basePathReference)
    }
}
