import { Injectable } from "@angular/core";
import { FirebaseService } from '../base/firebase.service';
import { ChallengeEntity } from '../Entity/challenge.model';
import { AngularFireObject, AngularFireList} from '@angular/fire/compat/database';
import { Referee } from "src/app/data/models/Manage/referee.model";
import { Challenge } from "src/app/data/models/Manage/challenge.model";



@Injectable()
export class ChallengeService{

    basePathReference: string = null;
    refereesPath: string = null;

    constructor(private firebaseService: FirebaseService, ){

    }

    getChallenge(): AngularFireObject<any>{

        return this.firebaseService.getObject(this.basePathReference)
    }

    getReferees(): AngularFireList<Referee | any>{
        return this.firebaseService.getObjectList(this.refereesPath)
    }

    getChallengeKey(alias: string) {

        return this.firebaseService.getObjectRef('ChallengesInfo/'+ alias, 'key')
    }

    getAllChallenges(): AngularFireList<any>{

        return this.firebaseService.getCollection('ChallengesInfo')
    }

    getActiveChallenges(): AngularFireList<any>{

      return this.firebaseService.getObjectListByProperty('ChallengesInfo', 'isActive', true)
  }
}
