<mat-toolbar>
        <div *ngIf="UIservice.isMobileSM" fxLayout="row" fxFill fxLayoutAlign="space-between center">
          <button mat-icon-button role="directory" (click)="onToggleSidenav()">
            <mat-icon>menu</mat-icon>
          </button>
          <div *ngIf="UIservice.isContest | async" class="header-challenge-name">{{this.UIservice.challengeName}}</div>
        </div>
    
        <ng-container *ngIf="{contest: UIservice.isContest | async, 
                              main: UIservice.isMain | async,
                              organiser: UIservice.isOrganiser | async,
                              referee: UIservice.isReferee | async} as app">
        
          <ul *ngIf="app.contest && !UIservice.isMobileSM" class="navigation-items" fxFlexFill fxLayout="row" fxLayoutGap="2vh"  fxLayoutAlign="center center">
            
            <li>
              <button 
              role="navigation" 
              mat-button 
              (click)="contestOnButtonClick('')"
              >
                <span i18n="@@b37a5ab5c59ac41cbfe668200f4768af7a28d81f">Home</span>
                 &nbsp;
                <mat-icon>home</mat-icon>
              </button>
            </li>

            <li>
              <a *ngIf="this.UIservice.rulesLink"
                      role="navigation"
                      mat-button 
                      target="_blank" 
                      [attr.href]="this.UIservice.rulesLink">
                <span i18n="@@4646d1c2f63ab603c78a10b414acd28a4ab07964">Rules</span>
                &nbsp;
                <mat-icon>menu_book</mat-icon>
            </a>
            </li>

            <li>
              <button *ngIf="this.UIservice.isTeamsAvailable && this.UIservice.isPremium"
                  role="navigation"
                  mat-button 
                  (click)="contestOnButtonClick('/teams')">
                  <span i18n="Teams|Teams button in header@@">Teams</span>
                  &nbsp;
                  <mat-icon>people_alt</mat-icon>
                </button>
              </li>

              <li>
                <button *ngIf="this.UIservice.isPremium && this.UIservice.isLive"
                    role="navigation"
                    mat-button 
                    (click)="contestOnButtonClick('/referees')">
                    <span i18n="@@be1989cd4e117d9200cc7e8c6cda5a19caaad20a">Referees</span>
                    &nbsp;
                    <mat-icon>people_alt</mat-icon>
                  </button>
                </li>

              <li>
                <button *ngIf="this.UIservice.isSingUpAvailable && this.UIservice.isPremium" 
                        role="navigation"
                        mat-button 
                        class="sign-up-button"
                        (click)="contestOnButtonClick('/sign-me')">
                  <span i18n="@@d0b5af36666fde27a71b18ea9330829656a39d8b">SIGN UP</span> 
                  &nbsp;
                  <mat-icon>how_to_reg</mat-icon>
                  </button>
              </li>

            <li>
              <button *ngIf="this.UIservice.isTeamsAvailable && this.UIservice.isPremium"
                  role="navigation"
                  class="sign-up-button"
                  mat-button 
                  (click)="contestOnButtonClick('/drawing')">
                  <span i18n="@@Drawing">Drawing</span>
                  &nbsp;
                  <mat-icon>loop</mat-icon>
                </button>
              </li>

            <li>
              <button *ngIf="this.UIservice.isLive && this.UIservice.isPremium"
                      role="navigation" 
                      class="live-button" 
                      mat-button 
                      (click)="contestOnButtonClick('/live')"
                      >
              <span>LIVE</span>
              &nbsp;
              <mat-icon>play_circle_outline</mat-icon>
              </button>
            </li>

            

            

          </ul>  
          
        <div *ngIf="app.main && !UIservice.isMobileSM" fxFlexFill fxLayout="row">

          <app-header-home-link fxFlex="33" fxFlex.sm="10" fxFlex.md="20" fxLayoutAlign="start center"></app-header-home-link>

            <ul fxFlex="33" fxFlex.sm="56" fxFlex.md="47" fxLayout="row" class="navigation-items" fxLayoutAlign="space-around center">
              
              <li>
                  
                <button [matMenuTriggerFor]="infocompetitorMenu"
                        mat-button
                        aria-label="Info menu for competitors"
                     
                 >
                <mat-icon>expand_more</mat-icon>
                <span i18n="Competitor|Competitor in header@@">Competitor</span>
                  </button>

                <mat-menu #infocompetitorMenu="matMenu" fxLayout="column">

          <button mat-menu-item
          role="navigation" 
          routerLink="main/info/competitor">
          <mat-icon>build</mat-icon>
          <span i18n="@@48d83874826df3e9c7ff1e807cd3ff376388b022">How it works ?</span>  
          </button>

          <button mat-menu-item
          role="navigation" 
          routerLink="main/info/competitor"
          fragment ="competitorData">
          <mat-icon>cloud_queue</mat-icon>
          <span i18n="WhatAboutMyData|What About My Data header@@">What about my data ?</span>
          </button>

                </mat-menu>
              </li> 

              <li>

              <button [matMenuTriggerFor]="infoRefereesMenu"
                        mat-button
                        aria-label="Info menu for referees"
                        
                  >
                  <mat-icon>expand_more</mat-icon>
                  <span i18n="@@Referee">Referee</span>
                </button>

                <mat-menu #infoRefereesMenu="matMenu" fxLayout="column">

                  <button mat-menu-item
                  role="navigation" 
                  routerLink="main/info/referee">
                  <mat-icon>build</mat-icon>
                  <span i18n="@@48d83874826df3e9c7ff1e807cd3ff376388b022">How it works ?</span>  
                  </button>

                  <button mat-menu-item
                  role="navigation"  
                  routerLink="main/info/referee"
                  fragment ="requirements">
                  <mat-icon>phonelink_setup</mat-icon>
                  <span i18n="Requirements|Requirements in header@@">Requirements </span> 
                  </button>

                  <button mat-menu-item
                  *ngIf="this.serveUser.isReferee"
                  style="background: rgba(9, 156, 4, 0.4);"
                  role="navigation" 
                  routerLink="referee">
                  <mat-icon>important_devices</mat-icon>
                  <span i18n="OpenConsole|Open Console in header@@">Open Console</span>  
                  </button>

                </mat-menu>
       
              </li>
              
              <li>
  
                <button [matMenuTriggerFor]="infoOrganisersMenu"
                        mat-button
                        aria-label="Info menu for organisers"

                  >
                  <mat-icon>expand_more</mat-icon>
                  <span i18n="48fedddf15c7e0d0282c46451c6e004aa38af6fb">Organiser</span>
                </button>

                <mat-menu #infoOrganisersMenu="matMenu" fxLayout="column">

                  <button mat-menu-item
                  role="navigation" 
                  routerLink="main/info/organiser">
                  <mat-icon>build</mat-icon>
                  <span i18n="@@48d83874826df3e9c7ff1e807cd3ff376388b022">How it works ?</span> 
                  </button>

                  <button mat-menu-item
                  role="navigation"  
                  routerLink="main/info/organiser/benefits">
                  <mat-icon>emoji_events</mat-icon>
                  <span i18n="Benefits|Benefits in header@@">Benefits</span> 
                  </button>

                  <button mat-menu-item
                        role="navigation" 
                        routerLink="main/info/organiser/future">
                  <mat-icon>trending_up</mat-icon>
                  <span i18n="Future| Future in header@@">Future</span>
                  </button>

                  <button mat-menu-item
                  role="navigation"
                  routerLink="main/info/organiser/pricing">
                  <mat-icon>receipt</mat-icon>
                  <span i18n="Pricing|Pricing in header@@">Pricing</span>
                  </button>

                  <button mat-menu-item
                  *ngIf="this.serveUser.isOrganiser"
                  style="background: rgba(9, 156, 4, 0.4);"
                  role="navigation" 
                  routerLink="organiser">
                  <mat-icon>important_devices</mat-icon>
                  <span i18n="OpenConsole|Open Console in header@@">Open Console</span>  
                  </button>

                </mat-menu>
  
              </li>

              

            </ul>

            <app-header-auth-links fxFlex="33" fxLayoutAlign="center center" ></app-header-auth-links>

        </div>

        <div *ngIf="app.organiser && !UIservice.isMobileSM" fxFlexFill fxLayout="row">

          <app-header-home-link fxFlex="33" fxFlex.sm="10" fxFlex.md="20" fxLayoutAlign="start center"></app-header-home-link>

            <ul fxFlex="33" fxFlex.sm="56" fxFlex.md="47" fxLayout="row" class="navigation-items" fxLayoutAlign="space-around center">
              
              <li>
                <app-header-manage-profile-links></app-header-manage-profile-links>
              </li>
              
              <li>
                <app-header-manage-contests-links></app-header-manage-contests-links>
              </li>

              <li>
                <app-header-manage-referees-links></app-header-manage-referees-links>
              </li>
            </ul>

            <app-header-auth-links fxFlex="33" fxLayoutAlign="center center"></app-header-auth-links>


        </div>

        <div *ngIf="app.referee && !UIservice.isMobileSM" fxFlexFill fxLayout="row">

          <app-header-home-link fxFlex="33" fxFlex.sm="10" fxFlex.md="20" fxLayoutAlign="start center"></app-header-home-link>

            <ul fxFlex="33" fxFlex.sm="56" fxFlex.md="47" fxLayout="row" class="navigation-items" fxLayoutAlign="space-around center">
              
              <li>
                <app-header-manage-profile-links></app-header-manage-profile-links>
              </li>
              
              <!-- <li>
                <app-header-manage-contests-links></app-header-manage-contests-links>
              </li>

              <li>
                <app-header-manage-referees-links></app-header-manage-referees-links>
              </li> -->
            </ul>

            <app-header-auth-links fxFlex="33" fxLayoutAlign="center center"></app-header-auth-links>


        </div>

      </ng-container>
      </mat-toolbar>