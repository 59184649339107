import { Contest } from 'src/app/data/models/Manage/contest.model';
import { Injectable } from '@angular/core';
import { FirebaseService } from '../base/firebase.service';
import { AngularFireObject, AngularFireList } from '@angular/fire/compat/database';
import { ChallengeService } from './challange.service';
import { PartnerService } from './partner.service';
import { TeamService } from './team.service';
import { Partner } from 'src/app/data/models/Manage/partner.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Team } from 'src/app/data/models/Manage/team.model';
import { Reservation } from 'src/app/data/models/Manage/reservation.model';
import { Challenge } from 'src/app/data/models/Manage/challenge.model';
import { Message } from 'src/app/data/models/Manage/message.model';
import { MessageService } from './message.service';
import { FireStoreService } from '../base/firestore.service';

import { RefereeService } from './referee.service';
import { Referee } from 'src/app/data/models/Manage/referee.model';
import { FishService } from './fish.service';
import { Fish } from 'src/app/data/models/Manage/fish.model';



@Injectable()
export class ContestService{

    basePathReference: string = null;
    contestKey: string = null

    constructor(private firebaseService: FirebaseService,
                private firestorageService: FireStoreService,
                private challengeService: ChallengeService,
                private messageService: MessageService,
                private partnerService: PartnerService,
                private refereeService: RefereeService,
                private fishService: FishService,
                private teamService: TeamService){

    }

    getContest(): AngularFireObject<Contest | any>{

        return this.firebaseService.getObject(this.basePathReference)
    }

    getContestKey(alias: string) {

        return this.firebaseService.getObjectRef('ChallengesInfo/'+ alias, 'key')
    }

    getChallenge(): Observable<Challenge>{
        this.challengeService.basePathReference = this.basePathReference + '/' + 'challenge'

        return this.challengeService.getChallenge().snapshotChanges()
                    .pipe<Challenge>(map(fireObject => {
                        return {
                            id: this.contestKey,
                            ...fireObject.payload.val()
                        }
                    }))
    }

    getAllContests(): AngularFireList<any>{

        return this.firebaseService.getCollection('ChallengesInfo')
    }

    getMessages(): Observable<Message[]>{
        this.messageService.basePathReference = this.basePathReference + '/' + 'messages'

        return this.messageService.getMessages().snapshotChanges()
                    .pipe<Message[]>(map(docArray => {
                        return docArray.map(doc => {
                            return {
                                id: doc.key,
                                ...doc.payload.val()
                            }
                        })
                    }))
    }

    getCatches(): Observable<Fish[]>{
        this.fishService.basePathReference = this.basePathReference + '/' + 'catches'

        return this.fishService.getFish().snapshotChanges()
                .pipe<Fish[]>(map(docArray => {
                    return docArray.map(doc => {
                        return {
                            id: doc.key,
                            ...doc.payload.val()
                        }
                    })
                }))

    }

    getContestPartners(): Observable<Partner[]>{

        this.partnerService.basePathReference = this.basePathReference + '/' + 'partners';

        return this.partnerService.getContestPartners().snapshotChanges()
                        .pipe<Partner[]>(map(docArray => {
                                return docArray.map(doc => {
                                        return {
                                                id: doc.key,
                                                ...doc.payload.val()
                                                }
                                            })
                                }))
    }

    getSignedTeams(): Observable<Team[]>{

        this.teamService.basePathReference = this.basePathReference + '/' + 'teams/private/';

        return this.teamService.getTeams()
            .snapshotChanges()
            .pipe<Team[]>(map(docArray => {
                return docArray.map(doc => {
                    return {
                        id: doc.key,
                        ...doc.payload.val()
                    }
                })
            }))

    }

    getReservationTeams(): Observable<Reservation[]>{

        this.teamService.reservationPath = this.basePathReference + '/' + 'reservations/private'

        return this.teamService.getReservationTeams()
            .snapshotChanges()
            .pipe<Reservation[]>(map(docArray => {
                return docArray.map(doc => {
                    return {
                        id: doc.key,
                        ...doc.payload.val()
                    }
                })
            }))

    }

    getReserveTeams(): Observable<Reservation[]>{

        this.teamService.reservationPath = this.basePathReference + '/' + 'reservations/private'

        return this.teamService.getReserveTeams()
            .snapshotChanges()
            .pipe<Reservation[]>(map(docArray => {
                return docArray.map(doc => {
                    return {
                        id: doc.key,
                        ...doc.payload.val()
                    }
                })
            }))

    }

    getRefereesConfirmed(): Observable<Referee[]>{

        this.refereeService.refereesConfirmedCollection = this.basePathReference + '/' + 'referees/private/'

        return this.refereeService.getRefereesConfirmed()
            .snapshotChanges()
            .pipe<Referee[]>(map(docArray => {
                return docArray.map(doc => {
                    return {
                        id: doc.key,
                        ...doc.payload.val()
                    }
                })
            }))

    }

    getRefereesReservation(): Observable<Referee[]>{
        this.refereeService.refereesReservationPath = this.basePathReference + '/' + 'refereesReservation'

        return this.refereeService.getRefereesReservation()
            .snapshotChanges()
            .pipe<Referee[]>(map(docArray => {
                return docArray.map(doc => {
                    return {
                        id: doc.key,
                        ...doc.payload.val()
                    }
                })
            }))
    }

    setNewChallenge(_challenge: Challenge, _alias: string, _userUID: string){

        const confirmAction = this.callWebFunction('createChallenge')

        return confirmAction({challenge : _challenge, userID: _userUID})
    }

    setNewAlias(_alias: string, _challengeInfo: object){

        return this.firebaseService.getDBRef('ChallengesInfo').child(_alias).set(_challengeInfo)

    }

    setObject(_key: string, _collection: string, object: object){
        const path = 'Challenges/' + _key + '/' + _collection
        return this.firebaseService.setNewObject(path, object)
    }

    updateCollectionObject(_challengeKey: string, _collection: string, _docKey: string, object: object){
        const path = 'Challenges/' + _challengeKey + '/' + _collection

        return this.firebaseService.updateObjectByKey(path, object,_docKey)
    }

    setNewUserOrganiserContest(_userUID: string, _hashKey: string, _challengeName: string)
    {
        const newChallenge = {
            'key': _hashKey,
            'name': _challengeName
        }


        return this.firebaseService.getDBRef('Users/' + _userUID + '/roles/organiser').child(_hashKey).set(newChallenge)
    }

    updateContestMainDetails(_key: string, mainDetials: object){

        var challengeRef = this.firebaseService.getDBRef('Challenges/'+_key+'/challenge')

        var updates = {};

        for (const element in mainDetials) {
            if (mainDetials.hasOwnProperty(element)) {

                if(mainDetials[element])
                {
                    updates[element] = mainDetials[element]
                }
                else
                {
                    updates[element] = null
                }
            }
        }

        return challengeRef.update(updates)


    }

    updateContestChild(_key: string, _dbChildName: string, _childValue: object, hasNestedChilds: boolean ){
        var challengeRef = this.firebaseService.getDBRef('Challenges/'+_key+'/challenge/' + _dbChildName)

        for (const element in _childValue){
            if (_childValue.hasOwnProperty(element))
            {
                if(hasNestedChilds)
                {
                    for (const nestedEl in _childValue[element])
                    {
                        if (!_childValue[element][nestedEl])
                        {
                            _childValue[element][nestedEl] = null
                        }
                    }
                }

                if(!_childValue[element])
                {
                    _childValue[element] = null
                }
            }
        }
        return challengeRef.update(_childValue)
    }

    removeObject(_key: string, _collecion: string, _docKey: string){

        const fullPath = "Challenges/" + _key + '/' + _collecion

        return this.firebaseService.deleteObject(fullPath, _docKey)
    }

    uploadFile(_file: File, collection: string){

        return this.firestorageService.uploadFile(_file, collection)

    }

    getDownloadURL(fileName: string, collection: string){
        return this.firestorageService.getDownloadURl(fileName, collection)
    }

    callWebFunction(name: string){
       return this.firebaseService.callFirebaseFunction(name)
    }

    updateObject(path: string, newObject: any){
        return this.firebaseService.getDBRef(path).set(newObject)
    }

    instagramPublishPhoto(catchKey: string, challengeKey: string){
        const publishAction = this.firebaseService.callFirebaseFunction('instagram_publish_catchPhoto')

        return publishAction({contestKey: challengeKey, catchKey: catchKey})
    }





}
