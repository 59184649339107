import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './main/main.component';
import { OrganiserGuard } from 'src/app/auth-module/guards/organiser-guard';
import { ContestComponent } from './contest/contest.component';
import { AddContestComponent } from './contest/add-contest/add-contest.component';
import { ProfileDataComponent } from './account/profile-data/profile-data.component';
import { BalanceComponent } from './account/balance/balance.component';


const routes: Routes = [
  {path: 'organiser', canActivate: [OrganiserGuard], children: [
    {path: 'add-contest', component: AddContestComponent},
    {path: 'account', component: ProfileDataComponent},
    {path: 'account-balance', component: BalanceComponent},
    {path: 'referees-list', component: MainComponent},
    {path: 'manage-referee', component: MainComponent},
    {path: 'invite-referee', component: MainComponent},
    {path: 'contests-list', component: MainComponent},
    {path: 'contest', children: [
      {path: ':key', component: ContestComponent}
    ]},
    {path: '',  component: MainComponent},
    {path: '**', redirectTo: 'organiser'}
  ]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
