<div class="content-container" fxLayout="row" fxLayoutAlign="center center" >

    <div fxFlex="70" fxLayout="column" fxLayoutGap="5vh" fxLayoutAlign="center center">
            
        <div fxLayout="column" class="content-container row">
            <div style="width: 100%; text-align: center;">
                <h3 class="mat-h3" i18n="contestSignUpActions |contest SignUp Actions header@@">Sign Up Actions</h3>
            </div>

            <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="3vw">

                <div fxFlex="50" fxLayout="row" fxLayout.lt-sm="column" class="action-container" fxLayoutGap="1vw">
                <div  fxLayout="column">
                        
                    <button
                    mat-raised-button color="accent" 
                    (click)="onOpenSignUp()"
                    i18n="OpenSignUp |Open SignUp Button@@"
                    >
                     Open Sign Up
                    </button>

                </div>

                <div fxLayout="column" fxFlex   fxLayoutAlign="center start" >
                    <p i18n="OpenSignupInfoText |Open Signup Info Text@@">
                        Allow users join to contest
                    </p>
                    
                </div>
                </div>

                <div fxFlex="50" fxLayout="row" fxLayout.lt-sm="column" class="action-container" fxLayoutGap="1vw">
                <div fxLayout="column">
                        
                    <button
                    mat-raised-button color="accent" 
                    (click)="onCloseSignUp()"
                    i18n="CloseSignUp |Close Sign Up Button@@"
                    >
                     Close Sign Up
                    </button>

                </div>

                <div fxLayout="column" fxFlex fxLayoutAlign="center start" >
                    <p i18n="CloseSignupInfoText |Close Signup Info Text@@">
                        Deny users join to contest
                    </p>
                </div>
                </div>

            </div>

        </div>

        <div fxLayout="column" class="content-container row">
            <div style="width: 100%; text-align: center;">
                <h3 class="mat-h3" i18n="@@FishAndWarningFunctions">Catch and Warning Actions</h3>
            </div>

            <div fxLayout="row"  fxLayout.lt-sm="column" fxLayoutGap="3vw">

                <div fxFlex="50" fxLayout="row" fxLayout.lt-sm="column" class="action-container" fxLayoutGap="1vw">
                <div fxLayout="column"> 
                    <button
                    mat-raised-button color="accent" 
                    (click)="openFishDialog()"
                    i18n="@@AddNewCatch"
                    >
                     Add new Catch
                    </button>

                </div>

                <div fxLayout="column" fxFlex   fxLayoutAlign="center start" >
                    <p i18n="@@AddNewCatchToTeam">
                        Add new Catch to Team
                    </p>
                    
                </div>
                </div>

                <div fxFlex="50" fxLayout="row" fxLayout.lt-sm="column" class="action-container" fxLayoutGap="1vw">
                <div fxLayout="column">
                        
                    <button
                    mat-raised-button color="accent" 
                    (click)="onAddWarningCard()"

                    i18n="@@AddWarningCard"
                    >
                     Add Warning Card
                    </button>

                </div>

                <div fxLayout="column" fxFlex   fxLayoutAlign="center start" >
                    <p i18n="@@AddWarningCardToTeam">
                        Add yellow or red warning card to the Team
                    </p>
                </div>
                </div>

            </div>

        </div>

        <div *ngIf="!creationMode; else creationModule" fxLayout="column" class="content-container row">

            <div style="width: 100%; text-align: center;">
                <h3 class="mat-h3" i18n="teamsActions |teams Actions header@@">Teams Actions</h3>
            </div>

            <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="3vw">
                <div fxLayout="row" fxFlex="50" fxLayout.lt-sm="column" class="action-container" fxLayoutGap="1vw">
                <div fxLayout="column">

                    <button
                    mat-raised-button color="accent" 
                    (click)="onAddTeam()"
                    i18n="AddTeam|Add Team Button@@"
                >
                Add Team
                </button>

                </div>

                <div fxLayout="column" fxFlex  fxLayoutAlign="center start">
                    <p i18n="AddTeamWithoutReservation |Add Team Without Reservation Info Text@@">
                        Add team without reservation
                    </p>
                </div>
                </div>

                <div fxLayout="row" fxFlex="50" fxLayout.lt-sm="column" class="action-container" fxLayoutGap="1vw">
                <div fxLayout="column">
                       
                    <button
                    mat-raised-button color="accent" 
                    (click)="onAddReservationTeam()"
                    i18n="AddResevationTeam|Add Resevation Team Button@@"
                    >
                    Add Resevation Team
                    </button>

                </div>

                <div fxLayout="column" fxFlex   fxLayoutAlign="center start">
                    <p i18n="AddTeamToReservation |Add Team to Reservations Info Text@@">
                        Add team to reservations
                    </p>
                </div>
                </div>
            </div>  
            
        </div>

        <ng-template #creationModule>
            <app-create-team [createType]="this.teamCreateType" (endCreation)="creationMode=$event"></app-create-team>
        </ng-template>
        
        <div  fxLayout="column" class="content-container row">

            <div style="width: 100%; text-align: center;">
                <h3 class="mat-h3" i18n="@@Drawing">Drawing</h3>
            </div>

            <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="3vw">
                <div fxLayout="row" fxFlex="50" fxLayout.lt-sm="column" class="action-container" fxLayoutGap="1vw">
                <div fxLayout="column">

                    <button
                    mat-raised-button color="accent" 
                    (click)="onDrawSequence()"
                    i18n="@@StartDrawSequence"
                >
                Draw Sequence
                </button>

                </div>

                <div fxLayout="column" fxFlex   fxLayoutAlign="center start">
                    <p i18n="@@DrawSequenceDescription">
                        Start drawing sequence for swim drawing
                    </p>
                </div>
                </div>

                <div fxLayout="row" fxFlex="50" fxLayout.lt-sm="column" class="action-container" fxLayoutGap="1vw">
                <div fxLayout="column">
                       
                    <button
                    mat-raised-button color="accent" 
                    (click)="onDrawSwim()"
                    i18n="@@drawSwim"
                    >
                    Draw Swim
                    </button>

                </div>

                <div fxLayout="column" fxFlex   fxLayoutAlign="center start">
                    <p i18n="@@StartDrawingSwimDescription">
                        Start drawing swim for teams
                    </p>
                </div>
                </div>
            </div>  
            
        </div>

        <div fxLayout="column" class="content-container row">

            <div style="width: 100%; text-align: center;">
                <h3 class="mat-h3" i18n="anonymusUserData |contest anonymus UserData header@@">User Data Actions</h3>
            </div>

            <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="3vw">
                <div fxLayout="row" fxFlex="50" fxLayout.lt-sm="column" class="action-container" fxLayoutGap="1vw">
                <div fxLayout="column">

                    <button
                    mat-raised-button color="accent" 
                    (click)="onAnonymusReferee()"
                    i18n="AnonymizationRefrees|Anonymization Refrees Button@@"
                >
                Anonymization of Referes
                </button>

                </div>

                <div fxLayout="column" fxFlex   fxLayoutAlign="center start">
                    <p i18n="AnonymizationRefreesInfo |Anonymization Refrees Info Text@@">
                        Anonymize Referees data i.e. Jack Sparrow -> Jack S******
                    </p>
                </div>
                </div>

                <div fxLayout="row" fxFlex="50" fxLayout.lt-sm="column" class="action-container" fxLayoutGap="1vw">
                <div fxLayout="column" >
                       
                    <button
                    mat-raised-button color="accent" 
                    (click)="onAnonymusAnglers()"
                    i18n="AnonymizationAnglers|Anonymization Anglers Button@@"
                    >
                     Anonymization of Anglers
                    </button>

                </div>

                <div fxLayout="column" fxFlex fxLayoutAlign="center start">
                    <p i18n="AnonymizationAnglersInfo |Anonymization Anglers Info Text@@">
                        Anonymize Anglers data i.e. Jack Sparrow -> Jack S******
                    </p>
                </div>
                </div>
            </div>  

        </div>
        

    </div>
</div>