<article  class="full-description-panel" fxFill fxLayout="column"  fxLayoutAlign="start center">

  <div fxLayout="column" fxLayoutAlign="start center">
      
      <h1 class="mat-display-2" i18n="@@IAmOrganiser"><strong>Organiser</strong></h1>

      <h2 class="mat-display-2" i18n="@@48d83874826df3e9c7ff1e807cd3ff376388b022">How it works ?</h2>
      
  </div>

  <div [ngClass.gt-xs]="'mat-title'" [ngClass.lt-md]="'mobile-font-style'" fxFill style="padding: 5vh 5vw;" fxLayoutGap="5vw" fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="center start" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="start center">

  <div class="description-panel" fxFlex fxLayout.lt-sm="column" fxLayoutGap.lt-sm="5vh">

      <h2 class="mat-display-1" style="text-align: center;" i18n="@@Registration"><strong>Registration</strong></h2>

      <div [ngClass.gt-sm]="'description-element'" fxLayout.lt-sm="column" fxLayoutAlign="start center" fxFlexOrder="1">
          <a routerLink="/main/signup" class="workflow-element" [ngClass]="{'icon-mobile-sm':  UIservice.isMobileSM, 'icon-desktop': !UIservice.isMobileSM}" fxLayoutAlign="center center">
              <mat-icon [inline]="true" aria-hidden="true">how_to_reg</mat-icon>            
          </a>
      
          <div>
              <p i18n="@@CreateYourRefereeAccount">
                    Use <strong>Sign Up</strong> form and create your account.<br>
                    Account can be shared between many Contests.</p>
          </div>
      </div>

      <div [ngClass.gt-sm]="'description-element'" fxLayout.lt-sm="column" fxLayoutAlign="start center" fxFlexOrder="2">
          <div class="workflow-element" [ngClass]="{'icon-mobile-sm':  UIservice.isMobileSM, 'icon-desktop': !UIservice.isMobileSM}" fxLayoutAlign="center center">
              <mat-icon [inline]="true" aria-hidden="true">face</mat-icon>            
          </div>
      
          <div>
              <p i18n="@@OrganiserChooseYourRole">
                    You will be asked to <strong>define</strong> your <strong>role</strong><br>
                    Choose <strong>Organiser</strong> option.
          </div>
      </div>

      <div [ngClass.gt-sm]="'description-element'" fxLayout.lt-sm="column" fxLayoutAlign="start center" fxFlexOrder="3">
          <div class="workflow-element" [ngClass]="{'icon-mobile-sm':  UIservice.isMobileSM, 'icon-desktop': !UIservice.isMobileSM}" fxLayoutAlign="center center">
              <mat-icon [inline]="true" aria-hidden="true">compare_arrows</mat-icon>            
          </div>
      
          <div>
              <p i18n="@@AddNewRole">
                  You can <strong>use</strong> account in dual mode.<br>
                  Simply go to <strong>Account</strong> settings and add new <strong>Role</strong>.
              </p>
          </div>
      </div>

      <div [ngClass.gt-sm]="'description-element'" fxLayout.lt-sm="column" fxLayoutAlign="start center" fxFlexOrder="4">
        <div class="workflow-element" [ngClass]="{'icon-mobile-sm':  UIservice.isMobileSM, 'icon-desktop': !UIservice.isMobileSM}" fxLayoutAlign="center center">
            <mat-icon [inline]="true" aria-hidden="true">receipt</mat-icon>            
        </div>
    
        <div>
            <p i18n="@@OrganiserFillAdditionalData">
                Fill your company data.<br>
                Company data is required for <strong>Free</strong> contest announcement and <strong>Paid</strong> contest features.</p>
        </div>
    </div>

  </div>

  <div class="description-panel" fxFlex fxLayout.lt-sm="column" fxLayoutGap.lt-sm="5vh">

      <h2 class="mat-display-1" style="text-align: center;"  i18n="@@AddNewContestBolder"><strong>Add New Contest</strong></h2>

      <div [ngClass.gt-sm]="'description-element'" fxLayout.lt-sm="column" fxLayoutAlign="start center" fxFlexOrder="1">
          <a routerLink="/main/login" class="workflow-element" [ngClass]="{'icon-mobile-sm':  UIservice.isMobileSM, 'icon-desktop': !UIservice.isMobileSM}" fxLayoutAlign="center center">
              <mat-icon [inline]="true" aria-hidden="true">input</mat-icon>            
          </a>
      
          <div>
              <p i18n="@@LoginIntoCarpContestApp">
                    Use <strong>Log In</strong> form and enter Carp Contest App.<br>
                    If your account is set to Organiser and Referee then choose right role.</p>
          </div>
      </div>

      <div [ngClass.gt-sm]="'description-element'" fxLayout.lt-sm="column" fxLayoutAlign="start center" fxFlexOrder="2">
          <div class="workflow-element" [ngClass]="{'icon-mobile-sm':  UIservice.isMobileSM, 'icon-desktop': !UIservice.isMobileSM}" fxLayoutAlign="center center">
              <mat-icon [inline]="true" aria-hidden="true">event</mat-icon>            
          </div>
      
          <div>
              <p i18n="@@OrganiserAddContest">
                  Choose <strong>Add Contest</strong> option and <strong>complete the form.</strong>
              </p>
          </div>
      </div>

      <div [ngClass.gt-sm]="'description-element'" fxLayout.lt-sm="column" fxLayoutAlign="start center" fxFlexOrder="3">
          <div class="workflow-element" [ngClass]="{'icon-mobile-sm':  UIservice.isMobileSM, 'icon-desktop': !UIservice.isMobileSM}" fxLayoutAlign="center center">
              <mat-icon [inline]="true" aria-hidden="true">rule</mat-icon>            
          </div>
      
          <div>
              <p i18n="@@ConfirmRulesAndChooseContestPayment">
                    Accept Carp Contest <strong>Rules</strong> and choose announcement type <strong>Paid</strong> or <strong>Free</strong>.</p>
              
          </div>
      </div>

      <div [ngClass.gt-sm]="'description-element'" fxLayout.lt-sm="column" fxLayoutAlign="start center" fxFlexOrder="4">
          <div class="workflow-element" [ngClass]="{'icon-mobile-sm':  UIservice.isMobileSM, 'icon-desktop': !UIservice.isMobileSM}" fxLayoutAlign="center center">
              <mat-icon [inline]="true" aria-hidden="true">task_alt</mat-icon>            
          </div>
      
          <div>
              <p i18n="@@WaitForTheContestConfirmation">
                    Wait for contest creation <strong>confirmation</strong>, it will be sent to account email address.<br>
                    Carp Contest will contact you by the phone in case of <strong>Paid</strong> contest type.</p>
          </div>
      </div>

  </div>

</div>

<div [ngClass.gt-xs]="'mat-title'" [ngClass.lt-md]="'mobile-font-style'" fxFill style="padding: 5vh 5vw;" fxLayoutGap="5vw" fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="center start" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="start center">

  <div class="description-panel" fxFlex fxLayout.lt-sm="column" fxLayoutGap.lt-sm="5vh">

    <h2 class="mat-display-1" style="text-align: center;"  i18n="@@PrepareContest"><strong>Prepare Contest</strong></h2>

    <div [ngClass.gt-sm]="'description-element'" fxLayout.lt-sm="column" fxLayoutAlign="start center" fxFlexOrder="1">
        <a routerLink="/main/login" class="workflow-element" [ngClass]="{'icon-mobile-sm':  UIservice.isMobileSM, 'icon-desktop': !UIservice.isMobileSM}" fxLayoutAlign="center center">
            <mat-icon [inline]="true" aria-hidden="true">input</mat-icon>            
        </a>
    
        <div>
            <p i18n="@@LoginIntoCarpContestApp">
                  Use <strong>Log In</strong> form and enter Carp Contest App.<br>
                  If your account is set to Organiser and Referee then choose right role.</p>
        </div>
    </div>

    <div [ngClass.gt-sm]="'description-element'" fxLayout.lt-sm="column" fxLayoutAlign="start center" fxFlexOrder="2">
        <div class="workflow-element" [ngClass]="{'icon-mobile-sm':  UIservice.isMobileSM, 'icon-desktop': !UIservice.isMobileSM}" fxLayoutAlign="center center">
            <mat-icon [inline]="true" aria-hidden="true">event</mat-icon>            
        </div>
    
        <div>
            <p i18n="@@OrganiserChooseContest">
                Choose <strong>Contest</strong>, <strong>submit</strong> and enter Contest Managment Console.
            </p>
        </div>
    </div>

    <div [ngClass.gt-sm]="'description-element'" fxLayout.lt-sm="column" fxLayoutAlign="start center" fxFlexOrder="3">
        <div class="workflow-element" [ngClass]="{'icon-mobile-sm':  UIservice.isMobileSM, 'icon-desktop': !UIservice.isMobileSM}" fxLayoutAlign="center center">
            <mat-icon [inline]="true" aria-hidden="true">playlist_add_check</mat-icon>            
        </div>
    
        <div>
            <p i18n="@@StartStopSignOption">
                  Easly <strong>Open</strong> or <strong>Stop</strong> competitors <strong>registration</strong>.<br>
                  The Amount of available <strong>Reservation</strong> and <strong>Team</strong> list slots is controlled by Carp Contest App.
            </p>          
        </div>
    </div>

    <div [ngClass.gt-sm]="'description-element'" fxLayout.lt-sm="column" fxLayoutAlign="start center" fxFlexOrder="4">
        <div class="workflow-element" [ngClass]="{'icon-mobile-sm':  UIservice.isMobileSM, 'icon-desktop': !UIservice.isMobileSM}" fxLayoutAlign="center center">
            <mat-icon [inline]="true" aria-hidden="true">task_alt</mat-icon>            
        </div>
    
        <div>
              <ul i18n="@@ConfirmReservationsAndReferees">
                <li>Add Partners and Sponsors.</li>
                <li>Confirm <strong>Team Reservation</strong> payment, then the Team will be moved to signed competitors list.</li>
                <li>Confirm <strong>Referee Reservation</strong>, then the Referee will be moved to confirmed Referees list.</li>
              </ul>
        </div>
    </div>

</div>

<div class="description-panel" fxFlex fxLayout.lt-sm="column" fxLayoutGap.lt-sm="5vh">

  <h2 class="mat-display-1" style="text-align: center;"  i18n="@@ManageContest"><strong>Manage Contest</strong></h2>

  <div [ngClass.gt-sm]="'description-element'" fxLayout.lt-sm="column" fxLayoutAlign="start center" fxFlexOrder="1">
      <a routerLink="/main/login" class="workflow-element" [ngClass]="{'icon-mobile-sm':  UIservice.isMobileSM, 'icon-desktop': !UIservice.isMobileSM}" fxLayoutAlign="center center">
          <mat-icon [inline]="true" aria-hidden="true">people</mat-icon>            
      </a>
  
      <div>
          <p i18n="@@ManageReservationAndTeams">
                Carp Contest Organiser console allows you to control <strong>Reservations</strong>, confirm <strong>Fee</strong> payments, and manage signed <strong>Teams</strong>.
          
          </p>
      </div>
  </div>

  <div [ngClass.gt-sm]="'description-element'" fxLayout.lt-sm="column" fxLayoutAlign="start center" fxFlexOrder="2">
    <a routerLink="/main/login" class="workflow-element" [ngClass]="{'icon-mobile-sm':  UIservice.isMobileSM, 'icon-desktop': !UIservice.isMobileSM}" fxLayoutAlign="center center">
        <mat-icon [inline]="true" aria-hidden="true">people</mat-icon>            
    </a>

    <div>
        <p i18n="@@ManageReferees">
              Carp Contest Organiser console allows you to accept <strong>Referees</strong>, only confirmed <strong>Account</strong> have access to your contest.
        </p>
    </div>
</div>

  <div [ngClass.gt-sm]="'description-element'" fxLayout.lt-sm="column" fxLayoutAlign="start center" fxFlexOrder="3">
      <div class="workflow-element" [ngClass]="{'icon-mobile-sm':  UIservice.isMobileSM, 'icon-desktop': !UIservice.isMobileSM}" fxLayoutAlign="center center">
          <mat-icon [inline]="true" aria-hidden="true">loop</mat-icon>            
      </div>
  
      <div>
          <p i18n="@@DrawSequenceAndSwim">
            Carp Contest Organiser console allows you to <strong>Draw</strong> Teams sequence for next <strong>Swim drawing</strong><br>
            Application will <strong>automatically draw</strong> the Swim for the Team one by one, results are immediately visible in <strong>Draw</strong> tab on Contest Page.
            Also, You will be allowed to <strong>assign</strong> Team to Swim <strong>manually</strong>.
          </p>
      </div>
  </div>

  <div [ngClass.gt-sm]="'description-element'" fxLayout.lt-sm="column" fxLayoutAlign="start center" fxFlexOrder="4">
      <div class="workflow-element" [ngClass]="{'icon-mobile-sm':  UIservice.isMobileSM, 'icon-desktop': !UIservice.isMobileSM}" fxLayoutAlign="center center">
          <mat-icon [inline]="true" aria-hidden="true">note</mat-icon>            
      </div>
  
      <div>
        <p i18n="@@AddMessages">
          Carp Contest Organiser console allows you to add <strong>Messages</strong>, they will be visible in <strong>Live</strong> tab on Contest Page..
    </p>
      </div>
  </div>

</div>

</div>


</article>