<div class="mat-headline" i18n="Welcome Info Card Details Header| Header for Conest Details@@"><b>Team Details:</b></div> 
        <div fxLayout="column"> 
                                                                                                     
                <p fxLayoutAlign="start start" fxFlexOrder="1">
                    <label  fxFlex.xs="120px" fxFlex="170px" fxLayoutAlign="start start" 
                            class="detailsLabel" title="Maximum Teams" 
                            i18n="Welcome Info Card Label Max teams| Label for Max teams information@@">
                            Max Teams:</label>
                &nbsp;{{this.challenge.amountTeamsMaximum}}</p>
                
                <p fxLayoutAlign="start start" fxFlexOrder="2">
                    <label fxFlex.xs="120px" fxFlex="170px" fxLayoutAlign="start start"
                            class="detailsLabel" title="Actual Teams Amount"
                            i18n="Welcome Info Card Label Actual Teams Amount| Label for Actual Teams Amount information@@">
                            Signed Teams:</label>
                &nbsp;{{ this.challenge.amountTeamsActual }}</p>                                                                             
                                                
                <p fxLayoutAlign="start start" fxFlexOrder="3">
                    <label fxFlex.xs="120px" fxFlex="170px" fxLayoutAlign="start start"
                            class="detailsLabel" title="Reservation" 
                            i18n="Welcome Info Card Label Reservation| Label for Reservation teams information@@">
                            Reservation:</label>
                &nbsp;{{this.challenge.amountTeamsReservation}}</p>

                <p fxLayoutAlign="start start" fxFlexOrder="3">
                        <label fxFlex.xs="120px" fxFlex="170px" fxLayoutAlign="start start"
                                class="detailsLabel" title="Reservation" 
                                i18n="@@ReserveListLabel">Reserve List:</label>
                    &nbsp;{{this.challenge.amountTeamsReserve}}</p>
                                                                                 
                                               
        </div>