

<div *ngIf="anyData; else noDataTemplate" fxFlexFill fxLayout="column" fxLayoutGap="2vh" fxLayoutAlign="start center">

<div style="width: 95%;" fxLayout="row wrap" fxLayoutGap="2vh" fxLayoutAlign="center start">

 <mat-card class="contest-card" *ngFor="let card of cardData | async">
    <mat-card-header>
    <div mat-card-avatar *ngIf="(card.isLive || card.isArchived) && card.isPremium">
        <a [routerLink]="['/contest', card.alias, 'live']"><mat-icon style="font-size: 40px;">play_circle_outline</mat-icon></a>
    </div>
      <mat-card-title>{{card.fullName}}</mat-card-title>
      <mat-card-subtitle>
          {{card.Data.startDate | date:"dd/MM" }} - {{card.Data.endDate | date:"dd/MM/yyyy" }}
          {{card.country}},&nbsp;{{card.region}},&nbsp;{{card.city}}<br>
          {{card.place}}
      </mat-card-subtitle>
    </mat-card-header>
    <img mat-card-image [attr.src]="card.Data.posterLink" alt="{{card.fullName}} Poster">
    <mat-card-content *ngIf="!uiService.isMobileSM">
      <p>{{card.Data.challengeInfo | truncate : 160 : true}}</p>
    </mat-card-content>
    <mat-card-actions fxLayoutAlign="space-around center" >
      <button mat-button [disabled]="true">LIKE</button>
      <button mat-button [routerLink]="['/contest', card.alias]" i18n="@@Open">OPEN</button>
      <button mat-button [disabled]="true">SHARE</button>
    </mat-card-actions>
  </mat-card> 

</div>

<mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>

</div>

<ng-template #noDataTemplate>
    
    <div style="padding: 5vh;" fxFlex fxLayoutAlign="center center">

            <p class="mat-h2" i18n="@@ThereIsNoContests">Uppss... There is no contests</p>
    </div>

</ng-template>
