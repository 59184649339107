import { Component, OnInit, ViewChild, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { Challenge, Awards, AwardsPlace, Region, Fee, Links, Organizer, Premium } from 'src/app/data/models/Manage/challenge.model';
import { ManageContestDataService } from 'projects/organiserApp/src/app/services/manage-contest-data';
import { UIService } from 'src/app/shared/service/ui.service';
import { Router } from '@angular/router';

export interface ChallengeData {
  challenge: Challenge
}

@Component({
  selector: 'app-add-contest-dialog',
  templateUrl: './add-contest-dialog.component.html',
  styleUrls: ['./add-contest-dialog.component.css']
})
export class AddContestDialogComponent implements OnInit, OnDestroy {

  @ViewChild('stepper') stepper: MatStepper;
  challenge: Challenge
  challengeAlias: string
  isGeneratingChallenge: boolean = false

  constructor(@Inject(MAT_DIALOG_DATA) public data: ChallengeData,
              private route:Router,
              public dialogRef: MatDialogRef<AddContestDialogComponent>,
              public UIservice: UIService,
              private manageContest: ManageContestDataService) {  this.challenge = this.newChallenge() }

  ngOnInit() {
  }

  newChallenge(): Challenge {
    const challenge = new Challenge()

    challenge.amountTeamsMaximum = null
    challenge.amountTeamsAvailable = null
    challenge.amountTeamsReservation = 0
    challenge.amountTeamsActual = 0
    challenge.areaDetails = null
    challenge.challengeInfo = null
    challenge.challengeModel = null
    challenge.challengeType = null
    challenge.endDate = null
    challenge.fishingMethod = null
    challenge.place = null
    challenge.sortTopBy = 3
    challenge.startDate = null
    challenge.teamSquad = 2
    challenge.title = null

    challenge.Awards = <Awards>{}
    challenge.Awards.First = <AwardsPlace>{}
    challenge.Awards.Second = <AwardsPlace>{}
    challenge.Awards.Third = <AwardsPlace>{}
    challenge.Awards.BigFish = <AwardsPlace>{}
    challenge.Awards.FirstFish = <AwardsPlace>{}

    challenge.Region = <Region>{}
    challenge.Fee = <Fee>{}
    challenge.Fee.paymentDetails = {
      providedDetails: false,
      bankAccountNumber: '',
      receiverName: '',
      receiverSurname: '',
      receiverCompany: '',
      receiverAddress: ''
    }
    challenge.Links = <Links>{}
    challenge.Organizer = <Organizer>{}

    challenge.Premium = <Premium>{}
    challenge.Premium.isPremium = false

    return challenge
  }

  onSubComplete(event){
    console.log(this.manageContest.rulesFile)
    this.stepper.selected.completed = event
    this.stepper.next()
  }

  onChallengeAlias(event){
    this.challengeAlias = event


    // do check if Alias is right
  }

  onSubmitNewContest(){

    this.challenge.amountTeamsAvailable = this.challenge.amountTeamsMaximum
    this.challenge.alias = this.challengeAlias
    const spinner = this.UIservice.openSpinnerDialog()
    this.isGeneratingChallenge = true
    this.manageContest.addNewContest(this.challenge, this.challengeAlias).subscribe({
      next: async (v) => {

        let response = v

          if (response.operation == 'END' && response.key != null)
          {
            this.manageContest.challengeKey = response.key
            await this.uploadNewFiles().then(() => this.manageContest.updateChallengeChild("Links" ,this.challenge.Links))
          }

        let data = {
                  title: $localize`Contest Creation Confirmation`,
                  content: $localize`<strong>Your Contest ${this.challenge.title} has been successfully created !</strong><br>Please check for email for final confirmation.`
              }
              spinner.close()
              this.UIservice.openInfoDialog(data.title, data.content,true)
              this.route.navigate[('/contests-list')]

            },
      error: (e) => {
        let data = {
                  title: $localize`Contest Creation Failed`,
                  content: $localize`<strong>Error, can't confirm your contest creation. Please contact Carp Contest Administrator</strong><br> ${e.message}`
              }
              spinner.close()
              this.isGeneratingChallenge = false
              this.UIservice.openInfoDialog(data.title, data.content,false)
      },
      complete: () => {spinner.close()}
  })
  }

  async uploadNewFiles()
{

 if(this.manageContest.rulesFile)
 {
  await this.manageContest.uploadContestInfoFiles(this.manageContest.rulesFile)
  .then((value) => { this.challenge.Links.rulesLink = value
  })
  .catch((e) =>{
    let title = $localize`Upload Error`
    let content = e.message
    this.UIservice.openInfoDialog(title, content,false)
  })
 }

 if(this.manageContest.spotsFile)
 {
  await this.manageContest.uploadContestInfoFiles(this.manageContest.spotsFile)
  .then((value) => { this.challenge.Links.bankSpotsMapLink = value
    console.log(value)
  })
  .catch((e) => {
    let title = $localize`Upload Error`
    let content = e.message
    this.UIservice.openInfoDialog(title, content,false)
  })
 }

 if(this.manageContest.posterFile)
 {
  await this.manageContest.uploadContestInfoFiles(this.manageContest.posterFile)
  .then((value) => { this.challenge.Links.posterLink = value})
  .catch((e) => {
    let title = $localize`Upload Error`
    let content = e.message
    this.UIservice.openInfoDialog(title, content,false)
  })
 }
}
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.dialogRef.close(false)
  }
}
