import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-premium-info',
  templateUrl: './premium-info.component.html',
  styleUrls: ['./premium-info.component.css']
})
export class PremiumInfoComponent implements OnInit {
  

  constructor() { }

  ngOnInit() {
  }

}
