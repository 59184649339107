import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { Team } from 'src/app/data/models/Manage/team.model';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, Subscription } from 'rxjs';
import { UIService } from 'src/app/shared/service/ui.service';

@Component({
  selector: 'app-teams-table',
  templateUrl: './teams-table.component.html',
  styleUrls: ['./teams-table.component.css']
})
export class TeamsTableComponent implements OnInit, OnDestroy {
  
  @Input() teams$: Observable<Team[]>
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  dataSubscription: Subscription
  cardData: Observable<Team[]>
  pageEvent: PageEvent;
  teams: Team[] = <Team[]>{}

  displayedColumns= ['station','promoTeam','Angler1teamRole', 'Angler1', 'Angler1Country', 
                      'Angler2teamRole', 'Angler2', 'Angler2Country', 'TeamRunner'];
  dataSource = new MatTableDataSource<Team>()
  
  constructor(public UIservice: UIService) {
   
   }

  ngOnInit() {

    this.dataSubscription = this.teams$.subscribe(array => {
      if(array.length > 0)
      {
        this.dataSource.data = array
        this.dataSource.paginator = this.paginator;
        this.cardData = this.dataSource.connect()
      }
     
    })

    this.dataSource.paginator = this.paginator;
    this.cardData = this.dataSource.connect()

    this.dataSource.filterPredicate = (data, filter) => { 
      return JSON.stringify(data).toLowerCase().indexOf(filter) != -1; 
    }
    
  }

  applyFilter(filterValue: string) {

    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  
  ngOnDestroy(): void {
    
    this.dataSubscription.unsubscribe()
    
  }
}
