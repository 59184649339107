<div  fxFlexFill fxLayout="column" fxLayoutAlign="start center">

    <h1 class="mat-h1" i18n="MessagesHeader| Messages Header@@">Messages</h1>
 
    <div [hidden]="!this.dataSource" fxFlexFill fxLayoutAlign="center start" >
        <div style="margin: 2vh;" fxFlex.gt-md="95" fxFlex="100" class="mat-elevation-z8" fxLayout="column">
            
            <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap.xs="1vh">
            
                <div fxFlex="100" fxFlex.xs fxFlexOrder="3">
            
                    <mat-paginator [length]="this.dataSource.data.length"
                                    [pageSize]="5"
                                    showFirstLastButtons
                                    (page)="pageEvent = $event">
                    </mat-paginator>
                    
                </div>
            
    
            <table *ngIf="!this.UIservice.isMobileXS; else cards" 
                    class="mat-elevation-z8"
                    fxFlexOrder="2" 
                    fxFlex="100" 
                    mat-table
                    multiTemplateDataRows
                    [dataSource]="dataSource">
                
                    <ng-container matColumnDef="createdBy">
                        <th fxFlex="12" fxLayoutAlign="center center" mat-header-cell *matHeaderCellDef i18n="Author | Author@@">Author</th>
                        <td fxFlex="12" fxLayoutAlign="center center" mat-cell *matCellDef="let element"> {{element.createdBy}} </td>
                      </ng-container>

                      <ng-container matColumnDef="createdAt">
                        <th fxFlex="20" fxLayoutAlign="center center" mat-header-cell *matHeaderCellDef i18n="Date | Date@@">Date</th>
                        <td fxFlex="20" fxLayoutAlign="center center" mat-cell *matCellDef="let element">{{element.createdAt | date:"dd/MM/yyyy HH:mm"}}</td>
                      </ng-container>

                      <ng-container matColumnDef="type">
                        <th fxFlex="10" fxLayoutAlign="center center" mat-header-cell *matHeaderCellDef i18n="Type | Type@@">Type</th>
                        <td fxFlex="10" fxLayoutAlign="center center" mat-cell *matCellDef="let element">{{element.type}}</td>
                      </ng-container>

                      <ng-container matColumnDef="text">
                        <th fxFlex="58" fxLayoutAlign="center center" mat-header-cell *matHeaderCellDef i18n="Content | Message Content@@">Content</th>
                        <td fxFlex="58" fxLayoutAlign="start center" mat-cell *matCellDef="let element">{{element.text.PL}}</td>
                      </ng-container>
                    

                    
                      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                      <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"></tr>
            </table>
            
            <ng-template #cards>
        
                <div fxFlexFill fxLayout="column" fxLayoutGap="1vh" fxFlexOrder.xs="2">
                    <mat-accordion *ngFor="let element of this.cardData | async">
                        <mat-expansion-panel>
                          <mat-expansion-panel-header fxLayout="row">  
                            
                            <mat-panel-title fxFlex="30">
                                                
                                {{element.type}}
                
                            </mat-panel-title>
                            
                            <mat-panel-description fxFlex="60">
   
                            {{element.createdAt | date:"dd/MM/yyyy HH:mm"}}
              
                            </mat-panel-description>
                          </mat-expansion-panel-header>
             
                          <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="2vh">
                            
                                    <h4 class="mat-h4" style="font-weight: bold;" i18n="Author | Author@@">Author</h4>
                                   <strong>{{element.createdBy}}</strong>
                          
                                    <h4 class="mat-h4" style="font-weight: bold;" i18n="Content | Message Content@@">Content</h4>
                                    <p>{{element.text}}</p>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
            </ng-template>
    
            </div>
            
        </div>
    
    
    </div>

</div>