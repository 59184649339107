import { Component, OnInit, OnDestroy} from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { UIService } from 'src/app/shared/service/ui.service';

import { BaseComponent } from '../base.component';
import { Store } from '@ngrx/store'
import * as fromRoot from 'src/app/presentation/app.reducer'
import * as UI from 'src/app/shared/service/ui.actions'
import { Subscription, Observable } from 'rxjs';
import { ProvideDataService } from '../../../services/provideDataService';
import { Challenge } from 'src/app/data/models/Manage/challenge.model';





@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css'],
  providers: []
})

export class WelcomeComponent extends BaseComponent implements OnInit, OnDestroy{
  
  challenge: Challenge;
  private challengeSubscription: Subscription
  private keySubscription: Subscription;
  private isChallengeKeyReady: boolean = false;

  contestInfoImageSRC: string;

  contestInfoImageType: number = 0;
  contestInfoImageState: boolean = false;
  contestPlaceMapState: boolean = false;
  
  private _challengeAreaLink
  private _posterLink: string;
  
  constructor(

      private route: ActivatedRoute, 
      private router: Router,
      private dataService: ProvideDataService,
      public uiService: UIService,
      private store: Store<fromRoot.State>) {
      
       super(route,dataService,uiService)

        if(this.dataService.challenge.value === null || this.dataService.challenge.value.alias != this.dataService.challengeAlias)
        {        
          this.store.dispatch(new UI.StartLoadingMain())    
          if(this.dataService.challenge.value){
            if(this.dataService.challenge.value.alias != this.dataService.challengeAlias){
              this.challenge = this.dataService.challenge.value
            }
            }
            
            if (!this.isChallengeKeyReady)
            {
              this.keySubscription = this.dataService.keyReady.asObservable().subscribe(bool => {
                this.isChallengeKeyReady = bool
                if(bool)
                  {
                   this.subscribeToChallenge()
                   this.store.dispatch(new UI.StopLoadingMain)
                  }
                 })
            }     

      }
      else{
        this.challenge = this.dataService.challenge.value
      }

     
            
        

   }

  ngOnInit() {  
  
  }

  contestOnButtonClick(path: string){
    this.router.navigate(['contest/' + this.uiService.challengeAlias + path])
  }

  subscribeToChallenge(){
    this.challengeSubscription = this.dataService.challenge$
              .subscribe({
                complete: () => {
                },
                error: () => {
                  this.uiService.showSnackBar('Fetching Contest Data Failed, please try again later.',null,3000)
                },
                next: res => {
                  this.dataService.challenge.next(res)
                  this.challenge = res 
                }
              })
  }

  

  public showInfoImage(type: number){

    let path: string
    
    if(type == 1){
      path = this.challenge.Links.posterLink;
    }else
    {
      path = this.challenge.Links.bankSpotsMapLink;
    }
    
    if(this.contestInfoImageState && this.contestInfoImageType == type){
      this.infoImageEvent(false,0,"")
    }
    else {
      this.infoImageEvent(true,type,path)
    }

  }
  public showMap(){
    this.contestPlaceMapState = !this.contestPlaceMapState;
  }
  public changeImageState(){
    this.contestInfoImageState = !this.contestInfoImageState;
  }
  private infoImageEvent(state: boolean, type: number, src: string){
    this.contestInfoImageType = type;
    this.contestInfoImageSRC = src;
    this.contestInfoImageState = state;
  }

  ngOnDestroy(){
   if (this.challengeSubscription) {this.challengeSubscription.unsubscribe()}
   if (this.keySubscription) {this.keySubscription.unsubscribe()}
  }
}
