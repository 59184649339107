import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-chart-pie-advanced',
  templateUrl: './chart-pie-advanced.component.html',
  styleUrls: ['./chart-pie-advanced.component.css']
})
export class ChartPieAdvancedComponent implements OnInit,OnDestroy {
  
  @Input() chartData$: Observable<any[]>;
  @Input() chartData: any[]
  
  dataSubs: Subscription

  single: any[];
  view: any[] ;

  // options
  gradient: boolean = false;
  showLegend: boolean = true;
  showLabels: boolean = false;
  isDoughnut: boolean = false;
  animations: boolean = true;


  colorScheme = {
    domain: ['#2196F3', '#F9A825','#1B5E20','#ef5350']
  };

  constructor() { }

  ngOnInit() {
    this.single = this.chartData
    this.dataSubs = this.chartData$.subscribe(data => {
      this.single = data
    })
  }
  
  ngOnDestroy(): void {
   this.dataSubs.unsubscribe()    
  }

}
