<section *ngIf="this.challenge && modeEdit === ''; else editForm"
            class="content-container" fxLayout="column"
            fxLayoutAlign="start center" fxLayoutGap="1vw">


    <h1 class="mat-headline" style="text-decoration: underline;" i18n="Welcome Info Card Constest Details Header| Header for Contest Details@@">Contest Details:</h1>

 <div style="width: 100%;" fxLayout="column" fxLayoutAlign="start start" >
    <p fxLayout="row" fxLayoutAlign="start start">
        <label class="propertyLabel" title="Title" i18n="Contest Info Title|Contest Info Title@@">Title:</label>
        {{this.challenge.title}}
    </p>

    <p fxLayout="row" fxLayoutAlign="start start">
        <label class="propertyLabel" title="Team Squad" i18n="Welcome Info Card Label Team Squad| Label for Team Squad information@@">Team Squad:</label>
        {{ this.challenge.teamSquad }}&nbsp;<span i18n="@@persons">Person\s</span>
    </p>

    <p fxLayout="row" fxLayoutAlign="start start">
        <label class="propertyLabel" title="Contest Type" i18n="Welcome Info Card Label Contest Type| Label for Contest Type information@@">Contest Type:</label>
        {{this.challenge.challengeType}}
    </p>

    <p fxLayout="row" fxLayoutAlign="start start">
        <label class="propertyLabel" title="Contest Model" i18n="Welcome Info Card Label Contest Model| Label for Contest Model information@@">Contest Model:</label>
        <span *ngIf="this.challenge.challengeModel == 'Social'" i18n="@@Social">Individual Contest</span>
        <span *ngIf="this.challenge.challengeModel == 'Team Contest'" i18n="@@TeamContest">Team Contest</span>
        <span *ngIf="this.challenge.challengeModel == 'Team Sectors'" i18n="@@TeamsInSectors">Teams in Sectors</span>
    </p>

    <p fxLayout="row" fxLayoutAlign="start start">
        <label class="propertyLabel" title="Fishing Method" i18n="Welcome Info Card Label Method| Label for Method information@@">Fishing Methods:</label>
        <span *ngIf="this.challenge.fishingMethod == 'Casting'" i18n="@@Casting">Casting</span>
        <span *ngIf="this.challenge.fishingMethod == 'RC Models'" i18n="@@RCmodels">RC Models</span>
        <span *ngIf="this.challenge.fishingMethod == 'Rubber Boat'" i18n="@@RubberBoat">Rubber Boat</span>
        <span *ngIf="this.challenge.fishingMethod == 'All'" i18n="@@Wszystkie">All</span>
    </p>

    <p fxLayout="row" fxLayoutAlign="start start">
        <label class="propertyLabel" title="Top Fish" i18n="Contest Info Top Fish| Label for Top Fish@@">Top Fish:</label>
        {{this.challenge.sortTopBy}}
    </p>
</div>

    <h1 class="mat-headline" style="text-decoration: underline;" i18n="Welcome Info Card Header| Header for card with informations@@">Contest Info</h1>

<div style="width: 100%;"  fxLayout="column" fxLayoutAlign="start start" >
   <p fxLayout="row" fxLayoutAlign="start start">
       <label class="propertyLabel" title="Where" i18n="Welcome Place&Time Card Label Where| Label for Where information@@">Where:</label>
       {{ this.challenge.place}}
   </p>

   <p fxLayout="row" fxLayoutAlign="start start">
       <label class="propertyLabel" title="Place details" i18n="Welcome Place&Time Card Label Area Details| Label for Area Details information@@">Details:</label>
       {{this.challenge.areaDetails}}
   </p>

   <p fxLayout="row" fxLayoutAlign="start start">
       <label class="propertyLabel" title="Start Date" i18n="Contest info Label Start Date| Label for Start Date information@@">Start:</label>
       {{ this.challenge.startDate | date:"dd/MM/yyyy"}}
   </p>

   <p fxLayout="row" fxLayoutAlign="start start">
       <label class="propertyLabel" title="End Date" i18n="Contest info Label End Date| Label for End Date information@@">Finish:</label>
       {{ this.challenge.endDate | date:"dd/MM/yyyy"}}
   </p>

   <p class="descriptionArea" fxLayout="row" fxLayoutAlign="start start">
        <label class="propertyLabel" title="Contest info" i18n="Contest Description Label Info| Label for Description Label information@@">Description:</label>
        {{this.challenge.challengeInfo}}
    </p>

</div>

    <h1 class="mat-headline" style="text-decoration: underline;" i18n="Welcome Info Card Details Header| Header for Conest Details@@">Team Details:</h1>

<div style="width: 100%;" fxLayout="column" fxLayoutAlign="start start" >
   <p fxLayout="row" fxLayoutAlign="start start">
       <label class="propertyLabel" title="Maximum Teams" i18n="Welcome Info Card Label Max teams| Label for Max teams information@@">Max Teams:</label>
       {{this.challenge.amountTeamsMaximum}}
   </p>
   <p fxLayout="row" fxLayoutAlign="start start">
       <label class="propertyLabel" title="Actual Teams Amount" i18n="Welcome Info Card Label Actual Teams Amount| Label for Actual Teams Amount information@@">Signed Teams:</label>
       {{ this.challenge.amountTeamsActual }}
   </p>
   <p fxLayout="row" fxLayoutAlign="start start">
       <label class="propertyLabel" title="Reservation" i18n="Welcome Info Card Label Reservation| Label for Reservation teams information@@">Reservation:</label>
       {{this.challenge.amountTeamsReservation}}
   </p>
   <p fxLayout="row" fxLayoutAlign="start start">
    <label class="propertyLabel" title="Reservation" i18n="@@ReserveListLabel">Reserve List:</label>
    {{this.challenge.amountTeamsReserve}}
</p>
   <p class="descriptionArea" fxLayout="row" fxLayoutAlign="start start">
    <label class="propertyLabel" title="Signup Info" i18n="SignupInformation| Label for Signup information@@">Signup info</label>
    {{this.challenge.signupInfo}}
</p>
</div>
    <button *ngIf="!this.modeEdit"
            mat-button color="accent"
            (click)="onEdit()"
            i18n="Challenge Details Edit Button|Challenge Details Edit Button@@"
    >
    Edit Details
    </button>
</section>

<ng-template #editForm>

    <section [ngClass]="{'content-container-edit': this.modeEdit === 'edit' || this.isCreate && !this.UIservice.isMobileSM,
                        'mobile-content-container-edit': this.isCreate && this.UIservice.isMobileSM}"
                        fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="1vw">

        <h1 *ngIf="!this.isCreate" class="mat-headline" i18n="Edit Details Header| Header for Edit Details@@">Edit Details:</h1>
        <h1 *ngIf="this.isCreate" class="mat-headline" i18n="Enter Details Header| Header for Enter Details@@">Enter Details:</h1>

        <form *ngIf="this.editdetails" fxLayout="row wrap" fxLayoutAlign="start start" #d="ngForm" fxLayoutGap="4vw">

            <div fxFlex fxLayout="column" fxLayoutAlign="start start" fxLayoutAlign.lt-md="start center">

                <h2 class="mat-headline" style="width: 100%; text-align: center;" i18n="Edit Details Base Info Header| Edit Details Base Info Header@@">Base Info</h2>

                <mat-form-field fxFlexFill>
                <mat-label i18n="Contest Info Title|Contest Info Title@@">Title:</mat-label>
                <input matInput
                        #title
                        [(ngModel)]="editdetails.title"
                        name="Title"
                        (blur)="proposeAlias(title.value)"
                        (keyup.enter)="proposeAlias(title.value)"
                        required
                >
                </mat-form-field>

                <mat-form-field fxFlexFill *ngIf="this.modeEdit === 'create'">
                    <mat-label i18n="Contest URL Alias|Contest URL Alias@@">URL Alias:</mat-label>
                    <input matInput
                            [(ngModel)]="contestAlias"
                            name="ContestURLAlias"
                            readonly
                    >
                </mat-form-field>

                <mat-form-field fxFlexFill>
                    <mat-label i18n="Welcome Info Card Label Contest Model| Label for Contest Model information@@">Contest Model:</mat-label>
                    <mat-select required ([ngModel])="editdetails.challengeModel" [(value)]="editdetails.challengeModel" [formControl]="contestType" (selectionChange)="checkIsPZW($event)">
                        <mat-option value="Team Contest" i18n="@@TeamContest">Team Contest</mat-option>
                        <mat-option value="PZW">PZW</mat-option>
                        <mat-option [disabled]="true" value="Individual Contest" i18n="@@Inividual">Individual Contest</mat-option>
                        <mat-option [disabled]="true" value="Team Sectors" i18n="@@TeamsInSectors">Teams in Sectors</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field fxFlexFill>
                    <mat-label i18n="Welcome Info Card Label Method| Label for Method information@@">Fishing Methods:</mat-label>
                    <mat-select required ([ngModel])="editdetails.fishingMethod" [(value)]="editdetails.fishingMethod" [disabled]="editdetails.challengeModel == 'PZW'">
                        <mat-option value="Casting" i18n="@@Casting">Casting</mat-option>
                        <mat-option value="RC Models" i18n="@@RCmodels">RC Models</mat-option>
                        <mat-option value="Rubber Boat" i18n="@@RubberBoat">Rubber Boat</mat-option>
                        <mat-option value="All" i18n="@@Wszystkie">All</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field fxFlexFill>
                    <mat-label i18n="Classification| Label Classification@@">Classification</mat-label>
                    <mat-select required ([ngModel])="editdetails.sortTopBy" [(value)]="editdetails.sortTopBy" [disabled]="editdetails.challengeModel === 'PZW'">
                        <mat-option value="3" i18n="@@Top3Fish">Top 3 Fish</mat-option>
                        <mat-option value="5" i18n="@@Top5Fish">Top 5 Fish</mat-option>
                        <mat-option value="PZW" >PZW</mat-option>
                        <mat-option [disabled]="true" value="Sectors" i18n="@@Sectors">Sectors</mat-option>

                    </mat-select>
                </mat-form-field>

                <mat-form-field fxFlexFill>
                    <mat-label i18n="Welcome Info Card Label Team Squad| Label for Team Squad information@@">Team Squad:</mat-label>
                    <input matInput
                            [(ngModel)]="editdetails.teamSquad"
                            name="Team Squad"
                            required
                    >
                </mat-form-field>

                <mat-form-field fxFlexFill>
                    <mat-label i18n="Welcome Info Card Label Max teams| Label for Max teams information@@">Max Teams:</mat-label>
                    <input matInput
                            type="number"
                            [(ngModel)]="editdetails.amountTeamsMaximum"
                            name="Max teams"
                            required
                    >
                </mat-form-field>

            </div>

            <div fxFlex fxLayout="column" fxLayoutAlign="start start" fxLayoutAlign.lt-md="start center">

                <h2 class="mat-headline" style="width: 100%; text-align: center;" i18n="Edit PlaceTime Header| Edit PlaceTime Header@@">Place & Time</h2>


                <mat-form-field fxFlexFill>
                    <mat-label i18n="Country | Country@@">Country</mat-label>
                    <mat-select required
                                ([ngModel])="editdetails.Region.country"
                                [(value)]="editdetails.Region.country"
                                (selectionChange)="onChangeCountry()">
                        <mat-option style="margin-left: 2vh" [value]="country" *ngFor="let country of _countries">{{country | titlecase}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field fxFlexFill *ngIf="this.regions">
                    <mat-label i18n="Region | Region@@">Region</mat-label>
                    <mat-select required
                                ([ngModel])="editdetails.Region.region"
                                [(value)]="editdetails.Region.region">
                        <mat-option style="margin-left: 2vh" [value]="region" *ngFor="let region of regions">{{region | titlecase}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field fxFlexFill >
                    <mat-label i18n="City| Label for City@@">City</mat-label>
                    <input matInput
                            [(ngModel)]="editdetails.Region.city"
                            name="ContestCity"
                            required
                    >
                </mat-form-field>

                <mat-form-field fxFlexFill>
                    <mat-label i18n="Fishery| Label for Fishery information@@">Fishery:</mat-label>
                    <input matInput
                            type="text"
                            [(ngModel)]="editdetails.place"
                            name="Fishery"
                            required
                    >
                </mat-form-field>

                <mat-form-field fxFlexFill>
                    <mat-label i18n="Start Date| Label for Start Date@@">Start Date</mat-label>
                    <input name="startDate" matInput [ngxMatDatetimePicker]="startpicker" readonly required [(ngModel)]="editdetails.startDate" (dateInput)="onDateInput('start', $event)">
                    <mat-datepicker-toggle matSuffix [for]="startpicker"></mat-datepicker-toggle>
                    <ngx-mat-datetime-picker #startpicker [touchUi]="true"  [color]="color" [stepHour]="true" [stepMinute]="true">
                    </ngx-mat-datetime-picker>
                  </mat-form-field>

                <mat-form-field fxFlexFill>
                    <mat-label i18n="End Date| Label for End Date@@">End Date</mat-label>
                    <input name="end Date" matInput [ngxMatDatetimePicker]="endpicker" readonly required [(ngModel)]="editdetails.endDate" (dateInput)="onDateInput('end', $event)">
                    <mat-datepicker-toggle matSuffix [for]="endpicker"></mat-datepicker-toggle>
                    <ngx-mat-datetime-picker #endpicker [touchUi]="true" [color]="color" [stepHour]="true" [stepMinute]="true">
                    </ngx-mat-datetime-picker>
                  </mat-form-field>

            </div>

            <div fxFlex [ngClass]="{'textarea-container': this.modeEdit === 'edit',
                                        'mobile-textarea-container': this.isCreate && this.UIservice.isMobileSM}"
                                        fxLayout="column" fxLayoutAlign="start center">

                <h2 class="mat-headline"  i18n="Edit Description Header| Edit Description Header@@">Contest Description</h2>

                <mat-form-field fxFlexFill>
                    <mat-label i18n="InvitationText| Label for Invitation text information@@">Invitation text:</mat-label>
                    <textarea matInput
                            type="text"
                            [(ngModel)]="editdetails.challengeInfo"
                            [placeholder]="mainDescription"
                            name="Description"
                            required
                            cdkTextareaAutosize
                            cdkAutosizeMinRows="1"
                            cdkAutosizeMaxRows="10"
                    ></textarea>
                </mat-form-field>

                <mat-form-field fxFlexFill>
                    <mat-label i18n="ContestTypeDescription| Label for Contest Type Description@@">Contest Type Description</mat-label>
                    <textarea matInput
                            [(ngModel)]="editdetails.challengeType"
                            [placeholder]="contestTypePlaceHolder"
                            name="ContestTypeDescription"
                            required
                            cdkTextareaAutosize
                            cdkAutosizeMinRows="1"
                            cdkAutosizeMaxRows="10"
                    ></textarea>
                </mat-form-field>

                <mat-form-field fxFlexFill>
                    <mat-label i18n="FisheryDescription| Label for Fishery Description information@@">Fishery Description</mat-label>
                    <textarea matInput
                            type="text"
                            [(ngModel)]="editdetails.areaDetails"
                            name="FisheryDescription"
                            cdkTextareaAutosize
                            cdkAutosizeMinRows="1"
                            cdkAutosizeMaxRows="10"

                    ></textarea>
                </mat-form-field>

                <mat-form-field fxFlexFill>
                    <mat-label i18n="SignupInformation| Label for Signup information@@">Signup info</mat-label>
                    <textarea matInput
                            type="text"
                            [(ngModel)]="editdetails.signupInfo"
                            name="SignupInformation"
                            cdkTextareaAutosize
                            cdkAutosizeMinRows="1"
                            cdkAutosizeMaxRows="10"

                    ></textarea>
                </mat-form-field>

            </div>

            <div fxFlex="100" fxLayoutAlign="center center" fxLayoutGap="2vh">
                    <button *ngIf="!this.isCreate"
                         mat-raised-button
                        color="warn"
                        i18n="cancel| cancel button@@"
                        (click)="onCancel()">Cancel</button>

                    <button [disabled]="!(d.valid && d.touched)"
                        mat-raised-button
                        color="primary"
                        i18n="Edit Save Button| Edit Save Button@@"
                        (click)="onUpdate()">Save</button>

                    <label i18n="fillRequiredFields| fill Required Data@@">* required fields</label>
            </div>

        </form>

    </section>

</ng-template>
