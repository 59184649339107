import { Component, OnInit } from '@angular/core';
import { UIService } from 'src/app/shared/service/ui.service';
import { Fish } from 'src/app/data/models/Manage/fish.model';
import { ContestDataService } from '../services/contest-data.service';
import { AuthService } from 'src/app/auth-module/service/auth.service';

@Component({
  selector: 'app-contest',
  templateUrl: './contest.component.html',
  styleUrls: ['./contest.component.css']
})
export class ContestComponent implements OnInit {

  constructor(public UIservice: UIService, 
              private contestData: ContestDataService,
              private authService: AuthService) { }

  ngOnInit() {
    this.contestData.getSignedTeams()
  }

 async openFishDialog(){

    const newFish = new Fish()
    

    this.UIservice.openAddFishDialog(newFish, this.contestData.signedTeams$).subscribe(async result => {
      if(result)
      {
        const spinner = this.UIservice.openSpinnerDialog()
        const sendFish = {... newFish}
        sendFish.refereeID = this.authService.getCurrentUserUID()
        
        await this.contestData.uploadFishPhoto(result.photoFile)
              .then((photoLink) => {sendFish.photoUrl = photoLink})
              .finally(() => { sendFish.photoFile = null})

        await this.contestData.setNewFish(sendFish)
              .then(() => { spinner.close() })
              .catch((e) => {console.log(e.message)})
          
      }
    })
  }

}
