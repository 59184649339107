import { Component, OnInit, OnDestroy } from '@angular/core';
import { Partner } from 'src/app/data/models/Manage/partner.model';
import { ContestDataService } from '../../services/contest-data.service';
import { Subscription, Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { PartnerDialogComponent } from './partner-dialog-component/partner-dialog-component.component';
import { UIService } from 'src/app/shared/service/ui.service';

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.css']
})
export class PartnersComponent implements OnInit, OnDestroy {

  dialogSubscription: Subscription
  dataSubscription: Subscription
  partners: Partner[]
  cardData: Observable<Partner[]>

  dataSource = new MatTableDataSource<Partner>()




  constructor(private contestData: ContestDataService, private dialog: MatDialog, private UIservice: UIService) { 

      this.contestData.getPartners()

  }

  ngOnInit() {

    this.dataSubscription = this.contestData.partners$.subscribe(array => {
      if(array!=null){
        this.partners = array.sort((a,b) => a.priority - b.priority)
       this.assignDataToDataSource(this.partners)
      }
    
  })

  this.cardData = this.dataSource.connect()

  }

  assignDataToDataSource(_array: Partner[]){

    this.dataSource.data = _array
    this.cardData = this.dataSource.connect()
}

onAddPartner(){
  this.dialogSubscription = this.openPartnerDialog().subscribe(async result => {
    if(result)
    {
      let data = {
        title: $localize`New Partner`,
        content: $localize`New Partner has been added successfully !`
    }
      this.UIservice.openInfoDialog(data.title, data.content,true)
    }
  })
}

openPartnerDialog(){
  const dialogRef = this.dialog.open(PartnerDialogComponent, {data: {modeAdd: true}})

  return dialogRef.afterClosed()
}



ngOnDestroy(): void {
  this.dataSubscription.unsubscribe()
 }

}
