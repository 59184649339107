import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { LandingAppSharedModule } from 'projects/landingApp/src/app/app.module';
import { AdminAppSharedModule } from 'projects/adminApp/src/app/app.module';
import { ContestAppSharedModule } from 'projects/contestApp/src/app/app.module';
import { OrganiserAppSharedModule } from 'projects/organiserApp/src/app/app.module';
import { RefereeAppSharedModule } from 'projects/refereeApp/src/app/app.module';
import { AdminGuard } from '../auth-module/guards/admin-guard';
import { RefereeGuard } from '../auth-module/guards/referee-guard';
import { OrganiserGuard } from '../auth-module/guards/organiser-guard';
import { LandingGuard } from '../auth-module/guards/landing-guard';
import { ContestGuard } from '../auth-module/guards/contest-guard';


const routes: Routes = [
    {path: 'main', canActivate: [LandingGuard], loadChildren: () => import('../../../projects/landingApp/src/app/app.module').then(m => m.LandingAppSharedModule)},
    {path: 'organiser', canActivate: [OrganiserGuard], loadChildren: () => import('../../../projects/organiserApp/src/app/app.module').then(m => m.OrganiserAppSharedModule) },
    {path: 'organizator', canActivate: [OrganiserGuard], loadChildren: () => import('../../../projects/organiserApp/src/app/app.module').then(m => m.OrganiserAppSharedModule) },
    {path: 'referee', canActivate: [RefereeGuard], loadChildren: () => import('../../../projects/refereeApp/src/app/app.module').then(m => m.RefereeAppSharedModule) },
    {path: 'sedzia', canActivate: [RefereeGuard], loadChildren: () => import('../../../projects/refereeApp/src/app/app.module').then(m => m.RefereeAppSharedModule) },
    {path: 'admin', canActivate: [AdminGuard], loadChildren: () => import('../../../projects/adminApp/src/app/app.module').then(m => m.AdminAppSharedModule) },
    {path: 'contest', canActivate: [ContestGuard], loadChildren: () => import('../../../projects/contestApp/src/app/app.module').then(m => m.ContestAppSharedModule)},
    {path: 'zawody', canActivate: [ContestGuard], loadChildren: () => import('../../../projects/contestApp/src/app/app.module').then(m => m.ContestAppSharedModule)}, 
    {path: '', redirectTo: '/main', pathMatch: 'full'},
    {path: '**', redirectTo: '/main'}
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }), 
            LandingAppSharedModule.forRoot(),
            AdminAppSharedModule.forRoot(),
            ContestAppSharedModule.forRoot(),
            OrganiserAppSharedModule.forRoot(),
            RefereeAppSharedModule.forRoot()],
  exports: [RouterModule]
})
export class AppRoutingModule { 
}
