import { Component, OnInit, HostListener } from '@angular/core';
import { UIService } from 'src/app/shared/service/ui.service';
import { BaseLandingComponent } from '../baseLanding.component';



@Component({
  selector: 'app-main-view',
  templateUrl: './main-view.component.html',
  styleUrls: ['./main-view.component.css']
})
export class MainViewComponent extends BaseLandingComponent implements OnInit {

        
  constructor(UIservice: UIService) {
    super(UIservice);
  }

  ngOnInit() {
  }

}
