import { Component, OnInit } from '@angular/core';
import { BaseLandingComponent } from '../../baseLanding.component';
import { UIService } from 'src/app/shared/service/ui.service';

@Component({
  selector: 'app-rodo',
  templateUrl: './rodo.component.html',
  styleUrls: ['./rodo.component.css']
})
export class RodoComponent extends BaseLandingComponent implements OnInit {

  constructor(private UIservice: UIService) {
    super(UIservice);
  }

  ngOnInit() {
  }

}
