import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Observable, Subscription, ReplaySubject } from 'rxjs';
import { Team } from 'src/app/data/models/Manage/team.model';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BaseOrganiserComponent } from '../../../baseOrganiser.component';
import { UIService } from 'src/app/shared/service/ui.service';
import { ContestDataService } from '../../../services/contest-data.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Angler } from 'src/app/data/models/Manage/angler.model';
import { ManageContestDataService } from '../../../services/manage-contest-data';


@Component({
  selector: 'app-confirmed-teams',
  templateUrl: './confirmed-teams.component.html',
  styleUrls: ['./confirmed-teams.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ConfirmedTeamsComponent extends BaseOrganiserComponent implements OnInit, OnDestroy {


  teams$: Observable<Team[]>
  @ViewChild(MatPaginator) paginator: MatPaginator;

  editData = {
    teamId: null,
    anglerId: null,
    editType: null
  }

  dataSubscription: Subscription
  cardData: Observable<Team[]>
  allTeams: Team[]

  editModeTeam: boolean = false;
  editModeAngler: boolean = false
  editTeam: Team
  editAngler: Angler

  columnsToDisplay= ['station', 'promoTeam', 'Angler1', 'Angler2' ];
  dataSource = new MatTableDataSource<Team>()
  expandedElement: Team | null;
  pageEvent: PageEvent;

  constructor(public UIservice: UIService,
              private contestData: ContestDataService,
              private manageContest: ManageContestDataService) {
    super(UIservice);


      this.contestData.getSignedTeams()


  }

ngOnInit() {
    this.dataSubscription = this.contestData.signedTeams$.subscribe(array => {
        if(array!=null){
          this.allTeams = array.sort((a,b) => a.drawSequence - b.drawSequence)

         this.assignDataToDataSource(this.allTeams)
        }

    })

    this.dataSource.paginator = this.paginator;
    this.cardData = this.dataSource.connect()
  }

  sortByStation(){
    this.dataSource.data.sort((a,b) => a.station - b.station)

  }

  sortByDrawSequence(){
    this.dataSource.data.sort((a,b) => a.drawSequence - b.drawSequence)

  }

assignDataToDataSource(_array: Team[]){

    this.dataSource.data = _array
    this.dataSource.paginator = this.paginator;
    this.cardData = this.dataSource.connect()
}

applyFilter(filterValue: string) {

  let searchResults: Team[] = []

  let searchValue = filterValue.trim().toLowerCase()

  if(this.allTeams && searchValue != ''){

    searchResults =  this.allTeams.filter(object => {
      let values: string = ''
        values += object.privateData.leaderEmail ? object.privateData.leaderEmail.toLocaleLowerCase() : ''
        values += object.privateData.leaderPhone ? object.privateData.leaderPhone.toLocaleLowerCase() : ''
        values += object.promoTeam ? object.promoTeam.toLocaleLowerCase() : ''
        values += object.id ? object.id.toLocaleLowerCase() : ''

      object.anglers.forEach(angler => {
        for (const property in angler)
      {
        values += angler[property].toLocaleLowerCase()
      }
      })

      return values.indexOf(searchValue) != -1

    })


  }
    else
    {
      searchResults = this.allTeams
    }

    this.assignDataToDataSource(searchResults)

}

onAnglerEdit(teamID: string, anglerID: number): void{
  let _team = this.allTeams.filter(team => team.id === teamID).map<Team>(team => ({... team}))
  let _angler = _team[0].anglers[anglerID]
  this.editAngler = {..._angler}
  this.editData.anglerId = anglerID
  this.editData.teamId = teamID
  this.editData.editType = ''
  this.editModeAngler = true

}

onAnglerDelete(teamID: string, anglerID: string): void{
  let title = $localize`Angler Delete`
    let content = $localize`Are you sure to delete selected Angler ?`
    this.UIservice.openConfirmationDialog(title, content).subscribe(async result => {
    if(result)
    {
      const spinner = this.UIservice.openSpinnerDialog()
      await this.manageContest.deleteTeamMember(teamID,anglerID).subscribe({
        complete: () => spinner.close(),
        error: (e) => {
          let data = {
            title: $localize`Angler Delete Error`,
            content: e.message}


          this.UIservice.openInfoDialog(data.title, data.content,false)

          spinner.close()}
        })
    }
  })
}

onTeamEdit(id: string): void{
  let _team = this.allTeams.filter(team => team.id === id).map<Team>(team => ({... team}))
  this.editTeam = {..._team[0]}
  this.editData.teamId = id
  this.editData.editType = "team"
  this.editModeTeam = true

}

onTeamDelete(id: string): void{
    let title = $localize`Team Delete`
    let content = $localize`Are you sure to delete selected Team ?`
    this.UIservice.openConfirmationDialog(title, content).subscribe(async result => {
    if(result)
    {
      const spinner = this.UIservice.openSpinnerDialog()
      await this.manageContest.deleteTeam(id).subscribe({
        complete: () => spinner.close(),
        error: (e) => {
          let data = {
            title: $localize`Team Delete Error`,
            content: e.message
        }
          this.UIservice.openInfoDialog(data.title, data.content,false)

          spinner.close()
        }
      })
    }
  })
}

onAnglerEditUpdate(editedAngler: Angler){

  this.manageContest.updateAngler(this.editData.teamId, this.editData.anglerId, editedAngler, 'team')

  this.editModeAngler = false
}

onExitEdit(): void {
  this.editModeAngler = false
  this.editModeTeam = false
}

onStationAssign(id: string){

  this.UIservice.openSimpleInputDialog('station').subscribe(async result => {
    if(typeof result.newValue === 'number' && result.newValue > 0)
    {
      const spinner = this.UIservice.openSpinnerDialog()
      await this.manageContest.assignStation(id, result.newValue)
            .finally(() => { spinner.close()})
            .catch((e) => {
              let data = {
                title: $localize`Assign Station Error`,
                content: e.message
            }
              this.UIservice.openInfoDialog(data.title, data.content,false)

              spinner.close()
            })
    }
  })

}

  ngOnDestroy(): void {
   this.dataSubscription.unsubscribe()
  }
}
