<div class="Landing-Background" fxFlex="100" fxLayoutAlign="center center" fxLayoutAlign.gt-sm="center center"  fxLayout="column">

    <div class="content-container" fxLayout="column" fxLayoutAlign="start center" fxLayoutAlign.gt-sm="center center" fxLayoutGap="2vh">

        <h1 class="mat-h1" i18n="@@27252bb90e6e784aa77df07382554ce154865d42">Guides:</h1>


        <div fxLayout.lt-md="column" fxFlex.lt-md fxLayout="row" fxLayoutGap="2vh">

            <div [ngClass]="{'positionCard': !UIservice.isMobileSM, 'smallPositionCard': UIservice.isMobileSM}">
                <div fxFlexFill fxLayoutAlign="center center">
                    
                    <button fxFlexFill mat-button
                            i18n="@@c919b10589ad635f14a7966aa798ffaf2acc255e"
                            [routerLink]="[ 'competitor']"
                            >
                    Competitor
                    </button>

                </div>
            </div>

            <div [ngClass]="{'positionCard': !UIservice.isMobileSM, 'smallPositionCard': UIservice.isMobileSM}">
                <div fxFlexFill fxLayoutAlign="center center">
                    <button fxFlexFill mat-button
                            i18n="@@Referee"
                            [routerLink]="[ 'referee']"
                            >Referee</button>
                </div>
            </div>

            <div [ngClass]="{'positionCard': !UIservice.isMobileSM, 'smallPositionCard': UIservice.isMobileSM}">
                <div fxFlexFill fxLayoutAlign="center center">
                    <button fxFlexFill mat-button
                            i18n="@@48fedddf15c7e0d0282c46451c6e004aa38af6fb"
                            [routerLink]="[ 'organiser']"
                            >
                    Organiser
                    </button>
                </div>
            </div>

        </div>

        <!-- <h1 class="mat-h1">Politics:</h1> -->

        <div fxLayout.lt-md="column" fxLayout="row" fxLayoutGap="2vh">

            <div [ngClass]="{'positionCard': !UIservice.isMobileSM, 'smallPositionCard': UIservice.isMobileSM}">
                <div fxFlexFill fxLayoutAlign="center center">
                    <button fxFlexFill 
                            mat-button
                            i18n="@@6403ad84dd7fad60490a9e74adaa37a8ec1745bc"
                            [routerLink]="[ 'organiser/benefits']"><strong>Benefits</strong></button>
                </div>
            </div>

            <div [ngClass]="{'positionCard': !UIservice.isMobileSM, 'smallPositionCard': UIservice.isMobileSM}">
                <div fxFlexFill fxLayoutAlign="center center">
                    
                    <button fxFlexFill 
                            mat-button
                            i18n="@@924ada7cae46906deadbf5390c7d6462dc9d576d"
                            [routerLink]="[ 'privacy-politics']">Privacy Politics</button>

                </div>
            </div>

            <div [ngClass]="{'positionCard': !UIservice.isMobileSM, 'smallPositionCard': UIservice.isMobileSM}">
                <div fxFlexFill fxLayoutAlign="center center">
                    <button fxFlexFill 
                            mat-button
                            i18n="@@a5c0f27df0d9b97aee4436b45198a64483fd5aaa"
                            [routerLink]="[ 'rodo']">RODO Politics</button>
                </div>
            </div>

        </div>
    </div>


</div>