        
    <!-- <button [matMenuTriggerFor]="manageReferees"
            mat-button
            aria-label="Manage Referees Menu"
     >
    <mat-icon>expand_more</mat-icon>
    <span i18n="@@31b84e7a88c0cf802c4e93149f36b7f579d18755">Referees</span>
    </button> -->

    <mat-menu #manageReferees="matMenu" fxLayout="column">

                <button *ngIf="this.serveUser.isAdmin || this.serveUser.isOrganiser"
                        mat-menu-item
                        role="navigation"  
                        routerLink="/organiser/referees-list">

                        <mat-icon>supervisor_account</mat-icon>
                        <span i18n="RefereesList|Referees List in header@@">Referees List</span>
                </button>

            <button *ngIf="this.serveUser.isAdmin || this.serveUser.isOrganiser"
                mat-menu-item
                role="navigation" 
                routerLink="organiser/manage-referee">
            
                    <mat-icon>build</mat-icon>
                    <span i18n="ManageReferee|Manage Referee in header@@">Manage Referee</span>  
            </button>

            <button *ngIf="this.serveUser.isAdmin || this.serveUser.isOrganiser"
                mat-menu-item
                role="navigation"  
                routerLink="/organiser/invite-referee">

                    <mat-icon>accessibility_new</mat-icon>
                    <span i18n="InviteReferees|Invite Referees in header@@">Invite Referees</span>
            </button>

    </mat-menu>
 