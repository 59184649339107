import { Component, OnInit } from '@angular/core';
import { BaseRefereeComponent } from '../baseReferee.component';
import { UIService } from 'src/app/shared/service/ui.service';
import { ServeUserService } from 'src/app/auth-module/service/serveUser.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent extends  BaseRefereeComponent implements OnInit {

  hasContests: boolean = false
  assignToContest: boolean = false
  submitContest: boolean = false

  constructor(UIservice: UIService, 
              private serveUser: ServeUserService) {
    super(UIservice);



  }

  ngOnInit() {

    if (this.serveUser.user.hasOwnProperty('roles')){
      this.hasContests = this.serveUser.user.roles.hasOwnProperty('referee') ? true : false
    }

  }

  assignToNewContest(){
    this.assignToContest = true
    this.submitContest = false
  }

  onSubmitToContest(){
    this.assignToContest = false
    this.submitContest = true
  }

  

}
