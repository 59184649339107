import { Component, OnInit } from '@angular/core';
import { BaseLandingComponent } from '../../../baseLanding.component';
import { UIService } from 'src/app/shared/service/ui.service';
import { Challenge } from 'src/app/data/models/Manage/challenge.model';
import {ThemePalette} from '@angular/material/core';

export interface CheckedBoxes {
  name: string;
  completed: boolean;
  color: ThemePalette;
}


@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.css']
})
export class PricingComponent extends BaseLandingComponent implements OnInit {

  challenge: Challenge
  checkedBoxes: CheckedBoxes = {
    name: "checked",
     completed: true,
     color: 'warn'
 }
  amountTeamsMaximum = 1
  feeAmount = 1
  teamSquad = 2
  fb = {isAdvancedFacebook: false, 
        disabled: false}
  insta = {
    isAdvancedInstagram: false,
    disabled: false
  }
  
  price = 0

  _premiumFee = 600
  _feeRate = 1 / 100
  _functionRate = 1 / 400
  perTeamPercent = 0
  perTeamPrice = 0


  constructor(private UIservice: UIService) {
    super(UIservice);
  }

  ngOnInit() {

     this.calculatePrice()     

  }
  calculatePrice(){
    if (this.checkedBoxes.completed)
    {
      this.insta.disabled = false
      this.fb.disabled = false


    let _totalContestFees =  this.amountTeamsMaximum * this.feeAmount

    let _fishPhotoFee = _totalContestFees * this._feeRate
    let _isAdvancedFacebookFee = this.fb.isAdvancedFacebook ? _totalContestFees * this._functionRate : 0
    let _isAdvancedInstagramFee = this.insta.isAdvancedInstagram ? _totalContestFees * this._functionRate : 0

    this.price = Math.round(this._premiumFee + _fishPhotoFee  + _isAdvancedFacebookFee + _isAdvancedInstagramFee)
    this.perTeamPrice = Math.round(this.price / this.amountTeamsMaximum * 100) /100
    this.perTeamPercent = Math.round(this.perTeamPrice / this.feeAmount * 1000) / 1000
    }
    else{
      this.fb.disabled = true
      this.insta.disabled = true
      this.price = 0
      this.perTeamPrice = 0
      this.perTeamPercent = 0
    }
  }

  setTrue(){
    this.checkedBoxes.completed = true
    console.log("settrue")
  }
}
