import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Fee } from 'src/app/data/models/Manage/challenge.model';
import { DateAdapter, MAT_DATE_FORMATS, ThemePalette } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { AppDateAdapter, APP_DATE_FORMATS } from 'src/app/shared/helpers/format-datepicker';
import { UIService } from 'src/app/shared/service/ui.service';
import { ActivatedRoute } from '@angular/router';
import { ManageContestDataService } from '../../../services/manage-contest-data';

@Component({
  selector: 'app-fee',
  templateUrl: './fee.component.html',
  styleUrls: ['./fee.component.css'],
  providers: [{provide: DateAdapter, useClass: AppDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS}]
})
export class FeeComponent implements OnInit {
  @Input() modeEdit?: string = ''
  @Input() isCreate?: boolean = false
  @Input() fee: Fee
  @Output() edit: EventEmitter<string> = new EventEmitter<string>()
  @Output() endCreation: EventEmitter<boolean> = new EventEmitter<boolean>()
  public color: ThemePalette = 'primary';
  
  editFees: Fee

  teamRunnedAllowed: '' | 'Not Allowed' = 'Not Allowed'
  
  constructor(public UIservice: UIService,
              private manageContest: ManageContestDataService) { }

  ngOnInit() {
    if (this.fee.feeTeamRunner != 'Not Allowed')
    {
      this.teamRunnedAllowed = ''
    }

    if (this.isCreate)
    {
      this.modeEdit ='create'
      this.editFees = this.fee
    }
  }

  onEdit(){
    if(this.fee)
    {
      this.editFees = {...this.fee}
      this.modeEdit = 'edit'
      this.edit.emit('fee')
    } 
  }

  onUpdate(){

    if (this.isCreate)
    {
      this.endCreation.emit(true)
    }
    else
    {

      this.manageContest.updateChallengeChild("Fee" ,this.editFees)
        .then(() => {
        this.modeEdit = ''
        this.edit.emit('none')

      })
      
    }

  }

  onCancel(){
    this.modeEdit = ''
    this.edit.emit('none')
  }

  onDateInput(event: MatDatepickerInputEvent<Date>): void{
    
    let choosenDate = event.value.toISOString()

    this.editFees.deadline = choosenDate
  }

  onRunnerType(decision: string){

    if (decision === 'allowed' && this.editFees.feeTeamRunner === 'Not Allowed')
    {
      this.editFees.feeTeamRunner = ''
    }
    else if (decision === 'not' && this.editFees.feeTeamRunner != 'Not Allowed')
    {
      this.editFees.feeTeamRunner = 'Not Allowed'
    }
  }
}
