import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Organizer} from 'src/app/data/models/Manage/challenge.model';
import { User } from 'src/app/auth-module/user';
import { UIService } from 'src/app/shared/service/ui.service';
import { ActivatedRoute } from '@angular/router';
import { ManageContestDataService } from '../../../services/manage-contest-data';

@Component({
  selector: 'app-organizer',
  templateUrl: './organizer.component.html',
  styleUrls: ['./organizer.component.css']
})
export class OrganizerComponent implements OnInit {
  @Input() modeEdit?: string = ''
  @Input() isCreate?: boolean = false
  @Input() organizer: Organizer
  @Output() edit: EventEmitter<string> = new EventEmitter<string>()
  @Output() endCreation: EventEmitter<boolean> = new EventEmitter<boolean>()

  editOrg: Organizer

  constructor(public UIservice: UIService,
              private manageContest: ManageContestDataService,
              private route: ActivatedRoute) {
   }

  ngOnInit() {

    if (this.isCreate)
    {
      this.modeEdit ='create'
      this.editOrg = this.organizer
    }
  }

  onCancel(){
    this.modeEdit = ''
    this.edit.emit('none')
  }

  onUpdate(){

    if (this.isCreate)
    {
      this.endCreation.emit(true)
    }
    else
    {

      this.manageContest.updateChallengeChild("Organizer" ,this.editOrg)
        .then(() => {
        this.modeEdit = ''
        this.edit.emit('none')

      })
    }
  }

  onEdit(){
    if(this.organizer)
    {       
      this.editOrg = {...this.organizer}
      this.modeEdit = 'edit'
      this.edit.emit('organizer')
    }
    
  }

}
