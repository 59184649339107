<div class="Landing-Background"  fxFlex="100" style="min-height: 100%;" fxLayoutAlign="start start"  fxLayout="row">

    <div fxLayout="row" fxFlex="100" fxLayoutAlign="center start" class="content-container">

        <section *ngIf="!this.updateMessage || this.error" class="form-container" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="2vh">

            <h2 class="mat-h2" i18n="YourProfileData Header| Header for Profile Data@@">Your Profile Data</h2>

            <form #a="ngForm" fxLayout="column">

            <mat-form-field>
                <mat-label title="Email" i18n="AccountEmailLabel |Account Email Label@@">Email</mat-label>
                <input matInput
                        type="email"
                        [(ngModel)]="user.email"
                        [disabled]=true
                        name="UserEmail"
                >
            </mat-form-field>

            <mat-form-field>
                <mat-label title="Phone" i18n="AccountPhoneLabel |Account Phone Label@@">Phone</mat-label>
                <input matInput
                        type="tel"
                        [(ngModel)]="user.phone"
                        name="UserPhone"
                >
            </mat-form-field>

            <mat-form-field>
                <mat-label title="Name" i18n="AccountNameLabel |Account Name Label@@">Name</mat-label>
                <input matInput
                        type="text"
                        [(ngModel)]="this.user.userData.name"
                        required
                        name="UserName"
                >
            </mat-form-field>

            <mat-form-field>
                <mat-label title="Surname" i18n="AccountSurnameLabel |Account Surname Label@@">Surname</mat-label>
                <input matInput
                        type="text"
                        [(ngModel)]="this.user.userData.surname"
                        required
                        name="UserSurname"
                >
            </mat-form-field>

        </form>

        <h3 class="mat-h3" i18n="YourCompanyData Header| Header for Company Data@@">Your Company Data</h3>
        
        <form #c="ngForm" fxLayout="column">

            <mat-form-field>
                <mat-label title="Company Name" i18n="CompanyNameLabel |Company Name Label@@">Company Name</mat-label>
                <input matInput
                        type="text"
                        [(ngModel)]="user.userData.comapany.companyName"
                        required
                        name="companyName"
                >
            </mat-form-field>

            <mat-form-field>
                <mat-label title="Company TAX" i18n="CompanyTaxNumberLabel |Company TaX Number Label@@">Company Tax Number</mat-label>
                <input matInput
                        type="text"
                        [(ngModel)]="user.userData.comapany.companyTaxNumber"
                        required
                        name="companyTaxNumber"
                >
            </mat-form-field>

            <mat-form-field>
                <mat-label title="Company Adress" i18n="CompanyAdressLabel |Company Adress Label@@">Company Adress</mat-label>
                <textarea matInput
                        type="email"
                        [(ngModel)]="user.userData.comapany.companyAddress"
                        required
                        name="companyAdress"
                        cdkTextareaAutosize
                        cdkAutosizeMinRows="1"
                        cdkAutosizeMaxRows="10"
                ></textarea>
            </mat-form-field>

        </form>

       

        <label *ngIf="!a.valid || !a.touched || !c.valid || !c.touched" i18n="fillRequiredFields| fill Required Data@@">* required fields</label>

        <button *ngIf="a.valid && c.valid && (a.touched || c.touched)"
        mat-stroked-button
        style="color:#23c268;"
        i18n="Edit Save Button| Edit Save Button@@"
        (click)="onUpdate()">Save</button>

        


    </section>

    <p style="font-size: 18px;" *ngIf="this.updateMessage" >{{this.updateMessage | uppercase}} </p>

    </div>

</div>