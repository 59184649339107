


<aside id="mainFooter" *ngIf="UIService.isMobileSM; else bigDisplayFooter" fxFlex fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10vh" class="mat-small">
    <ng-container *ngIf="UIService.isContest | async">
    <address *ngIf="organiser" class="orgContactsGroup">
        <h3 i18n="Organizator Contact| Header of Organizator Contact@@" class="mat-h3">Organiser Contact:</h3>
        <label *ngIf="this.organiser.orgCompany"><b>{{ this.organiser.orgCompany }}</b></label>
        <br>
        <label *ngIf="this.organiser.orgName || this.organiser.orgSurname">{{this.organiser.orgName}}&nbsp;{{this.organiser.orgSurname}}</label>
        <br>

        <div class="addressItem" fxLayoutAlign="start center">
            <mat-icon>phone</mat-icon>
             <label for="orgPhone" i18n="Phone| Phone@@">Phone:</label>
             <a class="labelValue" id="orgPhone"  targer="_blank" [attr.href]="'tel:' + this.organiser.orgPhone">{{ this.organiser.orgPhone }}</a>
         </div>

         <div class="addressItem" fxLayoutAlign="start center">
             <mat-icon>email</mat-icon>
             <label for="orgEmail">Email:</label>
             <a class="labelValue" id="orgEmail" targer="_blank" [attr.href]="'mailto:' + this.organiser.orgEmail">{{ this.organiser.orgEmail }}</a>
         </div>

         <div *ngIf="this.organiser.orgSite" class="addressItem" fxLayoutAlign="start center">
             <mat-icon>home</mat-icon>
             <label for="orgSite" i18n="Site| Site@@">Site:</label>
             <a class="labelValue" id="orgSite" targer="_blank" [attr.href]="this.organiser.orgSite">{{ this.organiser.orgSite }}</a>
         </div>
    </address>
    </ng-container>

    <div style="width: 100%;" fxLayout="column" fxLayoutAlign="center center">
        <div fxFlexFill fxLayoutAlign="center center">
                <img style="max-height: 250px; max-width: 250px;" src="assets/logos/rsz_cucy21v3-min.png">
        </div>

    </div>

    <address class="ccContactGroup">
        <h3 *ngIf="organiser" i18n="Carp Contest Contact| Header of Carp Contest Contact@@" class="ContactTitle">Contact:</h3>
        <h1 class="carp-contest-title">Carp Contest</h1>

        <div *ngIf="!(UIService.isContest | async)" class="addressItem" fxLayoutAlign="start center">
                <mat-icon>phone</mat-icon>
                 <label for="orgPhone" i18n="Phone| Phone@@">Phone:</label>
                 <a class="labelValue" id="orgPhone" href="tel:513505676">+48 513 505 676</a>
             </div>

             <div class="addressItem" fxLayoutAlign="start center">
                 <mat-icon>email</mat-icon>
                 <label for="orgEmail">Email:</label>
                 <a class="labelValue" id="orgEmail" href="mailto:administracja@carp-contest.pl">administracja@carp-contest.pl</a>
             </div>

             <div class="addressItem" fxLayoutAlign="start center">
                 <mat-icon>home</mat-icon>
                 <label for="orgSite" i18n="Site| Site@@">Site:</label>
                 <a class="labelValue" id="orgSite" href="https//carp-contest.pl">https//carp-contest.pl</a>
             </div>

            </address>
</aside>





<ng-template #bigDisplayFooter>


<aside id="mainFooter"  fxLayout="row" fxLayoutAlign="center space-between" >


    <div fxFlex="30" fxLayoutAlign="start center">
        <ng-container *ngIf="UIService.isContest | async; else linksList">
        <address *ngIf="organiser" class="orgContactsGroup">
           <h3 i18n="Organizator Contact| Header of Organizator Contact@@" class="ContactTitle">Organiser Contact:</h3>
           <label *ngIf="this.organiser.orgCompany"><b>{{ this.organiser.orgCompany }}</b></label>
           <br>
           <label *ngIf="this.organiser.orgName || this.organiser.orgSurname">{{this.organiser.orgName}}&nbsp;{{this.organiser.orgSurname}}</label>
           <br>

           <div class="addressItem" fxLayoutAlign="start center">
               <mat-icon>phone</mat-icon>
                <label for="orgPhone" i18n="Phone| Phone@@">Phone:</label>
                <a class="labelValue" id="orgPhone"  targer="_blank" [attr.href]="'tel:' + this.organiser.orgPhone">{{ this.organiser.orgPhone }}</a>
            </div>

            <div class="addressItem" fxLayoutAlign="start center">
                <mat-icon>email</mat-icon>
                <label for="orgEmail">Email:</label>
                <a class="labelValue" id="orgEmail" targer="_blank" [attr.href]="'mailto:' + this.organiser.orgEmail">{{ this.organiser.orgEmail }}</a>
            </div>

            <div *ngIf="this.organiser.orgSite" class="addressItem" fxLayoutAlign="start center">
                <mat-icon>home</mat-icon>
                <label for="orgSite" i18n="Site| Site@@">Site:</label>
                <a class="labelValue" id="orgSite" targer="_blank" [attr.href]="this.organiser.orgSite">{{ this.organiser.orgSite }}</a>
            </div>
       </address>
        </ng-container>
       <ng-template #linksList>


            <mat-list role="list" fxLayout="column">

                    <a role='navigation'
                        mat-list-item
                        routerLink="/main"
                        aria-label="Main Page"
                        >
                        <mat-icon>home</mat-icon>
                        <span i18n="Home| Home in footer@@">Home</span>
                    </a>

                    <a role='navigation'
                        mat-list-item
                        routerLink="/main/finder"
                        aria-label="Contests List"
                        >
                        <mat-icon>list</mat-icon>
                        <span i18n="ViewContests| View Contests in footer@@">View Contests</span>
                    </a>

                    <a role='navigation'
                        mat-list-item
                        routerLink="/main/info"
                        aria-label="Guides"
                        >
                        <mat-icon>book</mat-icon>
                        <span i18n="Guider| Guides link in footer@@">Guides</span>
                    </a>

                    <a role='navigation'
                        mat-list-item
                        routerLink="/main/info"
                        aria-label="Politics"
                        >
                        <mat-icon>book</mat-icon>
                        <span i18n="@@924ada7cae46906deadbf5390c7d6462dc9d576d">Privacy Politics</span>
                    </a>

            </mat-list>

       </ng-template>

    </div>

    <div fxLayout="column" fxFlex="30" fxLayoutAlign="end center">
            <div fxFlexFill fxLayoutAlign="center center">
                    <img style="max-height: 250px; max-width: 250px;" src="assets/logos/rsz_cucy21v3-min.png">
            </div>

    </div>

    <div fxFlex="30" fxLayoutAlign="end center" >
            <address class="ccContactGroup">
                    <h3 i18n="Carp Contest Contact| Header of Carp Contest Contact@@" class="ContactTitle">Contact:</h3>
                    <h1 class="carp-contest-title">Carp Contest</h1>

                    <div *ngIf="!(UIService.isContest | async)" class="addressItem" fxLayoutAlign="start center">
                            <mat-icon>phone</mat-icon>
                             <label for="orgPhone" i18n="Phone| Phone@@">Phone:</label>
                             <a class="labelValue" id="orgPhone" href="tel:513505676">+48 513 505 676</a>
                         </div>

                         <div class="addressItem" fxLayoutAlign="start center">
                             <mat-icon>email</mat-icon>
                             <label for="orgEmail" >Email:</label>
                             <a class="labelValue" id="orgEmail" href="mailto:administracja@carp-contest.pl">administracja@carp-contest.pl</a>
                         </div>

                         <div class="addressItem" fxLayoutAlign="start center">
                             <mat-icon>home</mat-icon>
                             <label for="orgSite" i18n="Site| Site@@">Site:</label>
                             <a class="labelValue" id="orgSite" href="https//carp-contest.pl">https//carp-contest.pl</a>
                         </div>

                </address>
    </div>
</aside>

</ng-template>
