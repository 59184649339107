import { Component, OnInit, Output, EventEmitter} from '@angular/core';

import { AuthService } from 'src/app/auth-module/service/auth.service';
import { ServeUserService } from 'src/app/auth-module/service/serveUser.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-header-auth-links',
  templateUrl: './header-auth-links.component.html', 
})
export class HeaderAuthLinksComponent implements OnInit {
 

  constructor(public authService: AuthService, 
              public serveUser: ServeUserService,
              private router: Router) { 
  }

  ngOnInit() {
  }

  onlogOut(){
    this.authService.signoutUser()
      // .then(() => { this.router.navigate(['main'])})
  }



}