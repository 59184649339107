import { Injectable } from "@angular/core";
import { Team } from '../data/models/Manage/team.model';
import { Observable } from 'rxjs';
import { TeamService } from 'src/app/core-module/entityServices/team.service';
import { GlobalsService } from 'src/app/core-module/entityServices/serviceGlobals';
import { map } from 'rxjs/operators';
import { Fish } from '../data/models/Manage/fish.model';
import { FishService } from 'src/app/core-module/entityServices/fish.service';
import { RankingItem } from '../data/models/Manage/rankingItem.model';
import { RankingService } from 'src/app/core-module/entityServices/ranking.service';

import { MessageService } from 'src/app/core-module/entityServices/message.service';
import { Message } from 'src/app/data/models/Manage/message.model';


@Injectable()
export class LiveDataService {

    team$: Observable<Team[]>
    fish$: Observable<Fish[]>
    ranking$: Observable<RankingItem[]>
    messages$: Observable<Message[]>

    constructor(private teamService: TeamService,
                private fishService: FishService,
                private rankingService: RankingService,
                private messageService: MessageService,
                private globals: GlobalsService
        )
    {
         
    }


    getTeams(){
        this.teamService.basePathReference = this.globals.getTeamsPathPublic();
        this.team$ = this.teamService.getTeams().snapshotChanges()
        .pipe<Team[]>(map(docArray => {
            return docArray.map<Team>(doc => {
                    return {
                            id: doc.key,
                            ...doc.payload.val()
                            }
                        })
            }))
    }
    getFish(){
        this.fishService.basePathReference = this.globals.getFishPath();
        this.fish$ = this.fishService.getFish().snapshotChanges()
        .pipe<Fish[]>(map(docArray => {
            return docArray.map<Fish>(doc => {
                return {
                    id: doc.key,
                    ...doc.payload.val()
                }
            })
        }))
    }
    getRanking(){
        this.rankingService.basePathReference = this.globals.getRankingPath();
        this.ranking$ = this.rankingService.getRanking().snapshotChanges()
        .pipe<RankingItem[]>(map(docArray => {
            return docArray.map<RankingItem>(doc => {
                return {
                    id: doc.key,
                    ...doc.payload.val()
                }
            })
        }))
    }
    getMessages(){
        this.messageService.basePathReference = this.globals.getMessagePath();
        this.messages$ = this.messageService.getMessages().snapshotChanges()
        .pipe<Message[]>(map(docArray => {
            return docArray.map<Message>(doc => {
                return {
                    id: doc.key,
                    ...doc.payload.val()
                }
            })
        }))
    }
} 