<div class="Landing-Background">

        <div fxFlexFill  fxLayoutAlign="center center"  fxLayout="column">
        
            <div style="margin: 5vh;" fxFlex.lt-md="100%" fxLayout="column" fxLayoutAlign="center center">
        
            <mat-card *ngIf="!changeActions; else changeActionsForm"  fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="2vh">
        
                    <mat-card-title i18n="LogIn| log In Header@@">Log In</mat-card-title>
    
                    <mat-card-content  fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="2vh">
                    <form fxLayout="column" [formGroup]="form" (keyup.enter)="onSubmitEnterForm($event)" >
                            
                        <mat-form-field appearance="outline">
                                <mat-label i18n="LoginEmailLabel| label for email in login form@@">Email address</mat-label>
                                <input  matInput 
                                        id="emailAdress"
                                        name="email"
                                        formControlName="email" 
                                        required>
                                <mat-error *ngIf="this.form.get('email').hasError">{{getErrorEmailValidator()}}</mat-error>
                        </mat-form-field>
            
                        <mat-form-field appearance="outline">
                                <mat-label i18n="LoginPasswordLabel| label for password in login form@@">Password</mat-label>
                                <input  matInput
                                        id="password"
                                        type="password"
                                        name="password"
                                        formControlName="password"
                                        autocomplete="on"  
                                        required>
                                <mat-error *ngIf="this.form.get('password').hasError">{{getErrorPasswordValidator()}}</mat-error>
                        </mat-form-field>
                           
                    </form>
    
                    <p *ngIf="this.error != null" class="error">
                        {{this.error}}
                    </p>
                    
                    <button mat-stroked-button [disabled]="!form.valid" 
                                type="submit"
                                (click)="onSubmit()"
                                i18n="SubmitButton| Submit Button@@">Submit</button>

                        <div style="width: 100%; margin-top: 2vh;">
                        <label i18n="NotaMember| Not a member? @@"> Not a member?</label>
                        &nbsp;
                        <a style="text-decoration: none;" [routerLink]="['/main/signup']"  i18n="SignUpButton| Sign Up Button@@">Sign Up</a>

                        </div>
                
                        <div style="width: 100%; margin-top: 2vh;">
                                <label i18n="ForgotenPassword| Forgoten Password? @@">Forgotten Password?</label>
                                &nbsp;
                                <a style="text-decoration: none;" href="javascript:void(0);" (click)="onForgottenPassword()"  i18n="SignUpButton| Sign Up Button@@">Reset Password</a>
                        </div>

                        <div *ngIf="!this.userEmailVerificated" style="width: 100%; margin-top: 2vh;">
                                <label i18n="@@AccountNotVerified">Account not verified?</label>
                                &nbsp;
                                <a style="text-decoration: none;" href="javascript:void(0);" (click)="onNewVerificationEmail()"  i18n="@@sendNewVerificationEmail">Send new verification email</a>
                        </div>
                
            </mat-card-content>
            </mat-card>
        
            <ng-template #changeActionsForm>

                <mat-card fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="2vh">
        
                        <mat-card-title *ngIf="this.changePassword" i18n="RestorePassword| Restore Password Header@@">Restore Password</mat-card-title>
                        <mat-card-title *ngIf="this.verificationEmail" i18n="@@sendNewVerificationEmail">Send new verification email</mat-card-title>

                        <mat-card-content  fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="2vh">
                        <form fxLayout="column" >
                                
                            <mat-form-field appearance="outline">
                                    <mat-label i18n="LoginEmailLabel| label for email in login form@@">Email address</mat-label>
                                    <input  matInput 
                                            id="emailAdress"
                                            name="email"
                                            [formControl]="email" 
                                            required>
                                    <mat-error *ngIf="email.invalid">{{getErrorRestoreEmailValidator()}}</mat-error>
                            </mat-form-field>
                               
                        </form>
        
                        <p *ngIf="this.error != null" class="error">
                            {{this.error}}
                        </p>
                        
                        <button mat-stroked-button *ngIf="this.changePassword" [disabled]="email.invalid || !email.touched" 
                                    type="submit"
                                    (click)="onRestoreSubmit()"
                                    i18n="SubmitButton| Submit Button@@">Submit</button>
                        
                        <button mat-stroked-button *ngIf="this.verificationEmail" [disabled]="email.invalid || !email.touched" 
                                    type="submit"
                                    (click)="onVerificationEmailSubmit()"
                                    i18n="SubmitButton| Submit Button@@">Submit</button>
    
                            <div style="width: 100%; margin-top: 2vh;">
                            <label i18n="NotaMember| Not a member? @@"> Not a member?</label>
                            &nbsp;
                            <a style="text-decoration: none;" [routerLink]="['/main/signup']"  i18n="SignUpButton| Sign Up Button@@">Sign Up</a>
    
                            </div>
                    
                            <div style="width: 100%; margin-top: 2vh;">
                                    <label i18n="BackToLoginForm| Back To Login Form@@">Back to Login form</label>
                                    &nbsp;
                                    <a style="text-decoration: none;" href="javascript:void(0);" (click)="onForgottenPassword()"  i18n="LogIn| Log In @@">Log In</a>
                            </div>
                    
                </mat-card-content>
                </mat-card>

            </ng-template>
            
            
        </div>
        </div>
        </div>


<!-- <div class="Landing-Background">

<div fxFlexFill  fxLayoutAlign="center center"  fxLayout="column">

    <div style="margin: 5vh;" fxFlex.lt-md="100%" fxLayout="column" fxLayoutAlign="center center">

    <div  fxLayout="column" class="loginContainer" fxLayoutAlign="center center" fxLayoutGap="2vh">

            <h1 class="mat-h1" i18n="LogIn| log In Header@@">Log In</h1>
            <form fxLayout="column" #authForm="ngForm" (ngSubmit)="onSubmit()" >

                <mat-form-field appearance="outline">
                        <mat-label i18n="LoginEmailLabel| label for email in login form@@">Email address</mat-label>
                        <input  matInput 
                                id="emailAdress"
                                name="email"
                                [formControl]="email" 
                                required>
                        <mat-error *ngIf="email.invalid">{{getErrorEmailValidator()}}</mat-error>
                </mat-form-field>
    
                <mat-form-field appearance="outline">
                        <mat-label i18n="LoginPasswordLabel| label for password in login form@@">Password</mat-label>
                        <input  matInput
                                id="password"
                                type="password"
                                name="password"
                                [formControl]="password"
                                autocomplete="on"  
                                required>
                        <mat-error *ngIf="password.invalid">{{getErrorPasswordValidator()}}</mat-error>
                </mat-form-field>
                <button mat-stroked-button [disabled]="email.invalid || password.invalid" 
                        type="submit"
                        i18n="SubmitButton| Submit Button@@">Submit</button>
            </form>

            <p *ngIf="this.error != null" class="mat-small" style="color: white;">
                {{this.error}}
            </p>
            
            <label i18n="or| or@@">or</label>
            <button mat-stroked-button 
                        [routerLink]="['/main/signup']"
                        i18n="SignUpButton| Sign Up Button@@"            
            >
            Sign Up</button>
        

    </div>

    
</div>
</div>
</div> -->