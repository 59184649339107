import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { BaseOrganiserComponent } from '../../../baseOrganiser.component';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription, Observable } from 'rxjs';
import { Referee } from 'src/app/data/models/Manage/referee.model';
import { UIService } from 'src/app/shared/service/ui.service';
import { ContestDataService } from '../../../services/contest-data.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { ManageContestDataService } from '../../../services/manage-contest-data';

@Component({
  selector: 'app-reservation-referees',
  templateUrl: './reservation-referees.component.html',
  styleUrls: ['./reservation-referees.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ])],
})
export class ReservationRefereesComponent extends BaseOrganiserComponent implements OnInit, OnDestroy  {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  dataSubscription: Subscription
  cardData: Observable<Referee[]>

  allReferees: Referee[]

  columnsToDisplay= ['Name', 'Surname', 'Phone', 'Actions'];
  dataSource = new MatTableDataSource<Referee>()
  expandedElement: Referee | null;
  pageEvent: PageEvent;

  constructor(public UIservice: UIService,
    private contestData: ContestDataService,
    private manageContest: ManageContestDataService) {
    super(UIservice);

    this.contestData.getRefereesReservation()

  }




  ngOnInit() {
    this.dataSubscription = this.contestData.refereesReservation$.subscribe(array => {
      if(array!=null){
        this.allReferees = array.filter(x => x.active)
       this.assignDataToDataSource(this.allReferees)
      }

  })

  this.dataSource.paginator = this.paginator;
  this.cardData = this.dataSource.connect()

  }

  assignDataToDataSource(_array: Referee[]){

    this.dataSource.data = _array
    this.dataSource.paginator = this.paginator;
    this.cardData = this.dataSource.connect()
}

  applyFilter(filterValue: string) {

  let searchResults: Referee[] = []

  let searchValue = filterValue.trim().toLowerCase()

  if(this.allReferees && searchValue != ''){

    searchResults =  this.allReferees.filter(object => {
      let values: string = ''
        values += object.phone ? object.phone: ''
        values += object.email ? object.email.toLocaleLowerCase() : ''
        values += object.userData.name ? object.userData.name.toLocaleLowerCase() : ''
        values += object.userData.surname ? object.userData.surname.toLocaleLowerCase() : ''

      return values.indexOf(searchValue) != -1

    })


  }
    else
    {
      searchResults = this.allReferees
    }

    this.assignDataToDataSource(searchResults)

}

onRefereeConfirm(key: string){
    let title = $localize`Referee Confirmation`
    let content = $localize`Are you sure to confirm selected Referee ?`
    this.UIservice.openConfirmationDialog(title, content).subscribe(async result => {
    if(result)
    {
      const spinner = this.UIservice.openSpinnerDialog()
      this.manageContest.confirmRefereeReservation(key).subscribe({complete: () =>  spinner.close(), error: (e) => {
        let data = {
          title: $localize`Confirm Referee Error`,
          content: e.message
      }
        this.UIservice.openInfoDialog(data.title, data.content,false)

        spinner.close()
      } })
    }
  })
}

onRefereeReservationDelete(key: string){
  let title = $localize`Delete Referee`
    let content = $localize`Are you sure to delete selected Referee ?`
    this.UIservice.openConfirmationDialog(title, content).subscribe(async result => {
    if(result)
    {
      const spinner = this.UIservice.openSpinnerDialog()
      this.manageContest.deleteRefereeReservation(key).subscribe({complete: () =>  spinner.close(),

        error: (e) => {
        let data = {
          title: $localize`Referee Delete Error`,
          content: e.message
          }
        this.UIservice.openInfoDialog(data.title, data.content,false)

        spinner.close()
      } })
    }
  })
}

  ngOnDestroy(): void {
    this.dataSubscription.unsubscribe()
  }

}
