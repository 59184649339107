<div class="content-container" fxLayout="row wrap" fxLayoutGap="2vw">

    <app-main-data [modeEdit]="true" [challenge]="this.challenge"></app-main-data>  

    <app-awards *ngIf="this.challenge" [modeEdit]="true" [awards]="this.challenge.Awards"></app-awards>

    <app-organizer *ngIf="this.challenge" [modeEdit]="true" [organizer]="this.challenge.Organizer"></app-organizer>

    <app-fee *ngIf="this.challenge" [modeEdit]="true" [fee]="this.challenge.Fee"></app-fee>

    <app-links *ngIf="this.challenge" [modeEdit]="true" [links]="this.challenge.Links"></app-links>

</div>