import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { FlexLayoutModule, CoreModule } from '@angular/flex-layout';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MaterialModule } from 'src/app/shared/material.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { RecaptchaModule, RecaptchaFormsModule, RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';
import { ProvideDataService } from './services/provideDataService';
import { WelcomeComponent } from './presentation/content/welcome/welcome.component';
import { LiveComponent } from './presentation/content/live/live.component';
import { TeamCatchesComponent } from './presentation/content/live/team-catches/team-catches.component';
import { GeneralScoresComponent } from './presentation/content/live/general-scores/general-scores.component';
import { WinnerPlacesComponent } from './presentation/content/live/general-scores/winner-places/winner-places.component';
import { RankingPlacesComponent } from './presentation/content/live/general-scores/ranking-places/ranking-places.component';
import { MessageComponent } from './presentation/content/live/general-scores/message/message.component';
import { ContestChartComponent } from './presentation/content/live/contest-chart/contest-chart.component';
import { SignupComponent } from './presentation/content/signup/signup.component';
import { TeamsComponent } from './presentation/content/teams/teams.component';
import { TeamsTableComponent } from './presentation/content/teams/teams-table/teams-table.component';
import { AwardsComponent } from './presentation/content/welcome/awards/awards.component';
import { TeamDetailsComponent } from './presentation/content/welcome/team-details/team-details.component';
import { ContestDetailsComponent } from './presentation/content/welcome/contest-details/contest-details.component';
import { PlaceTimeComponent } from './presentation/content/welcome/place-time/place-time.component';
import { JoinStartComponent } from './presentation/content/welcome/join-start/join-start.component';
import { AwardsDetailsComponent } from './presentation/content/welcome/awards/awards-details/awards-details.component';
import { DrawStationsComponent } from './presentation/content/draw-stations/draw-stations.component';
import { RefereesComponent } from './presentation/content/referees/referees.component';
import { YouTubePlayerModule } from '@angular/youtube-player';
import {YoutubePlayerComponent} from './presentation/content/live/general-scores/message/extraData/youtube.component';


const providers = [ProvideDataService,
  {
    provide: RECAPTCHA_SETTINGS,
    useValue: { siteKey: '6Lc2RdAUAAAAAEL6M8tuhmieByhTz-c-DW2vO5It' } as RecaptchaSettings
  }

]

@NgModule({
  declarations: [
    AppComponent,
    WelcomeComponent,
    LiveComponent,
    TeamCatchesComponent,
    GeneralScoresComponent,
    WinnerPlacesComponent,
    RankingPlacesComponent,
    MessageComponent,
    YoutubePlayerComponent,
    ContestChartComponent,
    SignupComponent,
    TeamsComponent,
    TeamsTableComponent,
    AwardsComponent,
    TeamDetailsComponent,
    ContestDetailsComponent,
    PlaceTimeComponent,
    JoinStartComponent,
    AwardsDetailsComponent,
    DrawStationsComponent,
    RefereesComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    FlexLayoutModule,
    CoreModule,
    SharedModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    YouTubePlayerModule
  ],
  exports:[
  ],
  providers: providers,
  bootstrap: [AppComponent]
})
export class AppModule { }

@NgModule({})
export class ContestAppSharedModule{
  static forRoot(): ModuleWithProviders<AppModule> {
    return {
      ngModule: AppModule,
      providers: providers
    }
  }
}
