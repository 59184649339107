        <mat-tab-group color="warn" mat-align-tabs="center" fxFlex="100">

          <mat-tab>

            <ng-template mat-tab-label>
                    <label i18n="@@Live">Live</label>
            </ng-template>

            <ng-template matTabContent>
              <app-message fxFill [dataLoadEvent]="messagesDataLoaded.asObservable()"></app-message>
          </ng-template>

          </mat-tab>

                <mat-tab>

                        <ng-template mat-tab-label>
                                <label i18n="@@GeneralTab">General</label>
                        </ng-template>

                        <app-general-scores
                        [awards]="awards"
                        [challengeType]="challengeType"
                        [messages]="messages"
                        [currency]="this.currency"
                        [rankingDataLoadEvent]="rankingDataLoaded.asObservable()"

                        >
                        </app-general-scores>

                </mat-tab>

                <mat-tab >
                    <ng-template mat-tab-label>
                            <label i18n="@@RankingTab">Ranking</label>
                    </ng-template>


                    <ng-template matTabContent>

                        <app-team-catches [ranking]="ranking" [rankingDataLoadEvent]="rankingDataLoaded.asObservable()" fxLayoutAlign="center center"></app-team-catches>
                   </ng-template>
                </mat-tab>

                <mat-tab>

                        <ng-template mat-tab-label>
                                <label i18n="@@ChartsTab">Charts</label>
                        </ng-template>
                        <ng-template matTabContent>
                                <app-contest-chart class="chartContainer"></app-contest-chart>
                        </ng-template>

                </mat-tab>

        </mat-tab-group>
