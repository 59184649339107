import { Component, OnInit } from '@angular/core';
import { BaseOrganiserComponent } from '../../baseOrganiser.component';
import { UIService } from 'src/app/shared/service/ui.service';

@Component({
  selector: 'app-balance',
  templateUrl: './balance.component.html',
  styleUrls: ['./balance.component.css']
})
export class BalanceComponent extends BaseOrganiserComponent implements OnInit {

  constructor(private UIservice: UIService) {
    super(UIservice);
  }

  ngOnInit() {
  }

}
