import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Referee } from 'src/app/data/models/Manage/referee.model';
import { UIService } from 'src/app/shared/service/ui.service';
import { ProvideDataService } from '../../../services/provideDataService';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-referees',
  templateUrl: './referees.component.html',
  styleUrls: ['./referees.component.css']
})
export class RefereesComponent extends BaseComponent implements OnInit, OnDestroy {

  private keySubscription: Subscription;
  private refereesSubscription: Subscription

  public refereesList: Referee[] = []

  constructor(private route: ActivatedRoute, 
    private dataService: ProvideDataService,
    public UIservice: UIService) {
    super(route,dataService, UIservice);

    this.keySubscription = this.dataService.keyReady.asObservable().subscribe(bool => {
      if(bool){
          this.refereesSubscription = this.dataService.getReferees()
              .subscribe(referees => {
                  this.refereesList = referees
              })
      }
    })
  }

  ngOnInit(): void {
  }

}
