<section fxFlex fxLayout="column grow" fxLayoutAlign="center start" class="content-container">
    <div fxLayout="column" fxFlexFill fxLayoutAlign.xs="start center" fxLayoutAlign="start center" fxLayoutGap="2vh">

        <h1 *ngIf="this.creation === 'newteam'" style="width: 100%; text-align: center;" class="mat-h1" i18n="@@AddNewTeam">Add New Team </h1>
        <h1 *ngIf="this.creation === 'newreservation'" style="width: 100%; text-align: center;" class="mat-h1" i18n="@@AddNewReservation">Add New Reservation</h1>


        <app-edit-team [editTeam]="this.team" [teamID]="'new'" [editType]="this.creation" (isValid)=checkTeam($event)></app-edit-team>


            <app-edit-angler *ngFor="let angler of this.team.anglers; index as i" [editAngler]="angler" [indexAngler]="i" [editType]="this.creation" (isValid)=checkAnglers(i,$event)></app-edit-angler>

            <div fxLayout="row" class="action-buttons" fxLayoutAlign="center center" fxLayoutGap="2vw">

              <button *ngIf="this.isValidTeam && IsValidAnglers"
              mat-raised-button
              color="primary"
              i18n="@@SaveTeam"
              (click)="onSaveTeam()">Save Team</button>

              <button
              mat-raised-button
              color="warn"
              i18n="cancel| cancel button@@"
              (click)="onCancel()">Cancel</button>

          </div>


    </div>




</section>
