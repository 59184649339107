import { Injectable } from '@angular/core';
import { FirebaseService } from 'src/app/core-module/base/firebase.service';
import { AngularFireList } from '@angular/fire/compat/database';
import { Team } from 'src/app/data/models/Manage/team.model';
import { Reservation } from 'src/app/data/models/Manage/reservation.model';

@Injectable()
export class TeamService {

    basePathReference: string;
    reservationPath: string;
    teamsPath: string;

    constructor(private firebaseService: FirebaseService){
    }

    getTeams(): AngularFireList<Team | any>{
        return this.firebaseService.getCollection(this.basePathReference)
    }

    getReservationTeams(): AngularFireList<Reservation | any>{
        return this.firebaseService.getObjectListByProperty(this.reservationPath,'isReservation',true)
    }

    getReserveTeams(): AngularFireList<Reservation | any>{
        return this.firebaseService.getObjectListByProperty(this.reservationPath,'isReserve',true)
    }

    setTeamReservation(_reservation) {

        let key = this.firebaseService.setObjectCreateHash(this.reservationPath)

        return this.firebaseService.updateObjectByKey(this.reservationPath + '/', _reservation, key )
    }

    setTeam(team: Team){
        let key = this.firebaseService.setObjectCreateHash(this.teamsPath)

        return this.firebaseService.updateObjectByKey(this.teamsPath + '/', team, key)
    }

    updateTeamReservation(_team: Team, reservsationKey: string){
        let key = reservsationKey + '/team'

        return this.firebaseService.updateObjectByKey(this.reservationPath + '/', _team, key)
    }

    updateTeam(_team: Team, teamKey: string){
        return this.firebaseService.updateObjectByKey(this.teamsPath + '/', _team, teamKey)

    }

    deleteTeam(teamKey: string, contestKey: string){
        const deleteAction = this.firebaseService.callFirebaseFunction('deleteTeam')

        return deleteAction({teamKey: teamKey, contestKey: contestKey})
    }

    deleteReservation(reservationKey: string, contestKey: string){
        const deleteAction = this.firebaseService.callFirebaseFunction('deleteReservation')

        return deleteAction({reservationKey: reservationKey, contestKey: contestKey})

    }

    assignTeamStation(teamKey: string, station: number){
        const path = this.teamsPath + '/' + teamKey + '/station'

        return this.firebaseService.getDBRef(path).set(station)
    }



}
