import { Component, OnInit } from '@angular/core';

import { ChartFishPartData } from 'src/app/data/models/Manage/chartFishPartData';
import { Subscription, Subject } from 'rxjs';
import { Fish } from 'src/app/data/models/Manage/fish.model';
import { RankingItem } from 'src/app/data/models/Manage/rankingItem.model';
import { UIService } from 'src/app/shared/service/ui.service';
import { LiveDataService } from 'projects/contestApp/src/app/services/liveDataService';

@Component({
  selector: 'app-contest-chart',
  templateUrl: './contest-chart.component.html',
  styleUrls: ['./contest-chart.component.css']
})
export class ContestChartComponent implements OnInit {

  sourceData$: ChartFishPartData[]

  fishPerDay: any[]
  fishPerDayNight: any[]
  fishPerTime: any[]
  fishPerWeight: any[]
  fishPerStation: any[]
  kgPerConcurentStations: any[]

  fishPerDayData: Subject<any[]> = new Subject<any[]>();
  fishPerDayNightData: Subject<any[]> = new Subject<any[]>();
  fishPerTimeData: Subject<any[]> = new Subject<any[]>();
  fishPerWeightData: Subject<any[]> = new Subject<any[]>();
  fishPerStationData: Subject<any[]> = new Subject<any[]>();
  kgPerConcurentStationsData: Subject<any[]> = new Subject<any[]>();

  private catchesSubscription: Subscription

  constructor(private liveDataService: LiveDataService, public UIservice: UIService){
    this.liveDataService.getFish()
 }

ngOnInit() {
  this.catchesSubscription = this.liveDataService.fish$
  .subscribe((fishArray: Fish[]) => {
    this.sourceData$ = fishArray.map<ChartFishPartData>(fish => {
      let _fishpartData = null
      try {
        _fishpartData = {
        chartDate: fish.date.slice(0,10),
        chartHour: fish.date.slice(11,13),
        date: new Date(fish.date),
        weight: fish.weight,
        type: fish.fishType,
        station: fish.station
        }
        return _fishpartData
      }
      finally {
        return _fishpartData
      }

    })

    this.sourceData$ = this.sourceData$.filter(x => x != null)

    this.fishPerDay = this.prepareChartData(this.sourceData$, 1)
    this.fishPerDayData.next(this.fishPerDay)

    console.log(this.fishPerDay)

    this.fishPerDayNight = this.prepareChartData(this.sourceData$,2)
    this.fishPerDayNightData.next(this.fishPerDayNight)

    this.fishPerTime = this.prepareChartData(this.sourceData$, 3)
    this.fishPerTimeData.next(this.fishPerTime)

    this.fishPerWeight = this.prepareChartData(this.sourceData$, 4)
    this.fishPerWeightData.next(this.fishPerWeight)

    this.fishPerStation = this.prepareChartData(this.sourceData$, 5)
    this.fishPerStationData.next(this.fishPerStation)

    this.kgPerConcurentStations = this.calculateAmountAndWeightPerConcurentStations(this.sourceData$)
    this.kgPerConcurentStationsData.next(this.kgPerConcurentStations)
  })
}

prepareChartData(data: ChartFishPartData[], chartId: number): any{

  let newDataArray: any[] =[]

  switch(chartId){
    case 1:
      {
        return newDataArray = this.calculateFishPerDay(data)
      }
    case 2:
      {
        return newDataArray = this.calculateFishPerDayNight(data)
      }
    case 3:
      {
       return newDataArray = this.calculateFishPerDayPeriod(data)
      }
    case 4:
      {
        return newDataArray = this.calculateFishPerWeight(data)
      }
    case 5:
      {
        return newDataArray = this.calculateFishPerStation(data)
      }
    default:
      {
        return newDataArray
      }
  }
}
calculateFishPerDayPeriod(data: ChartFishPartData[]): any[]{

  let series = []

  let firstPeriod = data.filter(b => b.chartHour >= '00' && b.chartHour < '06').length
  let firstPeriodName = '00:00-06:00'

  series.push({name: firstPeriodName, value: firstPeriod})

  let secondPeriod = data.filter(b => b.chartHour >= '06' && b.chartHour < '12').length
  let secondPeriodName = '06:00-12:00'

  series.push({name: secondPeriodName, value: secondPeriod})

  let thirdPeriod = data.filter(b => b.chartHour >= '12' && b.chartHour < '18').length
  let thirdPeriodName = '12:00-18:00'

  series.push({name: thirdPeriodName, value: thirdPeriod})

  let fourthPeriod = data.filter(b => b.chartHour >= '18' && b.chartHour < '24').length
  let fourthPeriodName = '18:00-24:00'

  series.push({name: fourthPeriodName, value: fourthPeriod})

   return series
}
calculateFishPerDayNight(data: ChartFishPartData[]): any[]{

  let series = []

  let firstPeriod = data.filter(b => b.chartHour >= '22').length
  firstPeriod = firstPeriod + data.filter(b => b.chartHour < '10' ).length
  let firstPeriodName = '22:00 - 10:00'

  series.push({name: firstPeriodName, value: firstPeriod})

  let secondPeriod = data.filter(b => b.chartHour >= '10' && b.chartHour < '22').length
  let secondPeriodName = '10:00 - 22:00'

  series.push({name: secondPeriodName, value: secondPeriod})

   return series
}
calculateFishPerDay(data: ChartFishPartData[]): any[]{

  let _fishPerDay = []

  data.sort((a,b) => {
    return a.date.getTime() > b.date.getTime() ? 1 : -1
  })
  const distinctDates = [... new Set(data.map(x => x.chartDate))]

  distinctDates.forEach(date => {
    _fishPerDay.push({
      name: date,
      value: data.filter(a => a.chartDate === date).length
    })
  })
  // _fishPerDay.push({
  //   name: '2019-09-30',
  //   value: 10
  // })
  // _fishPerDay.push({
  //   name: '2019-09-31',
  //   value: 1
  // })
  // _fishPerDay.push({
  //   name: '2019-09-32',
  //   value: 10
  // })
  // _fishPerDay.push({
  //   name: '2019-09-33',
  //   value: 10
  // })
  // _fishPerDay.push({
  //   name: '2019-09-34',
  //   value: 1
  // })
  // _fishPerDay.push({
  //   name: '2019-09-35',
  //   value: 10
  // })

   return _fishPerDay
}
calculateFishPerWeight(data: ChartFishPartData[]): any[]{

  let series = []

  let firstGroup = data.filter(b => b.weight > 0 && b.weight < 6).length
  let firstGroupName = '0 kg - 6 kg'

  series.push({name: firstGroupName, value: firstGroup})

  let secondGroup = data.filter(b => b.weight >= 6 && b.weight < 12).length
  let secondGroupName = '6 kg - 12 kg'

  series.push({name: secondGroupName, value: secondGroup})

  let thirdGroup = data.filter(b => b.weight >= 12 && b.weight < 18).length
  let thirdGroupName = '12 kg - 18kg'

  series.push({name: thirdGroupName, value: thirdGroup})

  let fourthGroup = data.filter(b => b.weight >= 18).length
  let fourthGroupName = '> 18 kg'

  series.push({name: fourthGroupName, value: fourthGroup})

   return series
}
calculateFishPerStation(data: ChartFishPartData[]): any[]{

  let _fishPerStation = []

  const distinctStations = [... new Set(data.map(x => x.station))]

  distinctStations.forEach(station => {
    _fishPerStation.push({
      name: station,
      value: data.filter(a => a.station === station).length
    })
  })

  _fishPerStation.sort((a,b) => b.value - a.value)

let series = []

let firstGroup = _fishPerStation.filter(b => b.value === 1).length
let firstGroupName = '1 Fish'

series.push({name: firstGroupName, value: firstGroup})

let secondGroup = _fishPerStation.filter(b => b.value >= 1 && b.value <= 3).length
let secondGroupName = '1 - 3 Fish'

series.push({name: secondGroupName, value: secondGroup})

let thirdGroup = _fishPerStation.filter(b => b.value > 3 && b.value <= 5).length
let thirdGroupName = '3 - 5 Fish'

series.push({name: thirdGroupName, value: thirdGroup})

let fourthGroup = _fishPerStation.filter(b => b.value >= 6).length
let fourthGroupName = '> 6 Fish'

series.push({name: fourthGroupName, value: fourthGroup})

   return series


}
calculateWeightPerConcurentStations(data: RankingItem[]): any[]{

  let series = []

  data.sort((a,b) => a.topFish[0].station - b.topFish[0].station)

  let arrayLen = data.length

  for (let index = 0; index < arrayLen;) {
    let team = data[index]
    let teamStation = team.topFish[0].station
    let nextTeamStation = Number(teamStation) + 1
    let nextTeamIndex = index + 1
    console.log('next team station ' + nextTeamStation )
    let nextSecondTeamStation = nextTeamStation + 1
    console.log('next second team station ' + nextSecondTeamStation)

    let groupLabel: string = teamStation.toString()
    let score = team.classificationScore


    while (nextTeamIndex < arrayLen && (data[nextTeamIndex].topFish[0].station <= nextSecondTeamStation)) {

      let _station = data[nextTeamIndex].topFish[0].station

      groupLabel = groupLabel + ' ; ' + _station
      score = score + data[nextTeamIndex].classificationScore

      nextTeamIndex += 1
      nextSecondTeamStation = Number(_station) + 2
    }

    score = Math.round(score)

    series.push({name: groupLabel, value: score})

    if(nextTeamIndex < arrayLen)
    {
      index = nextTeamIndex
    }
    else{
      index = arrayLen
    }
  }

  series.sort((a,b) => b.value - a.value)
  return series
}
calculateAmountAndWeightPerConcurentStations(data: ChartFishPartData[]): any[]{

  const _fishPerStation = []
  const series = []
  const distinctStations = [... new Set(data.map(x => x.station))]

  distinctStations.forEach(station => {
    let sumKg = 0
    let _data = data.filter(a => a.station === station)
    let _datalen = _data.length
    _data.forEach(fish => {
      sumKg += fish.weight
    })
    _fishPerStation.push({
      station: station,
      kgValue: sumKg,
      amount: _datalen
    })
  })
  _fishPerStation.sort((a,b) => a.station - b.station)


  let arrayLen = _fishPerStation.length

  for (let index = 0; index < arrayLen;) {
    let team = _fishPerStation[index]
    let teamStation = team.station

    let nextTeamIndex = index + 1
    let maxNextTeamStation = Number(teamStation) + 2

    let groupLabel: string = teamStation.toString()
    let score = team.kgValue
    let fishAmount = team.amount


    while (nextTeamIndex < arrayLen && (_fishPerStation[nextTeamIndex].station <= maxNextTeamStation)) {

      let _station = _fishPerStation[nextTeamIndex].station

      groupLabel += ' ; ' + _station
      score +=_fishPerStation[nextTeamIndex].kgValue
      fishAmount += _fishPerStation[nextTeamIndex].amount

      nextTeamIndex += 1
      maxNextTeamStation = Number(_station) + 2
    }

    score = fishAmount + ' Fish - ' + Math.round(score) + ' kg'

    series.push({name: groupLabel, value: score})

    if(nextTeamIndex < arrayLen)
    {
      index = nextTeamIndex
    }
    else{
      index = arrayLen
    }
  }

  series.sort((a,b) => b.value - a.value)
  return series

}
}
