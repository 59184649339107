import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { BaseLandingComponent } from '../baseLanding.component';
import { UIService } from 'src/app/shared/service/ui.service';
import { AuthService } from 'src/app/auth-module/service/auth.service';
import { ServeUserService } from 'src/app/auth-module/service/serveUser.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent extends BaseLandingComponent implements OnInit {

  public rulesChecked: boolean = false
  error: string = null
  public captcha

  form: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('',[Validators.required, Validators.minLength(6)]),
    name: new FormControl('',[Validators.required, Validators.minLength(3)]),
    surname: new FormControl('',[Validators.required, Validators.minLength(3)])
  })

  

  constructor(private UIservice: UIService, 
              private authService: AuthService,
              private serveUserService: ServeUserService,
              private router: Router) {
    super(UIservice);
    

  }

  ngOnInit() {
  }

  getErrorPasswordValidator(){
    return this.form.controls.password.hasError('required') ? 'You must enter a password' :
    this.form.controls.password.hasError('minlength') ? 'Password is too short': '';
  }

  getErrorEmailValidator() {
    return this.form.controls.email.hasError('required') ? 'You must enter a value' :
            this.form.controls.email.hasError('email') ? 'Not a valid email' : '';
  }
  getErrorNameValidator(){
    return this.form.controls.name.hasError('required') ? 'You must enter a value' :
            this.form.controls.name.hasError('minlength') ? 'Name is too short': '';      
  }
  getErrorSurnameValidator(){
    return this.form.controls.surname.hasError('required') ? 'You must enter a value' :
            this.form.controls.surname.hasError('minlength') ? 'Surname is too short': '';      
  }


  onSubmit(){
    this.error = null
    let name = this.form.controls.name.value
    let surname = this.form.controls.surname.value
    let email = this.form.controls.email.value
    let password = this.form.controls.password.value
    
    this.authService.signUpUser(email, password, name, surname)
        .then(() => { 
          let data = {
            title: $localize`Verify your Email Address`,
            content: $localize`Check provided e-mail box for account verification Link`
        }
        this.UIservice.openInfoDialog(data.title, data.content,true)
        this.router.navigateByUrl('/main')
        })
        .catch((e) => {this.error = e.message})
    
  }

}
