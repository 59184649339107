import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { BaseOrganiserComponent } from 'projects/organiserApp/src/app/baseOrganiser.component';
import { ContestDataService } from 'projects/organiserApp/src/app/services/contest-data.service';
import { ManageContestDataService } from 'projects/organiserApp/src/app/services/manage-contest-data';
import { Observable, Subscription } from 'rxjs';
import { Angler } from 'src/app/data/models/Manage/angler.model';
import { Reservation } from 'src/app/data/models/Manage/reservation.model';
import { Team } from 'src/app/data/models/Manage/team.model';
import { UIService } from 'src/app/shared/service/ui.service';

@Component({
  selector: 'app-reserve-list',
  templateUrl: './reserve-list.component.html',
  styleUrls: ['./reserve-list.component.css']
})
export class ReserveListComponent extends BaseOrganiserComponent implements OnInit, OnDestroy {


  teams$: Observable<Reservation[]>
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  editModeTeam: boolean = false;
  editModeAngler: boolean = false
  editTeam: Team
  editAngler: Angler

  editData = {
    teamId: null,
    anglerId: null,
    editType: null
  }

  dataSubscription: Subscription
  cardData: Observable<Reservation[]>
  reserveList: Reservation[]

  columnsToDisplay= ['No','reserveDate', 'promoTeam', 'email', 'phone', 'Angler1', 'Angler2', 'actions' ];
  dataSource = new MatTableDataSource<Reservation>()
  expandedElement: Reservation | null;
  pageEvent: PageEvent;

  constructor(public UIservice: UIService,
              private contestData: ContestDataService,
              private manageContest: ManageContestDataService) {
    super(UIservice);

      this.contestData.getReserveTeams()

  }

  ngOnInit() {
    this.dataSubscription = this.contestData.reserveList$.subscribe(array => {
        if(array!=null){
          this.reserveList = array
          this.assignDataToDataSource(this.reserveList)
        }

    })

    this.dataSource.paginator = this.paginator;
    this.cardData = this.dataSource.connect()
  }

  assignDataToDataSource(_array: Reservation[]){

          this.dataSource.data = _array
          this.dataSource.paginator = this.paginator;
          this.cardData = this.dataSource.connect()
  }

  applyFilter(filterValue: string): void{

    let searchResults: Reservation[] = []

    let searchValue = filterValue.trim().toLowerCase()

    if(this.reserveList && searchValue != ''){

      searchResults =  this.reserveList.filter(object => {
        let values: string = ''
          values += object.team.privateData.leaderEmail ? object.team.privateData.leaderEmail.toLocaleLowerCase() : ''
          values += object.team.privateData.leaderPhone ? object.team.privateData.leaderPhone.toLocaleLowerCase() : ''
          values += object.team.promoTeam ? object.team.promoTeam.toLocaleLowerCase() : ''
          values += object.id ? object.id.toLocaleLowerCase() : ''

        object.team.anglers.forEach(angler => {
          for (const property in angler)
        {
          values += angler[property].toLocaleLowerCase()
        }
        })

        return values.indexOf(searchValue) != -1

      })
    }
      else
      {
        searchResults = this.reserveList
      }

      this.assignDataToDataSource(searchResults)

  }

  onAnglerEdit(reservationID: string, anglerID: number): void{
    let _team = this.reserveList.filter(reservation => reservation.id === reservationID).map<Team>(reservation => ({... reservation.team}))
    let _angler = _team[0].anglers[anglerID]
    this.editAngler = {..._angler}
    this.editData.anglerId = anglerID
    this.editData.teamId = reservationID
    this.editData.editType = ''
    this.editModeAngler = true
  }

  onAnglerDelete(reservationID: string, anglerID: string): void{
    let title = $localize`Angler Delete`
    let content = $localize`Are you sure to delete selected Angler ?`
    this.UIservice.openConfirmationDialog(title, content).subscribe(async result => {
      if(result)
      {
        const spinner = this.UIservice.openSpinnerDialog()
        await this.manageContest.deleteTeamMemberReservation(reservationID,anglerID).subscribe({
          error: (e) => {
            let data = {
              title: $localize`Angler Delete Error`,
              content: e.message
          }
            this.UIservice.openInfoDialog(data.title, data.content,false)

            spinner.close()
          },
          complete: () => spinner.close()
      })
      }
    })
  }

  onTeamEdit(reservationID: string): void{

    let _team = this.reserveList.filter(reservation => reservation.id === reservationID).map<Team>(reservation => ({... reservation.team}))
    this.editTeam = {..._team[0]}
    this.editData.teamId = reservationID
    this.editData.editType = "reservation"
    this.editModeTeam = true

  }

  onTeamDelete(id: string): void{
    let title = $localize`Team Delete`
    let content = $localize`Are you sure to delete selected Team ?`
    this.UIservice.openConfirmationDialog(title, content).subscribe(async result => {
      if(result)
      {
        const spinner = this.UIservice.openSpinnerDialog()
        await this.manageContest.deleteTeamReservation(id).subscribe({
          error: (e) => {
            let data = {
              title: $localize`Team Delete Error`,
              content: e.message
          }
            this.UIservice.openInfoDialog(data.title, data.content,false)

            spinner.close()
          },
          complete: () => spinner.close()
      })
      }
    })

  }

  onConfirmReservation(id: string): void {
    let title = $localize`Move to Reservation`
    let content = $localize`Are you sure to move selected Team into Reservations ?`
    this.UIservice.openConfirmationDialog(title, content).subscribe(async result => {
      if(result)
      {
        const spinner = this.UIservice.openSpinnerDialog()
        await this.manageContest.changeFromReserveToReservation(id).subscribe({
          error: (e) => {
            let data = {
              title: $localize`Confirm Reservation Error`,
              content: e.message
          }
            this.UIservice.openInfoDialog(data.title, data.content,false)

            spinner.close()
          },
          complete: () => spinner.close()
      })
      }
    })

  }

  onAnglerEditUpdate(editedAngler: Angler){

    this.manageContest.updateAngler(this.editData.teamId, this.editData.anglerId, editedAngler, 'reservation')

    this.editModeAngler = false
  }

  onExitEdit(){
    this.editModeAngler = false
    this.editModeTeam = false
  }
  onTeamEditUpdate(): void {

  }


  ngOnDestroy(): void {
   this.dataSubscription.unsubscribe()
  }
}


