<div *ngIf="partnersLoaded && this.Partners.length > 0" fxLayout="column">
 <div class="mat-h1" style="text-align: center;" i18n="Partners Header| Header for partners list@@"><strong>Partners:</strong></div>

 <section *ngIf="!this.UIservice.isMobileXS; else smallDeviceSwipper" fxLayout="row wrap" fxLayoutGap="5vw" fxLayoutAlign="center center" >
                    <div *ngFor="let partner of this.Partners"  fxLayoutAlign="center center">

                       <a [attr.href]="partner.link" target="_blank" >
                        <img class="partnerLogo" [attr.alt]="partner.description" [attr.title]="partner.description" [attr.src]="partner.logoLink | safeResource">
                      </a>
                    </div>
    </section>

<ng-template #smallDeviceSwipper>

<section fxLayout="row wrap" fxLayoutGap="5vw" fxLayoutAlign="center center">
      <div *ngFor="let partner of this.Partners"  fxLayoutAlign="center center">

         <a [attr.href]="partner.link" target="_blank">
          <img class="partnerLogoMobile" [attr.alt]="partner.description" [attr.title]="partner.description" [attr.src]="partner.logoLink | safeResource">
        </a>
      </div>
    </section>

</ng-template>

</div>
