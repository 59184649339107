import { Component, OnInit } from '@angular/core';
import { BaseLandingComponent } from '../../baseLanding.component';
import { UIService } from 'src/app/shared/service/ui.service';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.css']
})
export class PrivacyComponent extends BaseLandingComponent implements OnInit {

  constructor(private UIservice: UIService) {
    super(UIservice);
  }

  ngOnInit() {
  }

}
