<ng-template [ngIf]="editModeAngler && editAngler">
    <app-edit-angler [editAngler]="this.editAngler" [editType]="this.editData.editType" (exit)="onExitEdit()" (onUpdate)="onAnglerEditUpdate($event)"></app-edit-angler>
</ng-template>

<ng-template [ngIf]="editModeTeam && editTeam">
    <app-edit-team [editTeam]="this.editTeam" [teamID]="this.editData.teamId" [editType]="this.editData.editType" (exit)="onExitEdit()"></app-edit-team>
</ng-template>

<div class="content-container" fxFlexFill fxLayout="column" fxLayoutAlign="start center">

    <h1 class="mat-h1" i18n="@@ReserveListLabel">Reserve List:</h1>

    <div [hidden]="!this.dataSource" fxFlexFill fxLayoutAlign="center start" >
        <div style="margin: 2vh;" fxFlex.gt-md="95" fxFlex="100" class="mat-elevation-z8" fxLayout="column">
            
            <div fxLayout="row wrap" class="fiter-container" fxLayout.xs="column" fxLayoutGap.xs="1vh">
                <div fxFlex="100" fxFlex.xs fxFlexOrder="1">
                    
                    <mat-form-field  fxLayoutAlign="center center" fxLayoutAlign.xs="center center">
                        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
                    </mat-form-field>
            
                </div>
            
                <div fxFlex="100" fxFlex.xs fxFlexOrder="3">
            
                    <mat-paginator [length]="this.dataSource.data.length"
                                    [pageSize]="5"
                                    showFirstLastButtons
                                    (page)="pageEvent = $event">
                    </mat-paginator>
                    
                </div>
            
    
            <table *ngIf="!this.UIservice.isMobileSM; else cards" 
                    class="mat-elevation-z8"
                    fxFlexOrder="2" 
                    fxFlex="100" 
                    mat-table
                    multiTemplateDataRows
                    [dataSource]="dataSource">
                
                    <ng-container matColumnDef="No">
                        <th fxFlex="5" fxLayoutAlign="center center" mat-header-cell *matHeaderCellDef i18n="@@Number">No</th>
                        <td fxFlex="5" fxLayoutAlign="center center" mat-cell *matCellDef="let element; let i = dataIndex;"> {{i +1}} </td>
                      </ng-container>

                      <ng-container matColumnDef="reserveDate">
                        <th fxFlex="10" fxLayoutAlign="center center" mat-header-cell *matHeaderCellDef i18n="@@ReserveDateApplication">Application</th>
                        <td fxFlex="10" fxLayoutAlign="center center" mat-cell *matCellDef="let element">{{element.reserveDate | date:"dd/MM/yyyy HH:mm"}}</td>
                      </ng-container>

                      <ng-container matColumnDef="promoTeam">
                        <th fxFlex="10" fxLayoutAlign="center center" mat-header-cell *matHeaderCellDef i18n="@@cc2bc5da34f5023a830bb63577ae545556348c65">Team </th>
                        <td fxFlex="10" fxLayoutAlign="center center" mat-cell *matCellDef="let element">{{element.team.promoTeam}}</td>
                      </ng-container>

                      <ng-container matColumnDef="email">
                        <th fxFlex="10" fxLayoutAlign="center center" mat-header-cell *matHeaderCellDef>Email </th>
                        <td fxFlex="10" fxLayoutAlign="center center" mat-cell *matCellDef="let element">{{element.team.privateData.leaderEmail}}</td>
                      </ng-container>

                      <ng-container matColumnDef="phone">
                        <th fxFlex="10" fxLayoutAlign="center center" mat-header-cell *matHeaderCellDef i18n="@@9267be8781930e8c0c72709bdd17b1f670dd8252">Phone</th>
                        <td fxFlex="10" fxLayoutAlign="center center" mat-cell *matCellDef="let element">{{element.team.privateData.leaderPhone}}</td>
                      </ng-container>

                      <ng-container matColumnDef="Angler1">
                        <th fxFlex="13" fxLayoutAlign="center center" mat-header-cell *matHeaderCellDef i18n="@@f9ac4b5125488e0daf4bfdfdb014a865783a6ca9">Angler 1 </th>
                        <td fxFlex="13" fxLayoutAlign="center center" mat-cell *matCellDef="let element">
                            {{element.team.anglers[0] ? element.team.anglers[0].name : ''}}&nbsp;{{element.team.anglers[0] ? element.team.anglers[0].surname : ''}}</td>
                      </ng-container>

                      <ng-container matColumnDef="Angler2">
                        <th fxFlex="13" fxLayoutAlign="center center" mat-header-cell *matHeaderCellDef i18n="@@9a5d75a9dd5efa727f0b9677355631165b448061">Angler 2 </th>
                        <td fxFlex="13" fxLayoutAlign="center center" mat-cell *matCellDef="let element">
                            {{element.team.anglers[1] ? element.team.anglers[1].name : ''}}&nbsp;{{element.team.anglers[1] ? element.team.anglers[1].surname : ''}}</td>
                      </ng-container>

                      <ng-container matColumnDef="actions">
                        <th fxFlex="29" fxLayoutAlign="center center" mat-header-cell *matHeaderCellDef i18n="@@actions">Actions</th>
                        <td fxFlex="29" fxLayoutAlign="center center" mat-cell *matCellDef="let element">
                            <button mat-button color="accent" (click)="onTeamEdit(element.id)" i18n="@@editTeam" i18n="@@74613218ed8e82d7b909dd2667add4d2a90a9313">Edit Team</button>
                            <button mat-button color="warn" (click)="onTeamDelete(element.id)" i18n="@@deleteTeam" i18n="@@deleteTeam">Delete Team</button>
                            <button mat-button style="color:#23c268;" (click)="onConfirmReservation(element.id)" i18n="@@ToReservation">Add Reservation</button>
                        </td>
                      </ng-container>
    
                    
                      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                      <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"></tr>
 
            </table>
            
            <ng-template #cards>
        
                <div fxFlexFill fxLayout="column" fxLayoutGap="1vh" fxFlexOrder.xs="2">
                    <mat-accordion *ngFor="let element of this.cardData | async, let i = index">
                        <mat-expansion-panel>
                          <mat-expansion-panel-header fxLayout="row">  
                            
                            <mat-panel-title fxFlex="30">
                                                 
                             <label >No:</label>
                                &nbsp;{{i +1}}
                
                            </mat-panel-title>
                            
                            <mat-panel-description fxFlex="60">
                                
                                <label fxFlex="50">{{element.team.anglers[0].surname}}</label>
                                <label fxFlex="50">{{element.team.anglers[1].surname}}</label>
              
                            </mat-panel-description>
                          </mat-expansion-panel-header>
             
                          <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="2vh">
                            
                                    <div class="mat-h4" style="font-weight: bold;" i18n="@@TeamPromoTeam">Team:&nbsp;{{element.team.promoTeam}}</div>
                          
                            <div fxLayout="row wrap" fxLayoutAlign="start center">

                                    <div fxFlex="100" fxLayout="column" fxLayoutAlign="space-between center"  fxLayoutGap="2vh">
                                
                                        <div fxLayout="row nowrap" fxLayoutAlign="center start">
                                            <mat-icon>phone</mat-icon>  
                                            <a class="cardLink" [attr.href]="'tel:' + element.team.leaderPhone">{{element.team.leaderPhone}}</a>
                                        </div>
                                        <div fxLayout="row nowrap" fxLayoutAlign="center start">
                                            <mat-icon>email</mat-icon>  
                                            <a class="cardLink" [attr.href]="'mailto:' + element.team.leaderEmail">{{element.team.leaderEmail}}</a>
                                        </div>
                                        
                                        <div fxLayoutAlign="center end">
                                            <button mat-stroked-button color="primary" (click)="onTeamEdit(element.id)" i18n="@@74613218ed8e82d7b909dd2667add4d2a90a9313" >Edit Team</button>
                                            <button mat-stroked-button color="warn" (click)="onTeamDelete(element.id)" i18n="@@deleteTeam">Delete Team</button>
                                        </div>  
            
                                    </div>
                            </div>
                            
                                    <div class="mat-h4" style="font-weight: bold;" i18n="@@Anglers">Anglers:&nbsp;</div>

                          <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="2vh">
                            

                            <div style="width: 100%;" fxLayout="row wrap" fxLayoutAlign="space-between center" *ngFor="let angler of element.team.anglers; let i = index;" >
                                
                                <div fxFlex="30" style="font-weight: bold;">
                                    {{angler.teamRole}}
                                </div>
                                <div fxFlex="60">
                                    {{angler.name}}&nbsp;{{angler.surname}}
                                </div>
                                <div fxFlex="10">
                                    {{angler.country}}
                                </div>
                                <div fxFlex="100" fxLayoutAlign="end center">
                                    <button mat-stroked-button color="primary" (click)="onAnglerEdit(element.id, i)" i18n="@@7893797581fe69150dc800615420ab3dbbb16514">Edit</button>
                                    <button mat-stroked-button color="warn" (click)="onAnglerDelete(element.id, i)" i18="@@7893797581fe69150dc800615420ab3dbbb16514">Delete</button>
                                </div>  
    
                            </div>

                          </div>

                                    
            
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
            </ng-template>
    
            </div>
            
        </div>  
    
    </div>

</div>

