<div fxLayout="row wrap" fxLayoutGap="5vh" fxLayoutAlign="center start">

    <div fxLayout="column" fxLayoutAlign="start center">
        <app-premium-info></app-premium-info>
    </div>

    <div fxLayout="column" fxLayoutAlign="start center">
        <app-premium-form [challenge]="this.challenge"></app-premium-form>
    </div>


</div>