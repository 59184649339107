import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { BaseOrganiserComponent } from '../../../baseOrganiser.component';
import { Observable, Subscription } from 'rxjs';
import { Team } from 'src/app/data/models/Manage/team.model';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { UIService } from 'src/app/shared/service/ui.service';
import { ContestDataService } from '../../../services/contest-data.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Reservation } from 'src/app/data/models/Manage/reservation.model';
import { Angler } from 'src/app/data/models/Manage/angler.model';
import { ManageContestDataService } from '../../../services/manage-contest-data';

@Component({
  selector: 'app-reservations',
  templateUrl: './reservations.component.html',
  styleUrls: ['./reservations.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ReservationsComponent extends BaseOrganiserComponent implements OnInit, OnDestroy {


  teams$: Observable<Reservation[]>
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  editModeTeam: boolean = false;
  editModeAngler: boolean = false
  editTeam: Team
  editAngler: Angler

  editData = {
    teamId: null,
    anglerId: null,
    editType: null
  }

  dataSubscription: Subscription
  cardData: Observable<Reservation[]>
  allReservations: Reservation[]

  columnsToDisplay= ['No', 'promoTeam', 'Angler1', 'Angler2' ];
  dataSource = new MatTableDataSource<Reservation>()
  expandedElement: Reservation | null;
  pageEvent: PageEvent;

  constructor(public UIservice: UIService,
              private contestData: ContestDataService,
              private manageContest: ManageContestDataService) {
    super(UIservice);

      this.contestData.getReservationTeams()

  }

  ngOnInit() {
    this.dataSubscription = this.contestData.reservationTeams$.subscribe(array => {
        if(array!=null){
          this.allReservations = array
          this.assignDataToDataSource(this.allReservations)
        }

    })

    this.dataSource.paginator = this.paginator;
    this.cardData = this.dataSource.connect()
  }

  assignDataToDataSource(_array: Reservation[]){

          this.dataSource.data = _array
          this.dataSource.paginator = this.paginator;
          this.cardData = this.dataSource.connect()
  }

  applyFilter(filterValue: string): void{

    let searchResults: Reservation[] = []

    let searchValue = filterValue.trim().toLowerCase()

    if(this.allReservations && searchValue != ''){

      searchResults =  this.allReservations.filter(object => {
        let values: string = ''
          values += object.team.privateData.leaderEmail ? object.team.privateData.leaderEmail.toLocaleLowerCase() : ''
          values += object.team.privateData.leaderPhone ? object.team.privateData.leaderPhone.toLocaleLowerCase() : ''
          values += object.team.promoTeam ? object.team.promoTeam.toLocaleLowerCase() : ''
          values += object.id ? object.id.toLocaleLowerCase() : ''

        object.team.anglers.forEach(angler => {
          for (const property in angler)
        {
          values += angler[property].toLocaleLowerCase()
        }
        })

        return values.indexOf(searchValue) != -1

      })
    }
      else
      {
        searchResults = this.allReservations
      }

      this.assignDataToDataSource(searchResults)

  }

  onAnglerEdit(reservationID: string, anglerID: number): void{
    let _team = this.allReservations.filter(reservation => reservation.id === reservationID).map<Team>(reservation => ({... reservation.team}))
    let _angler = _team[0].anglers[anglerID]
    this.editAngler = {..._angler}
    this.editData.anglerId = anglerID
    this.editData.teamId = reservationID
    this.editData.editType = ''
    this.editModeAngler = true
  }

  onAnglerDelete(reservationID: string, anglerID: string): void{
    let title = $localize`Angler Delete`
    let content = $localize`Are you sure to delete selected Angler ?`
    this.UIservice.openConfirmationDialog(title, content).subscribe(async result => {
      if(result)
      {
        const spinner = this.UIservice.openSpinnerDialog()
        await this.manageContest.deleteTeamMemberReservation(reservationID,anglerID).subscribe({
          complete: () =>  spinner.close(),
          error: (e) => {
            let data = {
              title: $localize`Angler Delete Error`,
              content: e.message
                   }
            this.UIservice.openInfoDialog(data.title, data.content,false)

            spinner.close()}
        })
      }
    })
  }

  onTeamEdit(reservationID: string): void{
    let _team = this.allReservations.filter(reservation => reservation.id === reservationID).map<Team>(reservation => ({... reservation.team}))
    this.editTeam = {..._team[0]}
    this.editData.teamId = reservationID
    this.editData.editType = "reservation"
    this.editModeTeam = true

  }

  onTeamDelete(id: string): void{
    let title = $localize`Team Delete`
    let content = $localize`Are you sure to delete selected Team ?`
    this.UIservice.openConfirmationDialog(title, content).subscribe(async result => {
      if(result)
      {
        const spinner = this.UIservice.openSpinnerDialog()
        await this.manageContest.deleteTeamReservation(id).subscribe({
          complete: () => spinner.close(),
          error: (e) => {
            let data = {
              title: $localize`Team Delete Error`,
              content: e.message
          }
            this.UIservice.openInfoDialog(data.title, data.content,false)

            spinner.close()
          }
        })
      }
    })

  }

  onConfirmPayment(id: string): void {
    let title = $localize`Payment Confirmation`
    let content = $localize`Are you sure to confirm Reservation payment ?`
    this.UIservice.openConfirmationDialog(title, content).subscribe(async result => {
      if(result)
      {
        const spinner = this.UIservice.openSpinnerDialog()
        await this.manageContest.confirmReservationPayment(id).subscribe({
          complete: () => spinner.close(),
          error: (e) => {
            let data = {
              title: $localize`Confirm Payment Error`,
              content: e.message
          }
            this.UIservice.openInfoDialog(data.title, data.content,false)

            spinner.close()
          }
        })
      }
    })

  }

  onAnglerEditUpdate(editedAngler: Angler){

    this.manageContest.updateAngler(this.editData.teamId, this.editData.anglerId, editedAngler, 'reservation')

    this.editModeAngler = false
  }

  onExitEdit(){
    this.editModeAngler = false
    this.editModeTeam = false
  }
  onTeamEditUpdate(): void {

  }


  ngOnDestroy(): void {
   this.dataSubscription.unsubscribe()
  }
}

