<div  style="max-width: 1300px;" fxLayout="row wrap" fxLayoutAlign="center center">

<div style="padding-top: 2vh;" fxFlex.gt-sm="33" fxFlex.gt-xs="50" fxFlex.lt-sm="90">
        <app-spinner fxFill *ngIf="this.rankingDataLoading"></app-spinner>
        <app-winner-places fxFill [awards]="awards" [currency]="this.currency" [dataLoadEvent]="rankingDataLoadEvent"></app-winner-places>

</div>

<div style="padding-top: 2vh;" fxFlex.gt-sm="33" fxFlex.gt-xs="50" fxFlex.lt-sm="90">
        <app-spinner fxFill *ngIf="this.rankingDataLoading"></app-spinner>
        <app-ranking-places fxFill  [dataLoadEvent]="rankingDataLoadEvent"></app-ranking-places>

</div>

<div style="padding-top: 2vh;" fxFlex.gt-md="70" fxFlex.lt-lg="100" fxLayout="row">
        <app-spinner fxFill *ngIf="this.messageDataLoading"></app-spinner>
        <!-- <app-message fxFill [dataLoadEvent]="messagesDataLoadEvent"></app-message> -->
</div>

</div>

