import { Injectable } from '@angular/core';
import { ChallengeService } from 'src/app/core-module/entityServices/challange.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ContestInfo } from '../model/contestInfo.model';




@Injectable()
export class ContestService{

    challenges$: Observable<ContestInfo[]>

    constructor(private challengeService: ChallengeService){

      this.challenges$ = this.challengeService.getActiveChallenges()
      .snapshotChanges()
      .pipe<ContestInfo[]>(map(docArray => {
          return docArray.map<ContestInfo>(doc => {
              return {
                  alias: doc.key,
                  isComming: doc.payload.child('isComming').val(),
                  isLive: doc.payload.child('isLive').val(),
                  isArchived: doc.payload.child('isArchived').val(),
                  isPremium: doc.payload.child('isPremium').val(),
                  key: doc.payload.child('key').val(),
                  fullName: doc.payload.child('fullName').val(),
                  country: doc.payload.child('country').val(),
                  region: doc.payload.child('region').val(),
                  city: doc.payload.child('city').val(),
                  place: doc.payload.child('place').val(),

                  Data: {
                    challengeInfo: doc.payload.child('Data').child('challengeInfo').val(),
                    posterLink: doc.payload.child('Data').child('posterLink').val(),
                    startDate: doc.payload.child('Data').child('startDate').val(),
                    endDate: doc.payload.child('Data').child('endDate').val(),
                }
              }
          })
      } ))
    }
}
