import { NgModule } from "@angular/core";
import { AuthService } from './service/auth.service';
import { ServeUserService } from './service/serveUser.service';
import { AdminGuard } from './guards/admin-guard';
import { OrganiserGuard } from './guards/organiser-guard';
import { RefereeGuard } from './guards/referee-guard';
import { UIService } from '../shared/service/ui.service';
import { LandingGuard } from './guards/landing-guard';
import { ContestGuard } from './guards/contest-guard';
import { PremiumGuard } from "./guards/premium-guard";

@NgModule({
    declarations: [
        ],
    imports: [
            ],
    exports: [
            ],
    providers: [
        AuthService, 
        ServeUserService, 
        AdminGuard,
        UIService, 
        PremiumGuard,
        LandingGuard,
        ContestGuard,
        OrganiserGuard,
        RefereeGuard ]
})
export class AuthModule {}