import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  type: string
  newValue: any
}

@Component({
  selector: 'app-dialog-simple-input',
  templateUrl: './dialog-simple-input.component.html',
  styleUrls: ['./dialog-simple-input.component.css']
})
export class DialogSimpleInputComponent implements OnInit {

  constructor( @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  startSwim: number = 0
  endSwim: number = 0
  ngOnInit() {
    if(this.data.type === 'refereeStations')
    {
      this.data.newValue = this.startSwim + ' - ' + this.endSwim
    }
  }
  refereeSwimChanged(){
    this.data.newValue = this.startSwim + ' - ' + this.endSwim
  }

}
