import { Component, OnInit } from '@angular/core';
import { BaseRefereeComponent } from '../../baseReferee.component';
import { UIService } from 'src/app/shared/service/ui.service';
import { Observable } from 'rxjs';
import { RefereeDataService } from '../../services/referee-data.service';
import { ServeUserService } from 'src/app/auth-module/service/serveUser.service';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ContestInfo } from 'projects/landingApp/src/app/model/contestInfo.model';

@Component({
  selector: 'app-assign-to-contest',
  templateUrl: './assign-to-contest.component.html',
  styleUrls: ['./assign-to-contest.component.css']
})
export class AssignToContestComponent extends  BaseRefereeComponent implements OnInit {

  contestIndex: string
  contestList$: Observable<ContestInfo[]>

  constructor(public UIservice: UIService,
              private refereeDataService: RefereeDataService,
              private serveUser: ServeUserService,
              private router: Router) {
    super(UIservice);
  }

  ngOnInit() {

    this.contestList$ = this.refereeDataService.getAllActiveContestsList()
                        .snapshotChanges()
                        .pipe<ContestInfo[]>(map(docArray => {
                            return docArray.map(doc => {
                                return {
                                    key: doc.payload.child('key').val(),
                                    fullName: doc.payload.child('fullName').val()
                                }
                            })
                        }))
  }

  onSubmit(){

    this.refereeDataService.signToContest(this.contestIndex)
      .then(() => {
        let data = {
          title: $localize`Registration sent`,
          content: $localize`Challenge organiser must accept it.`
      }
      this.UIservice.openInfoDialog(data.title, data.content,true)
      this.router.navigateByUrl('/main')
      })
      .catch((e) => {
        let data = {
          title: $localize`Error`,
          content: $localize`Please try later or contact Carp Contest Admin`
      }
      this.UIservice.openInfoDialog(data.title, data.content,false)
      this.router.navigateByUrl('/main')
      })

  }



}
