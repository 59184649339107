<div class="content-container" fxFlexFill fxLayout="column" fxLayoutAlign="start center">

    <h1 class="mat-h1" i18n="Refrees Confirmed|Refrees Confirmed header@@">Refrees Confirmed</h1>

    <div [hidden]="!this.dataSource" fxFlexFill fxLayoutAlign="center start" >
        <div style="margin: 2vh;" fxFlex.gt-md="95" fxFlex="100" class="mat-elevation-z8" fxLayout="column">
            
            <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap.xs="1vh">
                <div fxFlex="100" fxFlex.xs fxFlexOrder="1">
                    
                    <mat-form-field  fxLayoutAlign="center center" fxLayoutAlign.xs="center center">
                        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
                    </mat-form-field>
            
                </div>
            
                <div fxFlex="100" fxFlex.xs fxFlexOrder="3">
            
                    <mat-paginator [length]="this.dataSource.data.length"
                                    [pageSize]="5"
                                    showFirstLastButtons
                                    (page)="pageEvent = $event">
                    </mat-paginator>
                    
                </div>
            
    
            <table *ngIf="!this.UIservice.isMobileXS; else cards" 
                    class="mat-elevation-z8"
                    fxFlexOrder="2" 
                    fxFlex="100" 
                    mat-table
                    multiTemplateDataRows
                    [dataSource]="dataSource">
                
                    <ng-container matColumnDef="Name">
                        <th fxFlex="25" fxLayoutAlign="center center" mat-header-cell *matHeaderCellDef i18n="@@5f4504ebcc1baf8c9919de13a7ccf043a821e8ca">Name</th>
                        <td fxFlex="25" fxLayoutAlign="center center" mat-cell *matCellDef="let element"> {{element.userData.name}} </td>
                      </ng-container>

                      <ng-container matColumnDef="Surname">
                        <th fxFlex="25" fxLayoutAlign="center center" mat-header-cell *matHeaderCellDef i18n="@@fcb6ff7ea6d7e88ade21b6df268f0c71f0d7ae02">Surname</th>
                        <td fxFlex="25" fxLayoutAlign="center center" mat-cell *matCellDef="let element">{{element.userData.surname}}</td>
                      </ng-container>

                      <ng-container matColumnDef="Pegs">
                        <th fxFlex="50" fxLayoutAlign="center center" mat-header-cell *matHeaderCellDef i18n="@@d494be449864c2a49e7eedf6895ae54e5727ac2b">Peg</th>
                        <td fxFlex="50" fxLayoutAlign="center center" mat-cell *matCellDef="let element">
                            <span *ngIf="element.stations === 'All'" i18n="@@All">All</span>
                            <span *ngIf="element.stations != 'All'">{{element.stations}}</span>
                        </td>
                      </ng-container>
                    
                      <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                      <ng-container matColumnDef="expandedDetail">
                        <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
                            <div fxLayout="column" class="team-element-detail"
                               [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                            
                            <div class="team-element-diagram" fxLayout="row">
                                <div fxFlex fxLayoutAlign="center center">
                                    <mat-icon>phone</mat-icon>  
                                     <a [attr.href]="'tel:' + element.phone">{{element.phone}}</a>
                                 </div>
                     
                                 <div fxFlex fxLayoutAlign="center center">
                                     <mat-icon>email</mat-icon>  
                                     <a [attr.href]="'mailto:' + element.email">{{element.userData.email}}</a>
                                 </div>
                            </div>

                            <div class="team-element-diagram" fxLayout="row" fxFlexFill fxLayoutAlign="center center" >
                                
                                <!-- <button mat-stroked-button color="primary" (click)="onRefereeEdit(element.id)">Edit</button> -->
                                <button mat-stroked-button color="warn" (click)="onRefereeDelete(element.id)" i18n="Delete button| Delete Button@@">Delete</button>
                                <button mat-stroked-button  style="color:#23c268;" (click)="onStationAssign(element.id)" i18n="@@assignStation" >Assign Station</button>

                            </div>

                          </div>
                        </td>
                      </ng-container>
                    
                      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                      <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
                          class="example-element-row"
                          [class.example-expanded-row]="expandedElement === element"
                          (click)="expandedElement = expandedElement === element ? null : element">
                      </tr>
                      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>


            </table>
            
            <ng-template #cards>
        
                <div fxFlexFill fxLayout="column" fxLayoutGap="1vh" fxFlexOrder.xs="2">
                    <mat-accordion *ngFor="let element of this.cardData | async">
                        <mat-expansion-panel>
                          <mat-expansion-panel-header fxLayout="row">  
                            
                            <mat-panel-title fxFlex="90" fxLayoutAlign="center center">
                                                 
                            <span style="text-align: center;">{{element.userData.name}}&nbsp;{{element.userData.surname}}</span>

                
                            </mat-panel-title>
                            
                          </mat-expansion-panel-header>
             
                          <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="2vh">
                          
                            <div fxLayout="row wrap" fxLayoutAlign="start center">

                                    <div fxFlex="100" fxLayout="column" fxLayoutAlign="space-between center"  fxLayoutGap="2vh">
                                
                                        <div fxLayout="row nowrap" fxFlexFill fxLayoutAlign="start start">
                                            <mat-icon>phone</mat-icon>  
                                            <a class="cardLink" [attr.href]="'tel:' + element.phone">{{element.phone}}</a>
                                        </div>
                                        <div fxLayout="row nowrap" fxLayoutAlign="start start">
                                            <mat-icon>email</mat-icon>  
                                            <a class="cardLink" [attr.href]="'mailto:' + element.email">{{element.userData.email}}</a>
                                        </div>
                                        
                                        <div class="mat-h4" style="font-weight: bold;" i18n="@@d494be449864c2a49e7eedf6895ae54e5727ac2b">Peg</div>

                                        <div fxLayout="column" fxLayoutAlign="start center">
                                                <span *ngIf="element.stations === 'All'" i18n="@@All">All</span>
                                                <span *ngIf="element.stations != 'All'">{{element.stations}}</span>
                                        </div>
                                        
                                    </div>
                            </div>
                            
                            <div fxLayoutAlign="center end">
                                <button mat-stroked-button color="primary" (click)="onRefereeEdit(element.id)">Edit</button>
                                <button mat-stroked-button color="warn" (click)="onRefereeDelete(element.id)">Delete</button>
                                <button mat-stroked-button  style="color:#23c268;" (click)="onStationAssign(element.id)" i18n="@@assignStation" >Assign Station</button>
                               
                            </div>  
            
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
            </ng-template>
    
            </div>
            
        </div>
    
    
    </div>

</div>