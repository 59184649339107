<article  class="full-description-panel" fxFill fxLayout="column"  fxLayoutAlign="start center">

    <div fxLayout="column" fxLayoutAlign="start center">
        
        <h1 class="mat-display-2" i18n="@@IAmReferee"><strong>Referee</strong></h1>

        <h2 class="mat-display-2" i18n="@@48d83874826df3e9c7ff1e807cd3ff376388b022">How it works ?</h2>
        
    </div>

    <div [ngClass.gt-xs]="'mat-title'" [ngClass.lt-md]="'mobile-font-style'" fxFill style="padding: 5vh 5vw;" fxLayoutGap="5vw" fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="center start" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="start center">

    <div class="description-panel" fxFlex fxLayout.lt-sm="column" fxLayoutGap.lt-sm="5vh">

        <h2 class="mat-display-1" style="text-align: center;" i18n="@@Registration"><strong>Registration</strong></h2>

        <div [ngClass.gt-sm]="'description-element'" fxLayout.lt-sm="column" fxLayoutAlign="start center" fxFlexOrder="1">
            <a routerLink="/main/signup" class="workflow-element" [ngClass]="{'icon-mobile-sm':  UIservice.isMobileSM, 'icon-desktop': !UIservice.isMobileSM}" fxLayoutAlign="center center">
                <mat-icon [inline]="true" aria-hidden="true">how_to_reg</mat-icon>            
            </a>
        
            <div>
                <p i18n="@@CreateYourRefereeAccount">
                      Use <strong>Sign Up</strong> form and create your account.<br>
                      Account can be shared between many Contests.</p>
            </div>
        </div>

        <div [ngClass.gt-sm]="'description-element'" fxLayout.lt-sm="column" fxLayoutAlign="start center" fxFlexOrder="2">
            <div class="workflow-element" [ngClass]="{'icon-mobile-sm':  UIservice.isMobileSM, 'icon-desktop': !UIservice.isMobileSM}" fxLayoutAlign="center center">
                <mat-icon [inline]="true" aria-hidden="true">face</mat-icon>            
            </div>
        
            <div>
                <p i18n="@@RefereeChooseYourRole">
                      You will be asked to <strong>define</strong> your <strong>role</strong><br>
                      Choose <strong>Referee</strong> option.</p>
            </div>
        </div>

        <div [ngClass.gt-sm]="'description-element'" fxLayout.lt-sm="column" fxLayoutAlign="start center" fxFlexOrder="3">
            <div class="workflow-element" [ngClass]="{'icon-mobile-sm':  UIservice.isMobileSM, 'icon-desktop': !UIservice.isMobileSM}" fxLayoutAlign="center center">
                <mat-icon [inline]="true" aria-hidden="true">event</mat-icon>            
            </div>
        
            <div>
                <p i18n="@@RefereeChooseContest">
                    Once your account is ready, then<br>
                    choose <strong>Contest</strong> that you are going to <strong>Refereeing</strong><br>
                    <strong>Organiser</strong> have to <strong>confirm</strong> your request</p>
            </div>
        </div>

        <div [ngClass.gt-sm]="'description-element'" fxLayout.lt-sm="column" fxLayoutAlign="start center" fxFlexOrder="4">
            <div class="workflow-element" [ngClass]="{'icon-mobile-sm':  UIservice.isMobileSM, 'icon-desktop': !UIservice.isMobileSM}" fxLayoutAlign="center center">
                <mat-icon [inline]="true" aria-hidden="true">compare_arrows</mat-icon>            
            </div>
        
            <div>
                <p i18n="@@AddNewRole">
                    You can <strong>use</strong> account in dual mode.<br>
                    Simply go to <strong>Account</strong> settings and add new <strong>Role</strong>.
                </p>
            </div>
        </div>

    </div>

    <div class="description-panel" fxFlex fxLayout.lt-sm="column" fxLayoutGap.lt-sm="5vh">

        <h2 class="mat-display-1" style="text-align: center;"  i18n="@@Refereeing"><strong>Refereeing</strong></h2>

        <div [ngClass.gt-sm]="'description-element'" fxLayout.lt-sm="column" fxLayoutAlign="start center" fxFlexOrder="1">
            <a routerLink="/main/login" class="workflow-element" [ngClass]="{'icon-mobile-sm':  UIservice.isMobileSM, 'icon-desktop': !UIservice.isMobileSM}" fxLayoutAlign="center center">
                <mat-icon [inline]="true" aria-hidden="true">input</mat-icon>            
            </a>
        
            <div>
                <p i18n="@@LoginIntoCarpContestApp">
                      Use <strong>Log In</strong> form and enter Carp Contest App.<br>
                      If your account is set to Organiser and Referee then choose right role.</p>
            </div>
        </div>

        <div [ngClass.gt-sm]="'description-element'" fxLayout.lt-sm="column" fxLayoutAlign="start center" fxFlexOrder="2">
            <div class="workflow-element" [ngClass]="{'icon-mobile-sm':  UIservice.isMobileSM, 'icon-desktop': !UIservice.isMobileSM}" fxLayoutAlign="center center">
                <mat-icon [inline]="true" aria-hidden="true">event</mat-icon>            
            </div>
        
            <div>
                <p i18n="@@RefereeChooseContestSubmit">
                    Choose right <strong>Contest</strong> and <strong>Submit</strong>
                </p>
            </div>
        </div>

        <div [ngClass.gt-sm]="'description-element'" fxLayout.lt-sm="column" fxLayoutAlign="start center" fxFlexOrder="3">
            <div class="workflow-element" [ngClass]="{'icon-mobile-sm':  UIservice.isMobileSM, 'icon-desktop': !UIservice.isMobileSM}" fxLayoutAlign="center center">
                <mat-icon [inline]="true" aria-hidden="true">touch_app</mat-icon>            
            </div>
        
            <div>
                <p i18n="@@SelectAddFish">
                      <strong>Add Fish</strong> option allows you to <strong>introduce</strong> new <strong>Fish</strong>.<br>
                      Collect require data and submit:</p>
                      <ul i18n="@@CompetitorAddFishList">
                        <li>Catch Time</li> 
                        <li>Fish Type and Weight</li>
                        <li>Angler Name</li>
                        <li>Common Fish Photo</li>
                    </ul>
                
            </div>
        </div>

        <div [ngClass.gt-sm]="'description-element'" fxLayout.lt-sm="column" fxLayoutAlign="start center" fxFlexOrder="4">
            <div class="workflow-element" [ngClass]="{'icon-mobile-sm':  UIservice.isMobileSM, 'icon-desktop': !UIservice.isMobileSM}" fxLayoutAlign="center center">
                <mat-icon [inline]="true" aria-hidden="true">task_alt</mat-icon>            
            </div>
        
            <div>
                <p i18n="@@WaitForTheConfirmation">
                      Wait for the <strong>confirmation</strong> that Fish has been <strong>succesfully added</strong>.</p>
            </div>
        </div>

    </div>
</div>


</article>