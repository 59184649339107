import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Injectable, EventEmitter } from '@angular/core';
import {  Subject, Observable, Subscription, EMPTY } from 'rxjs';
import { User, createUserWithEmailAndPassword, Auth,signInWithEmailAndPassword, signOut } from '@angular/fire/auth';

@Injectable({
    providedIn: 'root'
})

export class FirebaseAuthService {

    private onAuthStateChanged: Subject<boolean> = new Subject()
    private isAuthenticated: boolean = false;
    private readonly userDisposable: Subscription|undefined;
    public readonly user: Observable<User | null> = EMPTY;


constructor(private fireAuth: Auth){

}

signUpUser(email: string, password: string){

   return createUserWithEmailAndPassword(this.fireAuth, email, password)
}

signinUser(email: string, password: string){

    return signInWithEmailAndPassword(this.fireAuth, email, password)
}

signoutUser(){

    return signOut(this.fireAuth)
}

getAuthState(): Observable<boolean>{
    return this.onAuthStateChanged.asObservable()
}

startAuthObserver(){
    this.fireAuth.onAuthStateChanged((user) => {
        if (user)
        {
            this.isAuthenticated = true
            this.onAuthStateChanged.next(this.isAuthenticated)
        }
        else
        {
            this.isAuthenticated = false
            console.log('false onAuthStateCHanged')
            this.onAuthStateChanged.next(this.isAuthenticated)
        }
    })
}

}
