<section *ngIf="this.awards && modeEdit === ''; else awardsForm"  class="content-container" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="1vw">
   
    <h1 class="mat-headline" style="text-decoration: underline;" i18n="Awards Details Header| Header for Awards Detailss@@">Awards:</h1> 
        
    <div *ngIf="this.awards.totalPool" style="width: 100%;" fxLayout="column" fxLayoutAlign="start start" >
        <p fxLayout="row" fxLayoutAlign="start start">
            <label class="propertyLabel" title="Awards Total Pool" i18n="Awards Total Pool Label|Awards Total Pool label@@">Total Pool:</label>
        {{this.awards.totalPool}}
        </p>

    </div>

    <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="1.5vw">
        
        <div *ngIf="this.awards.First" class="descriptionArea" fxLayout="column" fxLayoutAlign="start start" >
        
            <h1 class="mat-headline" style="text-decoration: underline;" i18n="Awards First Place Header| Header First Place Details@@">First Place:</h1> 
            
            <p fxLayout="row" fxLayoutAlign="start start">
                <label class="propertyLabel" title="First Place Cash" i18n="awards cash Type Label|awards cash Type label@@">Cash:</label>
            {{this.awards.First.cash}}
            </p>
    
            <p fxLayout="row" fxLayoutAlign="start start">
                <label class="propertyLabel" title="First Place free entry" i18n="awards free entry Type Label|awards free entry Type label@@">Free Entry:</label>
            {{this.awards.First.freeEntry}}
            </p>
            
            <p fxLayout="row" fxLayoutAlign="start start">
                <label class="propertyLabel" title="First Place sponsored items" i18n="awards sponsored items Type Label|awards sponsored items Type label@@">Sponsored items:</label>
                <span *ngIf="this.awards.First.sponsoredItems" i18n="@@Yes">Yes</span>
                <span *ngIf="!this.awards.First.sponsoredItems" i18n="@@NoNotation">No</span>
            </p>
        </div>
    
        <div *ngIf="this.awards.Second" class="descriptionArea" fxLayout="column" fxLayoutAlign="start start" >
            
            <h1 class="mat-headline" style="text-decoration: underline;" i18n="Awards Second Place Header| Header Second Place Details@@">Second Place:</h1> 
            
            <p fxLayout="row" fxLayoutAlign="start start">
                <label class="propertyLabel" title="Second Place Cash" i18n="awards cash Type Label|awards cash Type label@@">Cash:</label>
            {{this.awards.Second.cash}}
            </p>
    
            <p fxLayout="row" fxLayoutAlign="start start">
                <label class="propertyLabel" title="Second Place free entry" i18n="awards free entry Type Label|awards free entry Type label@@">Free Entry:</label>
            {{this.awards.Second.freeEntry}}
            </p>
            
            <p fxLayout="row" fxLayoutAlign="start start">
                <label class="propertyLabel" title="Second Place sponsored items" i18n="awards sponsored items Type Label|awards sponsored items Type label@@">Sponsored items:</label>
                <span *ngIf="this.awards.Second.sponsoredItems" i18n="@@Yes">Yes</span>
                <span *ngIf="!this.awards.Second.sponsoredItems" i18n="@@NoNotation">No</span>
            </p>
        </div>

        <div *ngIf="this.awards.Third" class="descriptionArea" fxLayout="column" fxLayoutAlign="start start" >
        
        <h1 class="mat-headline" style="text-decoration: underline;" i18n="Awards Third Place Header| Header Third Place Details@@">Third Place:</h1> 
        
        <p fxLayout="row" fxLayoutAlign="start start">
            <label class="propertyLabel" title="Third Place Cash" i18n="awards cash Type Label|awards cash Type label@@">Cash:</label>
        {{this.awards.Third.cash}}
        </p>

        <p fxLayout="row" fxLayoutAlign="start start">
            <label class="propertyLabel" title="Third Place free entry" i18n="awards free entry Type Label|awards free entry Type label@@">Free Entry:</label>
        {{this.awards.Third.freeEntry}}
        </p>
        
        <p fxLayout="row" fxLayoutAlign="start start">
            <label class="propertyLabel" title="Third Place sponsored items" i18n="awards sponsored items Type Label|awards sponsored items Type label@@">Sponsored items:</label>
            <span *ngIf="this.awards.Third.sponsoredItems" i18n="@@Yes">Yes</span>
            <span *ngIf="!this.awards.Third.sponsoredItems" i18n="@@NoNotation">No</span>
        </p>
        </div>

        <div *ngIf="this.awards.BigFish" class="descriptionArea" fxLayout="column" fxLayoutAlign="start start" >
        
        <h1 class="mat-headline" style="text-decoration: underline;" i18n="Awards BigFish Place Header| Header BigFish Place Details@@">Big Fish:</h1> 
        
        <p fxLayout="row" fxLayoutAlign="start start">
            <label class="propertyLabel" title="BigFish Place Cash" i18n="awards cash Type Label|awards cash Type label@@">Cash:</label>
        {{this.awards.BigFish.cash}}
        </p>

        <p fxLayout="row" fxLayoutAlign="start start">
            <label class="propertyLabel" title="BigFish Place free entry" i18n="awards free entry Type Label|awards free entry Type label@@">Free Entry:</label>
        {{this.awards.BigFish.freeEntry}}
        </p>
        
        <p fxLayout="row" fxLayoutAlign="start start">
            <label class="propertyLabel" title="BigFish Place sponsored items" i18n="awards sponsored items Type Label|awards sponsored items Type label@@">Sponsored items:</label>
            <span *ngIf="this.awards.BigFish.sponsoredItems" i18n="@@Yes">Yes</span>
            <span *ngIf="!this.awards.BigFish.sponsoredItems" i18n="@@NoNotation">No</span>
        </p>
        </div>

        <div *ngIf="this.awards.FirstFish" class="descriptionArea" fxLayout="column" fxLayoutAlign="start start" >
        
        <h1 class="mat-headline" style="text-decoration: underline;" i18n="Awards FirstFish Place Header| Header FirstFish Place Details@@">First Fish:</h1> 
        
        <p fxLayout="row" fxLayoutAlign="start start">
            <label class="propertyLabel" title="FirstFish Place Cash" i18n="awards cash Type Label|awards cash Type label@@">Cash:</label>
        {{this.awards.FirstFish.cash}}
        </p>

        <p fxLayout="row" fxLayoutAlign="start start">
            <label class="propertyLabel" title="FirstFish Place free entry" i18n="awards free entry Type Label|awards free entry Type label@@">Free Entry:</label>
        {{this.awards.FirstFish.freeEntry}}
        </p>
        
        <p fxLayout="row" fxLayoutAlign="start start">
            <label class="propertyLabel" title="FirstFish Place sponsored items" i18n="awards sponsored items Type Label|awards sponsored items Type label@@">Sponsored items:</label>
            <span *ngIf="this.awards.FirstFish.sponsoredItems" i18n="@@Yes">Yes</span>
            <span *ngIf="!this.awards.FirstFish.sponsoredItems" i18n="@@NoNotation">No</span>
        </p>
        </div>

        <div *ngIf="this.awards.more" class="descriptionArea" fxLayout="column" fxLayoutAlign="start start" >
        
        <h1 class="mat-headline" style="text-decoration: underline;" i18n="Awards More awards Place Header| Header More awards Place Details@@">More awards:</h1> 
        
        <p fxLayout="row" fxLayoutAlign="start start">
            <label class="propertyLabel" title="More awards" i18n="More awards Label|awards More awards label@@">More Awards:</label>
        {{this.awards.more}}
        </p>

        </div>

    </div>

    <button *ngIf="!this.modeEdit"
            mat-button color="accent" 
            (click)="onEdit()"
            i18n="awards Edit Button|awards Edit Button@@"
    >
    Edit Awards
    </button>


</section>

<ng-template #awardsForm>

    <section [ngClass]="{'content-container-edit': this.modeEdit === 'edit' || this.isCreate && !this.UIservice.isMobileSM,
                        'mobile-content-container-edit': this.isCreate && this.UIservice.isMobileSM}"  
                        fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="1vw">
    
        <h1 *ngIf="!this.isCreate" class="mat-headline" i18n="Edit Awards Header| Header for Edit Awards@@">Edit Awards:</h1> 
        <h1 *ngIf="this.isCreate" class="mat-headline" i18n="Enter Awards Header| Header for Enter Awards@@">Enter Awards:</h1>

        <form *ngIf="this.editAwards"   fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="10vh" 
                #a="ngForm"             fxLayout.gt-sm="row wrap" fxLayoutAlign.gt-sm="start start" fxLayoutGap.gt-sm="4vw" >

            <div fxFlex fxLayout="column" fxLayoutAlign="start center">
              
                <h2 class="mat-headline" style="width: 100%; text-align: center;" i18n="@@e144234646606fe54cfb483864f9abe4d2faf622">Awards Details</h2>

                <mat-form-field>
                    <mat-label title="Awards Total Pool" i18n="Awards Total Pool Label|Awards Total Pool label@@">Total Pool</mat-label>
                    <input matInput
                            [(ngModel)]="editAwards.totalPool"
                            name="totalPool"
                    >
                </mat-form-field>

                <section fxLayout="column" fxLayoutAlign="start start">                  
                    <mat-checkbox class="radio-margin" name="BigFishOption" (change)="onBigFish()" [(ngModel)]="this.bigFish" i18n="b7b9cbb9e30a08a2ecddb8e96e5804de0e9d04fc">Big Fish</mat-checkbox>
                    <mat-checkbox class="radio-margin" name="FirstFishOption" (change)="onFirstFish()" [(ngModel)]="this.firstFish" i18n="46e227c8098d63169d93d5cd1770a12b86fdea92">First Fish</mat-checkbox>
                    <mat-checkbox class="radio-margin" name="MoreAwardsOption" (change)="onMoreAwards()" [(ngModel)]="this.editAwards.more" i18n="MoreAwards">More Awards</mat-checkbox>
                </section>
            </div>
                   
            <div fxFlex fxLayout="column" fxLayoutAlign="start center">
                
                <h2 class="mat-headline" style="width: 100%; text-align: center;" i18n="Awards First Place Header| Header First Place Details@@">First Place</h2>

                <mat-form-field>
                <mat-label title="First Place Cash" i18n="awards cash Type Label|awards cash Type label@@">Cash</mat-label>
                <input matInput
                        [(ngModel)]="editAwards.First.cash"
                        name="First Place cash"
                        required
                >
                </mat-form-field>

                <mat-form-field>
                    <mat-label title="First Place free entry" i18n="awards free entry Type Label|awards free entry Type label@@">Free Entry</mat-label>
                    <input matInput
                            [(ngModel)]="editAwards.First.freeEntry"
                            name="First Place Free Entry"
                    >
                </mat-form-field>

                <section fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="1vh">
                    <label title="Sponsored items" i18n="awards sponsored items Type Label|awards sponsored items Type label@@">Sponsored items</label>
                    <mat-radio-group name="firstItemschoose" [(ngModel)]="editAwards.First.sponsoredItems">
                      <mat-radio-button class="radio-margin" [value]="true">Yes</mat-radio-button>
                      <mat-radio-button class="radio-margin" [value]="false">No</mat-radio-button>
                    </mat-radio-group>
                  </section>
                
            </div>

            <div fxFlex fxLayout="column" fxLayoutAlign="start center">
                
                <h2 class="mat-headline" style="width: 100%; text-align: center;" i18n="Awards Second Place Header| Header Second Place Details@@">Second Place</h2>

                <mat-form-field>
                <mat-label title="First Place Cash" i18n="awards cash Type Label|awards cash Type label@@">Cash</mat-label>
                <input matInput
                        [(ngModel)]="editAwards.Second.cash"
                        name="Second Place cash"
                        required
                >
                </mat-form-field>

                <mat-form-field>
                    <mat-label title="First Place free entry" i18n="awards free entry Type Label|awards free entry Type label@@">Free Entry</mat-label>
                    <input matInput
                            [(ngModel)]="editAwards.Second.freeEntry"
                            name="Second Place Free Entry"
                    >
                </mat-form-field>

                <section fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="1vh">
                    <label title="Sponsored items" i18n="awards sponsored items Type Label|awards sponsored items Type label@@">Sponsored items</label>
                    <mat-radio-group name="SecondItemschoose" [(ngModel)]="editAwards.Second.sponsoredItems">
                      <mat-radio-button class="radio-margin" [value]="true">Yes</mat-radio-button>
                      <mat-radio-button class="radio-margin" [value]="false">No</mat-radio-button>
                    </mat-radio-group>
                  </section>
                
            </div>

            <div fxFlex fxLayout="column" fxLayoutAlign="start center">
                
                <h2 class="mat-headline" style="width: 100%; text-align: center;" i18n="Awards Third Place Header| Header Third Place Details@@">Third Place</h2>

                <mat-form-field>
                <mat-label title="Third Place Cash" i18n="awards cash Type Label|awards cash Type label@@">Cash</mat-label>
                <input matInput
                        [(ngModel)]="editAwards.Third.cash"
                        name="Third Place cash"
                        required
                >
                </mat-form-field>

                <mat-form-field>
                    <mat-label title="Third Place free entry" i18n="awards free entry Type Label|awards free entry Type label@@">Free Entry</mat-label>
                    <input matInput
                            [(ngModel)]="editAwards.Third.freeEntry"
                            name="Third Place Free Entry"
                    >
                </mat-form-field>

                <section fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="1vh">
                    <label title="Sponsored items" i18n="awards sponsored items Type Label|awards sponsored items Type label@@">Sponsored items</label>
                    <mat-radio-group name="ThirdItemschoose" [(ngModel)]="editAwards.Third.sponsoredItems">
                      <mat-radio-button class="radio-margin" [value]="true">Yes</mat-radio-button>
                      <mat-radio-button class="radio-margin" [value]="false">No</mat-radio-button>
                    </mat-radio-group>
                  </section>
                
            </div>

            <div *ngIf="this.editAwards.BigFish" fxFlex fxLayout="column" fxLayoutAlign="start center">
                
                <h2 class="mat-headline" style="width: 100%; text-align: center;" i18n="Awards BigFish Place Header| Header BigFish Place Details@@">Big Fish</h2>

                <mat-form-field>
                <mat-label title="BigFish Place Cash" i18n="awards cash Type Label|awards cash Type label@@">Cash</mat-label>
                <input matInput
                        [(ngModel)]="editAwards.BigFish.cash"
                        name="BigFish Place cash"
                        required
                >
                </mat-form-field>

                <mat-form-field>
                    <mat-label title="BigFish Place free entry" i18n="awards free entry Type Label|awards free entry Type label@@">Free Entry</mat-label>
                    <input matInput
                            [(ngModel)]="editAwards.BigFish.freeEntry"
                            name="BigFish Place Free Entry"
                    >
                </mat-form-field>

                <section fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="1vh">
                    <label title="Sponsored items" i18n="awards sponsored items Type Label|awards sponsored items Type label@@">Sponsored items</label>
                    <mat-radio-group name="BigFishItemschoose" [(ngModel)]="editAwards.BigFish.sponsoredItems" >
                      <mat-radio-button class="radio-margin" [value]="true">Yes</mat-radio-button>
                      <mat-radio-button class="radio-margin" [value]="false">No</mat-radio-button>
                    </mat-radio-group>
                  </section>
                
            </div>

            <div *ngIf="this.editAwards.FirstFish" fxFlex fxLayout="column" fxLayoutAlign="start center">
                
                <h2 class="mat-headline" style="width: 100%; text-align: center;" i18n="Awards FirstFish Place Header| Header FirstFish Place Details@@">First Fish</h2>

                <mat-form-field>
                <mat-label title="First Place Cash" i18n="awards cash Type Label|awards cash Type label@@">Cash</mat-label>
                <input matInput
                        [(ngModel)]="editAwards.FirstFish.cash"
                        name="BigFish Place cash"
                        required
                >
                </mat-form-field>

                <mat-form-field>
                    <mat-label title="First Place free entry" i18n="awards free entry Type Label|awards free entry Type label@@">Free Entry</mat-label>
                    <input matInput
                            [(ngModel)]="editAwards.FirstFish.freeEntry"
                            name="BigFish Place Free Entry"
                    >
                </mat-form-field>

                <section fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="1vh">
                    <label title="Sponsored items" i18n="awards sponsored items Type Label|awards sponsored items Type label@@">Sponsored items</label>
                    <mat-radio-group name="FirstFishItemschoose" [(ngModel)]="editAwards.FirstFish.sponsoredItems">
                      <mat-radio-button class="radio-margin" [value]="true">Yes</mat-radio-button>
                      <mat-radio-button class="radio-margin" [value]="false">No</mat-radio-button>
                    </mat-radio-group>
                  </section>
                
            </div>

            <div fxFlex="90" fxLayoutAlign="center center" fxLayoutGap="2vh">
                    <button *ngIf="!this.isCreate"
                        mat-raised-button
                        color="warn"
                        i18n="cancel| cancel button@@"
                        (click)="onCancel()">Cancel</button>
                
                    <button [disabled]="!(a.valid && a.touched)" 
                        mat-raised-button
                        color="primary"
                        i18n="Edit Save Button| Edit Save Button@@"
                        (click)="onUpdate()">Save</button>
                    
                    <label i18n="fillRequiredFields| fill Required Data@@">* required fields</label>
            </div>        
                  
        </form>
        
    </section>

</ng-template>