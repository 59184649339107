
    <ng-template [ngIf]="editModeAngler && editAngler">
        <app-edit-angler [editAngler]="this.editAngler" [editType]="this.editData.editType" (exit)="onExitEdit()" (onUpdate)="onAnglerEditUpdate($event)"></app-edit-angler>
    </ng-template>

    <ng-template [ngIf]="editModeTeam && editTeam">
        <app-edit-team [editTeam]="this.editTeam" [teamID]="this.editData.teamId" [editType]="this.editData.editType" (exit)="onExitEdit()"></app-edit-team>
    </ng-template>

<div class="content-container" fxFlexFill fxLayout="column" fxLayoutAlign="start center">

    <h1 class="mat-h1" i18n="@@6dfb00a0592df4dbb0947ea5cb86085e69ca1386">Reservation:</h1>

    <div [hidden]="!this.dataSource" fxFlexFill fxLayoutAlign="center start" >
        <div style="margin: 2vh;" fxFlex.gt-md="95" fxFlex="100" class="mat-elevation-z8" fxLayout="column">

            <div fxLayout="row wrap" class="fiter-container" fxLayout.xs="column" fxLayoutGap.xs="1vh">
                <div fxFlex="100" fxFlex.xs fxFlexOrder="1">

                    <mat-form-field  fxLayoutAlign="center center" fxLayoutAlign.xs="center center">
                        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
                    </mat-form-field>

                </div>

                <div fxFlex="100" fxFlex.xs fxFlexOrder="3">

                    <mat-paginator [length]="this.dataSource.data.length"
                                    [pageSize]="5"
                                    showFirstLastButtons
                                    (page)="pageEvent = $event">
                    </mat-paginator>

                </div>


            <table *ngIf="!this.UIservice.isMobileSM; else cards"
                    class="mat-elevation-z8"
                    fxFlexOrder="2"
                    fxFlex="100"
                    mat-table
                    multiTemplateDataRows
                    [dataSource]="dataSource">

                    <ng-container matColumnDef="No">
                        <th fxFlex="10" fxLayoutAlign="center center" mat-header-cell *matHeaderCellDef i18n="@@Number">No</th>
                        <td fxFlex="10" fxLayoutAlign="center center" mat-cell *matCellDef="let element; let i = dataIndex;"> {{i +1}} </td>
                      </ng-container>

                      <ng-container matColumnDef="promoTeam">
                        <th fxFlex="30" fxLayoutAlign="center center" mat-header-cell *matHeaderCellDef i18n="@@cc2bc5da34f5023a830bb63577ae545556348c65">Team </th>
                        <td fxFlex="30" fxLayoutAlign="center center" mat-cell *matCellDef="let element">{{element.team.promoTeam}}</td>
                      </ng-container>

                      <ng-container matColumnDef="Angler1">
                        <th fxFlex="30" fxLayoutAlign="center center" mat-header-cell *matHeaderCellDef i18n="@@f9ac4b5125488e0daf4bfdfdb014a865783a6ca9">Angler 1 </th>
                        <td fxFlex="30" fxLayoutAlign="center center" mat-cell *matCellDef="let element">
                            {{element.team.anglers[0] ? element.team.anglers[0].name : ''}}&nbsp;{{element.team.anglers[0] ? element.team.anglers[0].surname : ''}}</td>
                      </ng-container>

                      <ng-container matColumnDef="Angler2">
                        <th fxFlex="30" fxLayoutAlign="center center" mat-header-cell *matHeaderCellDef i18n="@@9a5d75a9dd5efa727f0b9677355631165b448061">Angler 2 </th>
                        <td fxFlex="30" fxLayoutAlign="center center" mat-cell *matCellDef="let element">
                            {{element.team.anglers[1] ? element.team.anglers[1].name : ''}}&nbsp;{{element.team.anglers[1] ? element.team.anglers[1].surname : ''}}</td>
                      </ng-container>

                      <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                      <ng-container matColumnDef="expandedDetail">
                        <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
                            <div fxLayout="column" class="team-element-detail"
                               [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">

                            <div class="angler-element-diagram" fxLayout="row" *ngFor="let angler of element.team.anglers; let i = index;">
                              <div fxFlex fxLayoutAlign="center center" class="angler-element-role"> {{angler.teamRole}} </div>
                              <div fxFlex fxLayoutAlign="center center" class="angler-element-detail"> {{angler.name}}&nbsp;{{angler.surname}} </div>
                              <div fxFlex fxLayoutAlign="center center" class="angler-element-country"> {{angler.country}}</div>
                              <div fxFlex fxLayoutAlign="center center" class="angler-element-actions">
                                <button mat-button color="accent" (click)="onAnglerEdit(element.id, i)" i18n="@@edit">Edit</button>
                                <button mat-button color="warn" (click)="onAnglerDelete(element.id, i)" i18n="@@delete">Delete</button>
                              </div>
                            </div>

                            <div class="angler-element-diagram" fxLayout="row">
                                <div fxFlex fxLayoutAlign="center center">
                                    <mat-icon>phone</mat-icon>
                                     <a class="tableLink" [attr.href]="'tel:' + element.team.privateData.leaderPhone">{{element.team.privateData.leaderPhone}}</a>
                                 </div>

                                 <div fxFlex fxLayoutAlign="center center">
                                     <mat-icon>email</mat-icon>
                                     <a class="tableLink" [attr.href]="'mailto:' + element.team.privateData.leaderEmail">{{element.team.privateData.leaderEmail}}</a>
                                 </div>
                            </div>

                            <div class="angler-element-diagram" fxLayout="row">
                                <div fxFlex fxLayoutAlign="center center">
                                    <button mat-button color="accent" (click)="onTeamEdit(element.id)" i18n="@@editTeam">Edit Team</button>
                                    <button mat-button color="warn" (click)="onTeamDelete(element.id)" i18n="@@deleteTeam">Delete Team</button>
                                    <button mat-button style="color:#23c268;" (click)="onConfirmPayment(element.id)" i18n="@@confirmPayment">Confirm Payment</button>
                                </div>
                            </div>

                          </div>
                        </td>
                      </ng-container>

                      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                      <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
                          class="example-element-row"
                          [class.example-expanded-row]="expandedElement === element"
                          (click)="expandedElement = expandedElement === element ? null : element">
                      </tr>
                      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>


            </table>

            <ng-template #cards>

                <div fxFlexFill fxLayout="column" fxLayoutGap="1vh" fxFlexOrder.xs="2">
                    <mat-accordion *ngFor="let element of this.cardData | async, let i = index">
                        <mat-expansion-panel>
                          <mat-expansion-panel-header fxLayout="row">

                            <mat-panel-title fxFlex="30">

                             <label i18n="@@Number">No</label>
                                &nbsp;{{i +1}}

                            </mat-panel-title>

                            <mat-panel-description fxFlex="60">

                                <label fxFlex="50">{{element.team.anglers[0].surname}}</label>
                                <label fxFlex="50">{{element.team.anglers[1].surname}}</label>

                            </mat-panel-description>
                          </mat-expansion-panel-header>

                          <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="2vh">

                                    <div class="mat-h4" style="font-weight: bold;" i18n="@@TeamPromoTeam">Team:&nbsp;{{element.team.promoTeam}}</div>

                            <div fxLayout="row wrap" fxLayoutAlign="start center">

                                    <div fxFlex="100" fxLayout="column" fxLayoutAlign="space-between center"  fxLayoutGap="2vh">

                                        <div fxLayout="row nowrap" fxLayoutAlign="center start">
                                            <mat-icon>phone</mat-icon>
                                            <a class="cardLink" [attr.href]="'tel:' + element.team.leaderPhone">{{element.team.privateData.leaderPhone}}</a>
                                        </div>
                                        <div fxLayout="row nowrap" fxLayoutAlign="center start">
                                            <mat-icon>email</mat-icon>
                                            <a class="cardLink" [attr.href]="'mailto:' + element.team.leaderEmail">{{element.team.privateData.leaderEmail}}</a>
                                        </div>

                                        <div fxLayoutAlign="center end">
                                            <button i18n="@@editTeam" mat-stroked-button color="primary" (click)="onTeamEdit(element.id)">Edit Team</button>
                                            <button  i18n="@@deleteTeam" mat-stroked-button color="warn" (click)="onTeamDelete(element.id)">Delete Team</button>
                                        </div>

                                    </div>
                            </div>

                                    <div class="mat-h4" style="font-weight: bold;">Anglers:&nbsp;</div>

                          <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="2vh">


                            <div style="width: 100%;" fxLayout="row wrap" fxLayoutAlign="space-between center" *ngFor="let angler of element.team.anglers; let i = index;" >

                                <div fxFlex="30" style="font-weight: bold;">
                                    {{angler.teamRole}}
                                </div>
                                <div fxFlex="60">
                                    {{angler.name}}&nbsp;{{angler.surname}}
                                </div>
                                <div fxFlex="10">
                                    {{angler.country}}
                                </div>
                                <div fxFlex="100" fxLayoutAlign="end center">
                                    <button i18n="@@edit" mat-stroked-button color="primary" (click)="onAnglerEdit(element.id, i)">Edit</button>
                                    <button i18n="@@delete" mat-stroked-button color="warn" (click)="onAnglerDelete(element.id, i)">Delete</button>
                                </div>

                            </div>

                            <button mat-stroked-button style="color:#23c268;" (click)="onConfirmPayment(element.id)" i18n="@@confirmPayment">Confirm Payment</button>

                          </div>



                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
            </ng-template>

            </div>

        </div>

    </div>

</div>


