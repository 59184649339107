import { Component, OnInit, Output, EventEmitter} from '@angular/core';

import { AuthService } from 'src/app/auth-module/service/auth.service';


@Component({
  selector: 'app-sidebar-auth-links',
  template: `<a *ngIf="!this.authService.isAuthenticated"
                role="navigation"
                mat-list-item 
                routerLink="main/login"
            >
                <mat-icon>input</mat-icon>
                &nbsp;
                <span class="nav-caption" i18n="@@LogInMenuDevice">Log In</span>
            </a>

            <a *ngIf="!this.authService.isAuthenticated"
                role="navigation"
                mat-list-item 
                routerLink="main/signup"
            >
                <mat-icon>how_to_reg</mat-icon>
                &nbsp;
                <span class="nav-caption" i18n="@@SignUpMenuDevice">Sign Up</span>
            </a>


            <a *ngIf="this.authService.isAuthenticated"
                role="navigation"
                mat-list-item
                (click)="onLogOut()" 
            >
                <mat-icon style="color: red;">input</mat-icon>
                &nbsp;
                <span class="nav-caption" i18n="@@LogOutMenuDevice">Log Out</span>
            </a>`
})
export class SideBarAuthLinksComponent implements OnInit {
 

  constructor(public authService: AuthService) { 
  }

  ngOnInit() {
  }

  onLogOut(){
      this.authService.signoutUser()
  }



}