export class Fish {
    
    anglerID?: string;
    anglerName?: string
    anglerSurname?: string
    date?: string;
    fishType?: String; 
    photoUrl?: string;
    refereeID?: string;
    station?: number;
    teamID?: string;
    weight?: number;

    id?: string;
    photoFile?: any
    
    
}