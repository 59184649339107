import { Component, OnInit, Input } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { RankingItem } from 'src/app/data/models/Manage/rankingItem.model';
import { Fish } from 'src/app/data/models/Manage/fish.model';

import { Store } from '@ngrx/store'
import * as fromRoot from '../../../../../../../../../src/app/presentation/app.reducer'
import * as UI from '../../../../../../../../../src/app/shared/service/ui.actions'

@Component({
  selector: 'app-winner-places',
  templateUrl: './winner-places.component.html',
  styleUrls: ['./winner-places.component.css']
})
export class WinnerPlacesComponent implements OnInit {

  @Input() awards: any
  @Input() currency: string
  @Input() dataLoadEvent: Observable<RankingItem[]>;
  isLoading$: Observable<boolean>;

  winnerTeams: RankingItem[]
  
  bigFish: Fish
  firstFish: Fish

  private eventsSubscription: Subscription;
  
  constructor(private store: Store<fromRoot.State>) {
    this.winnerTeams = []
  }

  ngOnInit() {
    this.isLoading$ = this.store.select(fromRoot.getIsLoadingChild);
    this.store.dispatch(new UI.StartLoadingChild())

    this.eventsSubscription = this.dataLoadEvent
      .subscribe(teams => {
        
        if(teams.length > 0)
        {
          this.winnerTeams = teams
          this.bigFish = this.getBishFish(this.winnerTeams)
        }
         
         this.store.dispatch(new UI.StopLoadingChild())
        })

  }

  getBishFish(ranking: RankingItem[]): Fish{

    let rankingFish: Fish[] = []
    
    ranking.forEach(team => team.topFish.forEach(fish => rankingFish.push(fish)))
    rankingFish.sort((a,b) => b.weight - a.weight)

    let team = ranking.filter(team => team.id === rankingFish[0].teamID)

    let fish = team[0].topFish[0]
  
    let angler = team[0].anglers[fish.anglerID]

    fish.anglerName = angler.name
    fish.anglerSurname = angler.surname
    
    return fish
  }


  ngOnDestroy(){
    this.eventsSubscription.unsubscribe();
  }

}
