<div *ngIf="awardsDetails.cash" class="cashBagde">
    <label title="Prize: {{awardsDetails.cash}} in cash" class="mat-small">{{awardsDetails.cash}}&nbsp;{{this.currency}}</label>
</div>

<div *ngIf="awardsDetails.freeEntry" class="entryBagde">
    <label title="Prize: {{awardsDetails.freeEntry}} free entry" class="mat-small">{{awardsDetails.freeEntry | titlecase}}</label>
</div>

<div *ngIf="awardsDetails.sponsoredItems" class="itemsBagde">
        <label title="Prize: Sponsored awards" class="mat-small" i18n="SponsoredAwards|Sponsored Awards label@@">Sponsored Awards</label>
</div>