<section     class="content-container"  fxLayout="column" 
            fxLayoutAlign="start center" fxLayoutGap="1vw">

            <h1 class="mat-h1" i18n="Partners Header| Header for partners list@@">Partners</h1>

            <button mat-stroked-button 
                    color="warn" 
                    i18n="@@addPartner"
                    (click)="onAddPartner()">Add Partner</button>

            <div *ngIf="this.partners" fxFlexFill fxLayout="column" fxLayoutGap="2vh" fxLayoutAlign="start center">

                <div style="width: 95%;" fxLayout="row wrap" fxLayoutAlign="center start" >
                
                    <app-partner-card *ngFor="let _partner of cardData | async" [partner]="_partner"></app-partner-card>

                </div>
                
                </div>
                
                
                

</section>
