import { Injectable } from '@angular/core';

import { Router } from '@angular/router';
import { User } from '../user';
import * as fromRoot from 'src/app/presentation/app.reducer'
import * as App from 'src/app/shared/service/app.actions'
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ServeUserService {

    appState$ = this.store.select(fromRoot.getAppState)
    user: User = new User({email: 'none', phone: 'none'})
    newLogIn: boolean = false
    isAdmin: boolean = false
    isOrganiser: boolean = false
    isNewOrganiser: boolean = false
    isReferee: boolean = false
    isNewReferee: boolean = false
    isNewUser: boolean = false
    requireComanyData: boolean
    hasDataFilled: boolean

    constructor(private router: Router, private store: Store<fromRoot.State>){
    }

    serve(_user: User){

        if(this.user.email != _user.email)
        {
        this.user = _user
        
        this.assignUserRoles()

        this.requireComanyData = this.isOrganiser ? true : false

        this.checkUserData(this.requireComanyData, _user)

        this.navigateUser()
        }
        else
        {
            this.user = _user

            this.assignUserRoles()

            this.requireComanyData = this.isOrganiser ? true : false

            this.checkUserData(this.requireComanyData, _user)
        }
        
    }

    private assignUserRoles(): void{

        if (this.user.roles){
            this.isAdmin = this.user.roles.admin ? true : false
            this.isOrganiser = this.user.roles.organiser || this.user.newOrganiser ? true : false
            this.isReferee = this.user.roles.referee || this.user.newReferee ? true : false
        }
        else if (this.user.isNewUser)
        {
            this.isAdmin = false;
            this.isOrganiser = false;
            this.isReferee = false;
            this.isNewUser = true
        }
        else if (this.user.newOrganiser || this.user.newReferee)
        {
            this.isNewOrganiser = this.user.newOrganiser
            this.isNewReferee = this.user.newReferee

            this.isAdmin = false
            this.isOrganiser = this.user.newOrganiser ? true : false
            this.isReferee = this.user.newReferee ? true : false
        }
    }

    private checkUserData(_requireCompanyData: boolean, _user: User): void{
        let hasUserData: boolean = this.user.userData ? true : false
        

        if (!hasUserData){

                this.hasDataFilled = false
        }
        else
        {
            if (!this.user.userData.comapany && _requireCompanyData)
            {
                this.hasDataFilled = false
            }
            else
            {
                this.hasDataFilled = true
            }
            
        }

    }

    navigateUser(): void{
        let multiRole: boolean = (this.isAdmin || this.isOrganiser || this.isNewOrganiser) && (this.isReferee || this.isNewReferee) ? true : false

        this.appState$.pipe(take(1)).subscribe((appState) => {
            if (this.isAdmin && !(appState.isContest)){
                this.router.navigate(['/admin'])
            }
            else if (multiRole && !(appState.isContest)){
                this.router.navigate(['/main/choose-role'])
            }
            else if (this.isNewUser && !(appState.isContest)){
                this.router.navigate(['/main/choose-role'])
            }
            else if ((this.isOrganiser || this.isNewOrganiser) && !(appState.isContest)){
                this.router.navigate(['organiser'])
            }
            else if ((this.isReferee || this.isNewReferee) && !(appState.isContest)){
                this.router.navigate(['referee'])
            }
            else if (appState.isContest || appState.isMain){
                return this.appState$.subscribe()
            }
            else
            {
                this.router.navigate(['/main/choose-role'])
            }

        })

        this.appState$.subscribe()
        
    }

    

    

}