import { Component, OnInit } from '@angular/core';
import { BaseLandingComponent } from '../../../baseLanding.component';
import { UIService } from 'src/app/shared/service/ui.service';


@Component({
  selector: 'app-benefits',
  templateUrl: './benefits.component.html',
  styleUrls: ['./benefits.component.css']
})
export class BenefitsComponent extends BaseLandingComponent implements OnInit {

  public searchPicture = "assets/pictures/search.png";
  public detailsPicture = "assets/pictures/liveDetails.png";
  public chartPicture = "assets/pictures/liveCharts.png";
  public rankingPicture = "assets/pictures/WynikiLive.png";
  public signupPicture = "assets/pictures/zapisy.png";
  public mainPanelPicture = "assets/pictures/mainPanel.png";
  public teamsPanelPicture = "assets/pictures/teamsPanel.png";
  public contestDetailsPanelPicture = "assets/pictures/contestDetailsPanel.png";
  public sponsorsPanelPictue = "assets/pictures/sponsorsPanel.png";
  public facebookPicture = "assets/pictures/facebookPanel.png";

  constructor(public UIservice: UIService) {
    super(UIservice);
  }

  ngOnInit() {
  }

}
