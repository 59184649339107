import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Message } from 'src/app/data/models/Manage/message.model';
import { RankingItem } from 'src/app/data/models/Manage/rankingItem.model';
import { ProvideDataService } from 'projects/contestApp/src/app/services/provideDataService';

@Component({
  selector: 'app-general-scores',
  templateUrl: './general-scores.component.html',
  styleUrls: ['./general-scores.component.css']
})
export class GeneralScoresComponent implements OnInit, OnDestroy {

  @Input() awards;
  @Input() currency: string
  @Input() messages;
  @Input() challengeType: number;
  @Input() rankingDataLoadEvent: Observable<RankingItem[]>;
  // @Input() messagesDataLoadEvent: Observable<Message[]>

  rankingDataLoading: boolean
  messageDataLoading: boolean

  rankingSubs: Subscription;
  messageSubs: Subscription;

  constructor(private dataService: ProvideDataService) {
    this.rankingDataLoading = true;
    // this.messageDataLoading = true;
   }

  ngOnInit() {
    this.rankingSubs = this.rankingDataLoadEvent.subscribe(x => this.rankingDataLoading = false)
    // this.messageSubs = this.messagesDataLoadEvent.subscribe(x => this.messageDataLoading = false)

  }

  ngOnDestroy(){
    this.rankingSubs.unsubscribe()
    // this.messageSubs.unsubscribe()
  }

}
