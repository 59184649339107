
<article  class="full-description-panel" fxFill fxLayout="column"  fxLayoutAlign="start center">

    <div fxLayout="column" fxLayoutAlign="start center">
        
        <h1 class="mat-display-2" i18n="@@6403ad84dd7fad60490a9e74adaa37a8ec1745bc"><strong>Benefits</strong></h1>
  
        
    </div>
  
    <div [ngClass.gt-xs]="'mat-title'" [ngClass.lt-md]="'mobile-font-style'" fxFill style="padding: 5vh 5vw;" fxLayoutGap="5vw" fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="center start" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="start center">
  
    <div class="description-panel" fxFlex fxLayout="column" fxLayoutGap="10vh">
  
        <h2 class="mat-display-1" style="text-align: center;" i18n="@@ForCompetiotrs"><strong>For Competitors</strong></h2>
  
        <div [ngClass.gt-sm]="'description-element'" fxLayout="column" fxLayoutAlign="start center" fxFlexOrder="1">
            <div fxFlex="50">
                <img fxFlexFill src="assets/pictures/search.png" alt="Easy contest searching">
            </div>
           

            <div fxFlex="50" style="width: 90%;">
                <p i18n="@@BenefitsSearching">
                      <strong>Carp Contest</strong> portal <strong>mission</strong> is to <strong>help</strong> competitors, referees and organisers with their sport challenges. 
                      Competitors will be able to easly find right contest for required contry, Region, City or Fishery, also including date range or allowed fishing methods.<br>
                        Just add <strong>Free</strong> announcement</p>
            </div>
        </div>
  
        <div [ngClass.gt-sm]="'description-element'" fxLayout="column" fxLayoutAlign="start center" fxFlexOrder="2">
            <div fxFlex="50">
            <img fxFlexFill src="assets/pictures/zapisy.png" alt="Easy Sign Up">
            </div>
        
            <div fxFlex="50" style="width: 90%;">
                <p i18n="@@BenefitsSignUp">
                      <strong>Sign Up - Reservation</strong> for <strong>Premium</strong> contests allows to make a reservation by team interested in. 
                      The feature <strong>control actual amounts </strong> of confirmed Teams and waiting Reservations according to <strong>maximum allowed Teams.</strong><br>
                      Reservation will become as signed Team once organiser confirms ticket payment</p>
            </div>
        </div>
  
        <div [ngClass.gt-sm]="'description-element'" fxLayout="column" fxLayoutAlign="start center" fxFlexOrder="3">
            <div fxFlex="50">
                <img fxFlexFill src="assets/pictures/WynikiLive.png" alt="Easy Ranking Table">
            </div>
            

            <div fxFlex="50" style="width: 90%;">
                <p i18n="@@BenefitsLiveRanking">
                      <strong>Live Ranking table</strong> for <strong>Premium</strong> ships with <strong>automatically</strong> refreshing scores. 
                      Once noticed fish is weighted, Referee makes a photo and fills catch form on Carp Contest App (directly from his cell phone). 
                      The feature takes <strong>Referee notifications</strong> with catch details and photo then <strong>calculating</strong> actual scores and publishing new <strong>Ranking Table</strong></p>
            </div>
        </div>
  
        <div [ngClass.gt-sm]="'description-element'" fxLayout="column" fxLayoutAlign="start center" fxFlexOrder="4">
            <div fxFlex="50">
                <img fxFlexFill src="assets/pictures/search.png" alt="Easy Details Table">
            </div>
            

            <div fxFlex="50" style="width: 90%;">
                <p i18n="@@BenefitsDetailsRanking">
                      <strong>Live Details table</strong> for <strong>Premium</strong> ships with <strong>analysis and photos</strong> 
                        Live Details table presents <strong>kg's or points differences</strong> between teams, shows <strong>minimum new catch</strong>to beat next team or leader team. 
                        Also, users are able to find ranking <strong>fish photos</strong></p>
            </div>

      </div>

      <div [ngClass.gt-sm]="'description-element'" fxLayout="column" fxLayoutAlign="start center" fxFlexOrder="4">
        <div fxFlex="50">
            <img fxFlexFill src="assets/pictures/liveCharts.png" alt="Easy Charts Table">
        </div>
       

        <div fxFlex="50" style="width: 90%;">
            <p i18n="@@BenefitsCharts">
                  <strong>Live Charts</strong> for <strong>Premium</strong> ships with <strong>total analysis</strong> amoung contest<br>
                    Charts presents:</p>
                    <ul i18n="@@BenefitsChartList">
                    <li>Fish Amount Per Date, Day & Night</li>               
                      <li>Fish Amount Per Weight range</li>
                      <li>Grouped adjacents Swims with Fish</li>
                      <li>Fish Amount per Day Time Spans</li> 
                      <li>Swims Amount Per Amount of Fish</li>
                    </ul>
        </div>

  </div>
  
    </div>
  
    <div class="description-panel" fxFlex fxLayout="column" fxLayoutGap="10vh">
  
        <h2 class="mat-display-1" style="text-align: center;" i18n="@@ForOrganisersAndReferees"><strong>For Organisers and Referees</strong></h2>
  
        <div [ngClass.gt-sm]="'description-element'" fxLayout="column" fxLayoutAlign="start center" fxFlexOrder="1">
            <div fxFlex="50">
                <img fxFlexFill src="assets/pictures/mainPanel.png" alt="Main Organiser Panel">
            </div>
           

            <div fxFlex="50" style="width: 90%;">
                <p i18n="@@BenefitsMainPanel">
                      <strong>Organiser Console</strong> for <strong>Premium</strong> contests allows to <strong>control</strong> contest <strong>workflow</strong>. Possible actions in Main Panel:</p>
                      <ul i18n="@@BenefitsPanelList">
                        <li>Start & Stop Sign Up process</li>               
                          <li>Teams and Reservation manual addition</li>
                          <li>Automatic draws Sequence and Swims</li>
                        </ul>
            </div>
        </div>
  
  
        <div [ngClass.gt-sm]="'description-element'" fxLayout="column" fxLayoutAlign="start center" fxFlexOrder="2">
            <div fxFlex="50">
                <img fxFlexFill src="assets/pictures/contestDetailsPanel.png" alt="Easy Details management">
            </div>
            

            <div fxFlex="50" style="width: 90%;">
                <p i18n="@@BenefitsDetailsPanel">
                      <strong>Contest Panel</strong> allows easy event details changing, like:</p>
                      <ul i18n="@@BenefitsDetailsList">
                        <li>Place & Time</li>               
                          <li>Awards & Links</li>
                          <li>Fee & payment details</li>
                        </ul>
            </div>
        </div>

        <div [ngClass.gt-sm]="'description-element'" fxLayout="column" fxLayoutAlign="start center" fxFlexOrder="3">
            <div fxFlex="50">
            <img fxFlexFill src="assets/pictures/teamsPanel.png" alt="Easy Team & Reservation Management">
            </div>
        
            <div fxFlex="50" style="width: 90%;">
                <p i18n="@@BenefitsTeamsPanel">
                      <strong>Teams & Reservation Panel</strong> for <strong>Premium</strong> contests allows easy management of Teams and Reservation. 
                      Organiser is able to:</p>
                      <ul i18n="@@BenefitsTeamList">
                        <li>Find Team / Reservation anglers, phone number or email adress</li>               
                          <li>Edit / Delete anglers, teams, reservations</li>
                          <li>Confirm Reservation payment</li>
                          <li>Manual swim asssign</li>
                        </ul>
            </div>
        </div>
  
        <div [ngClass.gt-sm]="'description-element'" fxLayout="column" fxLayoutAlign="start center" fxFlexOrder="4">
            <div fxFlex="50">
                <img fxFlexFill src="assets/pictures/sponsorsPanel.png" alt="Easy Sponsors Managment">
            </div>
            

            <div fxFlex="50" style="width: 90%;">
                <p i18n="@@BenefitsSponsorsManagement">
                      <strong>Sponsors Panel</strong> allows easy <strong>management of Partners and Sponsors</strong>.<br> 
                        They will be displayed in the bottom on your Contest Page</p>
            </div>

      </div>

      <div [ngClass.gt-sm]="'description-element'" fxLayout="column" fxLayoutAlign="start center" fxFlexOrder="4">
        <div fxFlex="50">
            <img fxFlexFill src="assets/pictures/facebookPanel.png" alt="Easy Facebook & Instagram post">
        </div>
       

        <div fxFlex="50" style="width: 90%;">
            <p i18n="@@BenefitsFacebookInstagram">
                  <strong>Facebook & Instagram</strong> for <strong>Premium</strong> contest makes generic post on Carp Contest Fanpage.</p>

                    <ul i18n="@@BenefitsSocialsList">
                    <li>(FB&Insta) New Contest announcement</li>               
                      <li>(FB) Day Contest Ranking Update - 12:00 PM</li>
                      <li>(FB) Final Winners Post</li>
                      <li>(Insta) Catch Photos with Contest tags - not available, yet</li> 
                    </ul>
        </div>

  </div>
  
    </div>
  
  </div>
  
  </article>