import { Component, OnInit, Input } from '@angular/core';
import { Challenge } from 'src/app/data/models/Manage/challenge.model';

@Component({
  selector: 'app-contest-details',
  templateUrl: './contest-details.component.html',
  styleUrls: ['./contest-details.component.css']
})
export class ContestDetailsComponent implements OnInit {

  @Input() challenge: Challenge

  sortTopByIsNumber: boolean

  constructor() { }

  ngOnInit() {
  this.sortTopByIsNumber = !isNaN(this.challenge.sortTopBy);

  }

}
