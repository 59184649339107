import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/compat/storage';
import { Injectable} from '@angular/core';
import { Observable, from } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
  })

export class FireStoreService{

    constructor(private storage: AngularFireStorage ){
    }

   uploadFile(item: File, collection: string ){

    const path = collection + item.name
    const task = this.storage.upload(path, item)
    console.log(path)
    console.log(item)
    return task

    }

    getDownloadURl(fileName: string, collection: string){

        return this.storage.storage.ref(collection + fileName).getDownloadURL()
    }



    // isActive(){
    //     return console.log(this.task.task.snapshot.state === 'running' && this.task.task.snapshot.bytesTransferred < this.task.task.snapshot.totalBytes)
    // }

    getURL(itemName: string, folder: string){
    const path = folder + itemName
    const ref = this.storage.ref(path)
        return ref.getDownloadURL()
    }


}
