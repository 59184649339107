import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ContestService } from '../../services/contest.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Observable, Subscription } from 'rxjs';
import {polishRegions, germanRegion, czechRegions, slovakRegions, hungaryRegions} from 'src/app/shared/helpers/countryRegion'

@Component({
  selector: 'app-filter-regions',
  templateUrl: './filter-regions.component.html',
  styleUrls: ['./filter-regions.component.css']
})
export class FilterRegionsComponent implements OnInit, OnDestroy {

  @Input() selectedCountry: string
  @Input() countryChanged: Observable<string>
  @Output() selectedRegions = new EventEmitter<string[]>()

  countrySubscription: Subscription

  country: string
  prevCountry: string
  regions: string[]

  _polishRegions: string[] = polishRegions

  _germanRegion: string[] = germanRegion

  _czechRegions: string[] = czechRegions

  _slovakRegions: string[] = slovakRegions

  _hungaryRegions: string[] = hungaryRegions
  
  constructor() {
   }

  ngOnInit() {
    this.country = this.selectedCountry
    this.prevCountry = this.country
    this.regions = []

    this.countrySubscription = this.countryChanged
      .subscribe(newCountry => {
        this.prevCountry = this.country
        this.country = newCountry
    })
    
    

  }

  filter(event: MatCheckboxChange ,region: string): void {

    this.regions = this.prevCountry === this.country ? this.regions : []
    if(event.checked){
      this.regions.push(region)
    }
    else
    {
      this.regions = this.regions.filter(a => a != region)
    }
    this.selectedRegions.emit(this.regions)
  }

  ngOnDestroy(){
    this.countrySubscription.unsubscribe()
  }
}
