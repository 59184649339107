<div class="content-container" fxLayout="column" fxLayoutAlign="start center" fxLayout.gt-sm="row wrap" fxLayoutAlign.gt-sm="center start" fxLayoutGap="2vw">

    <app-main-data *ngIf="this.challenge && ( this.editMode === 'none' || this.editMode === 'main')" 
                    [challenge]="this.challenge"
                    (edit)="onEdit($event)">
                    
    </app-main-data>  

    <app-awards *ngIf="this.challenge && ( this.editMode === 'none' || this.editMode === 'awards')" 
                [awards]="this.challenge.Awards"
                (edit)="onEdit($event)">
    </app-awards>

    <app-fee    *ngIf="this.challenge && ( this.editMode === 'none' || this.editMode === 'fee')" 
    [fee]="this.challenge.Fee"
    (edit)="onEdit($event)">
    </app-fee>

    <app-organizer  *ngIf="this.challenge && ( this.editMode === 'none' || this.editMode === 'organizer')" 
                    [organizer]="this.challenge.Organizer"
                    (edit)="onEdit($event)">
    </app-organizer>

    <app-links *ngIf="this.challenge && ( this.editMode === 'none' || this.editMode === 'links')" 
                [links]="this.challenge.Links"
                (edit)="onEdit($event)">
    </app-links>

</div>