import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormGroup} from '@angular/forms';
import { UIService } from 'src/app/shared/service/ui.service';
import { BaseLandingComponent } from '../baseLanding.component';
import { AuthService } from 'src/app/auth-module/service/auth.service';
import { ServeUserService } from 'src/app/auth-module/service/serveUser.service';
import { credential } from 'firebase-admin';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent extends BaseLandingComponent implements OnInit {
  changeActions: boolean = false
  changePassword: boolean = false
  verificationEmail: boolean = false
  userEmailVerificated: boolean = true

  error: string = null

  form: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('',[Validators.required, Validators.minLength(6)])
  })

  email = new FormControl('', [Validators.required, Validators.email])

  constructor(public UIservice: UIService, private serveUserService: ServeUserService, private authService: AuthService) {
    super(UIservice);
    

  }

  ngOnInit() {
  }

  getErrorPasswordValidator(){
    return this.form.controls.password.hasError('required') ? 'You must enter a password' :
    this.form.controls.password.hasError('minlength') ? 'Password is too short': '';
  }

  getErrorEmailValidator() {
    return this.form.controls.email.hasError('required') ? 'You must enter a value' :
            this.form.controls.email.hasError('email') ? 'Not a valid email' : '';
  }

  getErrorRestoreEmailValidator(){
    return this.email.hasError('required') ? 'You must enter a value' :
            this.email.hasError('email') ? 'Not a valid email' : '';
  }

  onForgottenPassword(){
    this.changeActions = !this.changeActions
    this.changePassword = !this.changePassword
    this.error = null
  }

  onNewVerificationEmail(){
    this.verificationEmail = !this.verificationEmail
    this.changeActions = !this.changeActions
    this.error = null
  }

  onSubmitEnterForm(event){
    if(event.keyCode === 13){
      this.onSubmit()
    }
  }

  onSubmit(){
    this.error = null

    this.authService.signinUser(this.form.controls.email.value, this.form.controls.password.value)
        .then((credentials) => {
          this.userEmailVerificated = credentials.user.emailVerified
          if(credentials.user.emailVerified)
          {
            this.serveUserService.navigateUser()
          }
          else
          {
            this.error = "Account not verified. Please check your email box and verify account."
          }
        })
        .catch((e) => {this.error = e.message})
    
  }

  onRestoreSubmit(){
    this.error = null

      if (this.email.value)
      {
        this.authService.sendUserPasswordResetEmail(this.email.value)
        .then(() => { this.error = "Email with link to " + this.email.value + " has been sent"})
        .catch((e) => {this.error = e.message})
      }
    }

    onVerificationEmailSubmit(){
      this.error = null

      if (this.email.value)
      {
        this.authService.sendUserVerificationEmail(this.email.value)
        .then(() => { this.error = "Email with link to " + this.email.value + " has been sent"})
        .catch((e) => {this.error = e.message})
      }
    }

}
