import { Component, OnInit, Output, EventEmitter} from '@angular/core';

import { AuthService } from 'src/app/auth-module/service/auth.service';
import { ServeUserService } from 'src/app/auth-module/service/serveUser.service';


@Component({
  selector: 'app-sidebar-manage-referees-links',
  template: `
  <mat-expansion-panel>
                
  <mat-expansion-panel-header>
      
      <mat-panel-title style="color:rgb(255,255,255, 0.8);">
        <mat-icon style="padding: 0 2vw 0 0;">people</mat-icon>
          <span i18n="@@31b84e7a88c0cf802c4e93149f36b7f579d18755">Referees</span>
      </mat-panel-title>
  
  </mat-expansion-panel-header>

  <a *ngIf="this.serveUser.isAdmin || this.serveUser.isOrganiser"
      mat-list-item
      role="navigation" 
      routerLink="/organiser/referees-list"
      (click)="onClose()"
  >
      
      <mat-icon class="expansion-panel-item-icon">supervisor_account</mat-icon>
      <span class="expansion-panel-item-caption" i18n="RefereesList|Referees List in header@@">Referees List</span> 
  </a>

  <a *ngIf="this.serveUser.isAdmin || this.serveUser.isOrganiser"
      mat-list-item
      role="navigation"  
      routerLink="organiser/manage-referee"
      (click)="onClose()"
  >

      <mat-icon class="expansion-panel-item-icon">build</mat-icon>
      <span class="expansion-panel-item-caption" i18n="ManageReferee|Manage Referee in header@@">Manage Referee</span>
  </a>

  <a *ngIf="this.serveUser.isAdmin || this.serveUser.isOrganiser"
      mat-list-item
      role="navigation"  
      routerLink="organiser/invite-referee"
      (click)="onClose()"
  >

      <mat-icon class="expansion-panel-item-icon">accessibility_new</mat-icon>
      <span class="expansion-panel-item-caption" i18n="InviteReferees|Invite Referees in header@@">Invite Referees</span>
  </a>
  
</mat-expansion-panel> `,
    styleUrls: ['./sidenav-list.component.css']
})
export class SideBarManageRefereesLinksComponent implements OnInit {
 
@Output() closeSidenav = new EventEmitter<void>()

  constructor(public authService: AuthService, public serveUser: ServeUserService) { 
  }

  ngOnInit() {
  }

  onClose(){
      this.closeSidenav.emit()
  }


}