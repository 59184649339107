import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subscription, Observable } from 'rxjs';

@Component({
  selector: 'app-number-cards',
  templateUrl: './number-cards.component.html',
  styleUrls: ['./number-cards.component.css']
})
export class NumberCardsComponent implements OnInit, OnDestroy {

  @Input() chartData$: Observable<any[]>;
  @Input() chartData: any[]
  
  dataSubs: Subscription

  single: any[];
  view: any[];


  colorScheme = {
    domain: ['#F9A825']
  };
  cardColor: string = '#232837';
  
  constructor() { }

  ngOnInit() {
    this.single = this.chartData
    this.dataSubs = this.chartData$.subscribe(data => {
      this.single = data
    })
  }
  ngOnDestroy(){
    this.dataSubs.unsubscribe()
  }

}
