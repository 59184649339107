import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Partner } from 'src/app/data/models/Manage/partner.model';


export interface PartnerData{
  partner?: Partner,
  modeEdit?: boolean,
  modeAdd?: boolean
}

@Component({
  selector: 'app-partner-dialog-component',
  templateUrl: './partner-dialog-component.component.html',
  styleUrls: ['./partner-dialog-component.component.css']
})
export class PartnerDialogComponent implements OnInit {

  partner?: Partner
  modeAdd?: boolean = false
  modeEdit?: boolean = false  

  constructor(@Inject(MAT_DIALOG_DATA) public data: PartnerData) { }

  ngOnInit() {
    this.data.partner ? this.partner = this.data.partner : null
    this.data.modeAdd ? this.modeAdd = this.data.modeAdd : null
    this.data.modeEdit ? this.modeEdit = this.data.modeEdit : null
  }

}
