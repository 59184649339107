<div *ngIf="!(this.isLoading$ | async)" fxLayout="column" fxLayoutAlign="start center">

 <h1 class="mat-display-1" style="margin: 0 0 32px;">Podium</h1>

<div *ngIf="this.winnerTeams.length > 0 && awards && !(this.isLoading$ | async) ;else noData" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="2vh">

    <div fxFlex="100" fxLayout="column" *ngIf="awards.First">

        <div fxFlex="100" fxLayoutAlign="center center" >
                <label class="mat-h2" title="Contest First Place" i18n="Live General Scores First Place | Label for First Place in Live@@">1st Place:</label>          
        </div>
        
        <div fxFlex="100" fxLayoutAlign="center center" >
                
                <app-awards-details fxLayout="row"  [awardsDetails]="this.awards.First" [currency]="this.currency"></app-awards-details>
         
        </div>

        <div *ngIf="this.winnerTeams.length > 0" style="padding-top: 1vh;" fxFlex="100" fxLayoutAlign="center center" >
            <label class="mat-h3" title="First Place Anglers">{{this.winnerTeams[0].anglers[0].name}}&nbsp;{{this.winnerTeams[0].anglers[0].surname}}&nbsp;&&nbsp;{{this.winnerTeams[0].anglers[1].name}}&nbsp;{{this.winnerTeams[0].anglers[1].surname}}</label>          
        </div>
        <mat-divider></mat-divider>
    </div>
 
    <div fxFlex="100" fxLayout="column" *ngIf="awards.Second">

            <div fxFlex="100" fxLayoutAlign="center center" >
                   <label class="mat-h2" title="Contest Second Place" i18n="Live General Scores Second Place | Label for Second Place in Live@@">2nd Place:</label>          
            </div>
            
            <div fxFlex="100" fxLayoutAlign="center center" >
                
                <app-awards-details fxLayout="row"  [awardsDetails]="this.awards.Second" [currency]="this.currency"></app-awards-details>    

            </div>
    
            <div *ngIf="this.winnerTeams.length >= 2" style="padding-top: 1vh;" fxFlex="100" fxLayoutAlign="center center" >
                    <label class="mat-h3" title="Second Place Anglers">{{this.winnerTeams[1].anglers[0].name}}&nbsp;{{this.winnerTeams[1].anglers[0].surname}}&nbsp;&&nbsp;{{this.winnerTeams[1].anglers[1].name}}&nbsp;{{this.winnerTeams[1].anglers[1].surname}}</label>          
            </div>
            <mat-divider></mat-divider>
    </div>    
                     
    <div fxFlex="100" fxLayout="column" *ngIf="awards.Third">

            <div fxFlex="100" fxLayoutAlign="center center" >
                    <label class="mat-h2" title="Contest Third Place" i18n="Live General Scores Third Place | Label for Third Place in Live@@">3rd Place:</label>         
            </div>
            
            <div fxFlex="100" fxLayoutAlign="center center" >
                <app-awards-details fxLayout="row"  [awardsDetails]="this.awards.Third" [currency]="this.currency"></app-awards-details>
            </div>
    
            <div *ngIf="this.winnerTeams.length > 2" style="padding-top: 1vh;" fxFlex="100" fxLayoutAlign="center center" >
                    <label class="mat-h3" title="Third Place Anglers">{{this.winnerTeams[2].anglers[0].name}}&nbsp;{{this.winnerTeams[2].anglers[0].surname}}&nbsp;&&nbsp;{{this.winnerTeams[2].anglers[1].name}}&nbsp;{{this.winnerTeams[2].anglers[1].surname}}</label>          
            </div>
            <mat-divider></mat-divider>
    </div>
                
    <div fxFlex="100" fxLayout="column" *ngIf="awards.BigFish">

            <div fxFlex="100" fxLayoutAlign="center center" >
                    <label class="mat-h2" title="Contest Big Fish Place" i18n="Live General Scores Big Fish | Label for Big Fish in Live@@">Big Fish:</label>         
            </div>
            
            <div fxFlex="100" fxLayoutAlign="center center" >
                <app-awards-details fxLayout="row"  [awardsDetails]="this.awards.BigFish" [currency]="this.currency"></app-awards-details>         
            </div>
    
            <div *ngIf="this.bigFish" style="padding-top: 1vh;" fxFlex="100" fxLayoutAlign="center center" >
                <label class="mat-h3" title="Big Fish Angler">{{this.bigFish.anglerName}}&nbsp;{{this.bigFish.anglerSurname}}&nbsp;-&nbsp;{{this.bigFish.weight}}&nbsp;kg</label>                   
            </div>
            <mat-divider></mat-divider>
    </div>
</div>

<ng-template #noData>
        <div style="padding: 5vh;" fxFlex fxLayoutAlign="center center">

                <p class="mat-h2" i18n="@@NoFishingData">Uppss... No fish catches, yet.</p>
            </div>
</ng-template>

</div>