<section fxFlex="100" fxLayoutAlign="center start" class="content-container">
    <div fxLayout="column" fxFlexFill fxLayoutAlign.xs="start center" fxLayoutAlign="start center" fxLayoutGap="2vh">
    
        <label *ngIf="this.teamID !== 'new'" style="width: 100%; text-align: center;" class="mat-h1" i18n="SingUp Team Label| Header for Team sign up@@">Edit Team</label>


        <form *ngIf="this.team" fxLayout="column" fxLayoutAlign="start center" #f="ngForm">
                   
                <label class="mat-h3" i18n="SingUp Team Label| Header for Team form sign up@@">Team Details</label>

                <mat-form-field>
                    <mat-label i18n="SingUp Team PromoName| label for Team Promo Name form sign up@@">Promo Team name</mat-label>
                    <input  matInput
                        name="PromoTeam"
                        [(ngModel)]="team.promoTeam"
                     >
                    </mat-form-field>
    
                <mat-form-field>
                    <mat-label i18n="SingUp Team email| label for Team email form sign up@@">Leader email</mat-label>
                    <input matInput                         
                            name="LeaderEmail"
                            [(ngModel)]="team.privateData.leaderEmail"
                            (ngModelChange)="emitValidator(f.valid)"
                            required
                    >
                </mat-form-field>

                <mat-form-field>
                    <mat-label i18n="SingUp Team Phone| label for Team phone form sign up@@">Leader Telephone</mat-label>
                    <input matInput
                            [(ngModel)]="team.privateData.leaderPhone"
                            type="tel"
                            name="Team leaderPhone"
                            (ngModelChange)="emitValidator(f.valid)"
                            required
                    >
                </mat-form-field>

                <div fxFlex fxLayoutGap="2vh">
                    <button *ngIf="this.teamID !== 'new'"
                        mat-raised-button
                        color="warn"
                        i18n="cancel| cancel button@@"
                        (click)="onCancel()">Cancel</button>

                    <button *ngIf="!this.team.anglers[2] && !this.addTeamRunner" 
                        mat-stroked-button
                        color="primary"
                        i18n="SingUp AddTeamRunnerButton| Button for Runner additon in form sign up@@"
                        (click)="onNewTeamRunner()">Add Team Runner</button>
                
                    <button *ngIf="f.valid && this.teamID !== 'new'"
                        mat-raised-button
                        color="primary"
                        i18n="Edit Team UpdateTeam| Button for update Team additon in edit form@@"
                        (click)="onUpdateTeam()">Update Team</button>
                </div>
            

          
                  
        </form>
        
        <ng-template [ngIf]="addTeamRunner && !isNew">
            <app-edit-angler [editAngler]="this.teamRunner" (exit)="onRunnerExit()" (onUpdate)="onAnglerEditUpdate($event)"></app-edit-angler>
        </ng-template>
        
    </div>


</section>
