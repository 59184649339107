import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { throwToolbarMixedModesError } from '@angular/material/toolbar';
import { Subscription, Observable } from 'rxjs';
import { Team } from 'src/app/data/models/Manage/team.model';
import { UIService } from 'src/app/shared/service/ui.service';
import { ProvideDataService } from '../../../services/provideDataService';
import { BaseComponent } from '../base.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-draw-stations',
  templateUrl: './draw-stations.component.html',
  styleUrls: ['./draw-stations.component.css'],
  providers: []
})
export class DrawStationsComponent extends BaseComponent implements OnInit, OnDestroy {

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  
  private keySubscription: Subscription;
  private signedTeamsSubscription: Subscription
 
  teams: Team[]


  cardData: Observable<Team[]>
  pageEvent: PageEvent;


  displayedColumns= ['station','drawSequence', 'Angler1', 'Angler2'];
  dataSource = new MatTableDataSource<Team>()
  
  constructor(private route: ActivatedRoute,public UIservice: UIService, private dataService: ProvideDataService) {
    super(route,dataService, UIservice);

    }

  ngOnInit() {
    this.keySubscription = this.dataService.keyReady.subscribe(bool => {
      if(bool)
      {
      this.signedTeamsSubscription = this.dataService.getSignedTeams()
        .subscribe(signedTeams => {
          let teams = signedTeams
          this.teams = teams.sort((a,b) => a.drawSequence - b.drawSequence)
          this.dataSource.data = teams
          this.dataSource.paginator = this.paginator;
          this.cardData = this.dataSource.connect()
  })}
  this.dataSource.filterPredicate = (data, filter) => { 
    return JSON.stringify(data).toLowerCase().indexOf(filter) != -1; 
  }

})
    
  }

  applyFilter(filterValue: string) {

    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


  ngOnDestroy(): void {
    if(this.keySubscription)
    {
      this.keySubscription.unsubscribe()
    }
    if(this.dataSubscription){
      this.dataSubscription.unsubscribe()
    }
    
  }

}
