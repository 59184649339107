
<div style="margin: 2vh;" fxFlex="80" fxFlex.lt-lg="100" fxLayout="column" >
    <div class="mat-h2" style="font-weight: bold; text-align: center; width: 100%;" i18n="totals| totals in ranking@@">Totals:</div>
    <div style="margin-left: 2vh;"
        fxFlexFill.gt-md
        fxLayout.lt-md="column" 
        fxLayoutGap="2vh" 
        fxLayoutAlign.lt-sm="start start" 
        fxLayoutAlign="center center" 
        class="mat-h3">
        
    <div >
        <label i18n="Teams in Classification| Teams in Classification in ranking@@">Teams in Classification:</label>
        &nbsp;
        <label>{{this.teamsInRanking}}</label>
    </div >

    <div>
        <label i18n="Fish in Classification| Fish in Classification in ranking@@">Fish in Classification:</label>
        &nbsp;
        <label>{{this.fishInRanking}}</label>
    </div>

    <div>
        <label i18n="Total kg's in Classification| Total kg's in Classification in ranking@@">Total kg's in Classification:</label>
        &nbsp;
        <label>{{this.kgInRanking}}</label>
    </div>

    
    </div>

<div class="mat-h2" style="font-weight: bold; text-align: center; width: 100%;" i18n="Ranking Information| Ranking Information header@@">Ranking Information:</div>

<div [hidden]="this.ranking.length === 0" fxLayout="column" fxLayout.lt-md="column">
   <div fxLayout="row" fxLayout.lt-md="column">
    
    <div fxFlex="50" fxFlex.xs fxFlexOrder.lt-md="1">
        
        <mat-form-field class="mat-title" fxLayoutAlign="start center" fxLayoutAlign.lt-md="center center">
            <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
        </mat-form-field>

    </div>

    <div fxFlex="50" fxFlex.xs fxFlexOrder.lt-md="2">

        <mat-paginator [length]="dataSourceLength"
                        [pageSize]="5"
                        [pageSizeOptions]="[5 , 10]"
                        showFirstLastButtons
                        (page)="pageEvent = $event">
        </mat-paginator>
        
    </div>

   </div>

<div *ngIf="this.ranking.length > 0; else noRankingData" >
<div  *ngIf="UIservice.isMobileSM; else tableTemplate" >
    <div fxFlexFill fxLayout="column" fxLayoutGap="1vh" >
        <mat-accordion  *ngFor="let element of this.cardData | async">
            <mat-expansion-panel>
              <mat-expansion-panel-header fxLayout="row" [ngClass]="element.Stats.displayClass">  
                <mat-panel-title fxFlex="60" fxLayoutGap="2vh">
                 <div fxFlex="50">
                 <label i18n="@@PlaceDevice">Place</label>&nbsp;
                    {{element.place}}
                </div>

                <div fxFlex="40">   
                <label i18n="@@PegDevice">Peg:&nbsp;</label>
                    {{element.topFish[0].station}}
                </div>
                </mat-panel-title>
                <mat-panel-description fxFlex="30">
 
                    {{element.classificationScore}}&nbsp;kg
  
                </mat-panel-description>
              </mat-expansion-panel-header>
 
              <div fxLayout="column" fxLayoutAlign="start center">

                <div fxLayout="row" fxFlexFill fxLayoutAlign="center start" style="padding-bottom: 2.5vh;">

                    <div fxFlex="50"><label style="font-weight: bold;">{{element.anglers[0].name}} &nbsp; {{element.anglers[0].surname}}</label></div>
                    <div fxFlex="50"><label style="font-weight: bold;">{{element.anglers[1].name}} &nbsp; {{element.anglers[1].surname}}</label></div>
                    
                </div>

            <h3 class="mat-h3" style="font-weight: bold;" >Ranking:&nbsp;{{element.place}}&nbsp;<img [ngStyle]="{'width': '2.5vh;'}" [attr.src]="element.Stats.progressImage"></h3>

            <h3 class="mat-h3" fxLayout="row" fxFlexFill fxLayoutAlign="center start" style="padding-bottom: 2vh;">

                <div fxFlex="50"><label style="font-weight: bold;" i18n="@@d494be449864c2a49e7eedf6895ae54e5727ac2b">Peg No.</label><label>:&nbsp;{{element.topFish[0].station}}</label></div>
                <div fxFlex="50"><label style="font-weight: bold;" i18n="@@Score">Score</label><label>:&nbsp;{{element.classificationScore}}&nbsp;kg</label></div>
                
            </h3>

              <div *ngIf="element.place != 1" fxLayout="row" fxFlexFill >

                <div fxLayout="column" fxFlex="50" fxLayoutAlign="start center">
                
                <div style="text-align: center;">
                    <div>
                        <label style="font-weight: bold;" i18n="@@ToNextPlace">To Next Place:</label>
                    </div>
                    <div>
                        {{element.Stats.kilosToNext}}&nbsp;kg
                    </div>
                </div>

                <div style="margin-top: 1.5vh; text-align: center;">
                    <div>
                        <label style="font-weight: bold;" i18n="@@ToFirstPlace">To First Place:</label>
                    </div>
                    <div>
                        {{element.Stats.kilosToFirst}}&nbsp;kg
                    </div>
                </div>
                </div>

                <div fxLayout="column" fxFlex="50" fxLayoutAlign="start center">
            
                    <div style="text-align: center;">
                        <div>
                            <label style="font-weight: bold;" i18n="@@FishToNextPlace">Fish To Next</label>
                        </div>
                        <div>
                            <img [ngStyle]="{'width': '2.5vh;'}" src="https://firebasestorage.googleapis.com/v0/b/carp-contest-app.appspot.com/o/2029698.png?alt=media&token=3c0814d6-8889-418c-8161-57eeae488b71">
                            &nbsp;
                            <label>{{element.Stats.fishToNext}}&nbsp;kg</label>
                        </div>
                    </div>

                    <div style="margin-top: 1.5vh; text-align: center;">
                        <div>
                            <label style="font-weight: bold;" i18n="@@FishToFirstPlace">Fish To First Place:</label>
                        </div>
                        <div>
                            <img [ngStyle]="{'width': '2.5vh;'}" src="https://firebasestorage.googleapis.com/v0/b/carp-contest-app.appspot.com/o/2029698.png?alt=media&token=3c0814d6-8889-418c-8161-57eeae488b71">
                            &nbsp;
                            <label>{{element.Stats.fishToFirst}}&nbsp;kg</label>
                        </div>
                    </div>

                </div>     

                </div>

                <div class="mat-h4" style="font-weight: bold; padding-top: 2.5vh;" i18n="@@TopFish">Top Fish:&nbsp;</div>
              
              <div fxLayout="column" fxLayoutGap="1.5vh" fxLayoutAlign="center start">
                <div *ngFor="let fish of element.topFish" fxLayoutAlign="center center">
                    <mat-icon class="clickableIcon" (click)="openFishPhoto(fish.photoUrl)">photo_camera</mat-icon>
                    &nbsp;
                    {{fish.fishType}}
                    &nbsp;
                    <label>{{fish.weight}}&nbsp;kg</label> 
                </div>
              </div>

                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>

<ng-template #tableTemplate>

    <div [hidden]="!(this.dataSource.data.length > 0)" fxFlex="100">
        <div fxFlexFill class="mat-elevation-z8"  fxLayout="column">
            <table mat-table fxFlex="100" [dataSource]="dataSource">
                
                <ng-container matColumnDef="position">
                    <th fxFlex="5" class="mat-body-strong" fxLayoutAlign="center center" mat-header-cell *matHeaderCellDef i18n="@@PlaceDevice">Place</th>
                    <td [ngClass]="element.Stats.displayClass"
                        fxFlex="5" 
                        fxLayoutAlign="center center" 
                        mat-cell 
                        *matCellDef="let element">
                        {{element.place}}
                    </td>
                </ng-container>
        
                <ng-container matColumnDef="pegNo">
                        <th fxFlex="5" class="mat-body-strong" fxLayoutAlign="center center" mat-header-cell *matHeaderCellDef i18n="@@PegDevice">Peg:&nbsp;</th>
                        <td [ngClass]="element.Stats.displayClass"
                            fxFlex="5" 
                            fxLayoutAlign="center center" 
                            mat-cell *matCellDef="let element">
                            {{element.topFish[0].station}}
                        </td>
                </ng-container>
        
                <ng-container matColumnDef="lastChange">
                    <th fxFlex="5" fxHide.lt-lg class="mat-body-strong" fxLayoutAlign="center center" mat-header-cell *matHeaderCellDef i18n="@@81aad5532604a48b479329858c955303e7b79950">Change </th>
                    <td [ngClass]="element.Stats.displayClass"
                        fxHide.lt-lg
                        fxFlex="5"
                        fxLayoutAlign="center center" 
                        mat-cell 
                        *matCellDef="let element">
                        <img [ngStyle]="{'width': '2.5vh;'}" [attr.src]="element.Stats.progressImage">
                    </td>
                </ng-container>
        
                <ng-container matColumnDef="TopFish">
                        <th fxFlex class="mat-body-strong" fxLayoutAlign="center center" mat-header-cell *matHeaderCellDef i18n="@@TopFish">Top Fish:&nbsp;</th>
                        <td [ngClass]="element.Stats.displayClass"
                            fxFlex 
                            fxLayoutAlign="start center" 
                            fxLayoutGap="1vh"
                            mat-cell 
                            *matCellDef="let element">
                        
                           
                        <div fxFlex fxLayoutAlign="center center" *ngFor="let fish of element.topFish">
                            <mat-icon class="clickableIcon" (click)="openFishPhoto(fish.photoUrl)">photo_camera</mat-icon>
                            <!-- <img [ngStyle]="{'width': '2.5vh;'}" [attr.src]="fishIcon" > -->
                            &nbsp;
                            <label>{{fish.weight}}&nbsp;kg</label> 
                       
                    </div>
                        </td>
                </ng-container>
        
                <ng-container matColumnDef="Score" >
                        <th fxFlex="10" fxHide.lt-lg class="mat-body-strong" fxLayoutAlign="center center" mat-header-cell *matHeaderCellDef i18n="@@Score">Score </th>
                        <td [ngClass]="element.Stats.displayClass"
                            fxHide.lt-lg
                            fxFlex="10" 
                            fxLayoutAlign="center center" 
                            mat-cell 
                            *matCellDef="let element">
                            {{element.classificationScore}}&nbsp;kg
                        </td>
                </ng-container>
        
                <ng-container matColumnDef="kgToNext" >
                    <th fxFlex="10" class="mat-body-strong" fxLayoutAlign="center center" mat-header-cell *matHeaderCellDef i18n="@@kgToNextPlace">kg To Next</th>
                    <td 
                        [ngClass]="element.Stats.displayClass"
                        fxFlex="10" 
                        fxLayoutAlign="center center" 
                        mat-cell 
                        *matCellDef="let element">
                        {{element.Stats.kilosToNext}}&nbsp;kg
                    </td>
                </ng-container>
        
                <ng-container matColumnDef="kgToFirst">
                    <th fxFlex="10" class="mat-body-strong" fxLayoutAlign="center center" mat-header-cell *matHeaderCellDef i18n="@@kgToFirst">kg To First</th>
                    <td 
                        [ngClass]="element.Stats.displayClass"
                        fxFlex="10" 
                        fxLayoutAlign="center center" 
                        mat-cell 
                        *matCellDef="let element">
                        {{element.Stats.kilosToFirst}}&nbsp;kg
                    </td>
                </ng-container>
        
                <ng-container matColumnDef="fishToNext">
                    <th fxFlex="10" class="mat-body-strong" fxLayoutAlign="center center" mat-header-cell *matHeaderCellDef i18n="@@FishToNextPlace">Fish To Next</th>
                    <td 
                        [ngClass]="element.Stats.displayClass"
                        fxFlex="10" 
                        fxLayoutAlign="center center" 
                        mat-cell 
                        *matCellDef="let element">
                        <img [ngStyle]="{'width': '2.5vh;'}" src="https://firebasestorage.googleapis.com/v0/b/carp-contest-app.appspot.com/o/2029698.png?alt=media&token=3c0814d6-8889-418c-8161-57eeae488b71">
                        &nbsp;
                        <label>{{element.Stats.fishToNext}}&nbsp;kg</label>
        
                    </td>
                </ng-container>
        
                <ng-container matColumnDef="fishToFirst">
                    <th fxFlex="10" fxFlex.lt-lg="13" class="mat-body-strong" fxLayoutAlign="end center" mat-header-cell *matHeaderCellDef i18n="@@FishToFirst">Fish To First</th>
                    <td fxFlex.lt-lg="13"
                        [ngClass]="element.Stats.displayClass"
                        fxFlex="10" 
                        fxLayoutAlign="end center" 
                        mat-cell 
                        *matCellDef="let element">
                        
                        <img [ngStyle]="{'width': '2.5vh;'}" src="https://firebasestorage.googleapis.com/v0/b/carp-contest-app.appspot.com/o/2029698.png?alt=media&token=3c0814d6-8889-418c-8161-57eeae488b71">
                            &nbsp;
                        <label>{{element.Stats.fishToFirst}}&nbsp;kg</label>
                        
                    </td>
                </ng-container>
        
        
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
        
        </div>

</ng-template>
</div>

    <ng-template #noRankingData>
        <div style="padding: 5vh;" fxFlex fxLayoutAlign="center center">
    
                <p class="mat-h2" i18n="@@NoFishingData">Uppss... No fish catches, yet.</p>
            </div>
    </ng-template>

</div>


</div>



