import { Component, OnInit, Input } from '@angular/core';
import { BaseOrganiserComponent } from '../../../baseOrganiser.component';
import { UIService } from 'src/app/shared/service/ui.service';
import { Challenge } from 'src/app/data/models/Manage/challenge.model';

@Component({
  selector: 'app-create-edit-challenge',
  templateUrl: './create-edit-challenge.component.html',
  styleUrls: ['./create-edit-challenge.component.css']
})
export class CreateEditChallengeComponent extends BaseOrganiserComponent implements OnInit {

  @Input() challengeToEdit?: Challenge
  @Input() editMode?: boolean = false

  challenge: Challenge
  constructor(private UIservice: UIService) {
    super(UIservice);
  }

  ngOnInit() {

    if (this.editMode && this.challengeToEdit)
    {
      this.challenge = this.challengeToEdit
    }
    else
    {
      this.challenge = new Challenge()
    }
  }

}
