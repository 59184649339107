import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '../service/auth.service';
import { ServeUserService } from '../service/serveUser.service';
import { map } from 'rxjs/operators';
import { UIService } from 'src/app/shared/service/ui.service';
import * as fromRoot from 'src/app/presentation/app.reducer'
import * as App from 'src/app/shared/service/app.actions'
import * as UI from 'src/app/shared/service/ui.actions'
import { Store } from '@ngrx/store'

@Injectable()
export class OrganiserGuard implements CanActivate {
    
    constructor(private authService: AuthService,
                private serveUserService: ServeUserService,
                private store: Store<fromRoot.State>,
                private router: Router,
                private uiService: UIService){

                }


   canActivate(route: ActivatedRouteSnapshot, 
                router: RouterStateSnapshot
   ): boolean | Promise<boolean> | Observable<boolean> {
    
    return this.authService.user.pipe(map(user => {
            const isAuth = !!user
            const isAdmin = this.serveUserService.isAdmin
            const isNewOrganiser = this.serveUserService.isNewOrganiser
            const isOrganiser = this.serveUserService.isOrganiser

            if (isAuth && (isAdmin || isOrganiser || isNewOrganiser)){
                this.store.dispatch(new UI.StopLoadingChild)
                this.store.dispatch(new UI.StopLoadingMain)
                this.store.dispatch(new App.LoadOrganiserState)                
                return true
            }
            else
            {
                this.store.dispatch(new App.LoadLandingState)
                this.router.navigate(['/main/notAllowed'])
            }
            }))


   }
}