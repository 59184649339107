import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { BaseOrganiserComponent } from '../../baseOrganiser.component';
import { UIService } from 'src/app/shared/service/ui.service';
import { ServeUserService } from 'src/app/auth-module/service/serveUser.service';
import { ContestDataService } from '../../services/contest-data.service';
import { Router } from '@angular/router';
import { Contest } from 'src/app/data/models/Manage/contest.model';
import { Observable } from 'rxjs';
import { ManageContestDataService } from '../../services/manage-contest-data';

@Component({
  selector: 'app-welcome-choose-contest',
  templateUrl: './welcome-choose-contest.component.html',
  styleUrls: ['./welcome-choose-contest.component.css']
})
export class WelcomeChooseContestComponent extends BaseOrganiserComponent implements OnInit {

  contestIndex: number = null
  contestList$: Observable<Contest[]>

  constructor(public UIservice: UIService, 
              private serveUser: ServeUserService, 
              private contestDataService: ContestDataService,
              private manageContest: ManageContestDataService,
              private router: Router) {
    super(UIservice);
    
    this.contestList$ = this.contestDataService.getOrganiserContestsList()
  }

  ngOnInit() {
   
  }

  onSubmit(){
    let choosenContestKey: string;
    
    if (this.contestIndex != null)
    {
      choosenContestKey = this.serveUser.user.roles.organiser[this.contestIndex].key
      this.contestDataService.setContest(choosenContestKey)
      this.manageContest.challengeKey = choosenContestKey
      this.router.navigate(['organiser/contest', choosenContestKey])
    }
   
}
}