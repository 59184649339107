import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  header: string,
  content: string,
  resultIcon: string
}

@Component({
  selector: 'app-any-component-dialog',
  templateUrl: './any-component-dialog.component.html',
  styleUrls: ['./any-component-dialog.component.css']
})
export class AnyComponentDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit() {
  }

}
