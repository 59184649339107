<section fxFlex="100" fxLayoutAlign="center start" style="margin:2vh">
    <div fxLayout.xs="column" fxLayout="row wrap" fxFlexFill fxLayoutAlign.xs="start center" fxLayoutAlign="center start" style="margin: 2vh; padding-top: 2vh;" fxLayoutGap="2vh">
    
        <label style="width: 100%; text-align: center;" class="mat-h1" i18n="@@be1989cd4e117d9200cc7e8c6cda5a19caaad20a">Referees</label>

        <div *ngIf="refereesList.length > 0;else noReferee" style="padding-bottom: 3vh;" fxFlexFill fxLayoutAlign="center center" fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutGap="3vh">
            <a class="action-button" *ngFor="let item of refereesList" fxLayout="column" fxLayoutAlign="center center" [attr.href]="'tel:' + item.phone">

                <h1 class="mat-h1">{{item.name}}</h1>
                
                <i class="material-icons md-48">call</i>

                <p style="padding:1vh 0 1vh 0"><span i18n="@@b325714f6c8989a691cf5f21fef625d2ad58d571">Phone:</span>&nbsp;{{item.phone}}</p>
                <p><span i18n="@@Pegs">Pegs</span>:&nbsp;{{item.stations}}</p>
            </a>

        </div>

        <ng-template #noReferee>
            <div fxFlexFill fxLayoutAlign="center center">
                <h1 i18n="@@refereesNotAssigned">Referees not assigned, yet.</h1>
            </div>
        </ng-template>

        
    </div>
</section>