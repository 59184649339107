
import * as fromUi from '../shared/service/ui.reducer'
import * as fromApp from '../shared/service/app.reducer'
import {ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store'
import { from } from 'rxjs'

export interface State {
    ui: fromUi.State;
    app: fromApp.State
}

export const reducers: ActionReducerMap<State> = {
    ui: fromUi.uiReducer,
    app: fromApp.appReducer
}

export const getUiState = createFeatureSelector<fromUi.State>('ui');
export const getIsLoadingMain = createSelector(getUiState, fromUi.getIsLoadingMain);
export const getIsLoadingChild = createSelector(getUiState, fromUi.getIsLoadingChild);

export const getAppState = createFeatureSelector<fromApp.State>('app');
export const getAppStateValue = createSelector(getAppState, fromApp.getAppName);
export const getIsReferee = createSelector(getAppState, fromApp.getIsReferee);
export const getIsAdmin = createSelector(getAppState, fromApp.getIsAdmin);
export const getIsLanding = createSelector(getAppState, fromApp.getIsLanding);
export const getIsContest = createSelector(getAppState, fromApp.getIsContest);
export const getIsOrganiser = createSelector(getAppState, fromApp.getIsOrganiser);
