import { Component, OnInit } from '@angular/core';
import { UIService } from 'src/app/shared/service/ui.service';
import { BaseLandingComponent } from '../baseLanding.component';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.css']
})
export class InfoComponent extends BaseLandingComponent implements OnInit {

  constructor(public UIservice: UIService) {
    super(UIservice);
  }

  ngOnInit() {
  }

}
