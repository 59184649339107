<div class="Landing-Background" fxFlex="100" style="min-height: 100%;" fxLayoutAlign="center center"  fxLayout="column">

            <mat-tab-group fxFlexFill [backgroundColor]="colorBaseOnType" color="warn" mat-align-tabs="center">
                            
                <mat-tab *ngIf="this.isPremium" fxFlexFill>
            
                    <ng-template mat-tab-label>
                            <span i18n="@@Actions">Actions</span>
                    </ng-template>
    
                    <div>
                        <app-contest-actions></app-contest-actions>
                    </div>
    
                </mat-tab>

                <mat-tab *ngIf="this.isPremium">
                            <ng-template mat-tab-label>
                                    <span i18n="@@b125a092f7aeac185161ef4a237809bb2de25329">Teams</span>
                            </ng-template>
                            
                          
                            <div fxLayout="column" fxLayoutGap="5vh" >

                                <div fxFlexFill fxLayoutGap="4vw" fxLayout="column" fxLayout.gt-sm="row">
                
                                    <div fxFlex fxLayoutAlign="center start">
                                        <app-confirmed-teams></app-confirmed-teams>
                                    </div>
                
                                    <div fxFlex fxLayoutAlign="center start">
                                        <app-reservations></app-reservations>
                                    </div>
                
                                </div>

                                <div fxFlexFill fxLayoutAlign="center start">
                                    <app-reserve-list fxFlexFill></app-reserve-list>
                                </div>
                            </div>


                </mat-tab>
                    
                <mat-tab *ngIf="this.isPremium" fxFlexFill>
                            
                            <ng-template mat-tab-label>
                                    <span i18n="@@31b84e7a88c0cf802c4e93149f36b7f579d18755">Referees</span>
                            </ng-template>
            
                            <div fxLayout="row" >

                                <div fxFlexFill fxLayoutGap="4vw" fxLayout="column" fxLayout.gt-sm="row">

                                    <div fxFlex fxLayoutAlign="center start">
                                        <app-referees></app-referees>
                                    </div>
            
                                    <div fxFlex fxLayoutAlign="center start">
                                        <app-reservation-referees></app-reservation-referees>
                                    </div>
                                
                                </div>
                            </div>
            
                </mat-tab>

                <mat-tab *ngIf="this.isPremium" fxFlexFill>
                            
                    <ng-template mat-tab-label>
                            <span i18n="@@Catches">Catches</span>
                    </ng-template>

                            <div fxFlex fxLayoutAlign="center start">
                                <app-catches></app-catches>
                            </div>
    
                </mat-tab>
            
                <mat-tab fxFlexFill>
            
                            <ng-template mat-tab-label>
                                    <span i18n="ContestData">Contest Data</span>
                            </ng-template>
            
                        <app-main-challenge fxFlex="100"></app-main-challenge>
            
                </mat-tab>

                <mat-tab fxFlexFill>
            
                    <ng-template mat-tab-label>
                            <span i18n="@@e4f3064fea60e1b6d6aecbb13450dc42f1640762">Partners</span>
                    </ng-template>
                    
                    <div>
                        <app-partners fxFlex="100"></app-partners>
                    </div>
    
                </mat-tab>

                <mat-tab fxFlexFill>
            
                    <ng-template mat-tab-label>
                            <span i18n="@@f57d567de229385959e1d0c8e5ea0748f17d8610">Messages</span>
                    </ng-template>
    
                    <div>
                        <app-messages></app-messages>
                    </div>
    
                </mat-tab>

                <mat-tab disabled>
                    <ng-template *ngIf="this.isPremium; else freeType" mat-tab-label>
                        <span style="color: black !important; padding-left: 2vw;" i18n="@@TypePremium">Type: <strong>Premium</strong></span>
                    </ng-template>
                    <ng-template #freeType mat-tab-label>
                        <span style="color: white !important; padding-left: 2vw;" i18n="@@TypeFree">Type: <strong>Free</strong></span>
                    </ng-template>
                    
                </mat-tab>
            </mat-tab-group>
    

</div>