import { Component, OnInit, Input, OnDestroy, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, Subscription } from 'rxjs';
import { RankingItem } from 'src/app/data/models/Manage/rankingItem.model';
import { Store } from '@ngrx/store'
import * as fromRoot from '../../../../../../../../../src/app/presentation/app.reducer'
import { UIService } from 'src/app/shared/service/ui.service';

@Component({
  selector: 'app-ranking-places',
  templateUrl: './ranking-places.component.html',
  styleUrls: ['./ranking-places.component.css']
})
export class RankingPlacesComponent implements OnInit, OnDestroy {

    private eventsSubscription: Subscription;

    @Input() dataLoadEvent: Observable<RankingItem[]>;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator 

    isLoading$: Observable<boolean>;
    displayedColumns= ['position', 'pegNo', 'Score', 'lastChange'];
    dataSource = new MatTableDataSource<RankingItem>()
    teamData: RankingItem[]
    pageEvent: PageEvent;
    
    public pageSize: number 

  constructor(private uiService: UIService, private store: Store<fromRoot.State>) { 
    this.pageSize = window.innerWidth > 959 ? 10 : 5
  }

  ngOnInit() {
    this.isLoading$ = this.store.select(fromRoot.getIsLoadingChild)
    this.eventsSubscription = this.dataLoadEvent.subscribe((teams: RankingItem[]) => {
      if(teams.length > 0)
      {
      this.teamData = teams
      this.dataSource.data = this.teamData
      this.dataSource.paginator = this.paginator;
      }    
    }, error => {
      this.uiService.showSnackBar('Fetching actual Ranking failed, please try again later.',null,5000)
    })
  }

  ngAfterViewInit(){
    this.dataSource.paginator = this.paginator;
  }
  

  ngOnDestroy(){
    this.eventsSubscription.unsubscribe()
  }
}
