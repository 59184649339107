import { Component, OnInit, Output, EventEmitter} from '@angular/core';

import { AuthService } from 'src/app/auth-module/service/auth.service';
import { ServeUserService } from 'src/app/auth-module/service/serveUser.service';


@Component({
  selector: 'app-header-manage-referees-links',
  templateUrl: './header-manage-referees-links.component.html', 
})
export class HeaderManageRefereesLinksComponent implements OnInit {
 

  constructor(public authService: AuthService, public serveUser: ServeUserService) { 
  }

  ngOnInit() {
  }



}