<section fxFlex="100" fxLayoutAlign="center start" class="content-container">
    <div fxLayout="column" fxFlexFill fxLayoutAlign.xs="start center" fxLayoutAlign="start center" fxLayoutGap="2vh">
    
        <label *ngIf="!isNew" style="width: 100%; text-align: center;" class="mat-h1" i18n="SingUp Team Label| Header for Team sign up@@">Edit Angler</label>
        <label *ngIf="isNew" style="width: 100%; text-align: center;" class="mat-h3" i18n="@@NewAngler">Angler {{indexAngler + 1}}</label>

        <form *ngIf="this.angler" fxLayout="column" fxLayoutAlign="start center" #a="ngForm">
                   
            <mat-form-field>
                <mat-label i18n="SingUp Angler name| label for Angler name form sign up@@">Name</mat-label>
                <input matInput
                        [(ngModel)]="angler.name"
                        name="name"
                        (ngModelChange)="emitValidator(a.valid)"
                        required
                >
                </mat-form-field>
    
                <mat-form-field>
                    <mat-label i18n="SingUp Angler surname| label for Angler surname form sign up@@">Surname</mat-label>
                    <input matInput
                            [(ngModel)]="angler.surname"
                            name="surname"
                            (ngModelChange)="emitValidator(a.valid)"
                            required
                    >
                </mat-form-field>
    
                <mat-form-field>
                    <mat-label i18n="SingUp Angler country| label for Angler country form sign up@@">Country</mat-label>
                    <mat-select ([ngModel])="angler.country" [(value)]="angler.country">
                        <mat-option value="CZ">Czech Republic</mat-option>
                        <mat-option value="DE">Germany</mat-option>
                        <mat-option value="HU">Hungary</mat-option>
                        <mat-option value="PL">Poland</mat-option>
                        <mat-option value="SK">Slovakia</mat-option>
                        <mat-option value="UA">Ukraine</mat-option>
                        <mat-option value="Other">Other</mat-option>
                    </mat-select>
                </mat-form-field>
    
                <mat-form-field>
                    <mat-label i18n="SingUp Angler team Role| label for Angler teamRole sign up@@">Team Role</mat-label>
                    <mat-select ([ngModel])="angler.teamRole" [(value)]="angler.teamRole">
                        <mat-option value="Leader">Team Leader</mat-option>
                        <mat-option value="Member">Team Member</mat-option>
                        <mat-option value="Runner">Team Runner</mat-option>
                    </mat-select>
                </mat-form-field>

                <div fxFlex fxLayoutGap="2vh">
                    <button *ngIf="!isNew" 
                        mat-raised-button
                        color="warn"
                        i18n="cancel| cancel button@@"
                        (click)="onCancel()">Cancel</button>
                
                    <button *ngIf="a.valid && !isNew" 
                        mat-raised-button
                        color="primary"
                        i18n="Edit Team UpdateTeam| Button for update Team additon in edit form@@"
                        (click)="onUpdateAngler()">Update Angler</button>
                </div>        
                  
        </form>       
    </div>
</section>