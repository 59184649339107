
<div  fxFlex="100" fxLayoutAlign="center center" fxLayoutAlign.gt-sm="center center"  fxLayout="column">
    <!-- *ngIf="!this.UIservice.isMobileSM; else mobileDisplay" -->
    <mat-horizontal-stepper color="accent" [linear]="true" #stepper>

        <mat-step completed="false">

            <ng-template matStepLabel i18n="New Contest Organiser Header| Header for New Contest Organiser@@">Organiser</ng-template>

            <div fxFlex >

                <app-organizer  *ngIf="this.challenge"
                                [organizer]="this.challenge.Organizer"
                                [isCreate]="true"
                                (endCreation)="onSubComplete($event)">
                </app-organizer>

            </div>


        </mat-step>

        <mat-step completed="false">

            <ng-template matStepLabel i18n="New Contest Main Header| Header for New Contest Main@@">Main</ng-template>

            <div fxFlex>

            <app-main-data *ngIf="this.challenge"
                            [isCreate]="true"
                            [challenge]="this.challenge"
                            (endCreation)="onSubComplete($event)"
                            (challengeAlias)="onChallengeAlias($event)">
            </app-main-data>

        </div>

        </mat-step>

        <mat-step completed="false">

            <ng-template matStepLabel i18n="New Contest Fees Header| Header for New Contest Fees@@">Fees</ng-template>

            <div fxFlex>

                <app-fee    *ngIf="this.challenge"
                            [isCreate]="true"
                            [fee]="this.challenge.Fee"
                            (endCreation)="onSubComplete($event)">
                </app-fee>
            </div>


        </mat-step>

        <mat-step completed="false">

            <ng-template matStepLabel i18n="New Contest Awards Header| Header for New Contest Awards@@">Awards</ng-template>

            <div fxFlex>

                <app-awards *ngIf="this.challenge"
                            [isCreate]="true"
                            [awards]="this.challenge.Awards"
                            (endCreation)="onSubComplete($event)">
                </app-awards>

            </div>


        </mat-step>

        <mat-step completed="false">

            <ng-template matStepLabel i18n="New Contest Links Header| Header for New Contest Links@@">Links</ng-template>

            <div fxFlex>

                <app-links *ngIf="this.challenge"
                            [isCreate]="true"
                            [links]="this.challenge.Links"
                            (endCreation)="onSubComplete($event)">
                </app-links>

            </div>


        </mat-step>

        <mat-step completed="false">

            <ng-template matStepLabel i18n="New Contest Premium Header| Header for New Contest Premium@@">Premium</ng-template>

            <div class="content-container" fxFlex fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="5vh">

                <app-premium fxFlexFill
                            [challenge]="this.challenge"
                            >
                </app-premium>

                <div fxLayoutGap="5vw">

                    <button
                            [disabled]="this.isGeneratingChallenge"
                            mat-raised-button
                            color="primary"
                            i18n="Finish Button| Finish Button@@"
                            (click)="onSubmitNewContest()">
                    Finish
                    </button>

                </div>

            </div>


        </mat-step>

      </mat-horizontal-stepper>

      <button
      [disabled]="this.isGeneratingChallenge"
      mat-raised-button
      color="warn"
      i18n="cancel| cancel button@@"
      [routerLink]="['/organiser']"
      mat-dialog-close [mat-dialog-close]="false"
      >
      Cancel
      </button>

</div>

  <!-- <ng-template #mobileDisplay>

    <div class="Landing-Background" fxFlexFill fxLayoutAlign="center center" fxLayoutAlign.gt-sm="center center"  fxLayout="column">

        <mat-vertical-stepper color="accent" class="content-container-vertical" [linear]="true" #stepper>

            <mat-step completed="false">

                <ng-template matStepLabel i18n="New Contest Organiser Header| Header for New Contest Organiser@@">Organiser</ng-template>

                <div fxLayout="column" fxLayoutAlign="start center">

                    <app-organizer  *ngIf="this.challenge"
                                    [organizer]="this.challenge.Organizer"
                                    [isCreate]="true"
                                    (endCreation)="onSubComplete($event)">
                    </app-organizer>

                </div>


            </mat-step>

            <mat-step completed="false">

                <ng-template matStepLabel i18n="New Contest Main Header| Header for New Contest Main@@">Main</ng-template>

                <div fxFlex fxLayout="column" fxLayoutAlign="start center">

                <app-main-data *ngIf="this.challenge"
                                [isCreate]="true"
                                [challenge]="this.challenge"
                                (endCreation)="onSubComplete($event)">
                </app-main-data>

            </div>

            </mat-step>

            <mat-step completed="false">

                <ng-template matStepLabel i18n="New Contest Fees Header| Header for New Contest Fees@@">Fees</ng-template>

                <div fxFlex fxLayout="column" fxLayoutAlign="start center">

                    <app-fee    *ngIf="this.challenge"
                                [isCreate]="true"
                                [fee]="this.challenge.Fee"
                                (endCreation)="onSubComplete($event)">
                    </app-fee>
                </div>


            </mat-step>

            <mat-step completed="false">

                <ng-template matStepLabel i18n="New Contest Awards Header| Header for New Contest Awards@@">Awards</ng-template>

                <div fxFlex fxLayout="column" fxLayoutAlign="start center">

                    <app-awards *ngIf="this.challenge"
                                [isCreate]="true"
                                [awards]="this.challenge.Awards"
                                (endCreation)="onSubComplete($event)">
                    </app-awards>

                </div>


            </mat-step>

            <mat-step completed="false">

                <ng-template matStepLabel i18n="New Contest Links Header| Header for New Contest Links@@">Links</ng-template>

                <div fxFlex fxLayout="column" fxLayoutAlign="start center">

                    <app-links *ngIf="this.challenge"
                                [isCreate]="true"
                                [links]="this.challenge.Links"
                                (endCreation)="onSubComplete($event)">
                    </app-links>

                </div>


            </mat-step>

            <mat-step completed="false">

                <ng-template matStepLabel i18n="New Contest Premium Header| Header for New Contest Premium@@">Premium</ng-template>

                <div class="content-container" fxFlex fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="5vh">

                    <app-premium fxFlexFill
                                [challenge]="this.challenge"
                                >
                    </app-premium>

                    <div fxLayoutGap="5vw">

                        <button
                        mat-stroked-button
                        color="warn"
                        i18n="cancel| cancel button@@"
                        (click)="onCancel()">
                        Cancel
                        </button>

                        <button mat-stroked-button
                                style="color:#23c268;"
                                i18n="Finish Button| Finish Button@@"
                                (click)="onSubmitNewContest()">
                        Finish
                        </button>

                    </div>

                </div>


            </mat-step>

          </mat-vertical-stepper>
    </div>

</ng-template> -->



