import { AuthService } from 'src/app/auth-module/service/auth.service'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { Contest } from 'src/app/data/models/Manage/contest.model'
import { Challenge } from 'src/app/data/models/Manage/challenge.model'
import { Team } from 'src/app/data/models/Manage/team.model'
import { Message } from 'src/app/data/models/Manage/message.model'
import { ServeUserService } from 'src/app/auth-module/service/serveUser.service'
import { ContestService } from 'src/app/core-module/entityServices/contest.service'
import { Fish } from 'src/app/data/models/Manage/fish.model'





@Injectable()
export class ContestDataService {


    contestKey: string
    selectedContest$: Observable<Contest>

    challenge$: Observable<Challenge>
    signedTeams$: Observable<Team[]>
    messages$: Observable<Message[]>

    constructor(private contestService: ContestService)
    {
         
    }

    setContest(key: string): void{
        if (key && this.contestKey != key)
        {
            this.contestKey = key
            this.contestService.contestKey = key
            this.contestService.basePathReference = 'Challenges/'+ key 
            this.challenge$ = null
            this.signedTeams$ = null
            this.messages$ = null      
        }
    }

getChallenge(){
    this.challenge$ = this.contestService.getChallenge()
}

getSignedTeams(){
    this.signedTeams$ = this.contestService.getSignedTeams()
}

getMessages(){
    this.messages$ = this.contestService.getMessages()
}

async uploadFishPhoto(_file: File ){

    const _key = this.contestKey
    const collection = this.contestKey + "/contestFiles/photos/"
    var downloadLink

    await this.contestService.uploadFile(_file, collection)
                        .then(async () => { await this.contestService.getDownloadURL(_file.name, collection)
                                            .then((value) => { downloadLink = value})})
                        .catch((e) => { })

    return downloadLink
}

async setNewFish(newFish: Fish){

    const _key = this.contestKey
    const collection = this.contestKey + "/contestFiles/photos/"

    return this.contestService.setObject(_key, 'catches', newFish)
}
}