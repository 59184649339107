export class Message {
    id: string;
    createdBy: string;
    createdAt: number;
    type: string;
    text: {
        PL?: string,
        EN?: string,
        title?: string,

    }
    extraData? : {
      imgLink? : string,
      youtubeLink?: string,
      tweeterLink?: string,
      partner?: {
        partnerLink: string,
        partnerName: string

      }
    }
}
