import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Store} from '@ngrx/store'
import * as fromRoot from './app.reducer'
import { UIService } from '../shared/service/ui.service';
import { AuthService } from '../auth-module/service/auth.service';
import{Router, NavigationEnd} from '@angular/router';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [UIService]
  
})
export class AppComponent implements OnInit, OnDestroy {
  
  isLoading$: Observable<boolean>;

  
  constructor(private store: Store<fromRoot.State>,
              public UIService: UIService,
              private AuthService: AuthService, router: Router)
               { 
                router.events.subscribe(event => {
                  if(event instanceof NavigationEnd){
                    let pathElements = event.urlAfterRedirects.split('/')
                    let pageTitle = this.getPageTitleForGoogleAnalytics(pathElements)
                      gtag('config', 'G-CPFM3TN2Q6', 
                            { 
                              'page_title': pageTitle,                        
                              'page_path': event.urlAfterRedirects
                            })}
                          })

  }

  ngOnInit() {
        this.isLoading$ = this.store.select(fromRoot.getIsLoadingMain)
        this.cookieInfoShow()
        
  }

  getPageTitleForGoogleAnalytics(pathElement: string[]){
    let path_title
    
    switch(pathElement[1]){
      case 'contest': {
        if(pathElement.length > 3){
          if(pathElement[3] === 'live'){
           path_title = pathElement[2] + ' - Live'
          }
          else if (pathElement[3] === 'sign-me'){
            path_title = pathElement[2] + ' - Rejestracja'
          }
          else if (pathElement[3] === 'teams'){
            path_title = pathElement[2] + ' - Drużyny'
          }
          else
          {
            path_title = pathElement[2]
          }
        }
        else{
          path_title = pathElement[2]
        }
        break;
        }
      default:{
        path_title = pathElement[1]
        break;
      }
      }
      return path_title
    }

  

  cookieInfoShow(){
    let cc = window as any;
    cc.cookieconsent.initialise({
      palette: {
        popup: {
          background: "#000"
        },
        button: {
          background: "transparent",
          text: "#f1d600",
          border: "#f1d600"
        }
      },
      position: 'bottom-right'
    });

  }

  ngOnDestroy(): void {
   
  }

}
