<mat-tab-group color="warn" mat-align-tabs="center" fxFlex="100">
               
    <mat-tab>
            <ng-template mat-tab-label i18n="CompetitorList| Competitor List in Teams header@@">Competitor List</ng-template>

            <app-spinner fxFill *ngIf="!this.signedTeams"></app-spinner>
            <app-teams-table [teams$]="signedTeamsSubject.asObservable()">

            </app-teams-table>
              
    </mat-tab>
        
    <mat-tab>
        <ng-template mat-tab-label i18n="@@36886110102b6c509f0751cc5af36ba21e86dfce"> Reservations </ng-template>
        
        <app-spinner fxFill *ngIf="!this.reservationTeams"></app-spinner>
        <app-teams-table [teams$]="reservationTeamSubject.asObservable()"></app-teams-table>

    </mat-tab>

    <mat-tab>
        <ng-template mat-tab-label i18n="@@ReserveListHeader">Reserve List</ng-template>
        
        <app-spinner fxFill *ngIf="!this.reserveList"></app-spinner>
        <app-teams-table [teams$]="reserveListSubject.asObservable()"></app-teams-table>

    </mat-tab>

    
    
</mat-tab-group>