<h1 *ngIf="data.editMode === null" mat-dialog-title style="text-align: center;" i18n="@@AddFish">ADD FISH</h1>
<h1 *ngIf="data.editMode" mat-dialog-title style="text-align: center;" i18n="@@EditFish">EDIT FISH</h1>

<div mat-dialog-content fxLayout="column">
    <mat-form-field>
        <mat-label i18n="@@SelectStation">Select Station</mat-label>
        <mat-select required [(ngModel)]="data.newFish.station" [(value)]="data.newFish.station">
            <mat-option *ngFor="let team of teams; let i = index" (click)="onTeamSelect(i)" [value]="team.station">{{team.station}}</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="!!data.newFish.station">
        <mat-label i18n="@@SelectAngler">Select Angler</mat-label>
        <mat-select required [(ngModel)]="this.data.newFish.anglerID" [(value)]="this.data.newFish.anglerID">
            <mat-option *ngFor="let angler of anglers, let x = index" (click)="onAnglerSelect(x)" [value]="x">{{angler.name + ' ' + angler.surname}}</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="data.newFish.anglerID != null">
        <mat-label i18n="@@SelectFishType">Select Fish Type</mat-label>
        <mat-select required [(ngModel)]="data.newFish.fishType" [(value)]="data.newFish.fishType">
            <mat-option value="Karp">Karp</mat-option>
            <mat-option value="Amur">Amur</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="!!data.newFish.fishType" >
        <mat-label i18n="@@SelectCatchDate">Select Catch Date</mat-label>
        <input *ngIf="data.editMode === null" matInput [ngxMatDatetimePicker]="picker" readonly required (dateInput)="onDateInput($event)">
        <input *ngIf="data.editMode" matInput [ngxMatDatetimePicker]="picker" readonly required [(value)]="this.data.newFish.date" (dateInput)="onDateInput($event)">
        <mat-datepicker-toggle id="datePicker" matSuffix [for]="picker"></mat-datepicker-toggle>
        <ngx-mat-datetime-picker #picker [touchUi]="false" [color]="color" [stepHour]="true" [stepMinute]="true">
        </ngx-mat-datetime-picker>
      </mat-form-field>

      <!-- <mat-form-field>
        <input matInput [ngxMatDatetimePicker]="picker" placeholder="Choose a date" [formControl]="dateControl"
           [min]="minDate" [max]="maxDate" [disabled]="disabled">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <ngx-mat-datetime-picker #picker [showSpinners]="showSpinners" [showSeconds]="showSeconds"
           [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond"
           [touchUi]="touchUi" [color]="color" [enableMeridian]="enableMeridian"
           [disableMinute]="disableMinute" [hideTime]="hideTime">
        </ngx-mat-datetime-picker>
     </mat-form-field> -->

      <mat-form-field *ngIf="!!data.newFish.date">
        <mat-label i18n="@@EnterFishWeight">Enter Fish Weight</mat-label>
        <input matInput  [(ngModel)]="data.newFish.weight" type="number" step="0.001"
                name="Fish Weight"
                required>
    </mat-form-field>

    <mat-form-field *ngIf="!!data.newFish.date && photoName != null">
        <mat-label i18n="@@SelectFishFoto">Select Fish photo</mat-label>
        <input   matInput
                [(ngModel)]="photoName"
                [(value)]="photoName"
                name="Upload Fish Photo"
        >
    </mat-form-field>

    <button *ngIf="!!data.newFish.date" type="button" color="accent" mat-raised-button (click)="photInput.click()" i18n="@@SelectPhoto">Select Photo</button>
    <input hidden (change)="onFileSelected($event)" accept="image/*" #photInput type="file" id="photoFile">

</div>

<mat-dialog-actions fxFlexFill fxLayoutAlign="center center">
    <button  mat-stroked-button mat-dialog-close [mat-dialog-close]="false" color="warn" i18n="@@Reject" cdkFocusInitial>Reject</button>
    <button  mat-stroked-button mat-dialog-close [mat-dialog-close]="data.newFish" color="primary" i18n="@@Confirm">Confirm</button>
</mat-dialog-actions>
