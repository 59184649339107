import { Component, OnInit, HostListener } from '@angular/core';
import { fullScreenImage } from '../../animations'
import { UIService } from 'src/app/shared/service/ui.service';
import { Router } from '@angular/router';
import { ServeUserService } from 'src/app/auth-module/service/serveUser.service';

declare var require: any;
@Component({
  selector: 'app-welcome-image',
  templateUrl: './welcome-image.component.html',
  styleUrls: ['./welcome-image.component.css'],
  animations: [fullScreenImage]
})
export class WelcomeImageComponent implements OnInit {


  isOpen = true;
  navigateToNew = false

  constructor(public UIservice: UIService, private router: Router, private serveUser: ServeUserService) { }

  ngOnInit() {
    this.serveUser.user.email === 'none' ? this.navigateToNew = false : this.navigateToNew = true

  }

  applySearch(search: string){
    this.router.navigate(['/main/finder', search])
  }

}
