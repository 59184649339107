import { Component, OnInit, Input, ViewChild, ChangeDetectorRef, OnDestroy, Output } from '@angular/core';
import { ContestInfo } from '../../model/contestInfo.model';
import { Observable, Subscription } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { UIService } from 'src/app/shared/service/ui.service';

@Component({
  selector: 'app-contest-table',
  templateUrl: './contest-table.component.html',
  styleUrls: ['./contest-table.component.css']
})
export class ContestTableComponent implements OnInit, OnDestroy {

  @Input() data: Observable<ContestInfo[]>

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  
  anyData: boolean = false

  private dataSubscription: Subscription;

  cardData: Observable<ContestInfo[]>
  displayedColumns= ['No', 'fullName' ,'country', 'region', 'city'];
  dataSource = new MatTableDataSource<ContestInfo>()

  constructor(private cdRef:ChangeDetectorRef, public uiService: UIService) {
   }

  ngOnInit() {
    this.dataSubscription = this.data.subscribe(array => {
      this.dataSource.paginator = this.paginator;
      this.dataSource.data = array
      this.cardData = this.dataSource.connect()
      this.anyData = array.length > 0 ? true : false
    })
  }

  ngAfterViewChecked(){
    this.cdRef.detectChanges();
  }

  ngOnDestroy(){
    this.dataSubscription.unsubscribe()
  }

}
