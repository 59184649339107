import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { Message } from 'src/app/data/models/Manage/message.model';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, Subscription } from 'rxjs';
import { UIService } from 'src/app/shared/service/ui.service';


@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.css']
})
export class MessageComponent implements OnInit, OnDestroy {

  @Input() dataLoadEvent: Observable<Message[]>;
  obs: Observable<any>;
  private eventsSubscription: Subscription;
  public messages: Message[];
  pageEvent: PageEvent;
  language = "PL"

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  displayedColumns= ['From','Type','Text'];
  dataSource = new MatTableDataSource<Message>()

  constructor(public uiService: UIService) { }

  ngOnInit() {
    this.eventsSubscription = this.dataLoadEvent
      .subscribe((messages: Message[]) => {

        if(messages.length > 0){
          this.messages = messages
        this.messages.sort((a,b) => {
          if (a.createdAt > b.createdAt)
          {
            return -1
          }

          if(b.createdAt > a.createdAt)
          {
            return 1
          }
        })
        this.dataSource.data = this.messages
        }
        }, error => {
          this.uiService.showSnackBar('Message Service temporarly not available.',null,5000)
        })

    this.dataSource.paginator = this.paginator;
    this.obs = this.dataSource.connect()
  }

  ngOnDestroy(){
    this.eventsSubscription.unsubscribe()
  }

}
