import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Links } from 'src/app/data/models/Manage/challenge.model';
import { UIService } from 'src/app/shared/service/ui.service';
import { ActivatedRoute } from '@angular/router';
import { ManageContestDataService } from '../../../services/manage-contest-data';
import { cpuUsage } from 'process';

class ImageSnippet {
  constructor(public src: string, public file: File) {}
}

@Component({
  selector: 'app-links',
  templateUrl: './links.component.html',
  styleUrls: ['./links.component.css']
})
export class LinksComponent implements OnInit {
  @Input() modeEdit?: string = ''
  @Input() isCreate?: boolean = false
  @Input() links: Links
  @Output() edit: EventEmitter<string> = new EventEmitter<string>()
  @Output() endCreation: EventEmitter<boolean> = new EventEmitter<boolean>()

  uploadError
  renderSelected: ImageSnippet;

  editLinks: Links
  srcResult: any

  constructor(public UIservice: UIService,
              private manageContest: ManageContestDataService) { }

  ngOnInit() {
    if(this.isCreate)
    {
      this.modeEdit ='create'
      this.editLinks = this.links
    }
  }

  onCancel(){
    this.modeEdit = ''
    this.edit.emit('none')
  }

  onUpdate(){

    if (this.isCreate)
    {
      this.endCreation.emit(true)
    }
    else
    {
      this.uploadNewFiles()
              .then(() => {
                this.manageContest.updateChallengeChild("Links" ,this.editLinks)
              })
        .catch((e) => {console.log(e.message)})
        .finally(() => {
        this.modeEdit = ''
        this.edit.emit('none')
      })

    }

  }

  onEdit(){
    if(this.links)
    {
      this.editLinks = {...this.links}
      this.modeEdit = 'edit'
      this.edit.emit('links')
    }
  }

 onFileSelected(filetype: string, event){
   var blob = event.target.files[0]
    const reader = new FileReader()
    let fileHeader
    reader.onloadend = (e) => {
      fileHeader = this.getSignature(4,e.target.result)
      this.onSignatureReady(filetype, fileHeader, event)
    }

    reader.readAsArrayBuffer(blob)

 }

 onSignatureReady(filetype: string, fileHeader, event)
 {
  let incorrectFileTitle = $localize`Incorrect File`
  let incorrectFileContent = $localize`<strong>SECURITY - Incorrect file type !</strong>`

    if(event.target.files[0].size < 3145728)
    {

      switch (filetype) {
        case 'rules':

          const validRulesfile = this.checkTextFileSignature(fileHeader)

          if(validRulesfile)
          {
            this.manageContest.rulesFile = event.target.files[0]
            this.editLinks.rulesLink = this.manageContest.rulesFile.name
          }
          else{
            this.onUploadError(incorrectFileTitle, incorrectFileTitle)
          }

          break;

        case 'poster':

          let validPosterFile = this.checkImageFileSignature(fileHeader)
          if(validPosterFile)
          {
            this.manageContest.posterFile = event.target.files[0]
            this.editLinks.posterLink = this.manageContest.posterFile.name
          }
          else{
            this.onUploadError(incorrectFileTitle, incorrectFileTitle)
          }


          break;

        case 'spots':
          let valideSpotsFile = this.checkImageFileSignature(fileHeader)
          if(valideSpotsFile){
            this.manageContest.spotsFile = event.target.files[0]
            this.editLinks.bankSpotsMapLink = this.manageContest.spotsFile.name
          }
          else{

          this.onUploadError(incorrectFileTitle, incorrectFileTitle)
          }


            break;

        default:
          break;
      }
    }
    else
    {
        let content = $localize`<strong>File is to big, max size 3MB !</strong>`
        this.onUploadError(incorrectFileTitle, content)
    }



}
private getSignature(length: number, file){
  let arr = (new Uint8Array(file).subarray(0,length))
  let header = ""
        for(var i = 0; i < arr.length; i++){
          header += arr[i].toString(16)
        }
  return header
}

private checkImageFileSignature(header: string): boolean{

  let isRightFile: boolean
  let type
  switch (header) {
    case "89504e47":
        type = "image/png"
        isRightFile = true
        break;
    case "47494638":
        type = "image/gif";
        isRightFile = false
        break;
    case "ffd8ffe0":
    case "ffd8ffe1":
    case "ffd8ffe2":
    case "ffd8ffe3":
    case "ffd8ffe8":
        type = "image/jpeg"
        isRightFile = true
        break;
    default:
        type = "unknown"; // Or you can use the blob.type as fallback
        isRightFile = false
        break;
}
  return isRightFile

}

private checkTextFileSignature(header: string): boolean{

  let isRightFile: boolean
  let type
  switch (header) {
    case "25504446":
      type = "PDF document"
      isRightFile = true
    break;
    case "d0cf11e0":
      type ="Microsoft Office document"
      isRightFile = true
      break;
    case "504b0304":
      type ="MS Office 2007 documents"
      isRightFile = true
      break;
    default:
        type = "unknown"; // Or you can use the blob.type as fallback
        isRightFile = false
        break;
}
  return isRightFile
}

async uploadNewFiles()
{

 if(this.manageContest.rulesFile)
 {
  await this.manageContest.uploadContestInfoFiles(this.manageContest.rulesFile)
  .then((value) => { this.editLinks.rulesLink = value
  })
  .catch((e) =>{
    let title = $localize`Upload Error`
    let content = e.message
    this.onUploadError(title, content)
  })
 }

 if(this.manageContest.spotsFile)
 {
  await this.manageContest.uploadContestInfoFiles(this.manageContest.spotsFile)
  .then((value) => { this.editLinks.bankSpotsMapLink = value
    console.log(value)
  })
  .catch((e) => {
    let title = $localize`Upload Error`
    let content = e.message
    this.onUploadError(title, content)
  })
 }

 if(this.manageContest.posterFile)
 {
  await this.manageContest.uploadContestInfoFiles(this.manageContest.posterFile)
  .then((value) => { this.editLinks.posterLink = value})
  .catch((e) => {
    let title = $localize`Upload Error`
    let content = e.message
    this.onUploadError(title, content)
  })
 }


}

onUploadError(_title, _content){
  let data = {
    title: _title,
    content: _content
}
this.UIservice.openInfoDialog(data.title, data.content,false)
}

}
