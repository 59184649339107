
export const countries: string[] = ['Polska', 'Deutschland', 'Česko', 'Slovensko', 'Magyarország', 'other']

export const polishRegions: string[] = ['dolnośląskie', 'kujawsko-pomorskie', 'lubelskie', 'lubuskie', 'łódzkie', 'małopolskie', 'mazowieckie', 'opolskie', 'podkarpackie','podlaskie',
'pomorskie', 'śląskie', 'świętokrzyskie', 'warmińsko-mazurskie', 'wielkopolskie', 'zachodniopomorskie']

export const germanRegion: string[] = ['Baden-Württemberg', 'Bayern', 'Berlin', 'Brandenburg', 'Bremen', 'Hamburg', 'Hessen', 'Mecklenburg-Vorpommern','Niedersachsen', 
'Nordrhein-Westfalen', 'Rheinland-Pfalz', 'Saarland',  'Sachsen', 'Sachsen-Anhalt', 'Schleswig-Holstein', 'Thüringen']

export const czechRegions: string[] = ['Hlavní město Praha', 'Jihočeský', 'Jihomoravský', 'Karlovarský', 'Královéhradecký', 'Liberecký', 'Moravskoslezský', 'Olomoucký',
'Pardubický', 'Plzeňský', 'Středočeský', 'Ústecký', 'Vysočina', 'Zlínský']

export const slovakRegions: string[] = ['Banská Bystrica', 'Bratislava', 'Košice', 'Nitra', 'Prešov', 'Trenčín', 'Trnava', 'Žilina']

export const hungaryRegions: string[] = ['Dél-Alföld', 'Dél-Dunántúl', 'Észak-Alföld', 'Észak-Magyaroszág', 'Közép-Dunántúl', 'Közép-Magyarország', 'Nyugat-Dunántúl']
