<article id="requirements" class="full-description-panel" fxFill fxFlex fxLayout="column" fxLayoutAlign="start center">

    <div fxLayout="column" fxLayoutAlign="start center">
        
        <h1 class="mat-display-2" i18n="@@RefereeRequirements">Requirements</h1>
        
    </div>

    <div [ngClass.gt-xs]="'mat-title'" fxLayoutGap="5vw" [ngClass.lt-md]="'mobile-font-style'" fxFlex fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap.lt-sm="5vh">

        <div fxLayout.lt-sm="column" fxLayoutAlign="start center" fxFlexOrder="1">
            <div class="workflow-element" [ngClass]="{'icon-mobile-sm':  UIservice.isMobileSM, 'icon-desktop': !UIservice.isMobileSM}" fxLayoutAlign="center center">
                <mat-icon [inline]="true" aria-hidden="true">settings_cell</mat-icon>            
            </div>
        
            <div>
                <p i18n="@@CellPhoneRequirements">Before start Refereeing been sure <br>
                    that cell phone has got:</p>
                        <ul i18n="@@CompetitorRequirements">
                            <li>Internet Access</li> 
                            <li>Working Camera</li>
                            <li>Free disk space for photo</li>
                            <li>loaded battery</li>
                        </ul>
            </div>
        </div>

        <div fxLayout.lt-sm="column" fxLayoutAlign="start center" fxFlexOrder="2">
            <div class="workflow-element" [ngClass]="{'icon-mobile-sm':  UIservice.isMobileSM, 'icon-desktop': !UIservice.isMobileSM}" fxLayoutAlign="center center">
                <mat-icon [inline]="true" aria-hidden="true">important_devices</mat-icon>            
            </div>
        
            <div>
                <p i18n="@@WeKindlyRecomendSoftwar">
                      Carp Contest strongly recommend to use<br>
                      latest version of browsers:</p>
                      <ul>
                        <li>Google Chrome</li> 
                        <li>Safari</li>
                    </ul>
            </div>         
        </div>
    </div>
</article>