import { Component, OnInit, Output, EventEmitter} from '@angular/core';

import { AuthService } from 'src/app/auth-module/service/auth.service';
import { ServeUserService } from 'src/app/auth-module/service/serveUser.service';


@Component({
  selector: 'app-sidebar-manage-contests-links',
  template: ` <mat-expansion-panel>
                
                <mat-expansion-panel-header>
                    
                    <mat-panel-title style="color:rgb(255,255,255, 0.8);">
                        <mat-icon style="padding: 0 2vw 0 0;">event_note</mat-icon>
                        <span i18n="Contests| Contests in Navigation@@">Contests</span>
                    </mat-panel-title>
                
                </mat-expansion-panel-header>

                <a *ngIf="this.serveUser.isAdmin || this.serveUser.isOrganiser"
                    mat-list-item
                    role="navigation"  
                    routerLink="organiser/add-contest"
                    (click)="onClose()"
                >

                    <mat-icon class="expansion-panel-item-icon">add_to_queue</mat-icon>
                    <span class="expansion-panel-item-caption" i18n="NewContest| New Contest in Navigation@@">New Contest</span>
                </a>

                <a *ngIf="this.serveUser.isAdmin || this.serveUser.isOrganiser; else refereeData"
                    mat-list-item
                    role="navigation" 
                    routerLink="organiser/contests"
                    (click)="onClose()"
                >
                    
                    <mat-icon class="expansion-panel-item-icon">list</mat-icon>
                    <span class="expansion-panel-item-caption" i18n="MyContests| My Contests in Navigation@@">My Contests</span> 
                </a>

            <ng-template #refereeData>

                <a *ngIf="this.serveUser.isReferee"
                    mat-list-item
                    role="navigation" 
                    routerLink="referee/contests"
                    (click)="onClose()"
                 >
                
                <mat-icon class="expansion-panel-item-icon">build</mat-icon>
                <span class="expansion-panel-item-caption" i18n="MyContests| My Contests in Navigation@@">My Contests</span> 
                </a>

            </ng-template>

                
            </mat-expansion-panel>`,
    styleUrls: ['./sidenav-list.component.css']
})
export class SideBarManageContestsLinksComponent implements OnInit {
 
@Output() closeSidenav = new EventEmitter<void>()

  constructor(public authService: AuthService, public serveUser: ServeUserService) { 
  }

  ngOnInit() {
  }

  onClose(){
      this.closeSidenav.emit()
  }


}