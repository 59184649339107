<h2 *ngIf="data.type === 'station' || data.type === 'refereeStations'" mat-dialog-title style="text-align: center;" i18n="@@AssignStation">Assign Swim</h2>

<mat-dialog-content>

    <mat-form-field *ngIf="data.type === 'station'">
        <mat-label i18n="@@enterStation">Enter Swim Number</mat-label>
        <input matInput  [(ngModel)]="data.newValue" type="number" step="1"
                name="userInput"
                required>
    </mat-form-field>

    <mat-form-field *ngIf="data.type === 'refereeStations'">
        <mat-label i18n="@@enterStartSwim">Enter start swim number</mat-label>
        <input matInput  [(ngModel)]="startSwim" type="number" step="1"
                (change) = "refereeSwimChanged()"
                name="userInput"
                required>
    </mat-form-field>

    <mat-form-field *ngIf="data.type === 'refereeStations'">
        <mat-label i18n="@@enterEndSwim">Enter end swim number</mat-label>
        <input matInput  [(ngModel)]="endSwim" type="number" step="1"
                name="userInput"
                (change) = "refereeSwimChanged()"
                required>
    </mat-form-field>

</mat-dialog-content>
<mat-dialog-actions fxFlexFill fxLayoutAlign="center center">
    <button mat-button mat-dialog-close [mat-dialog-close]="false" i18n="@@Reject" >Reject</button>
    <button mat-button mat-dialog-close [mat-dialog-close]="data" i18n="@@Confirm">Confirm</button>
</mat-dialog-actions>