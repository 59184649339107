import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { MainViewComponent } from './main-view/main-view.component';
import { ContestListsComponent } from './contest-lists/contest-lists.component';
import { InfoComponent } from './info/info.component';
import { CompetitorComponent } from './info/competitor/competitor.component';
import { RefereeComponent } from './info/referee/referee.component';
import { OrganiserComponent } from './info/organiser/organiser.component';
import { PricingComponent } from './info/organiser/pricing/pricing.component';
import { FutureComponent } from './info/organiser/future/future.component';
import { BenefitsComponent } from './info/organiser/benefits/benefits.component';
import { RodoComponent } from './info/rodo/rodo.component';
import { PrivacyComponent } from './info/privacy/privacy.component';
import { TermsAndConditionsComponent } from './info/terms-and-conditions/terms-and-conditions.component';
import { LoginComponent } from './login/login.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { ChooseAppComponent } from './choose-app/choose-app.component';
import { LandingGuard } from '../../../../src/app/auth-module/guards/landing-guard';
import { NotAllowedComponent } from './not-allowed/not-allowed.component';
import { CompMyDataComponent } from './info/competitor/comp-my-data/comp-my-data.component';


const routes: Routes = [
{path: 'main', canActivate: [LandingGuard], children: [
  {path: '', component: MainViewComponent},
  {path: 'login', component: LoginComponent},
  {path: 'finder', children: [
    {path: '', component: ContestListsComponent},
    {path: ':searchValue', component: ContestListsComponent}
  ]},
  {path: 'info', children: [
    {path: '', component: InfoComponent, pathMatch: 'full'},
    {path: 'competitor', component: CompetitorComponent},
    {path: 'competitor#competitorData', component: CompetitorComponent, pathMatch: 'full'},
    {path: 'referee', component: RefereeComponent},
    {path: 'rodo', component: RodoComponent},
    {path: 'privacy-politics', component: PrivacyComponent},
    {path: 'terms-and-conditions', component: TermsAndConditionsComponent},
    {path: 'organiser', children: [
      {path: '', component: OrganiserComponent, pathMatch: 'full'},
      {path: 'pricing', component: PricingComponent},
      {path: 'future', component: FutureComponent},
      {path: 'benefits', component: BenefitsComponent},
      {path: '**', redirectTo: ''}
    ]},
    {path: '**', redirectTo: ''}
  ]},
  {path: 'signup', component: SignUpComponent},
  {path: 'choose-role', component: ChooseAppComponent},
  {path: 'notAllowed', component: NotAllowedComponent}
]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
