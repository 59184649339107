<article class="articleStyle" >
    <div fxLayout="column"> 
            <p fxLayoutAlign="start start" fxFlexOrder="1">
                <label fxFlex.xs="130px" fxFlex="170px" fxLayoutAlign="start start"
                        class="detailsLabel" title="Fee" i18n="Welcome Join&Start Card Label Fee| Label for Fee information@@">
                        Fee:</label>
            {{ this.fee.feeAmount }}&nbsp;{{this.fee.feeCurrency}}&nbsp;&frasl;&nbsp;{{this.fee.feeType}}</p>

            <p *ngIf="this.fee.feeTeamRunner === 'Not Allowed'; else allowedRunner" fxLayoutAlign="start start" fxFlexOrder="2">
                <label fxFlex.xs="130px" fxFlex="170px" fxLayoutAlign="start start"
                        class="detailsLabel" title="Team Runner" 
                        i18n="Welcome Join&Start Card Label Team Runner Fee| Label for Team Runner Fee information@@">
            Team Runner:</label>
            <label i18n="notAllowed|Not Allowed@@">Not Allowed</label>
            </p>

            <ng-template #allowedRunner>
                <p fxLayoutAlign="start start" fxFlexOrder="2">
                    <label fxFlex.xs="130px" fxFlex="170px" fxLayoutAlign="start start"
                            class="detailsLabel" title="Team Runner" 
                            i18n="Welcome Join&Start Card Label Team Runner Fee| Label for Team Runner Fee information@@">
                Team Runner:</label>
                {{this.fee.feeTeamRunner}}&nbsp;{{this.fee.feeCurrency}}
                </p>
            </ng-template>
            
            <p fxLayoutAlign="start start" fxFlexOrder="3">
                <label fxFlex.xs="130px" fxFlex="170px" fxLayoutAlign="start start"
                        class="detailsLabel" title="Deadline" 
                        i18n="Welcome Join&Start Card Label Deadline Fee| Label for Deadline Fee information@@">
                Deadline:</label>
                {{this.fee.deadline | date:"dd/MM/yyyy" }}
            </p>
            

            

    </div>
</article>