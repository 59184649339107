<div fxFlexFill fxLayout="column" fxLayoutAlign="start center">

<h1 class="mat-h1" style="margin-top: 2vh;" i18n="@@LetsFindTheBestCotest">Let's find the best contest !</h1>

<div    *ngIf="this.UIservice.isMobileSM; else bigScreenFilters" 
        style="width: 100%; padding-left: 2vh; padding-right: 2vh;" 
        fxLayout="column" fxLayoutAlign="start center"
        fxLayoutGap="1vh"
        >

        <mat-accordion fxFlex="100">
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title fxFlex="50">
                      <label i18n="@@FilterCountry">Filter Country</label>
                    </mat-panel-title>
                    <mat-panel-description fxFlex="50">
                      <label *ngIf="selectedCountry != 'all'" i18n="@@SelectedCountry">Selected: {{selectedCountry | uppercase}}></label>
                      <label *ngIf="selectedCountry === 'all'" i18n="@@AllCountries">All Countries</label>
                    </mat-panel-description>
                  </mat-expansion-panel-header>

                  <section id="contryFilter" >
                        <mat-radio-group fxFlex="45" fxLayout="column" [(ngModel)]="selectedCountry">
                                <mat-radio-button (change)="onSelectedCountry($event, 'all')" value="all" i18n="@@All">All</mat-radio-button>
                                <mat-radio-button (change)="onSelectedCountry($event, 'Polska')" value="pl">Poland</mat-radio-button>
                                <mat-radio-button (change)="onSelectedCountry($event, 'Česko')" value="cz"> Czech Rep.</mat-radio-button>
                                <mat-radio-button (change)="onSelectedCountry($event, 'Slovensko')" value="sk">Slovakia</mat-radio-button>
                                <mat-radio-button (change)="onSelectedCountry($event, 'Deutschland')" value="de">Germany</mat-radio-button>
                                <mat-radio-button (change)="onSelectedCountry($event, 'Magyarország')" value="hu">Hungary</mat-radio-button>
                                <mat-radio-button (change)="onSelectedCountry($event, 'other')" value="other">Others</mat-radio-button>
                        </mat-radio-group>
                </section>
        
                <div *ngIf="showRegions" fxFlex="45" fxLayout="column">
                        <app-filter-regions      fxLayout="column"
                                                 [selectedCountry]="selectedCountry" 
                                                 [countryChanged]="changeCountry.asObservable()"
                                                 (selectedRegions)="onSelectedRegions($event)">
                         </app-filter-regions> 
                 </div>
                </mat-expansion-panel>

                <mat-expansion-panel>
                        <mat-expansion-panel-header>
                          <mat-panel-title fxFlex="50">
                            <label i18n="@@FilterDate">Filter Date</label>
                          </mat-panel-title>
                          <mat-panel-description fxFlex="50">
                            <label *ngIf="isSelectedDate" i18n="@@SelectedDate">Strict Date</label>
                            <label *ngIf="!isSelectedDate" i18n="@@AllDates">All Dates</label>
                          </mat-panel-description>
                        </mat-expansion-panel-header>
      
                        <section id="dateFilter" >
                                <form #dateForm="ngForm">
                                <mat-form-field>
                                        <mat-label i18n="@@StartDate">Start Date</mat-label>
                                        <input matInput [matDatepicker]="startDatePicker"
                                                ngModel
                                                name="startDate"
                                                readonly 
                                                (dateInput)="onDateInput('start', $event)"
                                        >
                                        <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                                        <mat-datepicker #startDatePicker></mat-datepicker>
                                </mat-form-field>

                                <mat-form-field>
                                        <mat-label i18n="@@EndDate">End Date</mat-label>
                                        <input matInput
                                                ngModel
                                                readonly 
                                                name="endDate"
                                                [matDatepicker]="endDatePicker"
                                                (dateInput)="onDateInput('end', $event)"
                                        >
                                        <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                                        <mat-datepicker #endDatePicker></mat-datepicker>
                                </mat-form-field>
                            </form>
                        </section>
              
                      </mat-expansion-panel>
        </mat-accordion>
         
        <div fxFlex="100" fxLayout="column">

                <form fxLayoutAlign="end center" #searchForm="ngForm" (ngSubmit)="applySearch(searchForm.value.searchValue)">
                        <mat-form-field>
                            <mat-label i18n="MainWelcomeSearch| label for Main Welcome Search@@">Search Contest</mat-label>
                            <input matInput 
                                        ngModel
                                        required
                                        minlength="1"
                                        name="searchValue"
                                        type="text" 
                                        >
                        </mat-form-field>
                        <button mat-button [disabled]="searchForm.invalid" type="submit" i18n="@@Search">Search</button>
                    </form>
                <button *ngIf="searchForm.dirty || dateForm.dirty || selectedCountry != 'all'" mat-button (click)="onClearFilters()" i18n="@@Clean">Clean</button>
        </div> 

</div>

<ng-template #bigScreenFilters>

<div style="width: 100%; " fxLayout="row wrap" fxLayoutGap="2vh" fxLayoutAlign="center end">

    <div fxFlex="1 0 50%" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="1vh">

        <section id="contryFilter" >
                <mat-radio-group  fxLayout="row" [(ngModel)]="selectedCountry" fxLayoutGap="1vh" >
                        <mat-radio-button (change)="onSelectedCountry($event, 'all')" value="all" i18n="@@All">All</mat-radio-button>
                        <mat-radio-button (change)="onSelectedCountry($event, 'Polska')" value="pl">Poland</mat-radio-button>
                        <mat-radio-button (change)="onSelectedCountry($event, 'Česko')" value="cz"> Czech Rep.</mat-radio-button>
                        <mat-radio-button (change)="onSelectedCountry($event, 'Slovensko')" value="sk">Slovakia</mat-radio-button>
                        <mat-radio-button (change)="onSelectedCountry($event, 'Deutschland')" value="de">Germany</mat-radio-button>
                        <mat-radio-button (change)="onSelectedCountry($event, 'Magyarország')" value="hu">Hungary</mat-radio-button>
                        <mat-radio-button (change)="onSelectedCountry($event, 'other')" value="other">Others</mat-radio-button>
                </mat-radio-group>
        </section>

        <div *ngIf="showRegions" style="width: 30vw;" fxFlex="55" fxLayout="column" fxLayoutAlign="start start">
               <app-filter-regions      fxFlex="168px"  
                                        fxLayout="column wrap"
                                        fxLayoutAlign="start center"
                                        [selectedCountry]="selectedCountry" 
                                        [countryChanged]="changeCountry.asObservable()"
                                        (selectedRegions)="onSelectedRegions($event)">
                </app-filter-regions> 
        </div>
        
        </div>

<div fxFlex="1 0 50%" fxLayout="row" fxLayoutAlign="center end" fxLayoutGap="2vw">

        <div style="padding-top: 2vh;"  fxLayout="column">
                <section id="dateFilter" fxLayout="column" fxLayoutAlign="end center" >
                   <form #dateForm="ngForm" fxLayoutGap="2vw">
                        <mat-form-field>
                                <mat-label i18n="@@StartDate">Start Date</mat-label>
                                <input matInput [matDatepicker]="startDate"
                                        ngModel
                                        name="startDate"
                                        readonly 
                                        (dateInput)="onDateInput('start', $event)"
                                >
                                <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                                <mat-datepicker #startDate></mat-datepicker>
                        </mat-form-field>

                        <mat-form-field>
                                <mat-label i18n="@@EndDate">End Date</mat-label>
                                <input matInput
                                        ngModel
                                        name="endDate"
                                        readonly 
                                        name="endDatePicker"
                                        [matDatepicker]="endDate"
                                        (dateInput)="onDateInput('end', $event)"
                                >
                                <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
                                <mat-datepicker #endDate></mat-datepicker>
                        </mat-form-field>
                   </form>
                </section>
        </div> 

        <div fxLayout="column">
                <form fxLayoutAlign="center end" #searchForm="ngForm" (ngSubmit)="applySearch(searchForm.value.searchValue)">
                        <mat-form-field>
                            <mat-label i18n="MainWelcomeSearch| label for Main Welcome Search@@">Search Contest</mat-label>
                            <input matInput 
                                        ngModel
                                        required
                                        minlength="1"
                                        name="searchValue"
                                        type="text"
                                        >
                        </mat-form-field>
                        <button mat-button [disabled]="searchForm.invalid" type="submit" i18n="@@Search">Search</button>
                    </form>
                    <button *ngIf="searchForm.dirty || dateForm.dirty || selectedCountry != 'all'"  mat-button (click)="onClearFilters()" i18n="@@Clean">Clean</button>
        </div> 

</div>
</div>

</ng-template>


<mat-tab-group style="width: 100%;" color="warn" mat-align-tabs="center" fxFlex="100">
    
    <mat-tab>
                <ng-template mat-tab-label>
                        <span [ngClass]="{'small-tabs' : UIservice.isMobileSM}" [matBadge]="commingCount" [matBadgeOverlap]="false" matBadgeColor="primary" i18n="@@Comming">Comming</span>
                </ng-template>
            <app-contest-table [data]="commingContests.asObservable()"></app-contest-table>

    </mat-tab>
        
    <mat-tab>
                
                <ng-template mat-tab-label>
                        <span [ngClass]="{'small-tabs' : UIservice.isMobileSM}" [matBadge]="pendingCount" matBadgeOverlap="false" matBadgeColor="accent" i18n="@@Pending">Pending</span>
                </ng-template>

            <app-contest-table [data]="pedningContests.asObservable()"></app-contest-table>

    </mat-tab>

    <mat-tab>

                <ng-template mat-tab-label>
                        <span [ngClass]="{'small-tabs' : UIservice.isMobileSM}" [matBadge]="finishedCount" matBadgeOverlap="false" matBadgeColor="warn" i18n="@@Finished">Finished</span>
                </ng-template>

            <app-contest-table [data]="finishedContests.asObservable()"></app-contest-table>

    </mat-tab>
    
</mat-tab-group>

</div>