import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '../base.component';
import { Team } from 'src/app/data/models/Manage/team.model';
import { UIService } from 'src/app/shared/service/ui.service';
import { ProvideDataService } from '../../../services/provideDataService';

@Component({
  selector: 'app-teams',
  templateUrl: './teams.component.html',
  styleUrls: ['./teams.component.css']
})
export class TeamsComponent extends BaseComponent implements OnInit, OnDestroy {

  private keySubscription: Subscription;
  private signedTeamsSubscription: Subscription
  private reservationTeamsSubscription: Subscription
  private reserveListSubscription: Subscription

  signedTeamsSubject: Subject<Team[]> = new Subject<Team[]>()
  reservationTeamSubject: Subject<Team[]> = new Subject<Team[]>()
  reserveListSubject:  Subject<Team[]> = new Subject<Team[]>()

  public signedTeams: Team[]
  public reservationTeams: Team[]
  public reserveList: Team[]

  public signedTeamsReady: boolean = false
  public reservationTeamsReady: boolean = false
  public reserveListReady: boolean = false

  private action: string


  constructor(private route: ActivatedRoute, 
    private dataService: ProvideDataService,
    public UIservice: UIService) {

      super(route,dataService, UIservice)

      
      this.keySubscription = this.dataService.keyReady.asObservable().subscribe(bool => {
        if(bool)
        {
          this.signedTeamsSubscription = this.dataService.getSignedTeams()
          .subscribe(teams => {
            this.signedTeamsReady = true
            this.signedTeams = teams.sort((a,b) => a.station - b.station)
            this.signedTeamsSubject.next(this.signedTeams)
          })

      this.reservationTeamsSubscription = this.dataService.getReservationTeams()
          .subscribe(teams => {
            this.reservationTeamsReady = true
            this.reservationTeams = teams
            this.reservationTeamSubject.next(this.reservationTeams)
          })

      this.reserveListSubscription = this.dataService.getReserveListTeams()
      .subscribe(teams => {
        this.reserveListReady = true
        this.reserveList = teams
        this.reserveListSubject.next(this.reserveList)
      })
        }
       
      })

      this.action = route.snapshot.params['actionCode'];

     }

  ngOnInit() {

    if(!!this.action){

      switch (this.action){
        case 'ConfirmedReservation':{

        let data = {

            title: $localize`Reservation Confirmation`,
            content: $localize`<strong>Your Reservation has been confirmed !</strong><br>Please check your email box for fee payment details`
        }
        this.UIservice.openInfoDialog(data.title, data.content,true)
            
          break;
        }
        case 'errorConfirmedReservation':{

          let data = {
            title: $localize`Reservation Confirmation Error`,
            content: $localize`<strong>Error, can't confirm your Reservation. Please contact Carp Contest Administrator !</strong>`
        }
        this.UIservice.openInfoDialog(data.title, data.content,false)
          break;
        }
        case 'ConfirmedReserveList':{
          
          let data = {
            title: $localize`Reserve Team List Addition`,
            content: $localize`<strong>There is no enough free reservation place. Your application has been added to Reserve Team list.</strong>`
        }
        this.UIservice.openInfoDialog(data.title, data.content,false)

          break
        }
        default: {
          break;
        }
      }
    }

  }
  
  ngOnDestroy(): void {
    
    this.signedTeamsSubscription.unsubscribe()
    this.reserveListSubscription.unsubscribe()
    this.reservationTeamsSubscription.unsubscribe()
    this.keySubscription.unsubscribe()

  }
}
