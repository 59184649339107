<div fxLayout="column" fxLayoutAlign="start center">

    <div fxLayout="column" fxLayoutAlign="start center">
        
        <h1 class="mat-h1">Terms and Conditions</h1>
        
    </div>

    <div>
        //content
    </div>

</div>