<div class="Landing-Background">

    <div fxFlexFill  fxLayoutAlign="center center"  fxLayout="column">
    
        <div style="margin: 5vh;" fxFlex.lt-md="100%" fxLayout="column" fxLayoutAlign="center center">
    
        <mat-card  fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="2vh">
    
                <mat-card-title i18n="SignUp| Sign Up Header@@">Sign Up</mat-card-title>

                <mat-card-content  fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="2vh">
                <form fxLayout="column" [formGroup]="form" >
                        
                        

                    <mat-form-field appearance="outline">
                            <mat-label i18n="LoginEmailLabel| label for email in login form@@">Email address</mat-label>
                            <input  matInput 
                                    id="emailAdress"
                                    name="email"
                                    formControlName="email" 
                                    required>
                            <mat-error *ngIf="this.form.get('email').hasError">{{getErrorEmailValidator()}}</mat-error>
                    </mat-form-field>
        
                    <mat-form-field appearance="outline">
                            <mat-label i18n="LoginPasswordLabel| label for password in login form@@">Password</mat-label>
                            <input  matInput
                                    id="password"
                                    type="password"
                                    name="password"
                                    formControlName="password"
                                    autocomplete="on"  
                                    required>
                            <mat-error *ngIf="this.form.get('password').hasError">{{getErrorPasswordValidator()}}</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label i18n="LoginNameLabel| name sign In form@@">Name</mat-label>
                        <input  matInput
                                id="newUserName"
                                type="text"
                                name="name"
                                formControlName="name"
                                autocomplete="on"  
                                required>
                        <mat-error *ngIf="this.form.get('name').hasError">{{getErrorNameValidator()}}</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label i18n="LoginSurnameLabel| surname sign In form@@">Surname</mat-label>
                        <input  matInput
                                id="newUserSurname"
                                type="text"
                                name="surname"
                                formControlName="surname"
                                autocomplete="on"  
                                required>
                        <mat-error *ngIf="this.form.get('surname').hasError">{{getErrorSurnameValidator()}}</mat-error>
                    </mat-form-field>

                    
                    
                </form>

                <div style="max-width: 290px; white-space: normal;" fxLayout="row">
                <mat-checkbox [(ngModel)]="rulesChecked"></mat-checkbox>
                <p style="padding-left: 5px;" > <span i18n="RulesDeclaration| Rules Declaration@@" >
                        I hereby declare acceptance for</span><br>
                        <a i18n="PrivacyPolitics| Privacy Politics@@" target="_blank" [routerLink]="['/main/info/privacy-politics']">Privacy Politics</a>
                        ,
                        <a i18n="RODOPolitics| RODO Politics@@" target="_blank" [routerLink]="['/main/info/rodo']">RODO Politics</a>
                        
                        <!-- <a i18n="TermsAndConditions| Terms And Conditions@@" target="_blank" [routerLink]="['/main/info/terms-and-conditions']">Terms and Conditions</a> -->
                </p>
                </div>

                <re-captcha
                    [(ngModel)]="captcha"
                    name="captcha"
                    required
                ></re-captcha>

                <p *ngIf="this.error != null" class="error">
                    {{this.error}}
                </p>
                
                <button mat-stroked-button [disabled]="!form.valid || !rulesChecked || !captcha" 
                            type="submit"
                            (click)="onSubmit()"
                            i18n="SubmitButton| Submit Button@@">Submit</button>

                <div style="width: 100%; margin-top: 2vh;">
                        <label i18n="AlreadySigned| Already Signed? @@"> Already signed?</label>
                        &nbsp;
                        <a style="text-decoration: none;"[routerLink]="['/main/login']"  i18n="LogIn| Log In @@">Log In</a>

                </div>
            
        </mat-card-content>
        </mat-card>
    
        
    </div>
    </div>
    </div>