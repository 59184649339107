import { UIActions, START_LOADING_MAIN, STOP_LOADING_MAIN, START_LOADING_CHILD, STOP_LOADING_CHILD } from './ui.actions'

export interface State {
    isLoading: boolean;
    isLoadingChild: boolean;
    appState?: string;
};

const initialState: State = {
    isLoading: false,
    isLoadingChild: false,
    appState: 'landing'
};

export function uiReducer(state = initialState, action: UIActions){
    switch (action.type) {
        case START_LOADING_MAIN:
            return {
                isLoading: true,
                isLoadingChild: true
            }
        case STOP_LOADING_MAIN:
            return {
                isLoading: false,
                isLoadingChild: false
            }
        case START_LOADING_CHILD:
            return {
                    isLoading: false,
                    isLoadingChild: true
                }
        case STOP_LOADING_CHILD:
            return {
                    isLoading: false,
                    isLoadingChild: false
                }

        default:
            return state
    }
}

export const getIsLoadingMain = (state: State) => state.isLoading
export const getIsLoadingChild = (state: State) => state.isLoadingChild