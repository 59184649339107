import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RefereeGuard } from 'src/app/auth-module/guards/referee-guard';
import { MainComponent } from './main/main.component';
import { ContestComponent } from './contest/contest.component';
import { AccountComponent } from './account/account.component';


const routes: Routes = [
  {path: 'referee', canActivate: [RefereeGuard], children: [
    {path: 'account', component: AccountComponent},
    {path: 'contest', children: [
      {path: ':key', component: ContestComponent}
    ]},
    // {path: 'add-fish', component: AddContestComponent},
    {path: '',  component: MainComponent},
    {path: '**', redirectTo: 'referee'}
  ]},
  {path: 'sedzia', redirectTo: 'referee'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
