<div class="content-container" fxFlexFill fxLayout="column" fxLayoutAlign="start center">

    <h1 class="mat-h1" i18n="@@AllCatches">All Catches:</h1>

    <div>
        <h2 class="mat-h2">All Catches: {{allcatches}}:</h2>
        <h2 class="mat-h2">Total Weight: {{wholeWeight}}:</h2>
    </div>

    <div [hidden]="!this.dataSource" fxFlexFill fxLayoutAlign="center start" >
        <div style="margin: 2vh;" fxFlex.gt-md="95" fxFlex="100" class="mat-elevation-z8" fxLayout="column">
            
            <div fxLayout="row wrap" class="fiter-container" fxLayout.xs="column" fxLayoutGap.xs="1vh">
                <div fxFlex="100" fxFlex.xs fxFlexOrder="1">
                    
                    <mat-form-field  fxLayoutAlign="center center" fxLayoutAlign.xs="center center">
                        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
                    </mat-form-field>
            
                </div>
            
                <div fxFlex="100" fxFlex.xs fxFlexOrder="3">
            
                    <mat-paginator [length]="this.dataSource.data.length"
                                    [pageSize]="5"
                                    showFirstLastButtons
                                    (page)="pageEvent = $event">
                    </mat-paginator>
                    
                </div>
            
                <!-- *ngIf="!this.UIservice.isMobileXS; else cards"  -->
            <table 
                    class="mat-elevation-z8"
                    fxFlexOrder="2" 
                    fxFlex="100" 
                    mat-table
                    multiTemplateDataRows
                    [dataSource]="dataSource">
                
                    <ng-container matColumnDef="station">
                        <th fxFlex="30" fxLayoutAlign="center center" mat-header-cell *matHeaderCellDef i18n="PegDevice">Peg:&nbsp;</th>
                        <td fxFlex="30" fxLayoutAlign="center center" mat-cell *matCellDef="let element"> {{element.station}} </td>
                      </ng-container>

                      <ng-container matColumnDef="TotalFish">
                        <th fxFlex="35" fxLayoutAlign="center center" mat-header-cell *matHeaderCellDef i18n="@@TotalFish">Total Fish</th>
                        <td fxFlex="35" fxLayoutAlign="center center" mat-cell *matCellDef="let element">{{element.totalFish}}</td>
                      </ng-container>

                      <ng-container matColumnDef="TotalWeight">
                        <th fxFlex="35" fxLayoutAlign="center center" mat-header-cell *matHeaderCellDef i18n="@@TotalWeight">Total Weight</th>
                        <td fxFlex="35" fxLayoutAlign="center center" mat-cell *matCellDef="let element">{{element.totalWeight}}</td>
                      </ng-container>

                      <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                      <ng-container matColumnDef="expandedDetail">
                        <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
                            <div fxLayout="column" class="team-element-detail"
                               [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                            
                            <div class="angler-element-diagram" fxLayout="row" *ngFor="let fish of element.catches; let i = index;">
                              <div fxFlex class="angler-element-role" fxLayoutAlign="center center" > {{fish.date | date:"dd/MM/yyyy HH:mm"}} </div>
                              <div fxFlex class="angler-element-detail" fxLayoutAlign="center center" > 
                                  <span i18n="@@Angler">Angler:</span>
                                  &nbsp;{{fish.anglerName}}&nbsp;{{fish.anglerSurname}} </div>
                              <div fxFlex class="angler-element-detail" fxLayoutAlign="center center" >
                                  <span i18n="@@Photo">Photo:</span> 
                                  &nbsp;
                                  <mat-icon class="clickableIcon" (click)="openFishPhoto(fish.photoUrl)">remove_red_eye</mat-icon>
                              </div>
                              <div fxFlex class="angler-element-detail" fxLayoutAlign="center center" >
                                <span i18n="@@124bb9a84d8c356cb36b6f68d6de9fd50b68b59d">Type</span>  
                                :&nbsp;{{fish.fishType}}</div>
                              <div fxFlex class="angler-element-detail" fxLayoutAlign="center center" >
                                <span i18n="@@FishWeight">Weight:</span>
                                &nbsp;{{fish.weight}}&nbsp;kg</div>
                              <div fxFlex fxLayoutAlign="center center" >
                                <button *ngIf="!fish.ig_published" mat-button color="primary" (click)="onInstagramPublish(fish.id)" i18n="@@ig_publish">
                            Instagram
                            </button>
                                <button mat-button color="accent"  i18n="Edit button| Edit Button@@" (click)="onFishEdit(fish.id)" i18n="@@edit">Edit</button>
                                <button mat-button color="warn"  i18n="Delete button| Delete Button@@" (click)="onFishDelete(fish.id)" i18n="@@delete">Delete</button>                  
                              </div>
                            </div>                            

                          </div>
                        </td>
                      </ng-container>
                    
                      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                      <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
                          class="example-element-row"
                          [class.example-expanded-row]="expandedElement === element"
                          (click)="expandedElement = expandedElement === element ? null : element">
                      </tr>
                      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>


            </table>
            
            <!-- <ng-template #cards>
        
                <div fxFlexFill fxLayout="column" fxLayoutGap="1vh" fxFlexOrder.xs="2">
                    <mat-accordion *ngFor="let element of this.cardData | async">
                        <mat-expansion-panel>
                          <mat-expansion-panel-header fxLayout="row">  
                            
                            <mat-panel-title fxFlex="30">
                                                 
                             <label i18n="@@PegDevice">Peg:&nbsp;</label>
                                &nbsp;{{element.station}}
                
                            </mat-panel-title>
                            
                            <mat-panel-description fxFlex="60">
                                
                                <label fxFlex="50">{{element.anglers[0].surname}}</label>
                                <label fxFlex="50">{{element.anglers[1].surname}}</label>
              
                            </mat-panel-description>
                          </mat-expansion-panel-header>
             
                          <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="2vh">
                            
                                    <div class="mat-h4" style="font-weight: bold;" i18n="@@TeamElementPromo">Team:&nbsp;{{element.promoTeam}}</div>
                          
                            <div fxLayout="row wrap" fxLayoutAlign="start center">

                                    <div fxFlex="100" fxLayout="column" fxLayoutAlign="space-between center"  fxLayoutGap="2vh">
                                
                                        <div style="width: 100%;" fxLayout="row nowrap" fxLayoutAlign="start center">
                                            <mat-icon>phone</mat-icon>  
                                            <a class="cardLink" [attr.href]="'tel:' + element.privateData.leaderPhone">{{element.privateData.leaderPhone}}</a>
                                        </div>
                                        <div style="width: 100%;" fxLayout="row nowrap"  fxLayoutAlign="start center">
                                            <mat-icon>email</mat-icon>  
                                            <a class="cardLink" [attr.href]="'mailto:' + element.privateData.leaderEmail">{{element.privateData.leaderEmail}}</a>
                                        </div>
                                        
                                        <div fxLayout="row wrap" fxLayoutAlign="center end">
                                            <button mat-stroked-button style="color:#23c268;" (click)="onStationAssign(element.id)" i18n="@@assignStation" >Assign Station</button>
                                            <button mat-stroked-button color="primary" (click)="onTeamEdit(element.id)" i18n="@@74613218ed8e82d7b909dd2667add4d2a90a9313" >Edit Team</button>
                                            <button mat-stroked-button color="warn" (click)="onTeamDelete(element.id)" i18n="@@deleteTeam">Delete Team</button>
                                        </div>  
            
                                    </div>
                            </div>
                            
                                    <div class="mat-h4" style="font-weight: bold;" i18n="@@Anglers">Anglers:&nbsp;</div>

                          <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="2vh">
                            

                            <div style="width: 100%;" fxLayout="row wrap" fxLayoutAlign="space-between center" *ngFor="let angler of element.anglers; let i = index;" >
                                
                                <div fxFlex="30" style="font-weight: bold;">
                                    {{angler.teamRole}}
                                </div>
                                <div fxFlex="60">
                                    {{angler.name}}&nbsp;{{angler.surname}}
                                </div>
                                <div fxFlex="10">
                                    {{angler.country}}
                                </div>
                                <div fxFlex="100" style="padding-top: 1vh;" fxLayoutAlign="end center">
                                    <button mat-stroked-button color="primary" (click)="onAnglerEdit(element.id,i)" i18n="@@7893797581fe69150dc800615420ab3dbbb16514">Edit</button>
                                    <button mat-stroked-button color="warn" (click)="onAnglerDelete(element.id,i)" i18n="@@delete">Delete</button>
                                </div>  
    
                            </div>

                          </div>

            
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
            </ng-template> -->
    
            </div>
            
        </div>
    
    
    </div>

</div>