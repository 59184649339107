<div *ngIf="this.authService.isAuthenticated; else notAuthenticated" fxFlex class="navigation-items" fxLayout="row" fxLayoutAlign="end center">

        <label style="font-size: 14px;" i18n="@@WelcomeMenu">Welcome,</label>
        &nbsp;
        <span style="font-size: 14px;" *ngIf="this.serveUser.user.userData">{{this.serveUser.user.userData.name}}</span>

        <button role="navigation"
                mat-button  
                (click)="onlogOut()"
                i18n="@@LogOutMenu"
                >
                
          Log Out
          &nbsp;
          <mat-icon style="color: red;">input</mat-icon>
          </button>                          

        </div>
    <ng-template #notAuthenticated>
        
        <div fxFlex class="navigation-items" fxLayout="row" fxLayoutAlign="end center">
            <button role="navigation" 
                    id="signUpButton" 
                    mat-button 
                    routerLink="main/signup"
                    i18n="@@SignUpMenu" 
                    >
            Sign Up
            &nbsp;
            <mat-icon>how_to_reg</mat-icon>
            </button>
    
    
      
            <button role="navigation"
                    mat-button  
                    routerLink="main/login"
                    i18n="@@LogInMenu">
              Log In 
              &nbsp;
              <mat-icon>input</mat-icon>
              </button>                          
        </div>
    </ng-template>
