<ngx-charts-bar-vertical
[view]="view"
[scheme]="colorScheme"
[results]="single"
[gradient]="gradient"
[xAxis]="showXAxis"
[yAxis]="showYAxis"
[legend]="showLegend"
[showXAxisLabel]="showXAxisLabel"
[showYAxisLabel]="showYAxisLabel"
[xAxisLabel]="xAxisLabel"
[yAxisLabel]="yAxisLabel">
</ngx-charts-bar-vertical>
