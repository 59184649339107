<div class="content-container" fxLayout="column" fxLayoutAlign="start center" fxLayoutAlign.gt-sm="center center" fxLayoutGap="2vh">

    <h1 class="mat-h1" i18n="@@selectContest">Select Contest !</h1>
    
    
        <div [ngClass]="{'positionCard': !UIservice.isMobileSM, 'smallPositionCard': UIservice.isMobileSM}" fxFlexFill fxLayout="column" fxLayoutAlign="center center">
          
                <mat-form-field>
                    <mat-label i18n="@@Select">Select</mat-label>
                    <mat-select [(value)]="contestIndex">
                      <mat-option *ngFor="let contest of this.contestList$ | async" [value]="contest.key">
                            {{contest.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
        
          <button [disabled]="this.contestIndex === null"
                  fxFlexFill
                  color="primary"
                  mat-stroked-button
                  i18n="@@07318360fa15bce0d62e6949fd071572d482372f"
                  (click)="onSubmit()"
          >
          Submit
          </button>

            
        </div>

        

</div>