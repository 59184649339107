import { APPactions, REFEREE_STATE,  ADMIN_STATE, ORGANISER_STATE, LANDING_STATE, CONTEST_STATE} from './app.actions'

export interface State {
    isOrganiser: boolean;
    isReferee: boolean;
    isAdmin: boolean;
    isContest: boolean; 
    isMain: boolean;
    AppName: string
};

const initialState: State = {
    isOrganiser: false,
    isReferee: false,
    isAdmin: false,
    isContest: false,
    isMain: true,
    AppName: 'Initial'
};

export function appReducer(state = initialState, action: APPactions){
    switch (action.type) {
        case REFEREE_STATE:
            return {
                isOrganiser: false,
                isReferee: true,
                isAdmin: false,
                isMain: false,
                isContest: false,
                AppName: 'Referee App'
                }
        case ADMIN_STATE:
            return {
                isOrganiser: false,
                isReferee: false,
                isAdmin: true,
                isMain: false,
                isContest: false,
                AppName: 'Admin App'
                }
        case ORGANISER_STATE:
            return {
                isOrganiser: true,
                isReferee: false,
                isAdmin: false,
                isMain: false,
                isContest: false,
                AppName: 'Organiser App'
                }
        case LANDING_STATE:
            return {
                isOrganiser: false,
                isReferee: false,
                isAdmin: false,
                isMain: true,
                isContest: false,
                AppName: 'Landing App'
                }
        case CONTEST_STATE:
            return {
                isOrganiser: false,
                isReferee: false,
                isAdmin: false,
                isMain: false,
                isContest: true,
                AppName: 'Contest App'
                }
        default:
            return state
    }
}

export const getIsReferee = (state: State) => state.isReferee
export const getIsAdmin = (state: State) => state.isAdmin
export const getIsLanding = (state: State) => state.isMain
export const getIsContest = (state: State) => state.isContest
export const getIsOrganiser = (state: State) => state.isOrganiser
export const getAppName = (state: State) => state.AppName