<div fxLayout="column" fxLayoutAlign="start center" style="margin: 2vh;">

    <div fxLayout="column" fxLayoutAlign="start center">
        
        <h1 class="mat-h1" i18n="@@a5c0f27df0d9b97aee4436b45198a64483fd5aaa">RODO Politics</h1>
        
    </div>

    <div style="max-width: 80%;">
        <h2 class="mat-h2" style="text-align: center;">Niniejszy dokument jest rzeczowym uzupełnieniem Polityki Prywatności serwisów www.carp-contests.com oraz www.carp-contest.pl</h2>

        <ol>
            <li class="mat-h3" style="padding-top: 1vh; font-weight: bold;">Postanowienia Ogólne</li>

                <ol>
                    <li>Administratorem danych Carp Contest - Jacek Adamarek ADAMAR z siedzibą w 44-100 Gliwice, ul. Zwycięstwa 10  NIP: 6312568311. Ochrona danych odbywa się zgodnie z wymogami powszechnie obowiązujących przepisów prawa, a ich przechowywanie ma miejsce na zabezpieczonych serwerach.</li>
                    <li>Dla interpretacji terminów stosuje się słowniczek Regulaminu lub tak jak zostało to opisane w Polityce Prywatności (jeżeli wynika to bezpośrednio z opisu).</li>
                    <li>Na potrzeby lepszego odbioru Polityki Prywatności termin „Użytkownik” zastąpiony został określeniem „Ty”,  „Administrator” – „My”. Termin „RODO” oznacza Rozporządzenie Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE.</li>
                    <li>Szanujemy prawo do prywatności i dbamy o bezpieczeństwo danych. W tym celu używany jest m.in. bezpieczny protokół szyfrowania komunikacji (SSL).</li>
                    <li>Dane osoby fizycznej lub osobowości prawnej podawane w formularzach rejestracji Organizatora w Serwisie Carp Contest są traktowane jako ogólnodostępne i są dostępne na stronie zawodów wędkarskich których jest Organizatorem</li>
                    <li>Dane osobowe (Imię i Nazwisko) podawane w formularzach rejestracji Uczestnika, Drużyny i Sędziego oraz zdjęcia wykonane podczas zawodów wędkarskich są ogólnodostępne na stronie zawodów co do których nastąpiła rejestracja.</li>
                    <li>Pozostałem dane osobowe są traktowane jako poufne i nie są przekazywane osobom nieuprawnionym.</li>
                </ol>

            <li class="mat-h3" style="padding-top: 1vh; font-weight: bold;">Administrator Danych</li>
                
                <ol>
                    <li>Usługodawca jest administratorem danych swoich klientów. Oznacza to, że jeśli posiadasz Konto na naszej stronie lub byłeś zarejestrowanym Uczestnikiem zawodów, to przetwarzamy Twoje dane jak: imię, nazwisko, adres e-mail, numer telefonu, zdjęcia.</li>
                    <li>Twoje dane są dostępne dla Organizatora i Sędziów zawodów wędkarskich do których dokonałeś zapisu w formie Uczestnika lub Drużyny.</li>
                    <li>Dane osobowe przetwarzane są:</li>

                        <ol type="a">
                            <li>zgodnie z przepisami dotyczącymi ochrony danych osobowych,</li>
                            <li>zgodnie z wdrożoną Polityką Prywatności,</li>
                            <li>w zakresie i celu niezbędnym do nawiązania, ukształtowania treści Umowy, zmiany bądź jej rozwiązania oraz prawidłowej realizacji Usług świadczonych drogą elektroniczną,</li>
                            <li>w zakresie i celu niezbędnym do wypełnienia uzasadnionych interesów (prawnie usprawiedliwionych celów), a przetwarzanie nie narusza praw i wolności osoby, której dane dotyczą:</li>
                            
                                <ul>
                                    <li>w zakresie i celu zgodnym ze zgodą wyrażoną przez Ciebie jeśli zapisałeś się na zawody wędkarskie w formie Uczestnika lub Drużyny,</li>
                                    <li>w zakresie i celu zgodnym z wyrażoną przez Ciebie zgodą jeżeli zapisałeś się na zawody wędkarskie w formie Sędziego,</li>
                                    <li>w zakresie i celu zgodnym z wyrażoną przez Ciebie zgodą jeżeli jesteś Organizatorem zawodów wędkarskich.</li>
                                </ul>
                        </ol>   

                    <li>Każda osoba, której dane dotyczą (jeżeli jesteśmy ich administratorem) ma prawo dostępu do danych, sprostowania, usunięcia lub ograniczenia przetwarzania, prawo sprzeciwu, prawo wniesienia skargi do organu nadzorczego.</li>
                    <li>Kontakt z osobą nadzorującą przetwarzanie danych osobowych w organizacji jest możliwy drogą elektroniczną pod adresem e-mail: administracja@carp-contest.pl</li>
                    <li>Zastrzegamy sobie prawo do przetwarzania Twoich danych po rozwiązaniu Umowy lub cofnięciu zgody tylko w zakresie na potrzeby dochodzenia ewentualnych roszczeń przed sądem lub jeżeli przepisy krajowe albo unijne bądź prawa międzynarodowego obligują nas do retencji danych.</li>
                    <li>Usługodawca ma prawo udostępniać dane osobowe Użytkownika oraz innych jego danych podmiotom upoważnionym na podstawie właściwych przepisów prawa (np. organom ścigania).</li>
                    <li>Usunięcie danych osobowych może nastąpić na skutek cofnięcia zgody bądź wniesienia prawnie dopuszczalnego sprzeciwu na przetwarzanie danych osobowych.</li>
                    <li>Usługodawca nie udostępnia danych osobowych innym podmiotom aniżeli upoważnionym na podstawie właściwych przepisów prawa.</li>
                    <li>Wdrożyliśmy pseudonimizację, szyfrowanie danych oraz mamy wprowadzoną kontrolę dostępu dzięki czemu minimalizujemy skutki ewentualnego naruszenia bezpieczeństwa danych.</li>
                    <li>Dane osobowe przetwarzają osoby wyłącznie upoważnione przez nas albo przetwarzający, z którymi ściśle współpracujemy – Organizator zawodów lub Sędzia</li>
                                       
                </ol>
            
            <li class="mat-h3" style="padding-top: 1vh; font-weight: bold;">Pliki cookies</li>

                <ol>
                    <li>Serwis używa cookies. Są to niewielkie pliki tekstowe wysyłane przez serwer www i przechowywane przez oprogramowanie komputera przeglądarki. Kiedy przeglądarka ponownie połączy się ze stroną, witryna rozpoznaje rodzaj urządzenia, z którego łączy się użytkownik. Parametry pozwalają na odczytanie informacji w nich zawartych jedynie serwerowi, który je utworzył. Cookies ułatwiają więc korzystanie z wcześniej odwiedzonych witryn.</li>
                    <li>Gromadzone informacje dotyczą adresu IP, typu wykorzystywanej przeglądarki, języka, rodzaju systemu operacyjnego, dostawcy usług internetowych, informacji o czasie i dacie, lokalizacji oraz informacji przesyłanych do witryny za pośrednictwem formularza kontaktowego.</li>
                    <li>Zebrane dane służą do monitorowania i sprawdzenia, w jaki sposób użytkownicy korzystają z naszych witryn, aby usprawniać funkcjonowanie serwisu zapewniając bardziej efektywną i bezproblemową nawigację. Monitorowania informacji o użytkownikach dokonujemy korzystając z narzędzia Google Analytics, które rejestruje zachowanie użytkownika na stronie.</li>
                    <li>Cookies identyfikuje użytkownika, co pozwala na dopasowanie treści witryny, z której korzysta, do jego potrzeb. Zapamiętując jego preferencje, umożliwia odpowiednie dopasowanie skierowanych do niego reklam. Stosujemy pliki cookies, aby zagwarantować najwyższy standard wygody naszego serwisu, a zebrane dane są wykorzystywane jedynie wewnątrz firmy Carp Contest - Jacek Adamarek ADAMAR z siedzibą w 44-100 Gliwice, ul. Zwycięstwa 10  NIP: 6312568311 w celu optymalizacji działań.</li>
                    <li>Na naszej witrynie wykorzystujemy następujące pliki cookies: </li>

                        <ol type="a">
                            <li>„niezbędne” pliki cookies, umożliwiające korzystanie z usług dostępnych w ramach serwisu, np. uwierzytelniające pliki cookies wykorzystywane do usług wymagających uwierzytelniania w ramach serwisu</li>
                            <li>pliki cookies służące do zapewnienia bezpieczeństwa, np. wykorzystywane do wykrywania nadużyć w zakresie uwierzytelniania w ramach serwisu</li>
                            <li>„wydajnościowe” pliki cookies, umożliwiające zbieranie informacji o sposobie korzystania ze stron internetowych serwisu</li>
                            <li>„funkcjonalne” pliki cookies, umożliwiające „zapamiętanie” wybranych przez użytkownika ustawień i personalizację interfejsu użytkownika, np. w zakresie wybranego języka lub regionu, z którego pochodzi użytkownik, rozmiaru czcionki, wyglądu strony internetowej itp</li>
                            <li>„reklamowe” pliki cookies, umożliwiające dostarczanie użytkownikom treści reklamowych bardziej dostosowanych do ich zainteresowań</li>
                        </ol>
                    
                    <li>Dodatkowe dane osobowe, jak adres e-mail, zbierane są jedynie w miejscach, w których użytkownik wypełniając formularz wyraźnie wyraził na to zgodę. Powyższe dane zachowujemy i wykorzystujemy tylko do potrzeb niezbędnych do wykonania danej funkcji.</li>
                    <li>Użytkownik w każdej chwili ma możliwość wyłączenia lub przywrócenia opcji gromadzenia cookies poprzez zmianę ustawień w przeglądarce internetowej. Instrukcja zarządzania plikami cookies jest dostępna na stronie <a href="http://www.allaboutcookies.org/manage-cookies" style="text-decoration: none;" target="_blank">http://www.allaboutcookies.org/manage-cookies</a></li>
                </ol>

        </ol>
    </div>

</div>