import { Component, OnInit, OnDestroy } from '@angular/core';
import { BaseOrganiserComponent } from '../baseOrganiser.component';
import { UIService } from 'src/app/shared/service/ui.service';
import { ContestDataService } from '../services/contest-data.service';
import { ActivatedRoute, Router } from '@angular/router';

import { Subscription} from 'rxjs';
import { Contest } from 'src/app/data/models/Manage/contest.model';

@Component({
  selector: 'app-contest',
  templateUrl: './contest.component.html',
  styleUrls: ['./contest.component.css']
})
export class ContestComponent extends BaseOrganiserComponent implements OnInit, OnDestroy {

  isPremium: boolean = false
  contestName: string = ''
  colorBaseOnType: string = 'primary'
  constructor(private UIservice: UIService,
              private contestService: ContestDataService,
              private route: ActivatedRoute,
              private router: Router) {
    super(UIservice);

    let routeKey = this.route.snapshot.params['key']

        if(routeKey != this.contestService.contestKey){
          this.router.navigate['/organiser']
        }

    

  }

  ngOnInit() {
    this.contestService.challenge$.subscribe(challenge => {
      this.contestName = challenge.title
      if (challenge.Premium)
      {
        this.isPremium = challenge.Premium.isPremium
        this.UIservice.isPremium = challenge.Premium.isPremium
        this.colorBaseOnType = challenge.Premium.isPremium ? 'accent' : 'primary';
      }
      })
  }


  ngOnDestroy(){

  }

}
