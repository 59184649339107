import { Component, OnInit, OnDestroy } from '@angular/core';
import { BaseOrganiserComponent } from '../../baseOrganiser.component';
import { UIService } from 'src/app/shared/service/ui.service';
import { ContestDataService } from '../../services/contest-data.service';
import { Challenge } from 'src/app/data/models/Manage/challenge.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-main-challenge',
  templateUrl: './main-challenge.component.html',
  styleUrls: ['./main-challenge.component.css']
})
export class MainChallengeComponent extends BaseOrganiserComponent implements OnInit, OnDestroy {
 

  challenge: Challenge
  editMode: string = 'none'

  dataSubscription: Subscription
  constructor(private UIservice: UIService,
              private contestData: ContestDataService) {
    super(UIservice);


      this.contestData.getChallenge()


  }

  ngOnInit() {
      this.dataSubscription = this.contestData.challenge$
                                  .subscribe(challenge => {
                                    this.challenge = challenge
                                  })

  }

  onEdit(mode: string){
    
    if(mode){
      this.editMode = mode
    }

  }


  ngOnDestroy(): void {
    this.dataSubscription.unsubscribe()
  }
}
