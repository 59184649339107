import { UserData } from 'src/app/auth-module/user';

export class Referee {
    id?: string;    
    confirmed?: string;
    phone?: string;
    email?: string;
    userData?: UserData
    stations?: string;
    active?: boolean;
    name?: string;

}