import { Component, OnInit, Input } from '@angular/core';
import { Challenge } from 'src/app/data/models/Manage/challenge.model';

@Component({
  selector: 'app-premium',
  templateUrl: './premium.component.html',
  styleUrls: ['./premium.component.css']
})
export class PremiumComponent implements OnInit {

  @Input() challenge: Challenge
  
  constructor() { }

  ngOnInit() {
  }

}
