import { Component, OnInit, ViewChild } from '@angular/core';
import { Message } from 'src/app/data/models/Manage/message.model';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription, Observable } from 'rxjs';
import { ContestDataService } from '../../../services/contest-data.service';
import { UIService } from 'src/app/shared/service/ui.service';

@Component({
  selector: 'app-messages-list',
  templateUrl: './messages-list.component.html',
  styleUrls: ['./messages-list.component.css']
})
export class MessagesListComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;


  dataSubscription: Subscription

  cardData: Observable<Message[]>
  allMessages: Message[]


  columnsToDisplay= ['createdBy', 'createdAt', 'type', 'text' ];
  dataSource = new MatTableDataSource<Message>()
  expandedElement: Message | null;
  pageEvent: PageEvent;

  constructor(private contestData: ContestDataService,
              public UIservice: UIService) {


      this.contestData.getMessages()

   }

  ngOnInit() {

    this.dataSubscription = this.contestData.messages$.subscribe(array => {
      if(array!=null){
        this.allMessages = array.sort((a,b) => b.createdAt - a.createdAt)
       this.assignDataToDataSource(this.allMessages)
      }
    
  })

  this.dataSource.paginator = this.paginator;
  this.cardData = this.dataSource.connect()

  }

  assignDataToDataSource(_array: Message[]){

    this.dataSource.data = _array
    this.dataSource.paginator = this.paginator;
    this.cardData = this.dataSource.connect()
}

// applyFilter(_key: string){

//   let searchResults = []
//   let searchValue = _key.trim()
//   console.log(searchValue)

//   let lala

//   if(this.allMessages && searchValue != ''){

//     searchResults =  this.allMessages.filter(object => {
      
//       let values: string = ''
//       for (const property in object)
//       {
//         values += object[property]
//       }
//       lala = values
//       return values.indexOf(searchValue) != -1

//     })
//     console.log(lala)
//     this.assignDataToDataSource(searchResults)
//   }

//   else 
//   {
//     this.assignDataToDataSource(this.allMessages)
//   }



// }

ngOnDestroy(): void {
  this.dataSubscription.unsubscribe()
 }

}
