import { Injectable } from '@angular/core';
import { ServeUserService } from 'src/app/auth-module/service/serveUser.service';
import { ContestService } from 'src/app/core-module/entityServices/contest.service';
import { AuthService } from 'src/app/auth-module/service/auth.service';
import { Challenge, Links } from 'src/app/data/models/Manage/challenge.model';
import { Message } from 'src/app/data/models/Manage/message.model';
import { Partner } from 'src/app/data/models/Manage/partner.model';
import { Angler } from 'src/app/data/models/Manage/angler.model';
import { Team } from 'src/app/data/models/Manage/team.model';
import { Reservation } from 'src/app/data/models/Manage/reservation.model';
import { GlobalsService } from 'src/app/core-module/entityServices/serviceGlobals';
import { TeamService } from 'src/app/core-module/entityServices/team.service';
import { AnglerService } from 'src/app/core-module/entityServices/angler.service';
import { Observable } from 'rxjs';



@Injectable()
export class ManageContestDataService {

    challengeKey: string
    rulesFile: File
    posterFile: File
    spotsFile: File


    constructor(private serveUser: ServeUserService,
        private contestService: ContestService,
        private authService: AuthService,
        private teamService: TeamService,
        private anglerService: AnglerService,
        private globals: GlobalsService){ }


        addNewContest(_challenge: Challenge, alias: string){

            const userUID = this.authService.getCurrentUserUID()

            return this.contestService.setNewChallenge(_challenge, alias, userUID)

        }

        updateNewContestLinks(_links: Links, challengeKey: string)
        {

        }

        updateChallengeMainDetails(mainDetials: object){

            const _key = this.challengeKey

            if(mainDetials && _key)
            {
                return this.contestService.updateContestMainDetails(_key,mainDetials)
            }
        }

        updateChallengeChild(_childName: string, _updateValue: object){

            const _key = this.challengeKey

            var hasNestedChild: boolean = false

            for (const element in _updateValue)
            {
                if(_updateValue.hasOwnProperty(element))
                {
                    for (const childElement in _updateValue[element])
                    {
                      if(_updateValue[element].hasOwnProperty(childElement)){
                          hasNestedChild = true
                      }
                    }
                }
            }
            return this.contestService.updateContestChild(_key, _childName,_updateValue, hasNestedChild)
        }

        writeContestMessage(message: Message){

            const _key = this.challengeKey

            return this.contestService.setObject(_key, "messages", message)

        }

        async uploadMessagePhoto(_file: File ){

          const _key = this.contestService.contestKey
          const collection = _key + "/contestFiles/Messages/"
          var downloadLink

          await this.contestService.uploadFile(_file, collection)
                              .then(async () => { await this.contestService.getDownloadURL(_file.name, collection)
                                                  .then((value) => { downloadLink = value})})
                              .catch((e) => { })

          return downloadLink
      }

        async  upsertPartner(partner:Partner, _photo?: File){

            const _key = this.challengeKey

            if(_photo)
            {
                const partnerPath = "shared/Partners/"
                await this.contestService.uploadFile(_photo, partnerPath)
                                .then(async() =>{ await this.contestService.getDownloadURL(_photo.name, partnerPath)
                                                .then((value) => { partner.logoLink = value})  })
                                .catch((e) => {console.log(e.message)})
            }


            if(partner.id){
                const objectID = partner.id
                partner.id = null

                return this.contestService.setObject(_key, "partners/" + objectID, partner)
            }
            else
            {
                return this.contestService.setObject(_key, "partners", partner)
            }
        }

        deletePartner(partnerID: string){
            const _key = this.challengeKey

            return this.contestService.removeObject(_key, "partners", partnerID)

        }

        deleteFish(fishID: string){
            const _key = this.challengeKey

            return this.contestService.removeObject(_key, 'catches', fishID)
        }

        async uploadContestInfoFiles(_file: File){

            const _key = this.challengeKey
            const collection = this.challengeKey + "/contestFiles"

            var downloadLink

            await this.contestService.uploadFile(_file, collection)
                        .then(async () => { await this.contestService.getDownloadURL(_file.name, collection)
                                            .then((value) => { downloadLink = value})})
                        .catch((e) => { })
            console.log(downloadLink)
            return downloadLink
        }

        confirmRefereeReservation(refereeToConfirmKey: string){

            const confirmAction = this.contestService.callWebFunction('confirmRefereeReservation')

          return confirmAction({refereeKey: refereeToConfirmKey, contestKey: this.challengeKey})
        }

        confirmReservationPayment(reservationKey: string){
            const confirmAction = this.contestService.callWebFunction('confirmReservationPayment')

            return confirmAction({reservationKey: reservationKey, contestKey: this.challengeKey})
        }

        changeFromReserveToReservation(reserveKey: string){
            const confirmAction = this.contestService.callWebFunction('changeFromReserveToReservation')

            return confirmAction({reserveKey: reserveKey, contestKey: this.challengeKey})
        }

        manageSignUp(actionType: string){

            switch(actionType)
            {
                case 'Open': {
                    const action = this.contestService.callWebFunction('openSignUp')
                    return action({contestKey: this.challengeKey})
                }
                case 'Close': {
                   const action = this.contestService.callWebFunction('closeSignUp')
                    return action({contestKey: this.challengeKey})
                }
                default: {
                    break;}
            }
        }

        callDrawingSequence(){
            const action = this.contestService.callWebFunction('onStartAssignDrawSequence')

            return action({contestKey: this.challengeKey})
        }

        deleteRefereeReservation(refereeToDelete: string){
            const deleteAction = this.contestService.callWebFunction('deleteRefereeReservation')

            return deleteAction({refereeReservationKey: refereeToDelete, contestKey: this.challengeKey})
        }

        deleteReferee(refereeToDelete: string){
            const deleteAction = this.contestService.callWebFunction('deleteReferee')

            return deleteAction({refereeKey: refereeToDelete, contestKey: this.challengeKey})
        }

        assignRefereeStations(id: string, stations: string){
            const assignAction = this.contestService.callWebFunction('refereeAssignStations')

            return assignAction({refereeKey: id, stations: stations, contestKey: this.challengeKey})
        }

        deleteTeamMemberReservation(reservationKey: string, memberKey: string){

            return this.anglerService.deleteReservationAngler(reservationKey,memberKey,this.challengeKey)
        }

        deleteTeamReservation(reservationKey: string){

            return this.teamService.deleteReservation(reservationKey, this.challengeKey)
        }

        deleteTeamMember(teamKey: string, memberKey: string): Observable<any>{

            return this.anglerService.deleteTeamAngler(teamKey,memberKey,this.challengeKey)
        }

        deleteTeam(teamKey: string){

            return this.teamService.deleteTeam(teamKey,this.challengeKey)
        }

        updateAngler(teamID: string, anglerID: string, angler: Angler, updateType: string){

            this.anglerService.teamsPath = this.globals.getTeamsPathPrivate()
            this.anglerService.reservationPath = this.globals.getReservationPathPrivate()

            switch (updateType){
                case 'team':
                    {
                       return this.anglerService.updateTeamAngler(angler, teamID,anglerID)
                    }
                case 'reservation':
                    {
                        return this.anglerService.updateReservationAngler(angler, teamID,anglerID)
                    }
                default:
                {
                    return null
                }
            }

        }

        updateTeam(teamID: string, team: Team, updateType: string){

            this.teamService.teamsPath = this.globals.getTeamsPathPrivate()
            this.teamService.reservationPath = this.globals.getReservationPathPrivate()

            switch (updateType){
                case 'team':
                    {
                       return this.teamService.updateTeam(team, teamID)
                    }
                case 'reservation':
                    {
                        return this.teamService.updateTeamReservation(team,teamID)
                    }
                default:
                {
                    return null
                }
            }

        }

        addTeam(team: Team){
            this.teamService.teamsPath = this.globals.getTeamsPathPrivate()

            return this.teamService.setTeam(team)
        }

        addReservation(reservation){
            this.teamService.reservationPath = this.globals.getReservationPathPrivate()

            return this.teamService.setTeamReservation(reservation)
        }

        assignStation(id: string, station: number){
            this.teamService.teamsPath = this.globals.getTeamsPathPrivate()

            return this.teamService.assignTeamStation(id,station)
        }

        instagramPublishPhoto(catchKey: string){

            return this.contestService.instagramPublishPhoto(catchKey, this.challengeKey)
        }

}


