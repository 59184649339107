import { Injectable } from '@angular/core';
import { FirebaseService } from 'src/app/core-module/base/firebase.service';
import { AngularFireList } from '@angular/fire/compat/database';
import { Message } from 'src/app/data/models/Manage/message.model';

@Injectable()
export class MessageService {

    basePathReference: string;

    constructor(private firebaseService: FirebaseService){
    }

    getMessages(): AngularFireList<Message | any>{
        return this.firebaseService.getCollection(this.basePathReference)
    }



}
