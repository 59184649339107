import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Team, TeamPrivateData } from 'src/app/data/models/Manage/team.model';
import { Angler } from 'src/app/data/models/Manage/angler.model';
import { ManageContestDataService } from '../../../services/manage-contest-data';

@Component({
  selector: 'app-create-team',
  templateUrl: './create-team.component.html',
  styleUrls: ['./create-team.component.css']
})
export class CreateTeamComponent implements OnInit {
@Input('createType') createType : string
@Output('endCreation') endCreation = new EventEmitter<boolean>();

anglersValidator = []

isValidTeam: boolean
IsValidAnglers: boolean

creation: string

team: Team = new Team()

  constructor(private manageContest: ManageContestDataService) { }

  ngOnInit() {
    this.creation = 'new' + this.createType
    this.team.anglers = [new Angler(), new Angler()]
    this.team.privateData = <TeamPrivateData>{}
  }
  ngOnChanges(){
    this.creation = this.createType
  }
  checkTeam(result: boolean){
    this.isValidTeam = result;
  }

  checkAnglers(i: number, result: boolean){
    
    this.IsValidAnglers = this.anglersValidator.length == this.team.anglers.length

    this.anglersValidator[i] = result
    
    if (this.IsValidAnglers)
    {
      this.anglersValidator.forEach(a => this.IsValidAnglers = this.IsValidAnglers && a ? true : false)
    }
  }
 
  onSaveTeam(){
  
    if(this.createType === 'team')
    {
      this.manageContest.addTeam(this.team)
    .then(() =>{
      this.endCreation.emit(false)
    })
    .catch((e) => {
      console.log("Team Creation Error")
    })
    }
    else
    {
      let reservation = {
        reservationDate: new Date().toISOString(),
        team: this.team
      }
      
      this.manageContest.addReservation(reservation)
    .then(() =>{
      this.endCreation.emit(false)
    })
    .catch((e) => {
      console.log("Team Creation Error")
    })
    }

    
  }
  onCancel(){
    this.endCreation.emit(false)
  }


}
