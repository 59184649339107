<div fxLayout="column" fxLayoutAlign="start center">
<h2 mat-dialog-title [innerHTML]="data.header"></h2>

<mat-dialog-content  fxLayout="column" fxLayoutAlign="start center">

    <img fxFlex.xs="50" [attr.src]="data.resultIcon">
    <p [innerHTML]="data.content"></p>

  
    
</mat-dialog-content>
<mat-dialog-actions fxLayout="row" fxLayoutAlign="center start">
    <button mat-button mat-dialog-close [mat-dialog-close]="data" i18n="@@Confirm">Confirm</button>
</mat-dialog-actions> 
</div>