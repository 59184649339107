import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ModuleWithProviders } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainComponent } from './main/main.component';
import { AssignToContestComponent } from './main/assign-to-contest/assign-to-contest.component';
import { SelectContestComponent } from './main/select-contest/select-contest.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CoreModule } from 'src/app/core-module/core.module';
import { ContestComponent } from './contest/contest.component';
import { RefereeDataService } from './services/referee-data.service';
import { AccountComponent } from './account/account.component';
import { ContestDataService } from './services/contest-data.service';
import { MaterialModule } from 'src/app/shared/material.module';

const providers = [RefereeDataService, ContestDataService]
@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    AssignToContestComponent,
    SelectContestComponent,
    ContestComponent,
    AccountComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    FlexLayoutModule,
    CoreModule,
    MaterialModule
  ],
  providers: providers,
  bootstrap: [AppComponent]
})
export class AppModule { }

@NgModule({})
export class RefereeAppSharedModule{
  static forRoot(): ModuleWithProviders<AppModule> {
    return {
      ngModule: AppModule,
      providers: providers
    }
  }
}