<div class="mat-headline" i18n="Welcome Info Card Awards Header| Header for Conest Awards@@"><b>Awards:</b></div> 
        <div fxLayout="column"> 
                <p fxLayoutAlign="start start" fxFlexOrder="1">
                    <label fxFlex="110px" fxFlex.xs="70px" fxLayoutAlign="start start" 
                            class="detailsLabel" title="First Place Award" 
                            i18n="Welcome Info Card Label 1 award| Label for 1 award Contest@@">1st Place:</label
                            >&nbsp;
                            <app-awards-details [awardsDetails]="this.awards.First" [currency]="this.currency"></app-awards-details>
                </p>

                <p fxLayoutAlign="start start" fxFlexOrder="2">
                    <label fxFlex="110px" fxFlex.xs="70px" fxLayoutAlign="start start" 
                            class="detailsLabel" title="Second Place Award" 
                            i18n="Welcome Info Card Label 2 award| Label for 2 award Contest@@">2nd Place:</label>
                            &nbsp;
                            <app-awards-details [awardsDetails]="this.awards.Second" [currency]="this.currency"></app-awards-details>
                </p>

                <p fxLayoutAlign="start start" fxFlexOrder="3">
                    <label fxFlex="110px" fxFlex.xs="70px" fxLayoutAlign="start start"
                            class="detailsLabel" title="Third Place Award" 
                            i18n="Welcome Info Card Label 3 award | Label for 3 award Contest@@">3rd Place:</label>
                            &nbsp;
                            <app-awards-details [awardsDetails]="this.awards.Third" [currency]="this.currency"></app-awards-details>
                </p>
                <p *ngIf="this.awards.totalPool" fxLayoutAlign="start start" fxFlexOrder="4">
                    <label fxFlex="110px" fxFlex.xs="70px" fxLayoutAlign="start start"
                            class="detailsLabel" title="Total Pool Award"
                            i18n="Welcome Info Card Label Pool award | Label for Pool award Contest@@">Pool:</label>
                            &nbsp;{{ this.awards.totalPool }}&nbsp;{{this.currency}}
                </p>
                                                                                                                                                                                                                                             
</div>