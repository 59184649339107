import { Action } from '@ngrx/store';

export const START_LOADING_MAIN = '[UI] Start Main Loading';
export const STOP_LOADING_MAIN = '[UI] Stop Main Loading';

export const START_LOADING_CHILD = '[UI] Start Child Loading';
export const STOP_LOADING_CHILD = '[UI] Stop Child Loading';

export class StartLoadingMain implements Action {
    readonly type = START_LOADING_MAIN;
}

export class StopLoadingMain implements Action {
    readonly type = STOP_LOADING_MAIN;
}

export class StartLoadingChild implements Action {
    readonly type = START_LOADING_CHILD;
}

export class StopLoadingChild implements Action {
    readonly type = STOP_LOADING_CHILD;
}

export type UIActions = StartLoadingMain | StopLoadingMain | StartLoadingChild | StopLoadingChild