import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { BaseOrganiserComponent } from '../../baseOrganiser.component';
import { UIService } from 'src/app/shared/service/ui.service';
import { Challenge, Awards, Fee, Links, Organizer, AwardsPlace, Premium, Region } from 'src/app/data/models/Manage/challenge.model';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { ManageContestDataService } from '../../services/manage-contest-data';
import { Observable, Subscription } from 'rxjs';
import { DialogConfirmationComponent } from 'src/app/shared/components/dialogs/dialog-confirmation/dialog-confirmation.component';
import { AddContestDialogComponent } from './dialog/add-contest-dialog/add-contest-dialog.component';
import { AuthService } from 'src/app/auth-module/service/auth.service';



@Component({
  selector: 'app-add-contest',
  templateUrl: './add-contest.component.html',
  styleUrls: ['./add-contest.component.css']
})
export class AddContestComponent extends BaseOrganiserComponent implements OnInit, OnDestroy {

  @ViewChild('stepper') stepper: MatStepper;
  challenge: Challenge
  challengeAlias: string
  dialogSubscription: Subscription

  constructor(public UIservice: UIService,private authUser: AuthService, private manageContest: ManageContestDataService, private dialog: MatDialog) {
    super(UIservice);

    this.challenge = this.newChallenge()
  }

  ngOnInit() {
    this.dialogSubscription = this.openChallengeDialog().subscribe(async result => {
      if(result)
      {
      }
    })
  }

  openChallengeDialog(): Observable<any> {
    const dialogRef = this.dialog.open(AddContestDialogComponent, {data: {challenge: this.challenge}})

    return dialogRef.afterClosed()
}

  onSubComplete(event){

    this.stepper.selected.completed = event
    this.stepper.next()
  }

  onChallengeAlias(event){
    this.challengeAlias = event


    // do check if Alias is right
  }

  onSubmitNewContest(){

    this.challenge.amountTeamsAvailable = this.challenge.amountTeamsMaximum
    this.challenge.alias = this.challengeAlias


    this.manageContest.addNewContest(this.challenge, this.challengeAlias).subscribe({
      next: (v) => {
        let data = {
          title: $localize`Contest Creation Confirmation`,
          content: $localize`<strong>Your Contest ${this.challenge.title} has been successfully created !</strong><br>Please check for email for final confirmation.`
      }
      this.UIservice.openInfoDialog(data.title, data.content,true)
      },
      error: (e) => {
        let data = {
          title: $localize`Contest Creation Failed`,
          content: $localize`<strong>Error, can't confirm your contest creation. Please contact Carp Contest Administrator</strong><br> ${e.message}`
      }
      this.UIservice.openInfoDialog(data.title, data.content,false)
      }
  })
  }

  newChallenge(): Challenge {
    const challenge = new Challenge()

    challenge.amountTeamsMaximum = null
    challenge.amountTeamsAvailable = null
    challenge.amountTeamsReservation = 0
    challenge.amountTeamsActual = 0
    challenge.amountTeamsReserve = 0
    challenge.areaDetails = null
    challenge.challengeInfo = null
    challenge.challengeModel = null
    challenge.challengeType = null
    challenge.endDate = null
    challenge.fishingMethod = null
    challenge.place = null
    challenge.sortTopBy = 3
    challenge.startDate = null
    challenge.teamSquad = 2
    challenge.title = null

    challenge.Awards = <Awards>{}
    challenge.Awards.First = <AwardsPlace>{}
    challenge.Awards.Second = <AwardsPlace>{}
    challenge.Awards.Third = <AwardsPlace>{}
    challenge.Awards.BigFish = <AwardsPlace>{}
    challenge.Awards.FirstFish = <AwardsPlace>{}

    challenge.Region = <Region>{}
    challenge.Fee = <Fee>{}
    challenge.Fee.paymentDetails = {
      providedDetails: false,
      bankAccountNumber: '',
      receiverName: '',
      receiverSurname: '',
      receiverCompany: '',
      receiverAddress: ''
    }
    challenge.Links = <Links>{}
    challenge.Organizer = <Organizer>{}
    challenge.Organizer.key = this.authUser.getUserKey()

    challenge.Premium = <Premium>{}
    challenge.Premium.isPremium = false
    challenge.Premium.isFishPhotos = true

    return challenge
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.dialogSubscription.unsubscribe()
  }
}
