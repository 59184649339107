import { Component, OnInit, OnDestroy, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { Team } from 'src/app/data/models/Manage/team.model';
import { UIService } from 'src/app/shared/service/ui.service';
import { Angler } from 'src/app/data/models/Manage/angler.model';
import { ManageContestDataService } from '../../../services/manage-contest-data';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-edit-team',
  templateUrl: './edit-team.component.html',
  styleUrls: ['./edit-team.component.css']
})
export class EditTeamComponent implements OnInit, OnDestroy {
  
  @Input('editTeam') team: Team
  @Input('teamID') teamID: string
  @Input('editType') editType: string
  @Output() isValid: EventEmitter<boolean> = new EventEmitter()
  @Output() exit: EventEmitter<boolean> = new EventEmitter()
  @ViewChild('f')

  private f: NgForm;

  addTeamRunner: boolean = false
  teamRunner: Angler = new Angler()
  isNew: boolean
  editAngler: Angler

  constructor(private UIservice: UIService, private manageContest: ManageContestDataService) { 
 
  }
  ngOnInit() {
    this.isNew = this.editType.substr(0,3) === 'new' ? true : false
  }

  onUpdateTeam(){
    
    if(this.teamID && this.team && this.editType)
    {
      this.manageContest.updateTeam(this.teamID, this.team, this.editType)
      .then(() => {
        this.exit.emit(true)
      })
      .catch((e) => {
        console.log('Error not implemented')
      }) 
    }
  }

  onNewTeamRunner(){
    this.teamRunner.teamRole = "Runner"
    if (this.isNew)
    {
      this.team.anglers.push(this.teamRunner)
    }
    this.addTeamRunner = true
  }
  onCancel(){
    this.exit.emit(true)
  }
  onRunnerExit(): void {
    this.addTeamRunner = false
  }

  emitValidator(f: boolean){
    this.isValid.emit(f);
  }

  onAnglerEditUpdate(teamRunner: Angler){
    // zmienic edit type

    this.manageContest.updateAngler(this.teamID, '2', teamRunner, this.editType)
      .then(() => {
        this.addTeamRunner = false
      })
  }



  ngOnDestroy(): void {

  }

}
