<div @openClose *ngIf="isOpen" style=" transform: translateY(100%); display: none;">
  
    <div class="Landing-Background">
           
        
        <div [ngClass]="{'mobile-title': UIservice.isMobileSM, 'title': !UIservice.isMobileSM}">
                <h2 [ngClass]="{'company-card-text': true, 'company-welcome-mobile':  UIservice.isMobileSM, 'company-welcome': !UIservice.isMobileSM}" 
                    i18n="@@WelcomeAt">Welcome at</h2>

                <h1 [ngClass]="{'company-card-text': true, 'company-name-mobile':  UIservice.isMobileSM, 'company-name': !UIservice.isMobileSM}">Carp Contest</h1>
                
                <h3 [ngClass]="{'company-card-text': true, 'company-text-mobile':  UIservice.isMobileSM, 'company-text': !UIservice.isMobileSM}"
                    i18n="@@StartYourJourney">Start your own Carp Contest journey.</h3>

                <div [ngStyle]="{'margin-top': UIservice.isMobileSM ? '1vh' : '2vh'}">
                    <button mat-stroked-button
                            routerLink="/main/finder"
                            i18n="@@1870e3ec9fcc9a4e9e03b3feaba98ad755471648"
                        >View Contests</button>
                    <button *ngIf="this.navigateToNew"
                            mat-stroked-button
                            routerLink="/organiser"
                            i18n="@@ea5b080356d20dbc9afa4d6eea3b5aa5f7e08adf"
                            >
                            New Contest
                    </button>
                    <button *ngIf="!this.navigateToNew"
                            mat-stroked-button
                            routerLink="/main/login"
                            i18n="@@ea5b080356d20dbc9afa4d6eea3b5aa5f7e08adf"
                            >
                            New Contest
                    </button>

                </div>

                <div [ngClass]="{'welcome-mobile-search': UIservice.isMobileSM, 'welcome-search': !UIservice.isMobileSM}" class="welcome-search">
                    <form #searchForm="ngForm" (ngSubmit)="applySearch(searchForm.value.searchValue)">
                        <mat-form-field>
                        <mat-label style="color: white;" i18n="MainWelcomeSearch| label for Main Welcome Search@@">Search Contest</mat-label>
                            <input  matInput 
                                    required
                                    ngModel
                                    minlength="1"
                                    name="searchValue"
                                    type="text" 
                                    >
                        </mat-form-field>
                        <button mat-button  type="submit" i18n="@@Search">Search</button>
                    </form>
                </div>
        </div>
    

        
        
    </div>

</div>