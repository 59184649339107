import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/auth-module/service/auth.service';
import { Contest } from 'src/app/data/models/Manage/contest.model';
import { map } from 'rxjs/operators';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/compat/database';
import { FirebaseService } from 'src/app/core-module/base/firebase.service';
import { AccountComponent } from '../account/account.component';
import { Referee } from 'src/app/data/models/Manage/referee.model';


@Injectable()
export class RefereeDataService {


constructor(private authService: AuthService,
            private firebaseService: FirebaseService
             ) {


}

    getRefereeContestsList(){
        return this.authService.getUserContestList('referee').snapshotChanges()
                .pipe<Contest[]>(map(docArray => {
                    return docArray.map(doc => {
                        return {
                            ...doc.payload.val()
                        }
                    })
                }))
    }

    getAllActiveContestsList() : AngularFireList<Contest | any>{

        return this.firebaseService.getObjectListByProperty('ChallengesInfo', 'isComming', true)

    }

    signToContest(key: string){

        const collection = 'Challenges/' + key + '/refereesReservation'
        const userKey = this.authService.getCurrentUserUID()
        const newRefereeSign = new Referee()
        newRefereeSign.email = this.authService.user.value.email
        newRefereeSign.phone = this.authService.user.value.phone ? this.authService.user.value.phone : null
        newRefereeSign.userData = this.authService.user.value.userData
        newRefereeSign.active = true

        return this.firebaseService.updateObjectByKey(collection, newRefereeSign, userKey)
    }
}
