<div [hidden]="!this.dataSource" fxFlexFill fxLayoutAlign="center start" >
    <div style="margin: 2vh;" fxFlex.gt-md="80" fxFlex="100" class="mat-elevation-z8" fxLayout="column">
        
        <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap.xs="1vh">
            <div fxFlex="50" fxFlex.xs fxFlexOrder.xs="1">
                
                <mat-form-field  fxLayoutAlign="center center" fxLayoutAlign.xs="center center">
                    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
                </mat-form-field>
        
            </div>
        
            <div fxFlex="50" fxFlex.xs fxFlexOrder.xs="3">
        
                <mat-paginator [length]="this.dataSource.data.length"
                                [pageSize]="5"
                                [pageSizeOptions]="[5 , 10]"
                                showFirstLastButtons
                                (page)="pageEvent = $event">
                </mat-paginator>
                
            </div>

        <table *ngIf="!this.UIservice.isMobileSM; else cards" fxFlex="100" mat-table [dataSource]="dataSource">
            
            <ng-container matColumnDef="station">
                <th fxFlex fxLayoutAlign="center center" mat-header-cell *matHeaderCellDef i18n="PegNoDevice| PegNo@@">Peg No.</th>
                <td fxFlex
                    fxLayoutAlign="center center" 
                    mat-cell 
                    *matCellDef="let element">
                    {{element.station}}
                </td>
            </ng-container>
    
                <ng-container matColumnDef="promoTeam">
                    <th fxFlex fxLayoutAlign="center center" mat-header-cell *matHeaderCellDef i18n="@@1912c7cc0c6c08b8d2049e87880bffea1eeeabdc">Promo Team</th>
                    <td fxFlex
                        fxLayoutAlign="center center" 
                        mat-cell *matCellDef="let element">
                        {{element.promoTeam}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="Angler1teamRole">
                    <th fxFlex fxLayoutAlign="center center" mat-header-cell *matHeaderCellDef i18n="@@5bb093ca9e4fd7d78cdde93bec72c6905f8e1e8c">Role</th>
                    <td fxFlex 
                        fxLayoutAlign="center center" 
                        fxLayoutGap="1vh"
                        mat-cell 
                        *matCellDef="let element">
                        {{element.anglers[0].teamRole}}

                    </td>
                </ng-container>

                <ng-container matColumnDef="Angler1">
                    <th fxFlex fxLayoutAlign="center center" mat-header-cell *matHeaderCellDef i18n="Angler1| Angler1@@">Angler 1</th>
                    <td fxFlex 
                        fxLayoutAlign="center center" 
                        fxLayoutGap="1vh"
                        mat-cell 
                        *matCellDef="let element">
                        {{element.anglers[0].name}}&nbsp;{{element.anglers[0].surname}}

                    </td>
                </ng-container>

                <ng-container matColumnDef="Angler1Country">
                    <th fxFlex fxLayoutAlign="center center" mat-header-cell *matHeaderCellDef i18n="@@6c168f33a47867c01e13f6427d71fcf546226cc3">Country</th>
                    <td fxFlex 
                        fxLayoutAlign="center center" 
                        fxLayoutGap="1vh"
                        mat-cell 
                        *matCellDef="let element">
                            {{element.anglers[0].country}}

                    </td>
                </ng-container>

                <ng-container matColumnDef="Angler2teamRole">
                    <th fxFlex fxLayoutAlign="center center" mat-header-cell *matHeaderCellDef i18n="@@5bb093ca9e4fd7d78cdde93bec72c6905f8e1e8c">Role</th>
                    <td fxFlex 
                        fxLayoutAlign="center center" 
                        fxLayoutGap="1vh"
                        mat-cell 
                        *matCellDef="let element">
                        {{element.anglers[1].teamRole}}
    
                    </td>
                </ng-container>

            <ng-container matColumnDef="Angler2">
                <th fxFlex fxLayoutAlign="center center" mat-header-cell *matHeaderCellDef i18n="Angler2| Angler2@@">Angler 2</th>
                <td fxFlex 
                    fxLayoutAlign="center center" 
                    fxLayoutGap="1vh"
                    mat-cell 
                    *matCellDef="let element">
                    {{element.anglers[1].name}}&nbsp;{{element.anglers[1].surname}}

                </td>
            </ng-container>

            <ng-container matColumnDef="Angler2Country">
                <th fxFlex fxLayoutAlign="center center" mat-header-cell *matHeaderCellDef i18n="@@6c168f33a47867c01e13f6427d71fcf546226cc3">Country</th>
                <td fxFlex 
                    fxLayoutAlign="center center" 
                    fxLayoutGap="1vh"
                    mat-cell 
                    *matCellDef="let element">
                        {{element.anglers[1].country}}

                </td>
            </ng-container>

            <ng-container matColumnDef="TeamRunner">
                <th fxFlex fxLayoutAlign="center center" mat-header-cell *matHeaderCellDef>Team Runner</th>
                <td fxFlex 
                    fxLayoutAlign="center center" 
                    fxLayoutGap="1vh"
                    mat-cell 
                    *matCellDef="let element">
                    <div *ngIf="element.anglers[2]">
                        {{element.anglers[2].name}}&nbsp;{{element.anglers[2].surname}}
                    </div>

                </td>
            </ng-container>

    
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        
        <ng-template #cards>
    
            <div fxFlexFill fxLayout="column" fxLayoutGap="1vh" fxFlexOrder.xs="2">
                <mat-accordion *ngFor="let element of this.cardData | async, let i = index">
                    <mat-expansion-panel>
                      <mat-expansion-panel-header fxLayout="row">  
                        
                        <mat-panel-title fxFlex="30">
                         
                        <div *ngIf="element.station">
                         <label >Peg:</label>
                            &nbsp;{{element.station}}
                        </div>

                        <div *ngIf="!element.station">
                            {{i + 1}}
                        </div>
        
                        </mat-panel-title>
                        
                        <mat-panel-description fxFlex="60">
                        
                            <span *ngIf="element.promoTeam">{{element.promoTeam}}</span>
                            <span *ngIf="!element.promoTeam">{{element.anglers[0].name}}&nbsp;&&nbsp;{{element.anglers[1].name}}</span>
                        </mat-panel-description>
                      </mat-expansion-panel-header>
         
                      <div fxLayout="column" fxLayoutAlign="start center">
                        
                                <div class="mat-h4" style="font-weight: bold;">Team:&nbsp;</div>
                      
                      <div fxLayout="row wrap" fxLayoutAlign="start center">
                        <div fxFlex="100" fxLayoutAlign="space-between center" *ngFor="let angler of element.anglers" >
                            <div fxFlex="30" style="font-weight: bold;">
                                {{angler.teamRole}}
                            </div>
                            <div fxFlex>
                                {{angler.name}}&nbsp;{{angler.surname}}
                            </div>
                            <div fxFlex="10">
                                {{angler.country}}
                            </div>  

                        </div>
                      </div>
        
        
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </ng-template>

        </div>
        
    </div>


    </div>