<div class="Landing-Background" fxFlex="100" fxLayoutAlign="center center" fxLayoutAlign.gt-sm="center center"  fxLayout="column">

    <div class="content-container" fxLayout="column" fxLayoutAlign="start center" fxLayoutAlign.gt-sm="center center" fxLayoutGap="2vh">

        <h1 *ngIf="this.serveUser.isAdmin || this.serveUser.isOrganiser || this.serveUser.isReferee" class="mat-h1" i18n="@@ChooseYourRole">Choose your role :</h1>
    
        <div fxLayout.lt-md="column" fxFlex.lt-md fxLayout="row" fxLayoutGap="2vh">

            <div *ngIf="this.serveUser.isAdmin" [ngClass]="{'positionCard': !UIservice.isMobileSM, 'smallPositionCard': UIservice.isMobileSM}">
                <div fxFlexFill fxLayoutAlign="center center">
                    
                    <button fxFlexFill mat-button
                            [routerLink]="['/admin']">
                    Admin
                    </button>

                </div>
            </div>

            <div *ngIf="this.serveUser.isOrganiser" [ngClass]="{'positionCard': !UIservice.isMobileSM, 'smallPositionCard': UIservice.isMobileSM}">
                <div fxFlexFill fxLayoutAlign="center center">
                    <button fxFlexFill mat-button
                            [routerLink]="[ '/organiser']"
                            i18n="27155da2093eb99c3b1a40219a783660f032b533">
                    Organiser
                    </button>
                </div>
            </div>

            <div *ngIf="this.serveUser.isReferee" [ngClass]="{'positionCard': !UIservice.isMobileSM, 'smallPositionCard': UIservice.isMobileSM}">
                <div fxFlexFill fxLayoutAlign="center center">
                    <button fxFlexFill mat-button
                            [routerLink]="[ '/referee']"
                            i18n="@@Referee">Referee</button>
                </div>
            </div>

        </div>



        <h1 *ngIf="!this.serveUser.isOrganiser || !this.serveUser.isReferee" class="mat-h1" i18n="@@BecomeNew">Become new :</h1>

        <div fxLayout.lt-md="column" fxFlex.lt-md fxLayout="row" fxLayoutGap="2vh">


            <div *ngIf="!this.serveUser.isOrganiser" [ngClass]="{'positionCardNew': !UIservice.isMobileSM, 'smallPositionCardNew': UIservice.isMobileSM}">
                <div fxFlexFill fxLayoutAlign="center center">
                    <button fxFlexFill mat-button
                            (click)="becomeAsNewOrganiser()"
                            i18n="27155da2093eb99c3b1a40219a783660f032b533">
                    Organiser
                    </button>
                </div>
            </div>

            <div *ngIf="!this.serveUser.isReferee" [ngClass]="{'positionCardNew': !UIservice.isMobileSM, 'smallPositionCardNew': UIservice.isMobileSM}">
                <div fxFlexFill fxLayoutAlign="center center">
                    <button fxFlexFill mat-button
                            (click)=" becomeasNewReferee()"
                            i18n="@@Referee">Referee</button>
                </div>
            </div>

        </div>
    
    
    
    </div>

</div>