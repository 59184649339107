import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Fish } from 'src/app/data/models/Manage/fish.model';
import { Observable } from 'rxjs';
import { Team } from 'src/app/data/models/Manage/team.model';
import {ViewEncapsulation } from '@angular/core';
import { Angler } from 'src/app/data/models/Manage/angler.model';
import { DateAdapter, MAT_DATE_FORMATS, ThemePalette } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import * as moment from 'moment';



export interface DialogData {
  newFish: Fish,
  teamObsRef: Observable<any>
  editMode?: boolean
}

@Component({
  selector: 'app-dialog-add-fish',
  templateUrl: './dialog-add-fish.component.html',
  styleUrls: ['./dialog-add-fish.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DialogAddFishComponent implements OnInit  {

  @ViewChild('picker') picker: any;

  teams: Team[]
  anglers: Angler[]
  originPhoto: any
  photoName: any

  public color: ThemePalette = 'primary';


  constructor( @Inject(MAT_DIALOG_DATA) public data: DialogData) { }


  ngOnInit(): void {

    this.data.teamObsRef.subscribe(recData => {
      this.teams = recData.filter(a => a.station != null)
      this.teams.sort((a,b) => {
        if (a.station > b.station)
        {
          return 1
        }

        if(b.station > a.station)
        {
          return -1
        }
      })

      if(this.data.editMode){
        this.anglers = this.teams.filter(x => x.id = this.data.newFish.teamID)[0].anglers
      }
  })

}

  onTeamSelect(id) {
    this.anglers = this.teams[id].anglers
    this.data.newFish.teamID = this.teams[id].id
  }

  onAnglerSelect(id) {
    this.data.newFish.anglerName = this.anglers[id].name
    this.data.newFish.anglerSurname = this.anglers[id].surname
  }

  onDateInput(event){

      var _date = new Date(event.value)
      this.data.newFish.date = (moment(_date).format('YYYY-MM-DD HH:mm:ss.SSS'))


  }

  onFileSelected(event){

    const _file = event.target.files[0]
    const filePrefix = this.data.newFish.anglerName + this.data.newFish.anglerSurname + '_' + this.data.newFish.weight.toFixed(0) +'_'

    if(_file.size < 6291456)
    {
      this.originPhoto = _file
      this.photoName = _file.name
      this.data.newFish.photoFile = _file
    }
    else
    {
      //  this.uploadError = "File is to big, max size 3MB"
    }



}

}
