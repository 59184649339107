import { Component, OnInit } from '@angular/core';
import { UIService } from 'src/app/shared/service/ui.service';

@Component({
  selector: 'app-comp-how-it-works',
  templateUrl: './comp-how-it-works.component.html',
  styleUrls: ['./comp-how-it-works.component.css']
})
export class CompHowItWorksComponent implements OnInit {

  constructor(public UIservice: UIService) { }

  ngOnInit() {
  }

}
