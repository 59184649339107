import { Injectable, Optional, SkipSelf } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';


import { ActivatedRoute, Router } from '@angular/router';
import { ChallengeService } from 'src/app/core-module/entityServices/challange.service';
import { PartnerService } from 'src/app/core-module/entityServices/partner.service';
import { GlobalsService } from 'src/app/core-module/entityServices/serviceGlobals';
import { map } from 'rxjs/operators';
import { Team } from '../data/models/Manage/team.model';
import { TeamService } from 'src/app/core-module/entityServices/team.service';
import { UIService } from 'src/app/shared/service/ui.service';
import { Partner } from 'src/app/data/models/Manage/partner.model';
import { Challenge } from 'src/app/data/models/Manage/challenge.model';
import { Referee } from 'src/app/data/models/Manage/referee.model';
import { timeStamp } from 'console';



@Injectable({
    providedIn: 'root'
  })
export class ProvideDataService {


    subscribeChallenge = new Subject<any>();
    subscribeSignedTeams = new Subject<any>();
    subscribeReservationTeams = new Subject<any>();

    challenge$: Observable<Challenge>;
    partners$: Observable<Partner[]>
    signedTeams$: Observable<Team[]>
    reservationTeams$: Observable<Team[]>
    referees$: Observable<Referee[]>


    challenge:  BehaviorSubject<Challenge> = new BehaviorSubject<Challenge>(null)
    keyReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

    organizer: any;
    private challengeID: string;
    private rootInitialize: boolean = true
    challengeAlias: string

    constructor(private route: ActivatedRoute,
        private router: Router,
        private challengeService: ChallengeService,
        private partnerService: PartnerService,
        private teamService: TeamService,
        private globals: GlobalsService,
        @Optional() @SkipSelf() parent?: ProvideDataService)
        {
            if (parent)
            {
                throw Error(
                    `[ProvideDataService]: trying to create multiple instances,
                    but this service should be a singleton.`
                  );
            }

            this.setRoute(route)

        }


    setRoute(route: ActivatedRoute): void{

        let _challengeAlias = route.snapshot.params['challengeID'];

        if(_challengeAlias){
            if(this.challengeAlias != _challengeAlias){
                this.challenge.next(null)
                this.getChallengeKeyByAlias(_challengeAlias)
            }
            else if (this.challengeID === null || this.challengeAlias != _challengeAlias)
                {
                    this.challengeAlias = _challengeAlias
                    this.getChallengeKeyByAlias(_challengeAlias)
                }
            else if( _challengeAlias != null && this.challenge.value != null)
                {
                    if(this.challenge.value.alias != _challengeAlias)
                    {
                        this.challengeAlias = _challengeAlias
                        this.getChallengeKeyByAlias(_challengeAlias)
                    }
                }
            else
            {
                this.getChallengeKeyByAlias(_challengeAlias)
            }
        }



    }

    getChallengeKeyByAlias(_challengeAlias: string){
        let _key: string
        this.challengeService.getChallengeKey(_challengeAlias)
        .once('value')
        .then(data => {
            this.challengeID = data.val()
            this.challengeAlias = _challengeAlias


            if(this.challengeID == null){
                this.router.navigate(['/main'])
            }

            this.globals.setPathsWithKey(this.challengeID);
            this.getChallenge()
            this.getChallengePartners()
            this.keyReady.next(true)

        })
    }

    getChallenge(): void{

        this.challengeService.basePathReference = this.globals.getChallengePath() + '/challenge';

            this.challenge$ = this.challengeService.getChallenge().snapshotChanges()
                    .pipe<Challenge>(map(doc => {
                        return {
                                id: doc.key,
                                ...doc.payload.val(),

                         }
                }))
            this.subscribeChallenge.next(true);
    }

    getChallengePartners(): void{

        this.partnerService.basePathReference = this.globals.getPartnersPath();

        this.partners$ = this.partnerService.getContestPartners().snapshotChanges()
                        .pipe<Partner[]>(map(docArray => {
                                return docArray.map(doc => {
                                        return {
                                                id: doc.key,
                                                ...doc.payload.val()
                                                }
                                            })
                                }))

            // this.UIservice.partners$ = this.partners$
            // this.UIservice.subscribePartners.next();
    }

    getMainPartners(): void{

        if(!this.rootInitialize){

        this.partners$ = this.partnerService.getMainPartners().snapshotChanges()
                        .pipe<Partner[]>(map(docArray => {
                                return docArray.map(doc => {
                                        return {
                                                id: doc.key,
                                                ...doc.payload.val()
                                                }
                                            })
                                }))
    }
        this.rootInitialize = false
    }

    getSignedTeams(): Observable<Team[]>{

        this.teamService.basePathReference = this.globals.getTeamsPathPublic();

        this.signedTeams$ = this.teamService.getTeams()
            .snapshotChanges()
            .pipe<Team[]>(map(docArray => {
                return docArray.map(doc => {
                    return {
                        id: doc.key,
                        ...doc.payload.val()
                    }
                })
            }))

        return this.signedTeams$
    }

    getReferees(): Observable<Referee[]>{

        this.challengeService.refereesPath = this.globals.getRefereesPathPublic();
            this.referees$ = this.challengeService.getReferees()
                    .snapshotChanges()
                    .pipe<Referee[]>(map(docArray => {
                        return docArray.map(doc => {
                            return {
                                id: doc.key,
                                ...doc.payload.val()
                            }
                         })
                }))

            return this.referees$
    }


    getReservationTeams(): Observable<Team[]>{

        this.teamService.reservationPath = this.globals.getReservationPathPublic();
        this.reservationTeams$ = this.teamService.getReservationTeams()
            .snapshotChanges()
            .pipe<Team[]>(map(docArray => {
                return docArray.map(doc => {
                    return {
                        id: doc.key,
                        ...doc.payload.child('team').val()
                    }
                })
            }))

            return this.reservationTeams$
    }

    getReserveListTeams(): Observable<Team[]>{

        this.teamService.reservationPath = this.globals.getReservationPathPublic();

        this.reservationTeams$ = this.teamService.getReserveTeams()
            .snapshotChanges()
            .pipe<Team[]>(map(docArray => {
                return docArray.map(doc => {
                    return {
                        id: doc.key,
                        ...doc.payload.child('team').val()
                    }
                })
            }))

            return this.reservationTeams$
    }

}
