import { Component, OnInit, Input, Inject } from '@angular/core';
import { Partner } from 'src/app/data/models/Manage/partner.model';
import { ManageContestDataService } from '../../../services/manage-contest-data';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UIService } from 'src/app/shared/service/ui.service';


class ImageSnippet {
  constructor(public src: string, public file: File) {}
}



@Component({
  selector: 'app-partner-card',
  templateUrl: './parter-card.component.html',
  styleUrls: ['./parter-card.component.css']
})
export class ParterCardComponent implements OnInit {

  @Input() partner?: Partner
  @Input() modeAdd?: boolean = false
  @Input() modeEdit?: boolean = false

  selectedLogo: string
  uploadError: string
  formPartner: Partner
  fileSelected: File = null;
  renderSelected: ImageSnippet;
  srcResult: any

  constructor(private manageContest: ManageContestDataService, private UIservice: UIService) { }

  ngOnInit() {

    if(this.modeAdd)
    {
        this.formPartner = this.newPartner()
    }
  
  }

  private newPartner(){
    const formPartner = new Partner()
      formPartner.name = ""
      formPartner.description = ""
      formPartner.link = ""
      formPartner.priority = null
      formPartner.logoLink = ""
      formPartner.id = null
      return formPartner
  }

  onEdit(){
    this.modeEdit = true
    this.formPartner = {...this.partner}
  }

  onDelete(){
      
    this.manageContest.deletePartner(this.partner.id)
          .catch((e) => {
            let data = {
              title: $localize`Partner Delete Error`,
              content: e.message
          }
            this.UIservice.openInfoDialog(data.title, data.content,false)
          })

  }

  onSave(){
    
    if(this.fileSelected)
    {
      this.manageContest.upsertPartner(this.formPartner, this.fileSelected)
          .then(() => {this.formPartner = this.newPartner()
                      this.fileSelected = null
                      this.selectedLogo = ""})
          .catch((e) => {
            let data = {
              title: $localize`New Partner Error`,
              content: e.message
          }
            this.UIservice.openInfoDialog(data.title, data.content,false)
          })
    }
    else
    {
      this.manageContest.upsertPartner(this.formPartner)
      .then(() => {this.formPartner = this.newPartner()})
      .catch((e) => {console.log(e.message)})
    }
    

    

  }
  onCancel(){
    this.modeEdit = false
  }

  onFileSelected(event){

    this.fileSelected = event.target.files[0];

    if(this.fileSelected.size < 1048576)
    {
      const reader = new FileReader();  
      reader.readAsDataURL(this.fileSelected)
      reader.onload = (_event: Event) => {this.selectedLogo = reader.result.toString()}

    }
    else
    {
      let data = {
        title: $localize`Partner Logo To big`,
        content: $localize`File is to big, max size 1MB`
    }
      this.UIservice.openInfoDialog(data.title, data.content,false)
    }
    
  
}

}
