import { Component, OnInit, Output, EventEmitter} from '@angular/core';

import { AuthService } from 'src/app/auth-module/service/auth.service';


@Component({
  selector: 'app-header-home-link',
  template: `
                <button     
                    role='navigation'
                    mat-icon-button
                    routerLink="/"
                    aria-label="Back to Main Page"
                >
                <mat-icon>home</mat-icon>
                &nbsp;
                <span i18n="@@b37a5ab5c59ac41cbfe668200f4768af7a28d81f">Home</span>               
                </button>`
})
export class HeaderHomeLinkComponent implements OnInit {
 

  constructor(private authService: AuthService) { 
  }

  ngOnInit() {
  }

  onLogOut(){
      this.authService.signoutUser()
          
  }



}