<div  fxFlexFill fxLayout="column" fxLayoutAlign="start center">

    <h1 class="mat-h1" i18n="WriteMessageHeader| Write Message Header@@">Write Message</h1>

    <form fxLayout="column" fxLayoutAlign="start center" fxFlexFill #a="ngForm">



            <mat-card fxFlexFill [ngClass]="{'Alert-card': this.newMessage.type === 'Alert'}">

              <div fxLayoutAlign="center center">
              <mat-form-field>
                <mat-label i18n="Type | Type@@">Type</mat-label>
                <mat-select ([ngModel])="newMessage.type" [(value)]="newMessage.type">
                    <mat-option value="Text" i18n="@@TextMessage">Text Message</mat-option>
                    <mat-option value="Photo" i18n="@@PhotoMessage">Photo Message</mat-option>
                    <mat-option value="Youtube" i18n="@@YoutubeMessage">Youtube Message</mat-option>
                    <mat-option value="Alert" i18n="@@AlertMessage">Alert Message</mat-option>
                </mat-select>
              </mat-form-field>
              </div>

              <mat-card-header>



                <mat-card-title>

                   <mat-form-field fxFlexFill>

                      <mat-label i18n="@@Title">Title</mat-label>
                      <textarea matInput
                      type="text"
                      [(ngModel)]="newMessage.text.title"
                      name="messageTitle"
                      cdkTextareaAutosize
                      cdkAutosizeMinRows="1"
                      cdkAutosizeMaxRows="1"
                      ></textarea>
                  </mat-form-field>


                </mat-card-title>
                <mat-card-subtitle>{{dateTimeNow  | date:"dd/MM/yyyy HH:mm"}}</mat-card-subtitle>

              </mat-card-header>


                <mat-card-content fxLayoutGap="10px" fxLayoutAlign="start center" fxLayout="column">

                    <img mat-card-xl-image *ngIf="this.displayPhotoURL != null" [attr.src]="this.displayPhotoURL">
<!--
                    <mat-form-field *ngIf="photoName != null">
                      <mat-label i18n="@@SelectPhoto">Select Photo</mat-label>
                      <input   matInput
                              [(ngModel)]="photoName"
                              [(value)]="photoName"
                              name="Upload Photo"
                      >
                  </mat-form-field> -->


                <p [ngClass]="{'Alert-text': newMessage.type ==='Alert'}" fxFlexFill>

                  <mat-form-field fxFlexFill>
                    <mat-label i18n="Content | Message Content@@">Content</mat-label>
                    <textarea matInput
                            type="text"
                            [(ngModel)]="newMessage.text.PL"
                            name="messageContent"
                            cdkTextareaAutosize
                            cdkAutosizeMinRows="1"
                            cdkAutosizeMaxRows="10"
                    ></textarea>
                </mat-form-field>
                </p>

                <mat-form-field *ngIf="newMessage.type === 'Youtube'" fxFlexFill>
                  <mat-label>Youtube Link</mat-label>
                  <textarea matInput
                          type="text"
                          [(ngModel)]="newMessage.extraData.youtubeLink"
                          name="youtubeLink"
                          (change)="youtubeLinkReview($event)"
                          cdkTextareaAutosize
                          cdkAutosizeMinRows="1"
                          cdkAutosizeMaxRows="1"
                  ></textarea>
              </mat-form-field>

              <mat-form-field fxFlexFill>
                <mat-label i18n="@@PartnerName">Partner Name</mat-label>
                <textarea matInput
                        type="text"
                        [(ngModel)]="newMessage.extraData.partner.partnerName"
                        name="messagePartnerName"
                        cdkTextareaAutosize
                        cdkAutosizeMinRows="1"
                        cdkAutosizeMaxRows="10"
                ></textarea>
            </mat-form-field>

            <mat-form-field fxFlexFill>
              <mat-label i18n="@@PartnerLink">Partner Link</mat-label>
              <textarea matInput
                      type="text"
                      [(ngModel)]="newMessage.extraData.partner.partnerLink"
                      name="messagePartnerLink"
                      cdkTextareaAutosize
                      cdkAutosizeMinRows="1"
                      cdkAutosizeMaxRows="10"
              ></textarea>
          </mat-form-field>



                <!-- <div *ngIf="message.extraData?.partner != null" fxLayout="column" fxLayoutAlign="start center">
                          <label i18n="@@Partner">Partner:</label><a class="partner" [attr.href]="message.extraData?.partner.partnerLink">{{message.extraData.partner.partnerName}}</a>
                      </div> -->
              </mat-card-content>

              <mat-card-actions>
                <div fxFlex fxLayoutGap="2vh" fxLayoutAlign="center center">

                  <button *ngIf="newMessage.type === 'Photo'" type="button" color="accent" mat-raised-button (click)="photInput.click()" i18n="@@SelectPhoto">Select Photo</button>
                  <input hidden (change)="onFileSelected($event)" accept="image/*" #photInput type="file" id="photoFile">

                  <button
                      mat-stroked-button
                      color="warn"
                      i18n="clear| clear button@@"
                      (click)="onClear()">Clear</button>

                  <button *ngIf="a.valid"
                      mat-stroked-button
                      style="color:#23c268;"
                      i18n="Save button| Save Button@@"
                      (click)="onSave()">Save</button>

                    </div>
              </mat-card-actions>


            </mat-card>


            <div *ngIf="this.result">
                <p>{{this.result}}</p>
            </div>
    </form>




    </div>


