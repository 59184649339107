import { Component, OnInit } from '@angular/core';
import { UIService } from 'src/app/shared/service/ui.service';


@Component({
  selector: 'app-organiser-how-it-works',
  templateUrl: './organiser-how-it-works.component.html',
  styleUrls: ['./organiser-how-it-works.component.css']
})
export class OrganiserHowItWorksComponent implements OnInit {

  // signupPicture = "https://firebasestorage.googleapis.com/v0/b/carp-contests-app.appspot.com/o/shared%2Fapp%2FsignupForm.png?alt=media&token=fb3ea9c3-74a6-4c1e-b00b-aff5e22741f9"
  // firstStep = "https://firebasestorage.googleapis.com/v0/b/carp-contests-app.appspot.com/o/shared%2Fapp%2FstartInFewSteps.png?alt=media&token=70aa0f4f-fea0-4be0-b435-6d09a0139433"
  // fillData = "https://firebasestorage.googleapis.com/v0/b/carp-contests-app.appspot.com/o/shared%2Fapp%2FfillData.png?alt=media&token=ad90efe6-1d62-4caf-b6b0-7e913fffb6df"
  // secondStep = "https://firebasestorage.googleapis.com/v0/b/carp-contests-app.appspot.com/o/shared%2Fapp%2FstartInFewStep2.png?alt=media&token=51fb7cf6-5cd0-478b-9a85-cc569d8569de"
  // addContest = "https://firebasestorage.googleapis.com/v0/b/carp-contests-app.appspot.com/o/shared%2Fapp%2FaddContest.png?alt=media&token=1857eb5e-db57-4367-859b-71f058a04dbd"

  constructor(public UIservice: UIService) { }

  ngOnInit() {
  }

}
