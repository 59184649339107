export interface Awards {

    First: AwardsPlace;
    Second: AwardsPlace;
    Third: AwardsPlace;
    BigFish?: AwardsPlace;
    FirstFish?: AwardsPlace;
    totalPool?: string;
    more: boolean;
}

export interface AwardsPlace {
    cash?: string
    sponsoredItems?: boolean
    freeEntry?: string
}

export interface Fee {
    feeAmount: number;
    feeType: string;
    feeCurrency: string;
    feeTeamRunner: string;
    deadline: string;
    isSignUpOpen: boolean;
    paymentDetails: {
        providedDetails: boolean,
        bankAccountNumber?: string,
        receiverName?: string,
        receiverSurname?: string,
        receiverCompany?: string,
        receiverAddress?: string
    }
}

export interface Links{
    bankSpotsMapLink: string;
        rulesLink: string;
        posterLink: string;
        challengeAreaLink: string;
        challengeFisheryLink: string;
        challengeRouteMeLink: string;
}

export interface Organizer{
    key: string;
    orgName: string;
    orgSurname: string;
    orgEmail: string;
    orgPhone: string;
    orgCompany: string;
    orgSite: string;

}

export interface Premium{
    isPremium: boolean,
    isFishPhotos: boolean,
    isEmailInvitations: boolean,
    isAdvancedFacebook: boolean,
    isAdvancedInstagram: boolean
}

export interface Region{
    city: string,
    region: string,
    country: string
}

export class Challenge {

    id: string;
    alias: string
    areaDetails: string;
    startDate: string;
    endDate: string;
    challengeType: string;
    challengeInfo: string;
    challengeModel: string;
    signupInfo: string;
    place: string;
    rules?: string;
    amountTeamsMaximum: number;
    amountTeamsActual?: number;
    amountTeamsAvailable?: number;
    amountTeamsReservation?: number;
    amountTeamsReserve?: number;
    title: string;
    fishingMethod: string;
    teamSquad: number;
    sortTopBy: number;

    Region: Region
    Awards: Awards;
    Fee: Fee;
    Links: Links;
    Organizer: Organizer;
    Premium?: Premium;




}
