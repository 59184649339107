import { Component, OnInit, Output, EventEmitter} from '@angular/core';

import { AuthService } from 'src/app/auth-module/service/auth.service';
import { ServeUserService } from 'src/app/auth-module/service/serveUser.service';


@Component({
  selector: 'app-header-manage-profile-links',
  templateUrl: './header-manage-profile-links.component.html', 
})
export class HeaderManageProfileLinksComponent implements OnInit {
 

  constructor(private authService: AuthService, public serveUser: ServeUserService) { 
  }

  ngOnInit() {
  }

  becomeOrganiser(){
    this.authService.newOrganiser()
  }

  becomeReferee(){
    this.authService.newReferee()
  }



}