import { Component, OnInit } from '@angular/core';
import { BaseOrganiserComponent } from '../baseOrganiser.component';
import { UIService } from 'src/app/shared/service/ui.service';
import { ServeUserService } from 'src/app/auth-module/service/serveUser.service';


@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent extends BaseOrganiserComponent implements OnInit {

  hasContests: boolean = false
  hasDataFilled: boolean = false
  
  constructor(private UIservice: UIService, private serveUser: ServeUserService) {
    super(UIservice);

    if (this.serveUser.user.hasOwnProperty('roles')){
      this.hasContests = this.serveUser.user.roles.hasOwnProperty('organiser') ? true : false
    }
    this.hasDataFilled = this.serveUser.hasDataFilled

  }

  ngOnInit() {

  }

}
