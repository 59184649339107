import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseRefereeComponent } from '../../baseReferee.component';
import { UIService } from 'src/app/shared/service/ui.service';
import { RefereeDataService } from '../../services/referee-data.service';
import { Contest } from 'src/app/data/models/Manage/contest.model';
import { ServeUserService } from 'src/app/auth-module/service/serveUser.service';
import { Router } from '@angular/router';
import { ContestDataService } from '../../services/contest-data.service';

@Component({
  selector: 'app-select-contest',
  templateUrl: './select-contest.component.html',
  styleUrls: ['./select-contest.component.css']
})
export class SelectContestComponent extends  BaseRefereeComponent implements OnInit {

  contestIndex: number = null
  contestList$: Observable<Contest[]>

  constructor(public UIservice: UIService, 
              private refereeDataService: RefereeDataService,
              private contestDataService: ContestDataService,
              private serveUser: ServeUserService,
              private router: Router) {
    super(UIservice);

  }

  ngOnInit() {

    this.contestList$ = this.refereeDataService.getRefereeContestsList()
  }

  onSubmit(){

    let choosenContestKey: string;
    
    if (this.contestIndex != null)
    {
      choosenContestKey = this.serveUser.user.roles.referee[this.contestIndex].key
      this.contestDataService.setContest(choosenContestKey)
      // this.manageContest.challengeKey = choosenContestKey
      this.router.navigate(['referee/contest', choosenContestKey])
    }

  }

}
