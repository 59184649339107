import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';


@Component({
  selector: 'app-chart-bar-vertical',
  templateUrl: './chart-bar-vertical.component.html',
  styleUrls: ['./chart-bar-vertical.component.css']
})

export class ChartBarVerticalComponent implements OnInit,OnDestroy {
  
  @Input() xLabel: string
  @Input() yLabel: string
  @Input() chartData$: Observable<any[]>;
  @Input() chartData: any[]
  
  dataSubs: Subscription

  single: any[]
  preparedData: any[]
  multi: any[];

  dataPerDayPeriod: any[]
  dataPerHalfDate: any[]
  dataPerDay: any[]

  view: any[];
  
  // options
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = false;
  showXAxisLabel = true;
  xAxisLabel = '';
  showYAxisLabel = true;
  yAxisLabel = '';

  colorScheme = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
  };

  constructor(){
     
   }

  ngOnInit() {
    this.xAxisLabel = this.xLabel;
    this.yAxisLabel = this.yLabel;
    
    this.single = this.chartData
    this.dataSubs = this.chartData$.subscribe(data => {
      this.single = data
    })

  }


  onSelect(event) {
    console.log(event);
  }

  ngOnDestroy(){
    this.dataSubs.unsubscribe()
  }

}
