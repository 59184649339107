import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  photoUrl: string
}

@Component({
  selector: 'app-dialog-show-photo',
  templateUrl: './dialog-show-photo.component.html',
  styleUrls: ['./dialog-show-photo.component.css']
})
export class DialogShowPhotoComponent implements OnInit {

  constructor( @Inject(MAT_DIALOG_DATA) public data: DialogData) { }
  

  ngOnInit() {
  }

}
