<script src="https://cdn.jsdelivr.net/npm/cookieconsent@3/build/cookieconsent.min.js" data-cfasync="false"></script>
<mat-sidenav-container class="mat-typography">
  <mat-sidenav [fixedInViewport]="true" [fixedTopGap]="0" [fixedBottomGap]="0" #sidenav role="navigation">
    <app-sidenav-list (closeSidenav)="sidenav.close()" fxFlexFill fxLayout="column"></app-sidenav-list>
  </mat-sidenav>
      <mat-sidenav-content fxLayout="column">
        
          <app-header fxFlexOrder="1" fxFlex="none" (sidenavToggle)="sidenav.toggle()"></app-header>
           <main fxFlexOrder="2" fxFill>
                
                <div *ngIf="this.isLoading$ | async" fxFill fxLayout="column" fxLayoutAlign="center center">
                        <mat-spinner color="accent"></mat-spinner>
                </div>
                <router-outlet [hidden]="!(this.isLoading$ | async)"></router-outlet>
          </main>
          <app-partner-swiper *ngIf="this.UIService.isContest | async" fxFlex="none" fxFlexOrder="3"></app-partner-swiper>          
          <app-footer fxFlex="nogrow" fxFlexOrder="4"></app-footer>
        
       </mat-sidenav-content>
</mat-sidenav-container>
