<div [hidden]="(this.isLoading$ | async)" fxLayout="column" fxLayoutAlign="start center">
    
    <h1 class="mat-display-1" style="margin: 0 0 32px;" i18n="Classification">Classification</h1>
   
<div  class="mat-elevation-z8"  fxLayout="column" fxLayoutAlign="start center">
    <table *ngIf="this.dataSource.data.length > 0;else noData" mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="position">
            <th fxFlex="25" class="mat-body-strong" fxLayoutAlign="center center" mat-header-cell *matHeaderCellDef i18n="@@Place">Place</th>
            <td [ngClass]="element.Stats.displayClass"
                fxFlex="25" 
                fxLayoutAlign="center center" 
                mat-cell 
                *matCellDef="let element">
                <div class="mat-body-strong">{{element.place}}</div>
            </td>
        </ng-container>

        <ng-container matColumnDef="pegNo">
                <th fxFlex="25" class="mat-body-strong" fxLayoutAlign="center center" mat-header-cell *matHeaderCellDef i18n="@@d494be449864c2a49e7eedf6895ae54e5727ac2b">Peg</th>
                <td [ngClass]="element.Stats.displayClass"
                    fxFlex="25" 
                    fxLayoutAlign="center center" 
                    mat-cell *matCellDef="let element">
                    <div class="mat-body-strong">{{element.topFish[0].station}}</div>  
                </td>
        </ng-container>

        <ng-container matColumnDef="Score">
                <th fxFlex="25" class="mat-body-strong" fxLayoutAlign="center center" mat-header-cell *matHeaderCellDef i18n="@@Score">Score </th>
                <td [ngClass]="element.Stats.displayClass"
                    fxFlex="25" 
                    fxLayoutAlign="center center" 
                    mat-cell 
                    *matCellDef="let element">
                    <div class="mat-body-strong">{{element.classificationScore}}&nbsp;kg</div>
                </td>
        </ng-container>

        <ng-container matColumnDef="lastChange">
            <th fxFlex="25" class="mat-body-strong" fxLayoutAlign="center center" mat-header-cell *matHeaderCellDef i18n="changeInClassification| change in classification">Change </th>
            <td [ngClass]="element.Stats.displayClass"
                fxFlex="25" 
                fxLayoutAlign="center center" 
                mat-cell 
                *matCellDef="let element">
                <img [ngStyle]="{'width': '2.5vh;'}" [attr.src]="element.Stats.progressImage">
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator
            class="mat-small"
              [length]="this.dataSource.data.length"
              [pageSize]="pageSize"
              showFirstLastButtons
              (page)="pageEvent = $event">
    </mat-paginator>
</div>


<ng-template #noData>
    <div style="padding: 5vh;" fxFlex fxLayoutAlign="center center">

        <p class="mat-h2" i18n="@@NoFishingData">Uppss... No fish catches, yet.</p>
    </div>
</ng-template>

</div>