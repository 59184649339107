import { Component, OnInit, Input } from '@angular/core';
import { Awards } from 'src/app/data/models/Manage/challenge.model';

@Component({
  selector: 'app-awards',
  templateUrl: './awards.component.html',
  styleUrls: ['./awards.component.css']
})
export class AwardsComponent implements OnInit {

  @Input() awards: Awards
  @Input() currency: string

  constructor() { }

  ngOnInit() {
  }

}
