import { Injectable } from "@angular/core";
import { FirebaseService } from 'src/app/core-module/base/firebase.service';
import { } from '../entity/partner.model';
import { AngularFireList } from '@angular/fire/compat/database';
import { Partner } from 'src/app/data/models/Manage/partner.model';

@Injectable()

export class PartnerService{


    basePathReference: string;
    private mainPartnersReference = '/partners'

    constructor(private firebaseService: FirebaseService){

    }

    getContestPartners(): AngularFireList<Partner | any>{
        return this.firebaseService.getCollection(this.basePathReference)
    }

    getMainPartners():  AngularFireList<Partner | any>{
        return this.firebaseService.getCollection(this.mainPartnersReference)
    }
}

