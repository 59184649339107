import { Component, OnInit } from '@angular/core';
import { UIService } from 'src/app/shared/service/ui.service';
import { BaseLandingComponent } from '../baseLanding.component';
import { ServeUserService } from 'src/app/auth-module/service/serveUser.service';
import { AuthService } from 'src/app/auth-module/service/auth.service';

@Component({
  selector: 'app-choose-app',
  templateUrl: './choose-app.component.html',
  styleUrls: ['./choose-app.component.css']
})
export class ChooseAppComponent extends BaseLandingComponent implements OnInit {

  constructor(public UIservice: UIService, public serveUser: ServeUserService, private authService: AuthService) {
    super(UIservice);
  }

  ngOnInit() {
  }

  becomeAsNewOrganiser(){
    this.authService.newOrganiser()
    .then(() => console.log('success'))
    .catch(() => console.log('failed'))
  }

  becomeasNewReferee(){
    this.authService.newReferee()
    .then(() => console.log('success'))
    .catch(() => console.log('failed'))

  }

}
