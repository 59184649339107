import { Injectable } from '@angular/core';

@Injectable()
export class GlobalsService {

    private challengeKey: string
    private challengePath: string
    private partnersPath: string
    private teamsPathPublic: string
    private teamsPathPrivate: string
    private fishPath: string
    private rankingPath: string
    private messagePath: string
    private reservationPathPublic: string
    private reservationPathPrivate: string
    private refereePathPublic: string
    private refereePathPrivate: string

    setPathsWithKey(key: string){
        this.challengeKey = key;

        this.challengePath = 'Challenges' + '/' + key;
        this.partnersPath = this.challengePath + '/' + 'partners';
        this.teamsPathPublic = this.challengePath + '/' + 'teams/public';
        this.teamsPathPrivate = this.challengePath + '/' + 'teams/private';
        this.fishPath = this.challengePath + '/' + 'catches';
        this.rankingPath = this.challengePath + '/' + 'Ranking';
        this.messagePath = this.challengePath + '/' + 'messages';
        this.reservationPathPublic = this.challengePath + '/' + 'reservations/public'
        this.reservationPathPrivate = this.challengePath + '/' + 'reservations/private'
        this.refereePathPublic = this.challengePath + '/' + 'referees/public'
        this.refereePathPrivate = this.challengePath + '/' + 'referees/private'
    }
    getChallengePath(): string{
        return this.challengePath;
    }

    getPartnersPath(): string {
        return this.partnersPath;
    }

    getTeamsPathPublic(): string {
        return this.teamsPathPublic;
    }

    getTeamsPathPrivate(): string {
        return this.teamsPathPrivate;
    }

    getFishPath(): string {
        return this.fishPath;
    }

    getRankingPath(): string {
        return this.rankingPath;
    }

    getMessagePath(): string {
        return this.messagePath
    }
    getReservationPathPublic(): string {
        return this.reservationPathPublic
    }
    getReservationPathPrivate(): string {
        return this.reservationPathPrivate
    }
    getRefereesPathPublic(): string  {
        return this.refereePathPublic
    }
    getRefereesPathPrivate(): string  {
        return this.refereePathPrivate
    }
    
}