import { Component, OnInit, Input, EventEmitter, Output, ViewChild, NgZone } from '@angular/core';
import { Challenge, Region } from 'src/app/data/models/Manage/challenge.model';
import { DateAdapter, MAT_DATE_FORMATS, ThemePalette } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { AppDateAdapter, APP_DATE_FORMATS } from 'src/app/shared/helpers/format-datepicker';
import {CdkTextareaAutosize} from '@angular/cdk/text-field';
import { take } from 'rxjs/operators';
import { UIService } from 'src/app/shared/service/ui.service';
import {countries, polishRegions, germanRegion, czechRegions, slovakRegions, hungaryRegions} from 'src/app/shared/helpers/countryRegion'
import { ContestDataService } from '../../../services/contest-data.service';
import { ActivatedRoute } from '@angular/router';
import { ManageContestDataService } from '../../../services/manage-contest-data';
import { FormControl } from '@angular/forms';
import { Console } from 'console';
import * as moment from 'moment';

@Component({
  selector: 'app-main-data',
  templateUrl: './main-data.component.html',
  styleUrls: ['./main-data.component.css'],
  providers: [{provide: DateAdapter, useClass: AppDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS}]
})
export class MainDataComponent implements OnInit {
  @Input() modeEdit?: string = ''
  @Input() isCreate?: boolean = false
  @Input() challenge: Challenge
  @Output() edit: EventEmitter<string> = new EventEmitter<string>()
  @Output() endCreation: EventEmitter<boolean> = new EventEmitter<boolean>()
  @Output() challengeAlias: EventEmitter<string> = new EventEmitter<string>()

  @ViewChild('autosize', {static: true}) autosize: CdkTextareaAutosize;

  public color: ThemePalette = 'primary';
  public contestTypePlaceHolder = $localize`i.e. WCC, PCM, Social`
  public mainDescription = $localize`Main Invitation`
  editdetails = {
    title: null,
    teamSquad: null,
    challengeType: null,
    challengeModel: null,
    fishingMethod: null,
    signupInfo: null,
    sortTopBy: null,
    place: null,
    areaDetails: null,
    startDate: null,
    endDate: null,
    challengeInfo: null,
    amountTeamsMaximum: null,
    Region: <Region>{}
  }


  contestAlias: string
  selectedCountry: string

  _countries: string[] = countries
  regions: string[]

  contestType = new FormControl(false)

  constructor(private _ngZone: NgZone,
              public UIservice: UIService,
              private manageContest: ManageContestDataService) { }

  ngOnInit() {
    if (this.isCreate)
    {
      this.modeEdit ='create'
      this.editdetails = this.challenge
      this.challenge.challengeType = 'Team Contest'
    }


  }
  onCancel(){
    this.modeEdit = ''
    this.edit.emit('none')
  }

  checkIsPZW(event){
    if(event.value === 'PZW')
    {
      this.editdetails.fishingMethod = 'Casting'
      this.editdetails.sortTopBy = 'PZW'
    }
  }

  onDateInput(range: string, event: MatDatepickerInputEvent<Date>): void{

    let date = event.value

    if(range === 'start'){
        this.editdetails.startDate = date.toISOString()
    }
    else
    {
        this.editdetails.endDate = event.value.toISOString()
    }
  }

  proposeAlias(value: string){
    this.contestAlias = value.replace(/ /g, "").normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[.,\s]/g,"")
  }
  onEdit(){
    if(this.challenge)
    {
      this.editdetails.title = this.challenge.title
      this.editdetails.teamSquad = this.challenge.teamSquad
      this.editdetails.challengeType = this.challenge.challengeType
      this.editdetails.challengeModel = this.challenge.challengeModel
      this.editdetails.fishingMethod = this.challenge.fishingMethod
      this.editdetails.sortTopBy = this.challenge.sortTopBy
      this.editdetails.place = this.challenge.place
      this.editdetails.areaDetails = this.challenge.areaDetails
      this.editdetails.startDate = this.challenge.startDate
      this.editdetails.endDate = this.challenge.endDate
      this.editdetails.challengeInfo = this.challenge.challengeInfo
      this.editdetails.amountTeamsMaximum = this.challenge.amountTeamsMaximum
      this.editdetails.signupInfo = this.challenge.signupInfo
      this.editdetails.Region = {...this.challenge.Region}

      this.modeEdit = 'edit'
      this.edit.emit('main')
    }
  }

  onUpdate(){

    if (this.isCreate)
    {
      this.challengeAlias.emit(this.contestAlias)
      this.endCreation.emit(true)

    }
    else
    {

      this.manageContest.updateChallengeMainDetails(this.editdetails)
        .then(() => {
        this.modeEdit = ''
        this.edit.emit('none')
      })

    }

  }

  onChangeCountry(){

    switch (this.editdetails.Region.country)
    {
      case 'Polska':
      {
        this.regions = polishRegions
        break;
      }
      case 'Česko':
      {
        this.regions = czechRegions
        break;
      }
      case 'Slovensko':
        {
          this.regions = slovakRegions
          break;
        }
      case 'Magyarország':
        {
          this.regions = hungaryRegions
          break;
        }
      case 'Deutschland':
      {
        this.regions = germanRegion
        break;
      }
      default: {
        this.regions = null
      }

    }
  }

  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable.pipe(take(1))
        .subscribe(() => this.autosize.resizeToFitContent(true));
  }

}
