import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Angler } from 'src/app/data/models/Manage/angler.model';
import {FormControl, FormGroupDirective, NgForm, Validators, FormGroup} from '@angular/forms';
import { Team, TeamPrivateData } from 'src/app/data/models/Manage/team.model';
;
import { BaseComponent } from '../base.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store'
import * as fromRoot from '../../../../../../../src/app/presentation/app.reducer'
import * as UI from '../../../../../../../src/app/shared/service/ui.actions'
import { Subscription } from 'rxjs';

import { UIService } from 'src/app/shared/service/ui.service';
import { SignUpTeamService } from '../../../services/signUpTeam.service';
import { ProvideDataService } from '../../../services/provideDataService';

declare var require: any

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css'],
  providers: [SignUpTeamService]
})
export class SignupComponent extends BaseComponent implements OnInit, OnDestroy {

  @ViewChild('f') signupForm: NgForm
  public successIcon = "assets/logos/Green_check.svg.png";
  public failIcon = "assets/logos/210px-X_mark.svg.png";

  private isSignUpOpen: boolean
  rulesChecked = false

  public captcha
  signAction = false
  public signActionResult
  private keySubscription: Subscription;
  private challengeDataSubscription: Subscription;
  public resultIcon = this.successIcon

  team: Team = new Team()
  teamRunner: number | string

  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  telFormControl = new FormControl('', [
    Validators.minLength(9)
  ])

  nullFormControl = new FormControl('', [
    Validators.minLength(3)
  ])


  constructor(private route: ActivatedRoute,
              private router: Router,
              private dataService: ProvideDataService,
              private store: Store<fromRoot.State>,
              private reservationService: SignUpTeamService,
              public uiService: UIService)
              {

    super(route,dataService, uiService)

    if(!this.dataService.keyReady.value)
    {
      if(this.dataService.challenge.value !== null)
          {
            let data = this.dataService.challenge.value
            this.teamRunner = data.Fee.feeTeamRunner
            this.isSignUpOpen = data.Fee.isSignUpOpen
          }

    this.keySubscription = this.dataService.keyReady.asObservable().subscribe(bool => {
      this.store.dispatch(new UI.StartLoadingMain())

      if(bool)
      {
        this.challengeDataSubscription = this.dataService.challenge$.subscribe(data => {
          this.teamRunner = data.Fee.feeTeamRunner
          this.isSignUpOpen = data.Fee.isSignUpOpen
          this.store.dispatch(new UI.StopLoadingMain())
          if(!this.isSignUpOpen) this.router.navigate(['/main'])
        })
      }

    })
  }
  else
  {
    let data = this.dataService.challenge.value
    this.teamRunner = data.Fee.feeTeamRunner
    this.isSignUpOpen = data.Fee.isSignUpOpen
  }




   }

  ngOnInit() {

    this.team.anglers = [new Angler(), new Angler()]
    this.team.privateData = <TeamPrivateData>{}

  }

 async onSubmit(){

    if(!this.uiService.isPremium)
    {
      this.router.navigateByUrl('main')
    }
    else{

    this.store.dispatch(new UI.StartLoadingMain())

    let result: string

    let reservationTeam = {
      reservationDate: new Date().toISOString(),
      team: this.team
    }

    await this.reservationService.setTeamReservation(reservationTeam)
    .then(() => {
      this.signActionResult = 'success'
      this.resultIcon = this.successIcon
      this.store.dispatch(new UI.StopLoadingMain())
      this.signAction = true;
     })
    .catch((e) => {
      this.signActionResult = 'error'
      this.resultIcon = this.failIcon
      this.store.dispatch(new UI.StopLoadingMain())
      console.log(e.message)
      console.log(e)
      this.signAction = true;

    })
    .finally(() => {
      this.store.dispatch(new UI.StopLoadingMain())
    })
  }

  }
  newFormModel(){
    this.team = new Team()
    this.team.anglers = [new Angler(), new Angler()]
    this.team.privateData = <TeamPrivateData>{}
    this.rulesChecked = false
    this.signAction = false
  }
  addTeamRunner(){
    let runner = new Angler();
    runner.teamRole = 'Runner';
    this.team.anglers.push(runner)
  }
  ngOnDestroy(){
    if (this.challengeDataSubscription) {this.challengeDataSubscription.unsubscribe()}
   if (this.keySubscription) {this.keySubscription.unsubscribe()}
  }
}
