import { Component, OnInit } from '@angular/core';
import { BaseLandingComponent } from '../../baseLanding.component';
import { UIService } from 'src/app/shared/service/ui.service';

@Component({
  selector: 'app-referee',
  templateUrl: './referee.component.html',
  styleUrls: ['./referee.component.css']
})
export class RefereeComponent extends BaseLandingComponent  implements OnInit {

  constructor(private UIservice: UIService) {
    super(UIservice);
  }

  ngOnInit() {
  }

}
