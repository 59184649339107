
<div [hidden]="!this.dataSource" fxFlexFill fxLayout="column" fxLayoutAlign="start center" >
    
    <h1 class="mat-display-1" style="margin: 32px 0 32px;" i18n="@@Drawing">Drawing</h1>

    <div style="margin: 2vh;" fxFlex.gt-md="80" fxFlex="100" class="mat-elevation-z8" fxLayout="column">

        <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap.xs="1vh">

            <div fxFlex="50" fxFlex.xs fxFlexOrder.xs="1">
                
                <mat-form-field  fxLayoutAlign="center center" fxLayoutAlign.xs="center center">
                    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
                </mat-form-field>
        
            </div>
        
            <div fxFlex="50" fxFlex.xs fxFlexOrder.xs="3">
        
                <mat-paginator [length]="this.dataSource.data.length"
                                [pageSize]="!this.UIservice.isMobileSM ? 5 : this.dataSource.data.length"
                                [pageSizeOptions]="!this.UIservice.isMobileSM ? [5 , 10] : false"
                                showFirstLastButtons
                                (page)="pageEvent = $event">
                </mat-paginator>
                
            </div>
        

        <table *ngIf="!this.UIservice.isMobileSM; else cards" fxFlex="100" mat-table [dataSource]="dataSource">
            
            <ng-container matColumnDef="station">
                <th fxFlex fxLayoutAlign="center center" mat-header-cell *matHeaderCellDef i18n="@@Swim">Swim</th>
                <td fxFlex
                    fxLayoutAlign="center center" 
                    mat-cell 
                    *matCellDef="let element">
                    {{element.station}}
                </td>
            </ng-container>
    
                <ng-container matColumnDef="drawSequence">
                    <th fxFlex fxLayoutAlign="center center" mat-header-cell *matHeaderCellDef i18n="@@DrawSequence">Draw Seq.</th>
                    <td fxFlex
                        fxLayoutAlign="center center" 
                        mat-cell *matCellDef="let element">
                        {{element.drawSequence}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="Angler1">
                    <th fxFlex fxLayoutAlign="center center" mat-header-cell *matHeaderCellDef i18n="Angler1| Angler1@@">Angler 1</th>
                    <td fxFlex 
                        fxLayoutAlign="center center" 
                        fxLayoutGap="1vh"
                        mat-cell 
                        *matCellDef="let element">
                        {{element.anglers[0].name}}&nbsp;{{element.anglers[0].surname}}

                    </td>
                </ng-container>

    

            <ng-container matColumnDef="Angler2">
                <th fxFlex fxLayoutAlign="center center" mat-header-cell *matHeaderCellDef i18n="Angler2| Angler2@@">Angler 2</th>
                <td fxFlex 
                    fxLayoutAlign="center center" 
                    fxLayoutGap="1vh"
                    mat-cell 
                    *matCellDef="let element">
                    {{element.anglers[1].name}}&nbsp;{{element.anglers[1].surname}}

                </td>
            </ng-container>


    
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        
        <ng-template #cards>
    
            <div fxFlexFill fxLayout="column" fxLayoutGap="1vh" fxFlexOrder.xs="2">
                <mat-accordion *ngFor="let element of this.cardData | async, let i = index">
                    <mat-expansion-panel>
                      <mat-expansion-panel-header fxLayout="row" [ngStyle]="{'background-color': element.station ? '#7cd47c' : 'white' }">  
                        
                        <mat-panel-title fxFlex>
                         
                            <div fxFlex *ngFor="let angler of element.anglers">
                                {{angler.name}}&nbsp;{{angler.surname | slice:0:1}}
                            </div>
        
                        </mat-panel-title>
                        
                      </mat-expansion-panel-header>
         
                      <div fxLayout="column" fxLayoutAlign="start center">
                        
                                <div class="mat-h4" style="font-weight: bold;" i18n="@@Drawing">Drawing</div>
                      
                        <div fxLayout="row" style="width: 100%;" fxLayoutAlign="start center">
                        
                            <div fxFlex fxLayoutAlign="center center" style="font-weight: bold;">
                               <label i18n="Swim">Swim:&nbsp;{{element.station}}</label>
                               
                            </div>

                            <div fxFlex fxLayoutAlign="center center" style="font-weight: bold;">
                                <label i18n="@@DrawSequencee">Draw Seq.:&nbsp;{{element.drawSequence}}</label>                                
                             </div>

                        </div>
                      </div>
        
        
                       
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </ng-template>

        </div>
        
    </div>


    </div>