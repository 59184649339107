import { Subject, Observable} from 'rxjs'
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Injectable } from '@angular/core';
import { Partner } from 'src/app/data/models/Manage/partner.model';
import { DialogConfirmationComponent } from '../components/dialogs/dialog-confirmation/dialog-confirmation.component';
import { DialogSpinnerComponent } from '../components/dialogs/dialog-spinner/dialog-spinner.component';
import { DialogAddFishComponent } from '../components/dialogs/dialog-add-fish/dialog-add-fish.component';
import { Fish } from 'src/app/data/models/Manage/fish.model';
import { Store} from '@ngrx/store'
import * as fromRoot from 'src/app/presentation/app.reducer'
import { DialogShowPhotoComponent } from '../components/dialogs/dialog-show-photo/dialog-show-photo.component';
import { DialogSimpleInputComponent } from '../components/dialogs/dialog-simple-input/dialog-simple-input.component';
import { AnyComponentDialogComponent } from '../components/dialogs/any-component-dialog/any-component-dialog.component';


@Injectable()
export class UIService {

    loadingStateChanged = new Subject<boolean>();

    // Challenge routing
    rulesLink: string
    challengeName: string
    isPremium: boolean
    isLive: boolean = false;
    isSingUpAvailable: boolean = false;
    isTeamsAvailable: boolean = false;
    challengeAlias: string

    // Partners for challenge or Main
    partners$: Observable<Partner[]>
    partnersLoaded: Subject<boolean> = new Subject<boolean>();

    // Organiser contact for footer
    organiserLoaded: Subject<any> = new Subject<any>();

    // Application load status

    isOrganiser:  Observable<boolean>
    isReferee:  Observable<boolean>
    isAdmin:  Observable<boolean>
    isContest:  Observable<boolean>
    isMain:  Observable<boolean>

    isMobileXS = false;
    isMobileSM = false;

    constructor(private snackbar: MatSnackBar,
                private dialog: MatDialog,
                private store: Store<fromRoot.State>){
        this.getIsMobile()
        this.getAppStates()
    }

    getIsMobile(){
        const w = window.innerWidth
        const breakpointXS = 600;
        const breakpointSM = 960;
        if (w < breakpointXS) {
        this.isMobileXS = true
        this.isMobileSM = true
        }
        else if (w < breakpointSM){
            this.isMobileXS = false
            this.isMobileSM = true
        }
        else{
            this.isMobileXS = false
            this.isMobileSM = false
        }

    }

    private getAppStates(){
        this.isOrganiser = this.store.select(fromRoot.getIsOrganiser)
        this.isReferee = this.store.select(fromRoot.getIsReferee)
        this.isAdmin = this.store.select(fromRoot.getIsAdmin)
        this.isMain = this.store.select(fromRoot.getIsLanding)
        this.isContest = this.store.select(fromRoot.getIsContest)
    }

    showSnackBar(message, action, duration){

        this.snackbar.open(message,action,{
            duration: duration
        });
    }

    openConfirmationDialog(_title: string, _content: string): Observable<any> {
        const dialogRef = this.dialog.open(DialogConfirmationComponent, {data: {title: _title, content: _content}})

        return dialogRef.afterClosed()
    }

    openInfoDialog(_title: string, _content: string, _type: boolean){

        let picture = _type ? "assets/logos/Green_check.svg.png" : "assets/logos/210px-X_mark.svg.png"

        return this.dialog.open(AnyComponentDialogComponent, {data: {header: _title, content: _content, resultIcon: picture}})
    }

    openSpinnerDialog(){
        const dialogRef = this.dialog.open(DialogSpinnerComponent)
        return dialogRef
    }

    openAddFishDialog(fish: Fish, teamsObservableRef: Observable<any>){
        const dialogRef = this.dialog.open(DialogAddFishComponent, {
            data: {newFish: fish, teamObsRef: teamsObservableRef, editMode: null}
        })
        return dialogRef.afterClosed()
    }

    openEditFishDialog(fish: Fish, teamsObservableRef: Observable<any>){
        const dialogRef = this.dialog.open(DialogAddFishComponent, {
            data: {newFish: fish, teamObsRef: teamsObservableRef, editMode: true}
        })
        return dialogRef.afterClosed()
    }

    openFishPhotoDialog(photoUrl){
        const dialogRef = this.dialog.open(DialogShowPhotoComponent, {
            data: {photoUrl: photoUrl}
        })
        return dialogRef.afterClosed()
    }

    openSimpleInputDialog(_type: string){

        const dialogRef = this.dialog.open(DialogSimpleInputComponent, {
            data: {type: _type, newValue: null}
        })

        return dialogRef.afterClosed()
    }

}
