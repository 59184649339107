import { Injectable } from '@angular/core';
import { ServeUserService } from 'src/app/auth-module/service/serveUser.service';
import { Observable} from 'rxjs';
import { ContestService } from 'src/app/core-module/entityServices/contest.service';
import { Contest } from 'src/app/data/models/Manage/contest.model';
import { Partner } from 'src/app/data/models/Manage/partner.model';
import { Team } from 'src/app/data/models/Manage/team.model';
import { Reservation } from 'src/app/data/models/Manage/reservation.model';
import { Challenge } from 'src/app/data/models/Manage/challenge.model';
import { Message } from 'src/app/data/models/Manage/message.model';
import { AuthService } from 'src/app/auth-module/service/auth.service';
import { map } from 'rxjs/operators';
import { Referee } from 'src/app/data/models/Manage/referee.model';
import { GlobalsService } from 'src/app/core-module/entityServices/serviceGlobals';
import { Fish } from 'src/app/data/models/Manage/fish.model';




@Injectable()
export class ContestDataService {

    contestKey: string
    selectedContest$: Observable<Contest>

    challenge$: Observable<Challenge>
    partners$: Observable<Partner[]>
    signedTeams$: Observable<Team[]>
    reservationTeams$: Observable<Reservation[]>
    reserveList$: Observable<Reservation[]>
    messages$: Observable<Message[]>
    referees$: Observable<Referee[]>
    refereesReservation$: Observable<Referee[]>
    catches$: Observable<Fish[]>

    constructor(private serveUser: ServeUserService,
                private contestService: ContestService,
                private authService: AuthService,
                private globals: GlobalsService)
    {

    }

    setContest(key: string): void{
        if (key && this.contestKey != key)
        {
            this.contestKey = key
            this.contestService.contestKey = key
            this.globals.setPathsWithKey(key)
            this.contestService.basePathReference = 'Challenges/'+ key
            this.challenge$ = null
            this.partners$= null
            this.signedTeams$ = null
            this.reservationTeams$ = null
            this.messages$ = null
        }
    }

    getChallenge(){
        this.challenge$ = this.contestService.getChallenge()
    }

    getCatches(){
        this.catches$ = this.contestService.getCatches()
    }

    getPartners(){
        this.partners$ = this.contestService.getContestPartners()
    }

    getSignedTeams(){
        this.signedTeams$ = this.contestService.getSignedTeams()
    }

    getReservationTeams(){
        this.reservationTeams$ = this.contestService.getReservationTeams()
    }
    getReserveTeams(){
        this.reserveList$ = this.contestService.getReserveTeams()
    }

    getRefereesConfirmed(){
        this.referees$ = this.contestService.getRefereesConfirmed()
    }

    getRefereesReservation(){
        this.refereesReservation$ = this.contestService.getRefereesReservation()
    }

    getMessages(){
        this.messages$ = this.contestService.getMessages()
    }

    getOrganiserContestsList(): Observable<Contest[]>{

        return this.authService.getUserContestList('organiser').snapshotChanges()
              .pipe<Contest[]>(map(docArray => {
                  return docArray.map(doc => {
                      return {
                          ...doc.payload.val()
                      }
                  })
              }))
    }
    async uploadFishPhoto(_file: File ){

        const _key = this.contestKey
        const collection = this.contestKey + "/contestFiles/photos/"
        var downloadLink

        await this.contestService.uploadFile(_file, collection)
                            .then(async () => { await this.contestService.getDownloadURL(_file.name, collection)
                                                .then((value) => { downloadLink = value})})
                            .catch((e) => { })

        return downloadLink
    }

    async setNewFish(newFish: Fish){

        const _key = this.contestKey
        const collection = this.contestKey + "/contestFiles/photos/"

        let modernFish = {
          anglerID: newFish.anglerID,
          anglerName: newFish.anglerName,
          anglerSurname: newFish.anglerSurname,
          date: newFish.date,
          fishType: newFish.fishType,
          photoUrl: newFish.photoUrl,
          refereeID: newFish.refereeID,
          station: newFish.station.toString(),
          teamID: newFish.teamID,
          weight: newFish.weight
        }

        return this.contestService.setObject(_key, 'catches', modernFish)
    }

    async editFish(newFish: Fish){
        const _key = this.contestKey
        return this.contestService.updateCollectionObject(_key, 'catches',newFish.id ,newFish)
    }



}
