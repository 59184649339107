import {Component, Input, OnInit} from '@angular/core';

@Component({
  template: '<youtube-player [width]="xWidth" [height]="yHeight" [videoId]="this.videoId"></youtube-player>',
  selector: 'youtube-player-object',
})
export class YoutubePlayerComponent implements OnInit {
  @Input() videoId: String
  @Input() isSmallScreen: boolean

  xWidth = 550;
  yHeight = 335;

  ngOnInit() {

      if(this.isSmallScreen)
      {
        this.xWidth = 300;
        this.yHeight = 182;
      }

      // This code loads the IFrame Player API code asynchronously, according to the instructions at
      // https://developers.google.com/youtube/iframe_api_reference#Getting_Started
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      document.body.appendChild(tag);

  }
}
