import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { UIService } from 'src/app/shared/service/ui.service';

@Component({
  selector: 'app-comp-my-data',
  templateUrl: './comp-my-data.component.html',
  styleUrls: ['./comp-my-data.component.css']
})
export class CompMyDataComponent implements OnInit, OnDestroy {

  fragmentSubscription: Subscription

  constructor(public UIservice: UIService, private route: ActivatedRoute) {

   }

  ngOnInit() {
  this.fragmentSubscription = this.route.fragment.subscribe((fragment: string) => {
    if(!!fragment)
  {
    setTimeout(() => {
      this.scroll(fragment);
    }, 250)
    
}
  })
  }

  scroll(id){
    const elmnt = document.getElementById(id);
    elmnt.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
}

ngOnDestroy(): void {
  this.fragmentSubscription.unsubscribe();
}

}
