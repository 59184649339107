import { Component, OnInit, Input } from '@angular/core';
import { Challenge } from 'src/app/data/models/Manage/challenge.model';

@Component({
  selector: 'app-premium-form',
  templateUrl: './premium-form.component.html',
  styleUrls: ['./premium-form.component.css']
})
export class PremiumFormComponent implements OnInit {

  @Input() challenge: Challenge
  predictedPricePLN: number = 0

  constructor() { }

  ngOnInit() {
 
  }

  calculatePrice(){

    if (this.challenge.Premium.isPremium)
    {
      let _teams = this.challenge.amountTeamsMaximum
      let _contestFee = this.challenge.Fee.feeAmount
      let _teamSquad = this.challenge.teamSquad

      let _totalContestFees = this.challenge.Fee.feeType === 'Team' ? _teams * _contestFee : _teams * _contestFee * _teamSquad

      let _premiumFee = 600

      let _functionRate = 1 / 100

      // let _fishPhotoFee = this.challenge.Premium.isFishPhotos ? _totalContestFees * _functionRate : 0
      let _fishPhotoFee = _totalContestFees * _functionRate
      let _isEmailInvitationsFee = this.challenge.Premium.isEmailInvitations ? _totalContestFees * _functionRate : 0
      let _isAdvancedFacebookFee = this.challenge.Premium.isAdvancedFacebook ? _totalContestFees * _functionRate : 0
      let _isAdvancedInstagramFee = this.challenge.Premium.isAdvancedInstagram ? _totalContestFees * _functionRate : 0

      if (this.challenge.Fee.feeCurrency === "PLN")
      {
        this.predictedPricePLN = _premiumFee + _fishPhotoFee +_isEmailInvitationsFee + _isAdvancedFacebookFee + _isAdvancedInstagramFee
      }
      
    }
    else
    {
      if (this.challenge.Fee.feeCurrency === "PLN")
      {
        this.predictedPricePLN = 0
      }
    }
    

  }

  show(){
    console.log(this.challenge.Premium)
  }

}
