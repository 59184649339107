import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { WelcomeComponent } from './presentation/content/welcome/welcome.component';
import { LiveComponent } from './presentation/content/live/live.component';
import { SignupComponent } from './presentation/content/signup/signup.component';
import { TeamsComponent } from './presentation/content/teams/teams.component';
import { ContestGuard } from '../../../../src/app/auth-module/guards/contest-guard';
import { DrawStationsComponent } from './presentation/content/draw-stations/draw-stations.component';
import { PremiumGuard } from 'src/app/auth-module/guards/premium-guard';
import { RefereesComponent } from './presentation/content/referees/referees.component';




const routes: Routes = [
  {path: 'contest',  canActivate: [ContestGuard], children: [
  {path: ':challengeID', children: [
    {path: '', component: WelcomeComponent},
    {path: 'live', component: LiveComponent},
    {path: 'sign-me', component: SignupComponent},
    {path: 'referees', component: RefereesComponent},
    {path: 'teams', children: [
        {path: '', component: TeamsComponent},
        {path: ':actionCode', component: TeamsComponent}
    ]},
    {path: 'drawing',  component: DrawStationsComponent},
    {path: '**', redirectTo: '', pathMatch: 'full'}
  ]}
]},
{path: 'zawody',  canActivate: [ContestGuard], children: [
  {path: ':challengeID', children: [
    {path: '', component: WelcomeComponent},
    {path: 'live', component: LiveComponent},
    {path: 'zapisy', component: SignupComponent},
    {path: 'uczestnicy', component: TeamsComponent},
    {path: 'losowanie', component: DrawStationsComponent},
    {path: '**', redirectTo: '', pathMatch: 'full'}
  ]}
]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { 
}
