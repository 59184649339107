<section    *ngIf="this.organizer && modeEdit === ''; else editOrganiser"
            class="content-container"  fxLayout="column" 
            fxLayoutAlign="start center" fxLayoutGap="1vw">

       
    <h1 class="mat-headline" style="text-decoration: underline;" i18n="Organiser Details Header| Header for Organiser Details@@">Organiser Details:</h1> 
            
    <div style="width: 100%;" fxLayout="column" fxLayoutAlign="start start" >
        
        <p fxLayout="row" fxLayoutAlign="start start">
            <label class="propertyLabel" title="Organiser Name" i18n="Organiser Name Label|Organiser Name label@@">Name:</label>
        {{this.organizer.orgName}}
        </p>

        <p fxLayout="row" fxLayoutAlign="start start">
            <label class="propertyLabel" title="Organiser Surname" i18n="Organiser Surname Label|Organiser Surname label@@">Surname:</label>
        {{this.organizer.orgSurname}}
        </p>

        <p fxLayout="row" fxLayoutAlign="start start">
            <label class="propertyLabel" title="Organiser Company" i18n="Organiser Company Label|Organiser Company label@@">Company:</label>
        {{this.organizer.orgCompany}}
        </p>

        <p fxLayout="row" fxLayoutAlign="start start">
            <label class="propertyLabel" title="Organiser Phone" i18n="Organiser Phone Label|Organiser Phone label@@">Phone:</label>
        {{this.organizer.orgPhone}}
        </p>

        <p fxLayout="row" fxLayoutAlign="start start">
            <label class="propertyLabel" title="Organiser Site" i18n="Organiser Site Label|Organiser Site label@@">Site:</label>
        {{this.organizer.orgSite}}
        </p>

        <p fxLayout="row" fxLayoutAlign="start start">
            <label class="propertyLabel" title="Organiser Email" i18n="Organiser Email Label|Organiser Email label@@">Email:</label>
        {{this.organizer.orgEmail}}
        </p>

    </div>

    <button *ngIf="!this.modeEdit"
            mat-button color="accent" 
            (click)="onEdit()"
            i18n="Organiser Edit Button|Organiser Edit Button@@"
    >
    Edit Organiser
    </button>
</section>

<ng-template #editOrganiser>

    <section [ngClass]="{'content-container-edit': this.modeEdit === 'edit' || (this.isCreate && !this.UIservice.isMobileSM),
                        'mobile-content-container-edit': this.isCreate && this.UIservice.isMobileSM}" 
                        fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="1vw">
    
        <h1 *ngIf="!this.isCreate" class="mat-headline" i18n="Organiser Edit Details Header| Header for Edit Organiser Details@@">Edit Organiser Details:</h1> 
        <h1 *ngIf="this.isCreate" class="mat-headline" i18n="Organiser Data Header| Header for Organiser Data@@">Enter Organiser Data:</h1>

        <form *ngIf="this.editOrganiser" fxLayout="column" fxLayoutAlign="start center" #e="ngForm">
                   
            <mat-form-field fxFlexFill>
                <mat-label i18n="Name| label Name@@">Name:</mat-label>
                <input matInput
                        [(ngModel)]="editOrg.orgName"
                        name="name"
                        required
                >
                </mat-form-field>
                
                <mat-form-field fxFlexFill>
                    <mat-label i18n="Surname| label surname@@">Surname:</mat-label>
                    <input matInput
                            [(ngModel)]="editOrg.orgSurname"
                            name="surname"
                            required
                    >
                </mat-form-field>

                <mat-form-field fxFlexFill>
                    <mat-label i18n="Company| label Company@@">Company:</mat-label>
                    <input matInput
                            [(ngModel)]="editOrg.orgCompany"
                            name="company"
                            required
                    >
                </mat-form-field>

                <mat-form-field fxFlexFill>
                    <mat-label i18n="Phone| label Phone@@">Phone:</mat-label>
                    <input matInput
                            type="tel"
                            [(ngModel)]="editOrg.orgPhone"
                            name="phone"
                            placeholder="+48 555-555-1234"
                            required
                    >
                </mat-form-field>

                <mat-form-field fxFlexFill>
                    <mat-label i18n="Site| label Site@@">Site:</mat-label>
                    <input matInput
                            [(ngModel)]="editOrg.orgSite"
                            name="site"
                    >
                </mat-form-field>

                <mat-form-field fxFlexFill>
                    <mat-label i18n="Email| label Email@@">Email:</mat-label>
                    <input matInput
                            type="email"
                            [(ngModel)]="editOrg.orgEmail"
                            name="email"
                            required
                    >
                </mat-form-field>
    

                <div fxFlex fxLayoutGap="2vh">
                    <button *ngIf="!this.isCreate"
                        mat-raised-button
                        color="warn"
                        i18n="cancel| cancel button@@"
                        (click)="onCancel()">Cancel</button>
                
                    <button [disabled]="!(e.valid && e.touched)" 
                        mat-raised-button
                        color="primary"
                        i18n="Edit Save Button| Edit Save Button@@"
                        (click)="onUpdate()">Save</button>

                    <label i18n="fillRequiredFields| fill Required Data@@">* required fields</label>
                </div>        
                  
        </form>
        
    </section>

</ng-template>
