// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyC0--WWHvV8LEczQ6R5WgHyFFEtFjwxmRA",
    authDomain: "carp-contests-app.firebaseapp.com",
    databaseURL: "https://carp-contests-app.firebaseio.com",
    projectId: "carp-contests-app",
    storageBucket: "carp-contests-app.appspot.com",
    messagingSenderId: "661690246694",
    appId: "1:661690246694:web:69e8f43d9a8e79b4db68e7",
    measurementId: "G-DGQVSS8496"
  },
  appCheck: {
    recaptcha3SiteKey: '',
    isTokenAutoRefreshEnabled: true
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
