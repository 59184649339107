<div class="content-container" fxLayout="row" >

    <div fxFlexFill fxLayoutGap.gt-sm="4vw" fxLayoutGap="10vw"  fxLayout="column" fxLayout.gt-sm="row">

        <div fxFlex fxLayoutAlign="center center">
            <app-message-writer fxFlex></app-message-writer>
        </div>

        <div fxFlex fxLayoutAlign="center start">
            <app-messages-list></app-messages-list>
        </div>

    </div>
</div>
