<section *ngIf="this.links && modeEdit === ''; else editLinksForm" class="content-container" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="1vw">

       
    <h1 class="mat-headline" style="text-decoration: underline;" i18n="Links Details Header| Links for Fees Detailss@@">Links:</h1> 
            
    <div style="width: 100%;" fxLayout="column" fxLayoutAlign="start start" >
        
        <p *ngIf="this.links.bankSpotsMapLink" fxLayout="row" fxLayoutAlign="start start">
            <label class="propertyLabel" title="Bank Spot Map" i18n="Bank Spot Map Label|Bank Spot Map label@@">Bank Spot Map:</label>
        {{ (this.links.bankSpotsMapLink.length > 20) ? (this.links.bankSpotsMapLink | slice:0:20)+'...': this.links.bankSpotsMapLink}}
        </p>

        <p *ngIf="this.links.challengeAreaLink" fxLayout="row" fxLayoutAlign="start start">
            <label class="propertyLabel" title="Google Link" i18n="Google Link Label|Google Link label@@">Google Link:</label>
        {{ (this.links.challengeAreaLink.length > 20) ? (this.links.challengeAreaLink | slice:0:20)+'...': this.links.challengeAreaLink}}
        </p>

        <p *ngIf="this.links.challengeFisheryLink" fxLayout="row" fxLayoutAlign="start start">
            <label class="propertyLabel" title="Fishery Site" i18n="Fishery Site Label|Fishery Site label@@">Fishery Site:</label>
        {{ (this.links.challengeFisheryLink.length > 20) ? (this.links.challengeFisheryLink | slice:0:20)+'...': this.links.challengeFisheryLink}}
        </p>

        <p *ngIf="this.links.challengeRouteMeLink" fxLayout="row" fxLayoutAlign="start start">
            <label class="propertyLabel" title="Route Me Link" i18n="Route Me Link Label|Route Me Link label@@">Route Me Link:</label>
        {{ (this.links.challengeRouteMeLink.length > 20) ? (this.links.challengeRouteMeLink | slice:0:20)+'...': this.links.challengeRouteMeLink}}
        </p>

        <p *ngIf="this.links.posterLink" fxLayout="row" fxLayoutAlign="start start">
            <label class="propertyLabel" title="Poster Link" i18n="Poster Label|Poster label@@">Poster:</label>
        {{ (this.links.posterLink.length > 20) ? (this.links.posterLink | slice:0:20)+'...': this.links.posterLink}}
        </p>

        <p *ngIf="this.links.rulesLink" fxLayout="row" fxLayoutAlign="start start">
            <label class="propertyLabel" title="Rules Link" i18n="Rules Link Label|Rules Link label@@">Rules Link:</label>
        {{ (this.links.rulesLink.length > 20) ? (this.links.rulesLink | slice:0:20)+'...': this.links.rulesLink}}
        </p>

    </div>

    <button *ngIf="!this.modeEdit"
            mat-button color="accent" 
            (click)="onEdit()"
            i18n="Links Edit Button|Links Edit Button@@"
    >
    Edit Links
    </button>
</section>

<ng-template #editLinksForm>
    <section [ngClass]="{'content-container-edit': this.modeEdit === 'edit' || this.isCreate && !this.UIservice.isMobileSM,
                         'mobile-content-container-edit': this.isCreate && this.UIservice.isMobileSM}"  
                            fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="1vw">
    
        <h1 *ngIf="!this.isCreate"class="mat-headline" i18n="Links Edit Header| Header for Edit Links@@">Edit Links:</h1> 
        <h1 *ngIf="this.isCreate"class="mat-headline" i18n="Links Enter Header| Header for Enter Links@@">Enter Links:</h1> 

        <form *ngIf="this.editLinks" fxLayout="column" fxLayoutAlign="start center" #l="ngForm">
             
           <div fxLayout="row" fxLayoutAlign="center center">

            <mat-form-field>
                <mat-label title="Rules Link" i18n="Rules Link Label|Rules Link label@@">Rules</mat-label>
                <input matInput
                        type="email"
                        [(ngModel)]="editLinks.rulesLink"
                        name="UploadRules"
                        placeholder=".PDF, .doc"
                        (click)="rulesInput.click()"
                        required
                >
                
            </mat-form-field>
            
            <!-- <button type="button" color="accent" mat-stroked-button (click)="rulesInput.click()">Upload</button> -->
            <input hidden (change)="onFileSelected('rules', $event)" #rulesInput type="file"  accept=".doc,.pdf" id="rulesFile">

        </div> 

        <div fxLayout="row" fxLayoutAlign="center center">

            <mat-form-field>
                <mat-label title="Poster Link" i18n="Poster Label|Poster label@@">Poster</mat-label>
                <input matInput
                        [(ngModel)]="editLinks.posterLink"
                        name="UploadPoster"
                        placeholder=".JPG"
                        (click)="posterInput.click()"
                >
            </mat-form-field>

            <!-- <button type="button" color="accent" mat-stroked-button (click)="posterInput.click()">Upload</button> -->
            <input hidden (change)="onFileSelected('poster', $event)" #posterInput type="file" accept="image/*" id="posterFile">

        </div>

        <div fxLayout="row" fxLayoutAlign="center center">

            <mat-form-field>
                <mat-label title="Bank Spot Map" i18n="Bank Spot Map Label|Bank Spot Map label@@">Bank Spot Map</mat-label>
                <input matInput
                        [(ngModel)]="editLinks.bankSpotsMapLink"
                        name="BankSpotMapLink"
                        placeholder=".JPG"
                        (click)="spotsMapInput.click()"
                >
                </mat-form-field>

            <!-- <button type="button" color="accent" mat-stroked-button (click)="spotsMapInput.click()">Upload</button> -->
            <input hidden (change)="onFileSelected('spots', $event)" #spotsMapInput type="file" accept="image/*" id="SpotsMapFile">
                
        </div>

                <mat-form-field>
                    <mat-label title="Fishery Site" i18n="Fishery Site Label|Fishery Site label@@">Fishery Site</mat-label>
                     <input matInput
                            [(ngModel)]="editLinks.challengeFisheryLink"
                            name="FisherySite"
                    >
                </mat-form-field>

                <mat-form-field>
                    <mat-label title="Google Link" i18n="Google Link Label|Google Link label@@">Google Link</mat-label>
                    <input matInput
                            [(ngModel)]="editLinks.challengeAreaLink"
                            disabled
                            name="GoogleLink"
                    >
                </mat-form-field>

                <mat-form-field>
                    <mat-label title="Route Me Link" i18n="Route Me Link Label|Route Me Link label@@">Route Me Link</mat-label>
                    <input matInput
                            type="tel"
                            disabled
                            [(ngModel)]="editLinks.challengeRouteMeLink"
                            name="RouteMeLink"
                    >
                </mat-form-field>
                 

                <div fxFlex fxLayoutGap="2vh">
                    <button *ngIf="!this.isCreate"
                        mat-raised-button
                        color="warn"
                        i18n="cancel| cancel button@@"
                        (click)="onCancel()">Cancel</button>
                
                    <button [disabled]="!(l.valid && l.touched)" 
                        mat-raised-button
                        color="primary"
                        i18n="Edit Save Button| Edit Save Button@@"
                        (click)="onUpdate()">Save</button>

                    <label i18n="fillRequiredFields| fill Required Data@@">* required fields</label>
                </div>        
                  
        </form>
        
    </section>

</ng-template>
