
<article  class="full-description-panel" fxFill fxLayout="column"  fxLayoutAlign="start center">

    <div fxLayout="column" fxLayoutAlign="start center">
        
        <h1 class="mat-display-2" i18n="@@a4e3f91f985cf7715a50293a3a94c25d092cddc5"><strong>Pricing</strong></h1>
  
        
    </div>
  
    <div [ngClass.gt-xs]="'mat-title'" [ngClass.lt-md]="'mobile-font-style'" fxFill style="padding: 5vh 5vw;" fxLayoutGap="5vw" fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="center start" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="start center">
  
    <div class="description-panel free" fxFlex fxLayout="column" fxLayoutAlign="start center" >
        <div fxLayout="column" fxLayoutAlign="start center" style="width: 90%;">

            <h2 class="mat-h2" i18n="Premium Free Info Header| Header for Premium Free Info@@">Free Functions</h2>
    
            <ul fxLayout="column">
    
                <li>
                    <p i18n="Benefits Contest Page| Paragraph Benefits Contest Page@@">
                        Contest Page
                    </p>
                </li>
    
                <li>
                    <p i18n="Benefits Partners| Paragraph Benefits Partners@@">
                        Partners Swiper
                    </p>
                </li>
    
                <li>
                    <p i18n="Benefits Facebook| Paragraph Benefits Facebook@@">
                        Facebook & Instagram announcement post
                    </p>
                </li>
    
            </ul>
    
        </div>
  
    </div>
  
    <div class="description-panel premium" fxFlex fxLayout="column" fxLayoutAlign="start center" >
        <div fxLayout="column" fxLayoutAlign="start center" style="width: 90%;">
        <h2 class="mat-h2" i18n="Premium Premium Info Header| Header for Premium Info@@">Premium Functions</h2>

        <ul fxLayout="column">

            <li>
                <p i18n="@@36886110102b6c509f0751cc5af36ba21e86dfce">
                    Reservations
                </p>
            </li>

            <li>
                <p i18n="Benefits Referees| Paragraph Benefits Referees@@">
                    Referees
                </p>
            </li>

            <li>
                <p i18n="Benefits Live Scores| Paragraph Benefits Live Scores@@">
                    Live Scores
                </p>
            </li>

            
            <li>
                <p i18n="Benefits Charts| Paragraph Benefits Charts@@">
                    Charts
                </p>
            </li>


            <li>
                <p i18n="Benefits Fish Photos| Paragraph Benefits Fish Photos@@">
                    Fish Photos
                </p>
            </li>
 
            <li>
                <p i18n="Benefits Advanced Facebook| Paragraph Benefits Facebook@@">
                    Advanced Facebook & Instagram posts
                </p>
            </li>

            <li>
                <p i18n="Benefits web support| Paragraph Benefits web support@@">
                    Carp Contest web support
                </p>
            </li>

        </ul>

        </div>

  </div>
 
    </div>
    <div class="doted" fxFlexFill fxLayout="column" fxLayoutAlign="start center" >
        <h1 class="mat-display-2" i18n="@@CalculatePrice">Calculate Price</h1>
        
        <div fxLayout.lt-md="column" fxLayoutAlign.lt-md="start center" fxLayoutGap.lt-md="8vh" fxLayout="row" fxLayoutAlign="center center" style="width: 90%;" fxLayoutGap="5vw">
       
        <section fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="1vh">
     
            <mat-form-field class="mat-headline">
                <mat-label title="Fee Amount" i18n="@@FeeAmount">Fee Amount</mat-label>
                <input matInput
                        (change)="calculatePrice()"
                        type="number"
                        [(ngModel)]="feeAmount"
                        name="FeeAmount"
                        required
                >
            </mat-form-field>

            <mat-form-field>
                <mat-label i18n="@@TeamAmount">Teams Amount:</mat-label>
                <input matInput
                        (change)="calculatePrice()"
                        type="number"
                        [(ngModel)]="amountTeamsMaximum"
                        name="Max teams"
                        required
                >
            </mat-form-field>

            <mat-form-field>
                <mat-label i18n="Welcome Info Card Label Team Squad| Label for Team Squad information@@">Team Squad:</mat-label>
                <input matInput
                        (change)="calculatePrice()"
                        type="number"
                        [(ngModel)]="teamSquad"
                        name="Team Squad"
                        required
                >
            </mat-form-field>

    
        </section>

        <section fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="2vh">
     
                     <mat-checkbox  
                        [color]="checkedBoxes.color"
                        [(ngModel)]="checkedBoxes.completed"
                        (change)="calculatePrice()"
                        i18n="@@36886110102b6c509f0751cc5af36ba21e86dfce"
                        >
                        Reservations </mat-checkbox>
  
                <mat-checkbox   i18n="@@be1989cd4e117d9200cc7e8c6cda5a19caaad20a"
                [(ngModel)]="checkedBoxes.completed"
                (change)="calculatePrice()"
                [color]="checkedBoxes.color">Referees</mat-checkbox>

                <mat-checkbox  
                            [color]="checkedBoxes.color"
                            [(ngModel)]="checkedBoxes.completed"
                            (change)="calculatePrice()"
                            i18n="@@63c34e239b2a804d414276a93c51a7f1cfcbf236"
                            >
                Charts
                </mat-checkbox>
     
                <mat-checkbox   i18n="@@65ab043f3308e6dd57781fe84ff36c82ed3103e4"
                [(ngModel)]="checkedBoxes.completed"
                (change)="calculatePrice()"
                [color]="checkedBoxes.color">
                Live Scores
                </mat-checkbox>
        
                <mat-checkbox   i18n="@@9e0321a02c932ebcbde02027f1cba0bf527ca922"
                [(ngModel)]="checkedBoxes.completed"
                (change)="calculatePrice()"
                [color]="checkedBoxes.color">
                Fish Photos
                </mat-checkbox>
             
                <mat-checkbox   [(ngModel)]="this.fb.isAdvancedFacebook"
                            [color]="checkedBoxes.color"
                            [disabled]="this.fb.disabled"
                            i18n="@@eadcfd132bc5f54992c281096602c7dd00c81f81"
                            (change)="calculatePrice()">
                    Advanced Facebook posts
                </mat-checkbox>
           
                <mat-checkbox   [(ngModel)]="this.insta.isAdvancedInstagram"
                             [color]="checkedBoxes.color"
                             [disabled]="this.insta.disabled"
                            i18n="@@c8c57cde43720fcb1390033937581592eafe7664"
                            (change)="calculatePrice()">
                Advanced Instagram posts
                </mat-checkbox>
        
          </section>

          <section *ngIf="!(amountTeamsMaximum === 1) && !(feeAmount === 1)" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="1vh">
     
            <h1 class="mat-h1"><span i18n="@@PriceGross">Price:</span> {{price}} PLN <span i18n="@@net">Net</span></h1>
            <h2 class="mat-h2">{{perTeamPercent}}&nbsp;%&nbsp;<span i18n="@@WpisowegoDruzyny">of Team Fee</span></h2>
            <h3 class="mat-h2">{{perTeamPrice}}&nbsp;PLN&nbsp;<span i18n="@@net">Net</span>&nbsp;<span i18n="@@PerTeam">Per Team:</span></h3>
      </section>

       
        </div>
    
    </div>
  
  
  </article>