import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ModuleWithProviders } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainComponent } from './main/main.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { FlexLayoutModule, CoreModule } from '@angular/flex-layout';
import { WelcomeWithoutAnyContestComponent } from './main/welcome-without-any-contest/welcome-without-any-contest.component';
import { WelcomeChooseContestComponent } from './main/welcome-choose-contest/welcome-choose-contest.component';

import { ContestComponent } from './contest/contest.component';
import { ReservationsComponent } from './contest/teams/reservations/reservations.component';
import { ConfirmedTeamsComponent } from './contest/teams/confirmed-teams/confirmed-teams.component';
import { ReservationRefereesComponent } from './contest/referees/reservation-referees/reservation-referees.component';
import { RefereesComponent } from './contest/referees/confirmed-referees/referees.component';
import { ContestDataService } from './services/contest-data.service';
import { ContestService } from 'src/app/core-module/entityServices/contest.service';
import { EditTeamComponent } from './contest/edit-mode/edit-team/edit-team.component';
import { EditAnglerComponent } from './contest/edit-mode/edit-angler/edit-angler.component';
import { MainChallengeComponent } from './contest/challenge/main-challenge.component';
import { AwardsComponent } from './contest/challenge/awards/awards.component';
import { FeeComponent } from './contest/challenge/fee/fee.component';
import { LinksComponent } from './contest/challenge/links/links.component';
import { OrganizerComponent } from './contest/challenge/organizer/organizer.component';
import { MainDataComponent } from './contest/challenge/main-data/main-data.component';
import { CreateEditChallengeComponent } from './contest/edit-mode/create-edit-challenge/create-edit-challenge.component';

import { PartnersComponent } from './contest/partners/partners.component';
import { MessagesComponent } from './contest/messages/messages.component';
import { ParterCardComponent } from './contest/partners/parter-card/parter-card.component';
import { MessagesListComponent } from './contest/messages/messages-list/messages-list.component';
import { MessageWriterComponent } from './contest/messages/message-writer/message-writer.component';



import { PremiumComponent } from './contest/add-contest/premium/premium.component';
import { PremiumInfoComponent } from './contest/add-contest/premium/premium-info/premium-info.component';
import { PremiumFormComponent } from './contest/add-contest/premium/premium-form/premium-form.component';
import { AddContestComponent } from './contest/add-contest/add-contest.component';
import { ProfileDataComponent } from './account/profile-data/profile-data.component';
import { BalanceComponent } from './account/balance/balance.component';
import { ManageContestDataService } from './services/manage-contest-data';
import { ContestActionsComponent } from './contest/contest-actions/contest-actions.component';
import { CreateTeamComponent } from './contest/edit-mode/create-team/create-team.component';
import { AddContestDialogComponent } from './contest/add-contest/dialog/add-contest-dialog/add-contest-dialog.component';
import { PartnerDialogComponent } from './contest/partners/partner-dialog-component/partner-dialog-component.component';
import { ReserveListComponent } from './contest/teams/reserve-list/reserve-list/reserve-list.component';
import { CatchesComponent } from './contest/catches/catches.component';

const providers = [ContestDataService, ContestService, ManageContestDataService]
@NgModule({
    declarations: [
        AppComponent,
        MainComponent,
        WelcomeWithoutAnyContestComponent,
        WelcomeChooseContestComponent,
        ContestComponent,
        ReservationsComponent,
        ConfirmedTeamsComponent,
        ReservationRefereesComponent,
        RefereesComponent,
        EditTeamComponent,
        EditAnglerComponent,
        MainChallengeComponent,
        AwardsComponent,
        FeeComponent,
        LinksComponent,
        OrganizerComponent,
        MainDataComponent,
        CreateEditChallengeComponent,
        AddContestComponent,
        PartnersComponent,
        MessagesComponent,
        ParterCardComponent,
        MessagesListComponent,
        MessageWriterComponent,
        PremiumComponent,
        PremiumInfoComponent,
        PremiumFormComponent,
        ProfileDataComponent,
        BalanceComponent,
        ContestActionsComponent,
        CreateTeamComponent,
        AddContestDialogComponent,
        PartnerDialogComponent,
        ReserveListComponent,
        CatchesComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        SharedModule,
        FlexLayoutModule,
        CoreModule
    ],
    providers: providers,
    bootstrap: [AppComponent]
})
export class AppModule { }

@NgModule({})
export class OrganiserAppSharedModule{
  static forRoot(): ModuleWithProviders<AppModule> {
    return {
      ngModule: AppModule,
      providers: providers
    }
  }
}