import { Component, OnInit } from '@angular/core';
import { BaseLandingComponent } from '../../baseLanding.component';
import { UIService } from 'src/app/shared/service/ui.service';

@Component({
  selector: 'app-competitor',
  templateUrl: './competitor.component.html',
  styleUrls: ['./competitor.component.css']
})
export class CompetitorComponent extends BaseLandingComponent implements OnInit {

  constructor(private UIservice: UIService) {
    super(UIservice);
  }

  ngOnInit() {
  }

}
