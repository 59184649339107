import { Action } from '@ngrx/store';

export const REFEREE_STATE = '[APP] Referee Application'
export const ADMIN_STATE = '[APP] ADMIN Application'
export const ORGANISER_STATE = '[APP] Organiser Application'
export const LANDING_STATE = '[APP] Landing Application'
export const CONTEST_STATE = '[APP] Contest Application'

export class LoadRefereeState implements Action {
    readonly type = REFEREE_STATE;
}

export class LoadAdminState implements Action {
    readonly type = ADMIN_STATE;
}

export class LoadOrganiserState implements Action {
    readonly type = ORGANISER_STATE;
}

export class LoadLandingState implements Action {
    readonly type = LANDING_STATE;
}

export class LoadContestState implements Action {
    readonly type = CONTEST_STATE;
}

export type APPactions =  LoadRefereeState | LoadAdminState | LoadOrganiserState | LoadLandingState | LoadContestState