import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { UIService } from 'src/app/shared/service/ui.service';
import { AuthService } from 'src/app/auth-module/service/auth.service';
import { Store} from '@ngrx/store'
import * as fromRoot from 'src/app/shared/service/ui.reducer'
import { ServeUserService } from 'src/app/auth-module/service/serveUser.service';

declare var require: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

 @Output() sidenavToggle = new EventEmitter<void>();

  public logo = "assets/logos/rsz_cucy21v3-min.png";

  constructor(public UIservice: UIService,
              private router: Router,
              private authService: AuthService,
              public serveUser: ServeUserService) {

  }
  ngOnInit() {
  }

  onToggleSidenav(){
    this.sidenavToggle.emit();
  }


  contestOnButtonClick(path: string){
    this.router.navigate(['contest/' + this.UIservice.challengeAlias + path])
  }

}
