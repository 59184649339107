<article  class="full-description-panel" fxFill fxLayout="column"  fxLayoutAlign="start center">

    <div fxLayout="column" fxLayoutAlign="start center">
        
        <h1 class="mat-display-2" i18n="@@IAmCompetitor"><strong>Competitor</strong></h1>

        <h2 class="mat-display-2" i18n="@@48d83874826df3e9c7ff1e807cd3ff376388b022">How it works ?</h2>
        
    </div>

    <div [ngClass.gt-xs]="'mat-title'" [ngClass.lt-md]="'mobile-font-style'" fxFill style="padding: 5vh 5vw;" fxLayoutGap="5vw" fxLayout.gt-xs-="row" fxLayoutAlign.gt-xs="center start" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="start center">

    <div class="description-panel" fxFlex fxLayout.lt-sm="column" fxLayoutGap.lt-sm="5vh">

        <h2 class="mat-display-1" style="text-align: center;" i18n="@@Registration"><strong>Registration</strong></h2>

        <div [ngClass.gt-sm]="'description-element'" fxLayout.lt-sm="column" fxLayoutAlign="start center" fxFlexOrder="1">
            <a routerLink="/main/finder" class="workflow-element" [ngClass]="{'icon-mobile-sm':  UIservice.isMobileSM, 'icon-desktop': !UIservice.isMobileSM}" fxLayoutAlign="center center">
                <mat-icon [inline]="true" aria-hidden="true">search</mat-icon>            
            </a>
        
            <div>
                <p i18n="@@FindTheBestContest">
                      Use <strong>Search</strong> form and find the best matching contest.<br>
                      You can filter contests by:</p>
                        <ul i18n="@@CompetitorFilterList">
                            <li>Country, Region, Voivodeship,</li> 
                            <li>Start / End Date,</li>
                            <li>Name or type</li>
                        </ul>
            </div>
        </div>

        <div [ngClass.gt-sm]="'description-element'" fxLayout.lt-sm="column" fxLayoutAlign="start center" fxFlexOrder="2">
            <div class="workflow-element" [ngClass]="{'icon-mobile-sm':  UIservice.isMobileSM, 'icon-desktop': !UIservice.isMobileSM}" fxLayoutAlign="center center">
                <mat-icon [inline]="true" aria-hidden="true">assignment</mat-icon>            
            </div>
        
            <div>
                <p i18n="@@RegisterToContest">
                      Use <strong>Register</strong> option on <strong>Contest Page</strong> and make a <strong>Reservation</strong>.<br>
                      In case of Register option switched off contact directly Organiser</p>
            </div>
        </div>

        <div [ngClass.gt-sm]="'description-element'" fxLayout.lt-sm="column" fxLayoutAlign="start center" fxFlexOrder="3">
            <div class="workflow-element" [ngClass]="{'icon-mobile-sm':  UIservice.isMobileSM, 'icon-desktop': !UIservice.isMobileSM}" fxLayoutAlign="center center">
                <mat-icon [inline]="true" aria-hidden="true">payments</mat-icon>            
            </div>
        
            <div>
                <p i18n="@@MakeAPayment">
                      Perform contest <strong>Fee</strong> payment, <br>
                      you will send your money directly to Organiser bank account.</p>
            </div>
        </div>

        <div [ngClass.gt-sm]="'description-element'" fxLayout.lt-sm="column" fxLayoutAlign="start center" fxFlexOrder="4">
            <div class="workflow-element" [ngClass]="{'icon-mobile-sm':  UIservice.isMobileSM, 'icon-desktop': !UIservice.isMobileSM}" fxLayoutAlign="center center">
                <mat-icon [inline]="true" aria-hidden="true">check</mat-icon>            
            </div>
        
            <div>
                <p i18n="@@OrganiserPaymentRegister">
                      Once Organiser've <strong>registered</strong> and <strong>confirmed</strong> your <strong>payment</strong> then 
                      you will be transfered from <strong>Reservations</strong> list to <strong>Competitors</strong> List.</p>
            </div>
        </div>

        <div [ngClass.gt-sm]="'description-element'" fxLayout.lt-sm="column" fxLayoutAlign="start center" fxFlexOrder="5">
            <div class="workflow-element" [ngClass]="{'icon-mobile-sm':  UIservice.isMobileSM, 'icon-desktop': !UIservice.isMobileSM}" fxLayoutAlign="center center">
                <mat-icon [inline]="true" aria-hidden="true">thumb_up_alt</mat-icon>            
            </div>
        
            <div>
                <p i18n="@@StartYourCarpContestAdventure">
                      Now, the rest is up to you, start your own <strong>Carp Contest adventure</strong><br>
                    <strong>Fingers crossed !</strong></p>
            </div>
        </div>

    </div>

    <div class="description-panel" fxFlex fxLayout.lt-sm="column" fxLayoutGap.lt-sm="5vh">

        <h2 class="mat-display-1" style="text-align: center;"  i18n="@@9a3ee4da45ca34e947bd9adbcb32d6e3ac7d4b9b"><strong>Contests</strong></h2>

        <div [ngClass.gt-sm]="'description-element'" fxLayout.lt-sm="column" fxLayoutAlign="start center" fxFlexOrder="1">
            <div class="workflow-element" [ngClass]="{'icon-mobile-sm':  UIservice.isMobileSM, 'icon-desktop': !UIservice.isMobileSM}" fxLayoutAlign="center center">
                <mat-icon [inline]="true" aria-hidden="true">loop</mat-icon>            
            </div>
        
            <div>
                <p i18n="@@SwimDrawInformaction">
                      <strong>Swim Draw</strong> can be performed manually or automatically.<br>
                      In both cases you can follow live Draw results at Contest Page.</p>
            </div>
        </div>

        <div [ngClass.gt-sm]="'description-element'" fxLayout.lt-sm="column" fxLayoutAlign="start center" fxFlexOrder="2">
            <div class="workflow-element" [ngClass]="{'icon-mobile-sm':  UIservice.isMobileSM, 'icon-desktop': !UIservice.isMobileSM}" fxLayoutAlign="center center">
                <mat-icon [inline]="true" aria-hidden="true">message</mat-icon>            
            </div>
        
            <div>
                <p i18n="@@MessageInformation">
                      Organiser can send general <strong>Messages</strong>.<br>
                      You can find them in <strong>Live</strong> tab at Contest Page.
                    </p>
            </div>
        </div>

        <div [ngClass.gt-sm]="'description-element'" fxLayout.lt-sm="column" fxLayoutAlign="start center" fxFlexOrder="3">
            <div class="workflow-element" [ngClass]="{'icon-mobile-sm':  UIservice.isMobileSM, 'icon-desktop': !UIservice.isMobileSM}" fxLayoutAlign="center center">
                <mat-icon [inline]="true" aria-hidden="true">play_circle_outline</mat-icon>            
            </div>
        
            <div>
                <p i18n="@@CompetitorCatchedFish">
                      <strong>Referees</strong> will weight your <strong>Fish</strong> and introduce it into<br>
                      Carp Contest App. <strong>Live Score</strong> tab will be immediately refreshed<br>
                      so you will be able to check your <strong>actual position</strong>.
                </p>
            </div>
        </div>

        <div [ngClass.gt-sm]="'description-element'" fxLayout.lt-sm="column" fxLayoutAlign="start center" fxFlexOrder="4">
            <div class="workflow-element" [ngClass]="{'icon-mobile-sm':  UIservice.isMobileSM, 'icon-desktop': !UIservice.isMobileSM}" fxLayoutAlign="center center">
                <mat-icon [inline]="true" aria-hidden="true">list</mat-icon>            
            </div>
        
            <div>
                <p i18n="@@AnalizedDataInformation">
                      You will be able to follow <strong>Analized Data</strong><br>
                      You can find valuable informations like:</p>
                      <ul i18n="@@CompetitorAnalizedList">
                        <li>General Classification and Fish Photos</li>
                        <li>General Score and Fish weight difference between 1st Place and others</li> 
                        <li>General Score and Fish weight difference between you and next/previous places</li>
                    </ul>
            </div>
        </div>

        <div [ngClass.gt-sm]="'description-element'" fxLayout.lt-sm="column" fxLayoutAlign="start center" fxFlexOrder="5">
            <div class="workflow-element" [ngClass]="{'icon-mobile-sm':  UIservice.isMobileSM, 'icon-desktop': !UIservice.isMobileSM}" fxLayoutAlign="center center">
                <mat-icon [inline]="true" aria-hidden="true">leaderboard</mat-icon>            
            </div>
        
            <div>
                <p i18n="@@ChartsInformations">
                    You will be able to follow <strong>Charts</strong><br>
                    You can find valuable informations like:</p>
                    <ul i18n="@@CompetitorChartList">
                      <li>Fish Amount Per Date, Day & Night</li>               
                      <li>Fish Amount Per Weight range</li>
                      <li>Grouped adjacents Swims with Fish</li>
                      <li>Fish Amount per Day Time Spans</li> 
                      <li>Swims Amount Per Amount of Fish</li>
                  </ul>
            </div>
        </div>

    </div>

</div>


</article>