import { Component, OnInit } from '@angular/core';
import { ContestDataService } from '../../services/contest-data.service';
import { UIService } from 'src/app/shared/service/ui.service';
import { BaseOrganiserComponent } from '../../baseOrganiser.component';
import { ManageContestDataService } from '../../services/manage-contest-data';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../../../src/app/presentation/app.reducer'
import * as UI from '../../../../../../src/app/shared/service/ui.actions'
import { AuthService } from 'src/app/auth-module/service/auth.service';
import { Fish } from 'src/app/data/models/Manage/fish.model';

@Component({
  selector: 'app-contest-actions',
  templateUrl: './contest-actions.component.html',
  styleUrls: ['./contest-actions.component.css']
})
export class ContestActionsComponent implements OnInit {

  teamCreateType: string
  creationMode: boolean = false
  constructor(private manageContest: ManageContestDataService,
              private authService: AuthService,
              private store: Store<fromRoot.State>,
              private contestData: ContestDataService,
              public UIservice: UIService) {

  }

  ngOnInit() {
  }

  onOpenSignUp(){
     let title = $localize`Sign Up - Open`
     let content = $localize`Are you sure to open contest Sign Up ?`
      this.UIservice.openConfirmationDialog(title,content).subscribe(async result => {
        if(result)
        {
          const spinner = this.UIservice.openSpinnerDialog()
          await this.manageContest.manageSignUp('Open').subscribe({
            next: (v) => spinner.close(),
            error: (e) => {console.log(e.message)
              spinner.close()}
          });

        }
      })
  }

  onCloseSignUp(){
    let title = $localize`Sign Up - Open`
     let content = $localize`Are you sure to close contest Sign Up ?`
    this.UIservice.openConfirmationDialog(title, content).subscribe(async result => {
      if(result)
      {
        const spinner = this.UIservice.openSpinnerDialog()
        await this.manageContest.manageSignUp('Close').subscribe({
          next: (v) => spinner.close(),
          error: (e) => {console.log(e.message)
            spinner.close()}
        });
      }
    })
  }

  async openFishDialog(){

    const newFish = new Fish()


    this.UIservice.openAddFishDialog(newFish, this.contestData.signedTeams$).subscribe(async result => {
      if(result)
      {
        const spinner = this.UIservice.openSpinnerDialog()
        const sendFish = {... newFish}
        sendFish.refereeID = this.authService.getCurrentUserUID()

        await this.contestData.uploadFishPhoto(result.photoFile)
              .then((photoLink) => {sendFish.photoUrl = photoLink})
              .finally(() => { sendFish.photoFile = null})

        await this.contestData.setNewFish(sendFish)
              .then(() => { spinner.close() })
              .catch((e) => {
                let data = {
                  title: $localize`Add Fish Error`,
                  content: e.message
              }
                this.UIservice.openInfoDialog(data.title, data.content,false)
              })

      }
    })
  }

  onAddWarningCard(){
    console.log('NOT IMPLEMENTED')
  }
  onAddTeam(){
    this.creationMode = true
    this.teamCreateType = 'team'
  }

  onAddReservationTeam(){
    this.creationMode = true
    this.teamCreateType = 'reservation'
  }

  onAnonymusReferee(){
    let title = $localize`Anonymus Referees`
     let content = $localize`Are you sure to anonymize Referees ?`
    this.UIservice.openConfirmationDialog(title, content).subscribe(result => {
      if(result)
      {
        console.log('NOT IMPLEMENTED')
      }
    })
    console.log('NOT IMPLEMENTED - onAnonymusReferee()')
  }

  onAnonymusAnglers(){
    let title = $localize`Anonymus Competitors`
     let content = $localize`Are you sure to anonymize Comptetitors ?`
    this.UIservice.openConfirmationDialog(title, content).subscribe(result => {
      if(result)
      {
        console.log('NOT IMPLEMENTED')
      }
    })

    console.log('NOT IMPLEMENTED - onAnonymusAnglers()')
  }

  onDrawSequence(){
    let title = $localize`Draw Sequence`
     let content = $localize`Are you sure to start Draw Sequence ?`
    this.UIservice.openConfirmationDialog(title, content).subscribe(async result => {
      if(result)
      {
        const spinner = this.UIservice.openSpinnerDialog()
        await this.manageContest.callDrawingSequence().subscribe({
          next: (v) => spinner.close(),
          error: (e) => {console.log(e.message)
            spinner.close()}
        });
      }
    })

    console.log('NOT IMPLEMENTED - onDrawSequence()')
  }

  onDrawSwim(){
    let title = $localize`Draw Swims`
     let content = $localize`Are you sure to start Draw Swims ?`
    this.UIservice.openConfirmationDialog(title, content).subscribe(result => {
      if(result)
      {
        console.log('NOT IMPLEMENTED - onDrawSwim()')
      }
    })

    console.log('NOT IMPLEMENTED - onDrawSwim()')
  }


}
