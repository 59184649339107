import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Awards, AwardsPlace } from 'src/app/data/models/Manage/challenge.model';
import { UIService } from 'src/app/shared/service/ui.service';
import { ActivatedRoute } from '@angular/router';
import { ManageContestDataService } from '../../../services/manage-contest-data';

@Component({
  selector: 'app-awards',
  templateUrl: './awards.component.html',
  styleUrls: ['./awards.component.css']
})
export class AwardsComponent implements OnInit {

  @Input() modeEdit?: string = ''
  @Input() isCreate?: boolean = false
  @Input() awards: Awards
  @Output() edit: EventEmitter<string> = new EventEmitter<string>()
  @Output() endCreation: EventEmitter<boolean> = new EventEmitter<boolean>()
  
  firstFish: boolean
  bigFish: boolean
  editAwards: Awards

  constructor(public UIservice: UIService,
              private manageContest: ManageContestDataService) { }

  ngOnInit() {

    if (this.isCreate)
    {
      this.modeEdit ='create'
      this.editAwards = this.awards
    }

    this.bigFish = this.awards.BigFish ? true : false
    this.firstFish = this.awards.FirstFish ? true : false
  }

  onUpdate(){
    if (!this.bigFish)
      {
        this.editAwards.BigFish = null
      }
      if (!this.firstFish)
      {
        this.editAwards.FirstFish = null
      }

    if (this.isCreate)
    {
      this.endCreation.emit(true)
    }
    else
    {
      this.manageContest.updateChallengeChild("Awards" ,  this.editAwards)
        .then(() => {
        this.modeEdit = ''
        this.edit.emit('none')

      })
      
    }

  }

  onEdit(){
    if(this.awards)
    {  
     this.editAwards = this.prepareEdition(this.awards)
      this.modeEdit = 'edit'
      this.edit.emit('awards')
    }
  }

  onCancel(){
    this.modeEdit = ''
    this.edit.emit('none')
  }

  prepareEdition(actualData: Awards){
    const editAwards = {...actualData}
    
    for (const place in editAwards)
      {
        if(editAwards[place].sponsoredItems)
        {
          editAwards[place].sponsoredItems = actualData[place].sponsoredItems ? true : false
        }
      }

      return editAwards
  }

  onBigFish(){
    if(this.bigFish){
      this.editAwards.BigFish = <AwardsPlace>{}
      this.editAwards.BigFish.sponsoredItems = false
      console.log(this.editAwards)
    }
    else
    {
      this.editAwards.BigFish = null
    }
  }
  
  onFirstFish(){
    if(this.firstFish){
      this.editAwards.FirstFish = <AwardsPlace>{}
      this.editAwards.FirstFish.sponsoredItems = false
    }
    else
    {
      this.editAwards.FirstFish = null
    }

  }

  onMoreAwards(){
 
  }

}
