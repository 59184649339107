import { Component, OnInit } from '@angular/core';
import { BaseOrganiserComponent } from '../../baseOrganiser.component';
import { UIService } from 'src/app/shared/service/ui.service';
import { ServeUserService } from 'src/app/auth-module/service/serveUser.service';

@Component({
  selector: 'app-welcome-without-any-contest',
  templateUrl: './welcome-without-any-contest.component.html',
  styleUrls: ['./welcome-without-any-contest.component.css'],
})
export class WelcomeWithoutAnyContestComponent extends BaseOrganiserComponent implements OnInit {

  hasDataFilled: boolean = false

  constructor(public UIservice: UIService, private serveUser: ServeUserService) {
    super(UIservice);
    this.hasDataFilled = this.serveUser.hasDataFilled
  }
  ngOnInit() {
  }

}
