import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription, Observable } from 'rxjs';

import { BaseOrganiserComponent } from '../../../baseOrganiser.component';
import { UIService } from 'src/app/shared/service/ui.service';
import { ContestDataService } from '../../../services/contest-data.service';
import { Referee } from 'src/app/data/models/Manage/referee.model';
import { ManageContestDataService } from '../../../services/manage-contest-data';



@Component({
  selector: 'app-referees',
  templateUrl: './referees.component.html',
  styleUrls: ['./referees.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ])],
})
export class RefereesComponent extends BaseOrganiserComponent implements OnInit, OnDestroy  {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  dataSubscription: Subscription
  cardData: Observable<Referee[]>

  allReferees: Referee[]

  columnsToDisplay= ['Name', 'Surname', 'Pegs'];
  dataSource = new MatTableDataSource<Referee>()
  expandedElement: Referee | null;
  pageEvent: PageEvent;

  constructor(public UIservice: UIService,
    private contestData: ContestDataService,
    private manageContest: ManageContestDataService) {
    super(UIservice);

    this.contestData.getRefereesConfirmed()

  }




  ngOnInit() {
    this.dataSubscription = this.contestData.referees$.subscribe(array => {
      if(array!=null){
        this.allReferees = array
       this.assignDataToDataSource(this.allReferees)
      }

  })

  this.dataSource.paginator = this.paginator;
  this.cardData = this.dataSource.connect()

  }

  assignDataToDataSource(_array: Referee[]){

    this.dataSource.data = _array
    this.dataSource.paginator = this.paginator;
    this.cardData = this.dataSource.connect()
}

  applyFilter(filterValue: string) {

  let searchResults: Referee[] = []

  let searchValue = filterValue.trim().toLowerCase()

  if(this.allReferees && searchValue != ''){

    searchResults =  this.allReferees.filter(object => {
      let values: string = ''
        values += object.phone ? object.phone: ''
        values += object.email ? object.email.toLocaleLowerCase() : ''
        values += object.userData.name ? object.userData.name.toLocaleLowerCase() : ''
        values += object.userData.surname ? object.userData.surname.toLocaleLowerCase() : ''

      return values.indexOf(searchValue) != -1

    })


  }
    else
    {
      searchResults = this.allReferees
    }

    this.assignDataToDataSource(searchResults)

}

onRefereeEdit(id){
  console.log('NOT IMPLEMENTED - onRefereeEdit()')
}

onRefereeDelete(key: string){
  let title = $localize`Referee Delete`
  let content = $localize`Are you sure to delete selected Referee ?`

  this.UIservice.openConfirmationDialog(title, content).subscribe(async result => {
    if(result)
    {
      const spinner = this.UIservice.openSpinnerDialog()
      await  this.manageContest.deleteReferee(key).subscribe({
        error: (e) => {
          let data = {
            title: $localize`Referee Delete Error`,
            content: e.message
        }
          this.UIservice.openInfoDialog(data.title, data.content,false)

          spinner.close()
        },
        complete: () => spinner.close()
    })
    }
  })
}

onStationAssign(id){

  this.UIservice.openSimpleInputDialog('refereeStations').subscribe(async result => {
    if(result)
    {
      if(result.newValue != '0 - 0')
      {
        const spinner = this.UIservice.openSpinnerDialog()
        await this.manageContest.assignRefereeStations(id, result.newValue).subscribe({
          complete: () => spinner.close(),
          error: (e) => {
            let data = {
              title: $localize`Assign Station Error`,
              content: e.message
          }
            this.UIservice.openInfoDialog(data.title, data.content,false)

            spinner.close()
          }
      })
      }
    }
  })
}

  ngOnDestroy(): void {
    this.dataSubscription.unsubscribe()
  }

}
