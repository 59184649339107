
<section *ngIf="this.country === 'pl'" id="polishRegionsSection" fxLayout="column wrap" fxFlex.gt-xs="168px" >
    <mat-checkbox style="margin-left: 2vh" *ngFor="let region of _polishRegions" (change)="filter($event, region)">{{region | titlecase}}</mat-checkbox>
</section>

<section *ngIf="this.country === 'de'" id="germanRegionsSection" fxLayout="column wrap" fxFlex.gt-xs="168px" >
    <mat-checkbox style="margin-left: 2vh" *ngFor="let region of _germanRegion" (change)="filter($event, region)">{{region | titlecase}}</mat-checkbox>
</section>

<section *ngIf="this.country === 'cz'" id="czechRegionsSection" fxLayout="column wrap" fxFlex.gt-xs="168px" >
    <mat-checkbox style="margin-left: 2vh" *ngFor="let region of _czechRegions" (change)="filter($event, region)">{{region | titlecase}}</mat-checkbox>
</section>

<section *ngIf="this.country === 'sk'" id="slovakRegionsSection" fxLayout="column wrap" fxFlex.gt-xs="168px" >
    <mat-checkbox style="margin-left: 2vh" *ngFor="let region of _slovakRegions" (change)="filter($event, region)">{{region | titlecase}}</mat-checkbox>
</section>

<section *ngIf="this.country === 'hu'" id="hungarianRegionsSection" fxLayout="column wrap" fxFlex.gt-xs="168px" >
    <mat-checkbox style="margin-left: 2vh" *ngFor="let region of _hungaryRegions" (change)="filter($event, region)">{{region | titlecase}}</mat-checkbox>
</section>

