import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChange, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Angler } from 'src/app/data/models/Manage/angler.model';

@Component({
  selector: 'app-edit-angler',
  templateUrl: './edit-angler.component.html',
  styleUrls: ['./edit-angler.component.css']
})
export class EditAnglerComponent implements OnInit, OnChanges {

  @Input('editAngler') editAngler: Angler
  @Input('indexAngler') indexAngler?: any
  @Input('editType') editType: string
  @Output() onUpdate: EventEmitter<Angler> = new EventEmitter()
  @Output() isValid: EventEmitter<boolean> = new EventEmitter()
  @Output() exit: EventEmitter<boolean> = new EventEmitter()
  @ViewChild('a', {static: true})
  angler: Angler
  isNew: boolean

  public a: NgForm;
  constructor() { 
  }

  ngOnInit() {
    this.isNew = this.editType.substr(0,3) === 'new' ? true : false
    this.isNew ? this.angler = this.editAngler : this.angler = {...this.editAngler}
  }
  ngOnChanges(){
    this.angler = {...this.editAngler}
  }

  emitValidator(a: boolean){
    this.isValid.emit(a);
  }

  onUpdateAngler(){
    this.onUpdate.emit({...this.angler})
  }

  onCancel(){
    this.exit.emit(true)
  }


}
