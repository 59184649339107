import { Fish } from './fish.model';
import { Angler } from './angler.model';

export class Team {
    
    id?: string;
    station?: number;
    drawSequence?: number;
    promoTeam?: string; 
    catches?: Fish[];
    anglers?: Angler[];
    privateData?: TeamPrivateData
    
}
export interface TeamPrivateData {
        leaderPhone?: string;
        leaderEmail?: string;
}