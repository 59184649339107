<div [hidden]="!this.messages" style="margin: 1vh;">
    <!-- <div class="mat-display-1" style="text-align: center; margin: 0 0 32px;" i18n="News| News header in Live">News:</div> -->
<div class="mat-elevation-z8 card-column"   fxLayout="column" fxLayoutAlign="start center">

    <mat-card
    [ngClass]="{'big-screen-card' : !uiService.isMobileSM,
                'small-screen-card': uiService.isMobileSM,
                'Fish-card': message.type ==='Fish',
                'Alert-card': message.type ==='Alert'}"
                *ngFor="let message of obs | async">
      <mat-card-header>
        <!-- <div mat-card-avatar class="example-header-image"></div> -->
        <mat-card-title>{{message.text.title}}</mat-card-title>
        <mat-card-subtitle>{{message.createdAt  | date:"dd/MM/yyyy HH:mm"}}</mat-card-subtitle>

      </mat-card-header>
            <img mat-card-image [attr.src]="message.extraData?.imgLink">
      <mat-card-content>
        <p [ngClass]="{'Alert-text': message.type ==='Alert'}">
          {{message.text.PL}}
        </p>


        <youtube-player-object *ngIf="message.extraData?.youtubeLink"
        fxLayout="column" fxLayoutAlign="start center"
        [videoId]="message.extraData?.youtubeLink | youtube"
        [isSmallScreen]="uiService.isMobileSM"></youtube-player-object>

        <div *ngIf="message.extraData?.partner != null" fxLayout="column" fxLayoutAlign="start center">
                  <label i18n="@@Partner">Partner:</label><a class="partner" target="_blank"  [attr.href]="message.extraData?.partner.partnerLink">{{message.extraData.partner.partnerName}}</a>
              </div>
      </mat-card-content>
    </mat-card>


    <!-- <table mat-table fxFlex="100" [dataSource]="dataSource">

        <ng-container matColumnDef="From">
            <th fxFlex="15" fxFlex.gt-sm="8" fxHide.xs class="mat-body-strong" fxLayoutAlign="center center" mat-header-cell *matHeaderCellDef i18n="@@217b215f01c756cc64557d3cfd533845d6a5f82e">Author</th>
            <td fxHide.xs
                fxFlex.gt-sm="8"
                fxFlex="15"
                fxLayoutAlign="center center"
                mat-cell
                *matCellDef="let element">
                <div class="mat-body">{{element.createdBy}}</div>
            </td>
        </ng-container>

        <ng-container matColumnDef="Type">
                <th fxFlex="15" fxFlex.gt-sm="8" class="mat-body-strong" fxLayoutAlign="center center" mat-header-cell *matHeaderCellDef i18n="@@124bb9a84d8c356cb36b6f68d6de9fd50b68b59d">Type</th>
                <td fxFlex.gt-sm="8"
                    fxFlex="15"
                    fxLayoutAlign="center center"
                    [ngClass]="{'message-warn': element.type === 'Warn', 'message-info': element.type === 'Info', 'message-fish': element.type === 'Fish'}"
                    mat-cell
                    *matCellDef="let element">
                    <div class="mat-body">{{element.type}}</div>
                </td>
        </ng-container>

        <ng-container matColumnDef="Text">
                <th fxFlex class="mat-body-strong" fxLayoutAlign="start center" fxLayoutAlign.xs="center center" mat-header-cell *matHeaderCellDef i18n="@@a0795d2f42a2eec4e150bc8ef8def84f3d4f78f1">Info Text</th>
                <td
                    fxFlex
                    fxLayoutAlign="start center"
                    mat-cell
                    [ngClass]="{'message-warn': element.type === 'Warn', 'message-info': element.type === 'Info', 'message-fish': element.type === 'Fish'}"
                    *matCellDef="let element">
                    <div class="mat-small">{{language === "PL" ? element.text.PL : element.text.EN}}</div>
                </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table> -->

    <mat-paginator [length]="this.dataSource.data.length"
              [pageSize]="5"
              [pageSizeOptions]="[10,20,50]"
              showFirstLastButtons
              (page)="pageEvent = $event">
    </mat-paginator>
</div>
</div>
