import { Component, OnInit, OnDestroy} from '@angular/core';
import { Subscription } from 'rxjs';
import { UIService } from 'src/app/shared/service/ui.service';


declare var require: any;
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit, OnDestroy{

  public logo = "assets/logos/rsz_cucy21v3-min.png";

  private organiserSubscription: Subscription;
  organiser;

  constructor( public UIService: UIService) {
    this.organiserSubscription = this.UIService.organiserLoaded.asObservable()
      .subscribe(value => this.organiser = value)
  }


  ngOnInit() {
  }

  ngOnDestroy(){
   this.organiserSubscription.unsubscribe()
  }


}
