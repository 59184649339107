import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafeResUrlPipe } from './pipes/safeResUrl.pipe';
import { NgxChartsModule } from '@swimlane/ngx-charts'
import { ChartBarVerticalComponent } from './components/charts/chart-bar-vertical/chart-bar-vertical.component';
import { ChartPieAdvancedComponent } from './components/charts/chart-pie-advanced/chart-pie-advanced.component';
import { NumberCardsComponent } from './components/charts/number-cards/number-cards.component';
import { ChartBarHorizontalComponent } from './components/charts/chart-bar-horizontal/chart-bar-horizontal.component';
import { UIService } from './service/ui.service';
import { MaterialModule } from './material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { PartnerSwiperComponent } from './components/partner-swiper/partner-swiper.component';
import { SwiperModule } from 'swiper/angular';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DialogConfirmationComponent } from './components/dialogs/dialog-confirmation/dialog-confirmation.component';
import { DialogSpinnerComponent } from './components/dialogs/dialog-spinner/dialog-spinner.component';
import { DialogAddFishComponent } from './components/dialogs/dialog-add-fish/dialog-add-fish.component';
import { DialogShowPhotoComponent } from './components/dialogs/dialog-show-photo/dialog-show-photo.component';
import { DialogSimpleInputComponent } from './components/dialogs/dialog-simple-input/dialog-simple-input.component';
import { TruncatePipe } from './pipes/truncate.pipe';
import { AnyComponentDialogComponent } from './components/dialogs/any-component-dialog/any-component-dialog.component';
import { YoutubePipe } from './pipes/youtubeIDfromURL.pipe';



@NgModule({
    declarations: [
            SafeResUrlPipe,
            TruncatePipe,
            YoutubePipe,
            ChartBarVerticalComponent,
            ChartPieAdvancedComponent,
            NumberCardsComponent,
            ChartBarHorizontalComponent,
            SpinnerComponent,
            PartnerSwiperComponent,
            DialogConfirmationComponent,
            DialogSpinnerComponent,
            DialogAddFishComponent,
            DialogShowPhotoComponent,
            DialogSimpleInputComponent,
            AnyComponentDialogComponent
        ],

    imports: [
            CommonModule,
            NgxChartsModule,
            MaterialModule,
            FormsModule,
            ReactiveFormsModule,
            FlexLayoutModule,
            SwiperModule
            ],
    exports: [
            SafeResUrlPipe,
            TruncatePipe,
            YoutubePipe,
            NgxChartsModule,
            ChartBarVerticalComponent,
            ChartPieAdvancedComponent,
            NumberCardsComponent,
            ChartBarHorizontalComponent,
            MaterialModule,
            FormsModule,
            ReactiveFormsModule,
            SpinnerComponent,
            PartnerSwiperComponent,
            SwiperModule
            ],
    providers: []
})
export class SharedModule {}
