import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/compat/database';
import { Router } from '@angular/router'


import { BehaviorSubject, of, Observable} from 'rxjs';
import { switchMap, map  } from 'rxjs/operators';
import { ServeUserService } from './serveUser.service';
import * as appUser from '../user';
import { Auth, authState, User,sendEmailVerification, signOut, sendPasswordResetEmail, updateProfile, signInWithEmailAndPassword } from '@angular/fire/auth';
import { Contest } from 'src/app/data/models/Manage/contest.model';
import { FirebaseService } from 'src/app/core-module/base/firebase.service';
import { createUserWithEmailAndPassword } from 'firebase/auth';



@Injectable({
    providedIn: 'root'
})
export class AuthService {

    user: BehaviorSubject<appUser.User> = new BehaviorSubject(null);
    isAuthenticated: boolean = false;
    fireUser: User;

    constructor(private auth: Auth,
                private db: AngularFireDatabase,
                private serveUser: ServeUserService,
                private firebaseService: FirebaseService,
                private router: Router
                ){

            authState(this.auth)
                    .pipe(switchMap<User, Observable<appUser.User>>(auth =>{

                        if(auth && auth.emailVerified){
                          this.fireUser = auth
                            this.isAuthenticated = true;
                            return this.db.object<appUser.User>('Users/' + auth.uid).valueChanges()
                        }
                        else
                        {

                            this.isAuthenticated = false;
                            return of(null)
                        }
                    }))
                    .subscribe((user: appUser.User) => {
                        if (!!user)
                        {
                            this.user.next(user)
                            this.serveUser.serve(user)
                        }
                    })

    }

    signUpUser(email: string, password: string, _name: string, _surname: string){

        return createUserWithEmailAndPassword(this.auth, email, password)
                .then(async (user) => {
                    if(user)
                    {
                      this.fireUser = user.user;

                        var userData = {
                            email: user.user.email,
                            displayName: _name,
                            isNewUser: true,
                            emailVerified: user.user.emailVerified,
                            userData: {
                                name: _name,
                                surname: _surname
                            }
                        }
                       await updateProfile(this.fireUser, {displayName : _name}).then(() => sendEmailVerification(this.fireUser))

                       return this.firebaseService.updateObjectByKey('Users',userData,this.fireUser.uid)
                    }
                })
     }

    signinUser(email: string, password: string){

         return signInWithEmailAndPassword(this.auth, email, password)

     }

    signoutUser(){

         return signOut(this.auth)
                    .then(() => { this.router.navigate(['main'])})
     }

     sendUserPasswordResetEmail(email: string){
        return sendPasswordResetEmail(this.auth, email)
     }

     sendUserVerificationEmail(email: string){
         return sendEmailVerification(this.fireUser)
     }

     getUserKey(): string {
       return this.fireUser.uid
     }

     getUserContestList(role: string): AngularFireList<Contest | any>{

        var loggenUserUID = this.fireUser.uid
        const collection = 'Users/' + loggenUserUID + '/roles/' + role

       return this.firebaseService.getCollection(collection)
     }

    async updateUser(_user: appUser.User): Promise<string>{

       var result: string

       var loggenUserUID = this.fireUser.uid
       if (this.user.value.email === _user.email)
       {
        await this.db.object<appUser.User>('Users/' + loggenUserUID).set(_user)
            .then(() => {return result = 'success'})
            .catch((e) => {return result = e.message})
       }
       else
       {
           result = "Internal Permision Error"
       }

       return result
    }

    getCurrentUserUID(): string {
        return this.fireUser.uid
    }

    newReferee(){
        const newReferee = {...this.user.value}
        newReferee.newReferee = true
        newReferee.isNewUser = null;

        return this.updateUser(newReferee)
    }

    newOrganiser(){
        const newOrganiser = {...this.user.value}
        newOrganiser.newOrganiser = true
        newOrganiser.isNewUser = null

        return this.updateUser(newOrganiser)
    }


}
