<ng-container *ngIf="{contest: UIservice.isContest | async, 
  main: UIservice.isMain | async,
  organiser: UIservice.isOrganiser | async,
  referee: UIservice.isReferee | async} as app">

<div fxLayoutAlign="center center">
  <a routerLink="/"><img style="max-height: 250px; max-width: 250px;" src="{{logo}}"></a>
</div>

<mat-nav-list *ngIf="app.contest" >
           <a role="navigation"
              mat-list-item 
              style="color:rgb(255,255,255, 0.8)"
              routerLink="contest/{{this.UIservice.challengeAlias}}" 
              (click)="onClose()"
              >
            <mat-icon>info</mat-icon>
            &nbsp;
            <span class="nav-caption" i18n="@@MainPage">Main</span>
        </a>

        <a *ngIf="this.UIservice.isTeamsAvailable && this.UIservice.isPremium" 
            role="navigation"
            mat-list-item 
            style="color:rgb(255,255,255, 0.8)"
            routerLink="contest/{{this.UIservice.challengeAlias}}/teams" 
            (click)="onClose()"
            >
          <mat-icon>people_alt</mat-icon>
          &nbsp;
          <span class="nav-caption" i18n="Teams|Teams button in header@@">Teams</span>
        </a>

        <a *ngIf="this.UIservice.isPremium && this.UIservice.isLive" 
            role="navigation"
            mat-list-item 
            style="color:rgb(255,255,255, 0.8)"
            routerLink="contest/{{this.UIservice.challengeAlias}}/referees" 
            (click)="onClose()"
            >
          <mat-icon>people_alt</mat-icon>
          &nbsp;
          <span class="nav-caption" i18n="@@be1989cd4e117d9200cc7e8c6cda5a19caaad20a">Referees</span>
        </a>

        <a *ngIf="this.UIservice.isSingUpAvailable && this.UIservice.isPremium" 
            role="navigation"
            mat-list-item 
            style="color:#ffad41"
            routerLink="contest/{{this.UIservice.challengeAlias}}/sign-me" 
            (click)="onClose()">
          <mat-icon>how_to_reg</mat-icon>
          &nbsp;
          <span class="nav-caption" i18n="@@d0b5af36666fde27a71b18ea9330829656a39d8b">SIGN UP</span>
        </a>

        <a  role="navigation"
            mat-list-item 
            style="color:#ffd92f;"
            routerLink="contest/{{this.UIservice.challengeAlias}}/drawing" 
            (click)="onClose()"
            >
          <mat-icon>loop</mat-icon>
          &nbsp;
          <span class="nav-caption" i18n="@@Drawing">Drawing</span>
        </a>

        <a *ngIf="this.UIservice.isLive && this.UIservice.isPremium" 
            role="navigation"
            mat-list-item 
            style="color:#adff2f;"
            routerLink="contest/{{this.UIservice.challengeAlias}}/live" 
            (click)="onClose()"
            >
          <mat-icon>play_circle_outline</mat-icon>
          &nbsp;
          <span class="nav-caption">LIVE</span>
        </a>

        <a *ngIf="this.UIservice.rulesLink && this.UIservice.isPremium"
            role="navigation" 
            mat-list-item 
            target="_blank" 
            style="color:rgb(255,255,255, 0.8)"
            [attr.href]="this.UIservice.rulesLink"  
            (click)="onClose()"
            >
          <mat-icon>menu_book</mat-icon>
          &nbsp;
          <span class="nav-caption" i18n="@@4646d1c2f63ab603c78a10b414acd28a4ab07964">Rules</span>
        </a>

</mat-nav-list>

<mat-nav-list *ngIf="app.organiser && this.authService.isAuthenticated" fxFlex="100" fxLayout="column">
    
  <mat-accordion fxFlex>
    
    <app-sidebar-manage-profile-links (closeSidenav)="onClose()"></app-sidebar-manage-profile-links>
    <app-sidebar-manage-contests-links (closeSidenav)="onClose()"></app-sidebar-manage-contests-links>
    <app-sidebar-manage-referees-links (closeSidenav)="onClose()"></app-sidebar-manage-referees-links>
  </mat-accordion>

  <app-sidebar-auth-links (click)="onClose()"></app-sidebar-auth-links>

  </mat-nav-list>

<mat-nav-list *ngIf="app.main" fxFlex="100" fxLayout="column">
<div >
<a  class="mat-item-color"
  role="navigation"
  mat-list-item 
  routerLink="main/finder"
  (click)="onClose()"
  >
    <mat-icon>list</mat-icon>
      &nbsp;
    <span class="nav-caption" i18n="@@1870e3ec9fcc9a4e9e03b3feaba98ad755471648">View Contests</span>
</a>

</div>
<mat-accordion fxFlex>
  
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title class="mat-item-color">
        <mat-icon>accessibility_new</mat-icon>
        <span i18n="Competitor|Competitor in header@@">Competitor</span>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <a mat-list-item
      role="navigation" 
      routerLink="main/info/competitor"
      (click)="onClose()">
    <mat-icon class="expansion-panel-item-icon">build</mat-icon>
    <span class="expansion-panel-item-caption" 
          i18n="@@48d83874826df3e9c7ff1e807cd3ff376388b022">How it works ?</span> 
  </a>

    <a mat-list-item
       role="navigation"  
       routerLink="main/info/competitor"
       fragment ="competitorData"
       (click)="onClose()"
    >
    <mat-icon class="expansion-panel-item-icon">cloud_queue</mat-icon>
    <span class="expansion-panel-item-caption" 
    i18n="WhatAboutMyData|What About My Data header@@">What about my data ?</span>
    </a>
  </mat-expansion-panel>

  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title  class="mat-item-color">
        <mat-icon>accessibility_new</mat-icon>
        <span i18n="@@Referee">Referee</span>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <a mat-list-item
      *ngIf="this.serveUser.isReferee"
       role="navigation"  
       routerLink="referee"
       (click)="onClose()">
       <mat-icon class="expansion-panel-item-icon">important_devices</mat-icon>
       <span i18n="OpenConsole|Open Console in header@@">Open Console</span>  
    </a>

    <a mat-list-item
      role="navigation" 
      routerLink="main/info/referee"
      (click)="onClose()">
    <mat-icon class="expansion-panel-item-icon">build</mat-icon>
    <span class="expansion-panel-item-caption" 
    i18n="@@48d83874826df3e9c7ff1e807cd3ff376388b022">How it works ?</span> 
  </a>

    <a mat-list-item
       role="navigation"  
       routerLink="main/info/referee"
       fragment ="requirements"
       (click)="onClose()">
    <mat-icon class="expansion-panel-item-icon">phonelink_setup</mat-icon>
    <span class="expansion-panel-item-caption"
    i18n="Requirements|Requirements in header@@">Requirements </span>
    </a>

  </mat-expansion-panel>

  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title  class="mat-item-color">
        <mat-icon>accessibility_new</mat-icon>
        <span i18n="48fedddf15c7e0d0282c46451c6e004aa38af6fb">Organiser</span>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <a mat-list-item
    *ngIf="this.serveUser.isOrganiser"
       role="navigation"
       routerLink="organiser"
       (click)="onClose()">
       <mat-icon class="expansion-panel-item-icon">important_devices</mat-icon>
    <span i18n="OpenConsole|Open Console in header@@">Open Console</span>  
    </a>

    <a mat-list-item
      role="navigation"
      routerLink="main/info/organiser"
      (click)="onClose()">
    <mat-icon class="expansion-panel-item-icon">build</mat-icon>
    <span class="expansion-panel-item-caption"
    i18n="@@48d83874826df3e9c7ff1e807cd3ff376388b022">How it works ?</span>
    </a>

    <a mat-list-item
       role="navigation"  
       routerLink="main/info/organiser/benefits"
       (click)="onClose()">
    <mat-icon class="expansion-panel-item-icon">emoji_events</mat-icon>
    <span class="expansion-panel-item-caption"
    i18n="Benefits|Benefits in header@@">Benefits</span>
    </a>

    <a mat-list-item
      role="navigation" 
      routerLink="main/info/organiser/future"
      (click)="onClose()">
    <mat-icon class="expansion-panel-item-icon">trending_up</mat-icon>
    <span class="expansion-panel-item-caption"
    i18n="Future| Future in header@@">Future</span> 
    </a>

    <a mat-list-item
       *ngIf="this.serveUser.isOrganiser"
       role="navigation"
       routerLink="main/info/organiser/pricing"
       (click)="onClose()">
       <mat-icon class="expansion-panel-item-icon">receipt</mat-icon>
    <span class="expansion-panel-item-caption"
    i18n="Pricing|Pricing in header@@">Pricing</span> 
    </a>


  </mat-expansion-panel>

</mat-accordion>

<app-sidebar-auth-links (click)="onClose()"></app-sidebar-auth-links>
</mat-nav-list>

</ng-container>

