import { Component, OnInit, Output, EventEmitter} from '@angular/core';

import { AuthService } from 'src/app/auth-module/service/auth.service';
import { ServeUserService } from 'src/app/auth-module/service/serveUser.service';


@Component({
  selector: 'app-header-manage-contests-links',
  templateUrl: './header-manage-contests-links.component.html', 
})
export class HeaderManageContestsLinksComponent implements OnInit {
 

  constructor(private authService: AuthService, public serveUser: ServeUserService) { 
  }

  ngOnInit() {
  }



}