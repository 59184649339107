export interface Roles{
    organiser?: Constest[];
    referee?: Constest[];
    admin?: Constest[];
}

export interface Constest{
    name: string;
    key: string
}

export interface UserData{
    name: string;
    surname: string;
    email?: string;
    comapany?: CompanyData;
}
export interface CompanyData{
    companyName: string;
    companyTaxNumber: string;
    companyAddress: string;
}


export class User {

    email: string;
    phone: string;
    roles?: Roles;
    userData?: UserData;
    newOrganiser?: boolean;
    newReferee?: boolean;
    isNewUser?: boolean;


    constructor(authData) {

        this.email = authData.email;
        this.phone = authData.phone;
    }

}