import { Component, OnInit } from '@angular/core';
import { BaseOrganiserComponent } from '../../baseOrganiser.component';
import { UIService } from 'src/app/shared/service/ui.service';
import { User, UserData, CompanyData } from 'src/app/auth-module/user';
import { ServeUserService } from 'src/app/auth-module/service/serveUser.service';
import { AuthService } from 'src/app/auth-module/service/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-profile-data',
  templateUrl: './profile-data.component.html',
  styleUrls: ['./profile-data.component.css']
})
export class ProfileDataComponent extends BaseOrganiserComponent implements OnInit {

  user: User
  error: boolean = false
  
  updateMessage: string

  constructor(private UIservice: UIService, 
              private serveUser: ServeUserService,
              private authService: AuthService,
              private router: Router
              ) {
    super(UIservice);
  }

  ngOnInit() {

    if (this.serveUser.user.email != 'none')
    {
      this.user = {...this.serveUser.user}
      this.user.userData = this.user.userData ? this.user.userData : <UserData>{}
      this.user.userData.comapany = this.user.userData.comapany ? this.user.userData.comapany : <CompanyData>{}
    }
    else
    {
      this.error = true
    }
    
  }

 async onUpdate(){
    
    const status = this.authService.updateUser(this.user)
    await status.then((result) => {this.onUpdateResult(result)})
  }

  onUpdateResult(_result: string)
  {
    if (_result){
      let data = {
        title: $localize`Profile Data Updated`,
        content: $localize`You will be redirected to next step`
    }
    this.UIservice.openInfoDialog(data.title, data.content,true)
    this.router.navigate(['/organiser'])
    }
    else {
      let data = {
        title: $localize`Profile Data NOT updated`,
        content: $localize`Internall error occured. Please try later`
    }
    this.UIservice.openInfoDialog(data.title, data.content,false)
    this.router.navigate(['/organiser'])
    }
 
    
    
  }

}
