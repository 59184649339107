import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MaterialModule } from '../shared/material.module';
import { CoreModule } from '../core-module/core.module'

import { HeaderComponent } from './navigation/header/header.component';
import { SidenavListComponent } from './navigation/sidenav-list/sidenav-list.component';
import { SharedModule } from '../shared/shared.module';
import { StoreModule } from '@ngrx/store'
import { reducers } from './app.reducer';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';
import { LandingAppSharedModule } from 'projects/landingApp/src/app/app.module';
import { AdminAppSharedModule } from 'projects/adminApp/src/app/app.module';
import { ContestAppSharedModule } from 'projects/contestApp/src/app/app.module';
import { OrganiserAppSharedModule } from 'projects/organiserApp/src/app/app.module';
import { RefereeAppSharedModule } from 'projects/refereeApp/src/app/app.module';
import { FooterComponent } from './footer/footer.component';
import { AuthModule } from '../auth-module/auth.module';
import { SideBarAuthLinksComponent } from './navigation/sidenav-list/sidebar-auth-links.component';
import { SideBarManageProfileLinksComponent } from './navigation/sidenav-list/sidebar-manage-profile-links.component';
import { SideBarManageContestsLinksComponent } from './navigation/sidenav-list/sidebar-manage-contests-link.component';
import { HeaderManageProfileLinksComponent } from './navigation/header/parts/organiser/manage-profile/header-manage-profile-links.component';
import { HeaderManageContestsLinksComponent } from './navigation/header/parts/organiser/manage-contests/header-manage-contests-links.component';
import { HeaderAuthLinksComponent } from './navigation/header/parts/auth-links/header-auth-links.component';
import { HeaderHomeLinkComponent } from './navigation/header/parts/home-link/header-home-link.component';
import { HeaderManageRefereesLinksComponent } from './navigation/header/parts/organiser/manage-referees/header-manage-referees-links.component';
import { SideBarManageRefereesLinksComponent } from './navigation/sidenav-list/sidebar-manage-referees-links.component';






@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SidenavListComponent,
    FooterComponent,
    SideBarAuthLinksComponent,
    SideBarManageProfileLinksComponent,
    SideBarManageContestsLinksComponent,
    SideBarManageRefereesLinksComponent,

    HeaderManageProfileLinksComponent,
    HeaderManageContestsLinksComponent,
    HeaderManageRefereesLinksComponent,
    HeaderAuthLinksComponent,
    HeaderHomeLinkComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    FlexLayoutModule,
    CoreModule,
    SharedModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    AuthModule,
    StoreModule.forRoot(reducers),
    LandingAppSharedModule.forRoot(),
    AdminAppSharedModule.forRoot(),
    ContestAppSharedModule.forRoot(),
    OrganiserAppSharedModule.forRoot(),
    RefereeAppSharedModule.forRoot()
  ],
  exports:[
  ],
  providers: [
  {
    provide: RECAPTCHA_SETTINGS,
    useValue: { siteKey: '6Lc2RdAUAAAAAEL6M8tuhmieByhTz-c-DW2vO5It' } as RecaptchaSettings
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
