<section *ngIf="this.fee && modeEdit === ''; else editFeesForm" class="content-container" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="1vw">


    <h1 class="mat-headline" style="text-decoration: underline;" i18n="Fees Details Header| Header for Fees Detailss@@">Fees:</h1>

    <div style="width: 100%;" fxLayout="column" fxLayoutAlign="start start" >

        <p fxLayout="row" fxLayoutAlign="start start">
            <label class="propertyLabel" title="Fee Type" i18n="Fee Type Label|Fee Type label@@">Fee Type:</label>
            <span *ngIf="this.fee.feeType == 'Angler'" i18n="@@Person">Person</span>
            <span *ngIf="this.fee.feeType == 'Team'" i18n="@@cc2bc5da34f5023a830bb63577ae545556348c65">Team </span>
        </p>

        <p fxLayout="row" fxLayoutAlign="start start">
            <label class="propertyLabel" title="Fee Amount" i18n="Fee Amount Label|Fee Amount label@@">Fee Amount:</label>
        {{this.fee.feeAmount}}
        </p>

        <p fxLayout="row" fxLayoutAlign="start start">
            <label class="propertyLabel" title="Fee Currency" i18n="Fee Currency Label|Fee Currency label@@">Fee Currency:</label>
        {{this.fee.feeCurrency}}
        </p>

        <p fxLayout="row" fxLayoutAlign="start start">
            <label class="propertyLabel" title="Fee TeamRunner" i18n="Fee TeamRunner Label|Fee TeamRunner label@@">Fee TeamRunner:</label>
            <span *ngIf="this.fee.feeTeamRunner == 'Not Allowed'" i18n="@@1eed209570b0bb170d64cbde69ff7e075fc0b289">Not Allowed</span>
            <span *ngIf="this.fee.feeTeamRunner != 'Not Allowed'">{{this.fee.feeTeamRunner}}</span>

        </p>

        <p fxLayout="row" fxLayoutAlign="start start">
            <label class="propertyLabel" title="Fee Deadline" i18n="Fee Deadline Label|Fee Deadline label@@">Fee Deadline:</label>
        {{this.fee.deadline | date:"dd/MM/yyyy"}}
        </p>

        <p fxLayout="row" fxLayoutAlign="start start">
            <label class="propertyLabel" title="Fee payment details provided" i18n="@@FeePaymentDetailsProvided">Fee Payment Details:</label>
            <span *ngIf="this.fee.paymentDetails.providedDetails" i18n="@@CarpContestSendsData">Carp Contest send details</span>
            <span *ngIf="!this.fee.paymentDetails.providedDetails" i18n="@@OrganiserSendsData">Organiser send details</span>
        </p>

        <div *ngIf="this.fee.paymentDetails.providedDetails" fxLayout="column" fxLayoutAlign="start start">

            <p fxLayout="row" fxLayoutAlign="start start">
                <label class="propertyLabel" title="Fee bank Account Number" i18n="@@BankAccountNumber">Bank Account Number:</label>
            {{this.fee.paymentDetails.bankAccountNumber}}
            </p>

            <p fxLayout="row" fxLayoutAlign="start start">
                <label class="propertyLabel" title="Fee recipient Name" i18n="@@RecipientName">Recipient Name:</label>
            {{this.fee.paymentDetails.receiverName}}
            </p>

            <p fxLayout="row" fxLayoutAlign="start start">
                <label class="propertyLabel" title="Fee recipient Surname" i18n="@@RecipientSurname">Recipient Surname:</label>
            {{this.fee.paymentDetails.receiverSurname}}
            </p>

            <p fxLayout="row" fxLayoutAlign="start start">
                <label class="propertyLabel" title="Fee recipient Company" i18n="@@RecipientCompany">Recipient Company:</label>
            {{this.fee.paymentDetails.receiverCompany}}
            </p>

            <p fxLayout="row" fxLayoutAlign="start start">
                <label class="propertyLabel" title="Fee recipient Company Address" i18n="@@RecipientCompanyAdress">Recipient Company Address:</label>
            {{this.fee.paymentDetails.receiverAddress}}
            </p>

        </div>

    </div>

    <button *ngIf="!this.modeEdit"
            mat-button color="accent"
            (click)="onEdit()"
            i18n="Fee Edit Button|Fee Edit Button@@"
    >
    Edit Fees
    </button>
</section>

<ng-template #editFeesForm>

    <section [ngClass]="{'content-container-edit': this.modeEdit === 'edit' || this.isCreate && !this.UIservice.isMobileSM,
                        'mobile-content-container-edit': this.isCreate && this.UIservice.isMobileSM}"
                        fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="1vw">

        <h1 *ngIf="!this.isCreate" class="mat-headline" i18n="Organiser Edit Fees Header| Header for Edit Fees Details@@">Edit Fees:</h1>
        <h1 *ngIf="this.isCreate" class="mat-headline" i18n="Organiser Enter Fees Header| Header for Enter Fees Details@@">Enter Fees:</h1>

        <form *ngIf="this.editFees" fxLayout="column" fxLayoutAlign="start center" #q="ngForm">

            <div fxLayout="row" fxLayoutGap="5vw">
            <section fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="1vh">
                <mat-form-field>
                    <mat-label title="Fee Type" i18n="Fee Type Label|Fee Type label@@">Fee Type</mat-label>
                    <mat-select ([ngModel])="editFees.feeType" [(value)]="editFees.feeType" required>
                        <mat-option value="Angler" i18n="5deaa1abff8a881eb41426c36a1a34b7847775e2">Person</mat-option>
                        <mat-option value="Team" i18n="cc2bc5da34f5023a830bb63577ae545556348c65">Team</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field>
                    <mat-label title="Fee Amount" i18n="Fee Amount Label|Fee Amount label@@">Fee Amount</mat-label>
                    <input matInput
                            type="number"
                            [(ngModel)]="editFees.feeAmount"
                            name="FeeAmount"
                            required
                    >
                </mat-form-field>

                <mat-form-field>
                    <mat-label title="Fee Currency" i18n="Fee Currency Label|Fee Currency label@@">Fee Currency</mat-label>
                    <mat-select ([ngModel])="editFees.feeCurrency" [(value)]="editFees.feeCurrency" required>
                        <mat-option value="PLN" >PLN</mat-option>
                        <mat-option [disabled]="true" value="EUR">Euro</mat-option>
                        <mat-option [disabled]="true" value="CZK">Kč</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field>
                    <mat-label title="Fee Deadline" i18n="Fee Deadline Label|Fee Deadline label@@">Fee Deadline</mat-label>
                    <input matInput [matDatepicker]="deadlinePicker"
                            [(ngModel)]="editFees.deadline"
                            readonly
                            required
                            name="feeDeadline"
                            (dateInput)="onDateInput($event)"
                    >
                    <mat-datepicker-toggle matSuffix [for]="deadlinePicker"></mat-datepicker-toggle>
                    <mat-datepicker #deadlinePicker  [touchUi]="true" [color]="color"></mat-datepicker>
                </mat-form-field>

                <section fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="1vh">
                    <label title="Team Runner" i18n="Team Runner Label|Fee Team Runner label@@">Team Runner</label>
                    <mat-radio-group [(ngModel)]="teamRunnedAllowed" name="RunneOption">
                      <mat-radio-button class="radio-margin" (click)="onRunnerType('allowed')" value="">Allowed</mat-radio-button>
                      <mat-radio-button class="radio-margin" (click)="onRunnerType('not')" value="Not Allowed">Not Allowed</mat-radio-button>
                    </mat-radio-group>
                  </section>

                <mat-form-field *ngIf="teamRunnedAllowed === ''">
                    <mat-label title="Fee TeamRunner" i18n="Fee TeamRunner Label|Fee TeamRunner label@@">Fee TeamRunner</mat-label>
                    <input matInput
                            [(ngModel)]="editFees.feeTeamRunner"
                            name="FeeTeamRunner"
                    >
                </mat-form-field>

            </section>

            <section fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="1vh">

                <mat-form-field>
                    <mat-label title="Fee payment details provided" i18n="@@FeePaymentDetailsProvided">Fee Payment Details:</mat-label>
                    <mat-select ([ngModel])="editFees.paymentDetails.providedDetails" [(value)]="editFees.paymentDetails.providedDetails" required>
                        <mat-option [value]="true" i18n="@@CarpContestSendsData" >Carp Contest send details</mat-option>
                        <mat-option [value]="false" i18n="@@OrganiserSendsData">Organiser send details</mat-option>
                    </mat-select>
                </mat-form-field>

                <section *ngIf="editFees.paymentDetails.providedDetails === true" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="1vh">

                    <mat-form-field>
                        <mat-label i18n="@@RecipientName">Recipient Name:</mat-label>
                        <input matInput
                                type="text"
                                [(ngModel)]="editFees.paymentDetails.receiverName"
                                name="Name"

                        >
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label i18n="@@RecipientSurname">Recipient Surname:</mat-label>
                        <input matInput
                                type="text"
                                [(ngModel)]="editFees.paymentDetails.receiverSurname"
                                name="Surname"

                        >
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label i18n="@@RecipientCompany">Recipient Company:</mat-label>
                        <input matInput
                                type="text"
                                [(ngModel)]="editFees.paymentDetails.receiverCompany"
                                name="Company"
                        >
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label i18n="@@BankAccountNumber">Bank Account Number:</mat-label>
                        <input matInput
                                type="text"
                                [(ngModel)]="editFees.paymentDetails.bankAccountNumber"
                                name="Bank Account number"
                                required
                        >
                    </mat-form-field>


                    <mat-form-field >
                        <mat-label i18n="@@RecipientCompanyAdress">Recipient Company Address:</mat-label>
                        <textarea matInput
                                type="text"
                                [(ngModel)]="editFees.paymentDetails.receiverAddress"
                                name="Address"
                                required
                                cdkTextareaAutosize
                                cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="10"
                        ></textarea>
                    </mat-form-field>

                  </section>

            </section>

            </div>

                <div fxFlex fxLayoutGap="2vh">
                    <button *ngIf="!this.isCreate"
                        mat-raised-button
                        color="warn"
                        i18n="cancel| cancel button@@"
                        (click)="onCancel()">Cancel</button>

                    <button [disabled]="!(q.valid && q.touched)"
                        mat-raised-button
                        color="primary"
                        i18n="Edit Save Button| Edit Save Button@@"
                        (click)="onUpdate()">Save</button>

                    <label i18n="fillRequiredFields| fill Required Data@@">* required fields</label>
                </div>

        </form>

    </section>

</ng-template>
