        
    <button [matMenuTriggerFor]="manageAccount"
            mat-button
            aria-label="Manage Profiles Menu"
     >
    <mat-icon>expand_more</mat-icon>
    <span i18n="Account| Account in Navigation@@">Account</span>
      </button>

    <mat-menu #manageAccount="matMenu" fxLayout="column">

            <button *ngIf="this.serveUser.isAdmin || this.serveUser.isOrganiser; else refereeData"
                mat-menu-item
                role="navigation" 
                routerLink="organiser/account">
            
                    <mat-icon>build</mat-icon>
                    <span i18n="ProfileData| Profile Data in Navigation@@">Profile Data</span>  
            </button>

            <ng-template #refereeData>

                <button *ngIf="this.serveUser.isReferee && !this.serveUser.isOrganiser"
                mat-menu-item
                role="navigation" 
                routerLink="referee/account">
            
                    <mat-icon>build</mat-icon>
                    <span i18n="ProfileData| Profile Data in Navigation@@">Profile Data</span>  
            </button>

            </ng-template>

            <button *ngIf="this.serveUser.isAdmin || this.serveUser.isOrganiser"
                mat-menu-item
                role="navigation"  
                routerLink="/organiser/account-balance">

                    <mat-icon>payment</mat-icon>
                    <span i18n="AccountBalance| Account Balance in Header@@">Account Balance</span>
            </button>

            <button *ngIf="!this.serveUser.isOrganiser"
                mat-menu-item
                role="navigation"  
                (click)="becomeOrganiser()">

                    <mat-icon>accessibility_new</mat-icon>
                    <span i18n="@@BecomeOrganiser">Become an Organiser</span>
            </button>

            <button *ngIf="!this.serveUser.isReferee"
                mat-menu-item
                role="navigation"  
                (click)="becomeReferee()">

                    <mat-icon>accessibility_new</mat-icon>
                    <span i18n="@@BecomeReferee">Become a Referee</span>
            </button>

            

    </mat-menu>
 