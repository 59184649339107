<div class="Landing-Background" fxFlex="100" fxLayoutAlign="center center" fxLayoutAlign.gt-sm="center center"  fxLayout="column">

    <button class="action-button" role="navigation" (click)="openFishDialog()">
        <div fxLayout="column" fxLayoutAlign="center center" fxFlex>
            <h2 class="mat-h2" i18n="@@AddFish">ADD FISH</h2>

            <div fxFlex style="width: 100%;" fxLayoutAlign="center center">
                <i class="material-icons md-48">addchart</i>
            </div>
        </div>
    </button>


</div>