import { Component, OnInit, Input, ViewChild, OnDestroy,ChangeDetectorRef} from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { RankingItem } from 'src/app/data/models/Manage/rankingItem.model';
import { Observable, Subscription } from 'rxjs';
import { UIService } from 'src/app/shared/service/ui.service';

declare var require: any
@Component({
  selector: 'app-team-catches',
  templateUrl: './team-catches.component.html',
  styleUrls: ['./team-catches.component.css']
})
export class TeamCatchesComponent implements OnInit, OnDestroy {


    @Input() ranking: RankingItem[]
    @Input() rankingDataLoadEvent: Observable<RankingItem[]>
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;


    private eventsSubscription: Subscription;

    kgInRanking:number
    fishInRanking:number
    teamsInRanking:number
    public dataSourceLength: number = 0

    cardData: Observable<RankingItem[]>
    displayedColumns= ['position', 'pegNo', 'lastChange', 'TopFish', 'Score', 'kgToNext', 'kgToFirst', 'fishToNext', 'fishToFirst'];
    dataSource = new MatTableDataSource<RankingItem>()
    pageEvent: PageEvent;


  constructor(private cdRef:ChangeDetectorRef,  public UIservice: UIService) {
    this.fishInRanking = 0;
    this.kgInRanking = 0;
    this.teamsInRanking = 0;
    this.ranking =  <RankingItem[]>{}
    this.dataSource.data = this.ranking
    this.dataSourceLength = this.dataSource.data.length
    this.cardData = this.dataSource.connect()
  }

  ngOnInit() {


    this.eventsSubscription = this.rankingDataLoadEvent.subscribe(ranking => {
      if(ranking.length > 0)
      {
      this.ranking = this.prepareStatistics(ranking)
      this.dataSource.data = this.ranking
      this.dataSource.paginator = this.paginator;

      }


    })
    if(this.ranking.length > 0)
    {
      this.ranking = this.prepareStatistics(this.ranking)
      this.dataSource.data = this.ranking
      this.dataSource.paginator = this.paginator;
      this.cardData = this.dataSource.connect()
    }
  }

  ngAfterViewChecked(){

    this.cdRef.detectChanges();
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  prepareStatistics(_rankingData: RankingItem[]): RankingItem[]{

   this.calculateTotalRankingStats(_rankingData)

   return this.calculateTeamRankingStats(_rankingData)

  }

  calculateTotalRankingStats(_rankingData: RankingItem[]){

    this.fishInRanking = 0;
    this.kgInRanking = 0;
    this.teamsInRanking = 0;


    if (this.ranking){
      this.ranking.forEach(team => {
        this.kgInRanking = this.kgInRanking + team.classificationScore * 1
        this.fishInRanking = this.fishInRanking + team.topFish.length;
      })
      this.teamsInRanking = this.ranking.length
      this.kgInRanking = Math.round(this.kgInRanking * 100) /100
    }
  }

  calculateTeamRankingStats(_rankingData: RankingItem[]): RankingItem[]{

    const data = _rankingData
    let dataIndex = data.length -1

    for(let i = dataIndex; i > 0; i--){

      let team = data[i]
      let myCatches = team.topFish
      let myCatchesLen = team.topFish.length
      let nextTeam = _rankingData[i -1]
      let firstTeam = _rankingData[0]

      let myScore = team.classificationScore
      let nextScore = nextTeam.classificationScore
      let firstTeamScore = firstTeam.classificationScore

      team.Stats.kilosToNext = Math.round((myScore - nextScore) * 100) / 100
      team.Stats.kilosToFirst = Math.round((myScore - firstTeamScore) * 100) / 100
      team.Stats.fishToNext = -team.Stats.kilosToNext
      team.Stats.fishToFirst = -team.Stats.kilosToFirst


      if (myCatchesLen == team.sortType){

        myCatches.sort((a,b)=> a.weight - b.weight)

        let leastFishWeight = myCatches[0].weight

      team.Stats.fishToNext = Math.round((team.Stats.kilosToNext - leastFishWeight - 0.01) * 100) / 100 * -1
      team.Stats.fishToFirst = Math.round((team.Stats.kilosToFirst - leastFishWeight - 0.01) * 100) /100 * -1

      }
    }
  return data
  }

  openFishPhoto(photoUrl){

    if(!!photoUrl)
    {
      this.UIservice.openFishPhotoDialog(photoUrl)
    }

  }

  ngOnDestroy(){
    this.eventsSubscription.unsubscribe()
  }
}
