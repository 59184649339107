
import { UIService } from 'src/app/shared/service/ui.service';


export abstract class BaseLandingComponent {
    constructor(UIservice: UIService){
        UIservice.getIsMobile()
        window.onresize = () => {
            UIservice.getIsMobile()
          };
    }


}