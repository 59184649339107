<div class="Landing-Background" fxFlex="100" fxLayoutAlign="center center" fxLayoutAlign.gt-sm="center center"  fxLayout="column">

    <app-assign-to-contest *ngIf="assignToContest || !hasContests"></app-assign-to-contest>


    <app-select-contest *ngIf="submitContest"></app-select-contest>

    <section *ngIf="!submitContest && !assignToContest && hasContests" fxLayout="column" fxLayoutGap="5vh">
        <button class="action-button" role="navigation" (click)="assignToNewContest()">
            <div fxLayout="column" fxFlexFill fxLayoutAlign="center center" fxFlex>
                <h2 class="mat-h2" i18n="@@AssignToContest">Assign To Contest</h2>
    
                <div fxFlex style="width: 100%;" fxLayoutAlign="center center">
                    <i class="material-icons md-48">library_add</i>
                </div>
            </div>
        </button>

        <button class="action-button" role="navigation" (click)="onSubmitToContest()">
            <div fxLayout="column" fxFlexFill fxLayoutAlign="center center" fxFlex>
                <h2 class="mat-h2" i18n="@@SelectContestToReferee">Select Contest</h2>
    
                <div fxFlex style="width: 100%;" fxLayoutAlign="center center">
                    <i class="material-icons md-48">library_add_check</i>
                </div>
            </div>
        </button>
    </section>
    
    
</div>