import { TeamService } from 'src/app/core-module/entityServices/team.service';
import { GlobalsService } from 'src/app/core-module/entityServices/serviceGlobals';
import { Injectable } from '@angular/core';





@Injectable()

  export class SignUpTeamService {

    constructor(private teamService: TeamService,
                private globals: GlobalsService
                ){
            
    }

   setTeamReservation(_reservation): Promise<any> {

        this.teamService.reservationPath = this.globals.getReservationPathPrivate()

       return this.teamService.setTeamReservation(_reservation)
       
    }
  }

