<div fxLayout="column" fxLayoutAlign="start center" style="margin: 2vh;">

    <div fxLayout="column" fxLayoutAlign="start center">
        
        <h1 class="mat-h1" i18n="@@924ada7cae46906deadbf5390c7d6462dc9d576d">Privacy Politics</h1>
        
    </div>

    <div fxLayout="column" fxLayoutAlign="center center">

        

        <h2 class="mat-h2" style="text-align: center; max-width: 50%;">Polityka prywatności serwisu www.carp-contests.com oraz www.carp-contest.pl, nieodłącznym dokumentem uzupełniającym niniejszą Politykę Prywatności jest dokument Polityka RODO.
            </h2>
        
        <div style="max-width: 80%;">
        
        <ol>
            <li class="mat-h3" style="padding-top: 1vh; font-weight: bold;">Pojęcia</li>

                <ul>
                    <li><strong>Serwis </strong>– Aplikacja internetowa pod adresami carp-contests.com oraz carp-contest.pl </li>
                    <li><strong>Panel Sędziego </strong>– Część Serwisu, dostępna tylko dla osób zarejestrowanych w Serwisie jako Sędzia i potwierdzonych przez Organizatora jako sędzia konkretnych zawodów wędkarskich. </li>
                    <li><strong>Panel Organizatora </strong>Część Serwisu, dostępna tylko dla osób zarejestrowanych w Serwisie jako Organizator. Do potwierdzenia danych Organizatora Serwis może żądać dodatkowych akcji uwierzytelniających.</li>
                    <li><strong>Panel Zawodów </strong>– Część Serwisu, ogólnodostępna dla wszystkich odwiedzających Serwis, zawierające podstawowe informacje, regulamin, zapisy, zapisane drużyny i relacje na żywo dotyczące konkretnych zawodów wędkarskich.</li>
                    <li><strong>Użytkownik</strong>– osoba dobrowolnie korzystająca z Serwisu bez względu na inne pełnione funkcje</li>
                    <li><strong>Uczestnik</strong>– osoba dobrowolnie zgłaszająca chęć udziału w wybranych przez siebie zawodach wędkarskich, bądź zgłoszona przez innego Uczestnika jako członek Drużyny.</li>
                    <li><strong>Drużyna</strong>– zbiór Uczestników o ilości dozwolonej w ramach regulaminu konkretnych zawodów wędkarskich, ustalonych przez Organizatora.</li>
                    <li><strong>Sędzia</strong>– osoba dobrowolnie zgłaszająca się lub wskazana przez Organizatora do sprawowania obowiązków sędziego na zawodach wędkarskich. Sędzia dokonuje pomiaru masy, oceny gatunku,  wykonuje zdjęcia oraz zapis informacji dot. Drużyny lub Uczestników oraz połowu. Dane zapisywane są poprzez Panel Sędziego w Serwisie. Sędzia musi być przypisany do co najmniej 1 zawodów wędkarskich.</li>
                    <li><strong>Organizator</strong>– osoba fizyczna lub osobowość prawna zgłoszona jako prawny organizator zawodów wędkarskich. Organizator odpowiada za wszelkie kwestie dotyczące organizacji zawodów wędkarskich, finansów, regulaminu oraz ponosi finalny koszt korzystania z usług Serwisu (w przypadku wybory płatnej wersji rozszerzonej).</li>

                </ul>

            <li class="mat-h3" style="padding-top: 1vh; font-weight: bold;">Informacje ogólne</li>
                
                <ol>
                    <li>Niniejsza Polityka Prywatności określa zasady przetwarzania i ochrony danych osobowych przekazanych przez Użytkowników, Uczestników, Sędziów i Organizatorów w związku z korzystaniem przez nich z usług Serwisu bądź zgłoszenia uczestnictwa w wędkarskich imprezach sportowych publikowanych lub relacjonowanych poprzez Serwis. Rejestracja w Serwisie nie jest obowiązkowa. Udostępnienie danych osobowych jest dobrowolne, przy czym podanie adresu poczty elektronicznej Uczestnika, Sędziego lub Organizatora jest niezbędne do zarejestrowania konta oraz czynnego korzystania z usług. </li>        
                    <li>Formularze zaproponowane na dedykowanych stronach mogą wskazywać inne dane osobowe, których przekazanie jest niezbędne w celu skorzystania z konkretnej usługi. W tym celu Uczestnik, Sędzia lub Organizator wyraża zgodę dotyczącą przetwarzania danych osobowych na potrzeby skorzystania z określonych usług, co oznacza, że podstawą prawną przetwarzania jest art. 6 ust. 1 pkt a RODO. Przekazane dane są chronione przed dostępem do nich osób trzecich.</li>
                    <li>Administratorem danych osobowych zawartych w serwisie jest Carp Contest - Jacek Adamarek ADAMAR z siedzibą w 44-100 Gliwice, ul. Zwycięstwa 10  NIP: 6312568311.</li>
                    <li>W trosce o bezpieczeństwo powierzonych danych opracowano wewnętrzne procedury i zalecenia, które mają zapobiec udostępnieniu danych osobom nieupoważnionym. Kontrolujemy ich wykonywanie i stale sprawdzamy ich zgodność z odpowiednimi aktami prawnymi - ustawą o ochronie danych osobowych, ustawą o świadczeniu usług drogą elektroniczną, a także wszelkiego rodzaju aktach wykonawczych i aktach prawa wspólnotowego.</li>
                    <li>Dane Osobowe przetwarzane są na podstawie zgody wyrażanej przez Uczestnika, Sędziego lub Organizatora oraz w przypadkach, w których przepisy prawa upoważniają Administratora do przetwarzania danych osobowych na podstawie przepisów prawa lub w celu realizacji zawartej pomiędzy stronami umowy.</li>
                    <li>Serwis realizuje funkcje pozyskiwania danych i wizerunków użytkowników oraz informacje dotyczących ich zachowania w Serwisie w następujący sposób:</li>

                        <ol type="a">
                            <li>poprzez dobrowolnie wprowadzone w formularzach informacje,</li>
                            <li>poprzez wprowadzone zdjęcia przez Sędziego podczas trwania zawodów wędkarskich,</li>
                            <li>poprzez gromadzenie plików “cookies” </li>
                        </ol>
                    
                    <li>Serwis zbiera informacje dobrowolnie podane przez użytkownika.</li>
                    <li>Dane podane w formularzu są przetwarzane w celu wynikającym z funkcji konkretnego formularza oraz usług Serwisu:</li>

                        <ul>
                            <li>w celu dokonania procesu obsługi zawodów wędkarskich przez Organizatora,</li>
                            <li>w celu relacjonowania i publikacji wyników zawodów wędkarskich,</li>
                            <li>w celu umożliwienia kontaktu telefonicznego lub poprzez adres email pomiędzy Administratorem Serwisu, Organizatorami, Sędziami, Drużynami i Uczestnikami,</li>
                            <li>w celach marketingowych.</li>
                        </ul>
                    
                    <li>Dane osobowe pozostawione w serwisie nie zostaną sprzedane ani udostępnione osobom trzecim, zgodnie z przepisami Ustawy o ochronie danych osobowych</li>
                    <li>Warunkiem wysłania zgłoszenia na zawody wędkarskie jest akceptacja niniejszej Polityki Prywatności oraz wyrażenie zgody na publikację Imienia i Nazwiska oraz zdjęć w Serwisie oraz w portalach Instagram.com oraz Facebook.pl .</li>
                    <li>Do danych zawartych w formularzu przysługuje wgląd osobie fizycznej, która je tam umieściła. Osoba ta ma również prawo do modyfikacji i zaprzestania przetwarzania swoich danych w dowolnym momencie. Zaprzestanie używania danych odbywa się poprzez trwałe zniszczenie wszelkich cech umożliwiających identyfikację osobę której dane te dotyczą.</li>      
                    <li>Zastrzegamy sobie prawo do zmiany w polityce ochrony prywatności serwisu, na które może wpłynąć rozwój technologii internetowej, ewentualne zmiany prawa w zakresie ochrony danych osobowych oraz rozwój naszego serwisu internetowego. O wszelkich zmianach będziemy informować w sposób widoczny i zrozumiały.</li>
                    <li>W Serwisie mogą pojawiać się linki do innych stron internetowych. Takie strony internetowe działają niezależnie od Serwisu i nie są w żaden sposób nadzorowane przez serwis Carp Contest. Strony te mogą posiadać własne polityki dotyczące prywatności oraz regulaminy, z którymi zalecamy się zapoznać.</li>
                    <li>W razie wątpliwości co któregokolwiek z zapisów niniejszej polityki prywatności jesteśmy do dyspozycji - nasze dane znaleźć można w sekcji kontakt na w dolnej części serwisu.</li>
                    <li>Kontakt z Inspektorem Danych Osobowych jest możliwy drogą elektroniczną pod adresem e-mail: administracja@carp-contest.pl</li>
                
                </ol>
        </ol>
    </div>
    </div>


</div>