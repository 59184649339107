import { AngularFireDatabase } from '@angular/fire/compat/database';
import { Injectable } from '@angular/core';
import {AngularFirestore} from '@angular/fire/compat/firestore'
import {AngularFireFunctions} from '@angular/fire/compat/functions'



@Injectable({
    providedIn: 'root'
  })

export class FirebaseService{


    constructor(private db: AngularFireDatabase, private fs: AngularFirestore, public readonly functions: AngularFireFunctions){}




    getCollection(collection: string){
       return this.db.list(collection)
    }

    getDBRef(collection: string){
      return this.db.database.ref(collection)
    }

    getFirestoreRef(){
     return this.fs
    }

    getObjectListByProperty(collection: string, property: string, value: any ){
      return this.db.list(collection, ref => ref.orderByChild(property).equalTo(value))
    }

    getObjectByRef(collection: string, ref: string){
      return this.db.object(collection + '/' + ref)
    }

    getObject(pathReference: string){
      return this.db.object(pathReference)
    }

    getObjectList(collection: string){
      return this.db.list(collection)
    }

    getObjectRef(collection: string, ref: string){
      return this.db.database.ref(collection).child(ref)
    }

    setObject(collection: string, docKey: string, object: any){
      return this.db.database.ref(collection).child(docKey).push(object)
    }

    setNewObject(collection: string, object: any){
      return this.db.database.ref(collection).push(object)
    }

    updateObjectByKey(collection: string, object: any, key: string){
      return this.db.database.ref(collection).child(key).set(object)
    }

    addPropetyValueToObject(collection: string, object: any, key: string, property: string){
      this.db.database.ref(collection).child(key).child(property).push().set(object)
    }

    setObjectCreateHash(collection: string){
     return this.db.database.ref(collection).push().key
    }

    deleteObject(collection: string, docKey: string){
      return this.db.database.ref(collection).child(docKey).remove()
    }

    callFirebaseFunction(name: string){
      console.log(name)
      return this.functions.httpsCallable(name)
    }

    }

