<section fxFlex fxLayout="column" fxLayoutGap="2vh" fxLayoutAlign="start center">

        <section fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="1vh">
            <h2 class="mat-h2" i18n="Premium Contest Tariff Header| Header Contest Tariff@@">Contest Tariff</h2>
            <mat-radio-group fxLayoutGap="20px"
                            [(ngModel)]="this.challenge.Premium.isPremium"
                            (change)="calculatePrice()" >
              <mat-radio-button  [value]="false">Free</mat-radio-button>
              <mat-radio-button  [value]="true" >Premium</mat-radio-button>
            </mat-radio-group>
          </section>

        <div *ngIf="this.challenge.Premium.isPremium; else freeInfo" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="1vh">

          <h2 class="mat-h2" i18n="SelectFunctionsHeader| Header for Select Functions@@">Select Functions</h2>

        <section fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="1vh">
            
            <mat-checkbox   i18n="Benefits Charts| Paragraph Benefits Charts@@"
                            [checked]="true"
                            disabled>
            Charts
            </mat-checkbox>

            <mat-checkbox   i18n="Benefits Referees| Paragraph Benefits Referees@@"
                            [checked]="true"
                            disabled>
            Referees
            </mat-checkbox>

            <mat-checkbox   i18n="Benefits Referees| Paragraph Benefits Referees@@"
                            [checked]="true"
                            disabled>
            Live Scores
            </mat-checkbox>
            
            
            <mat-checkbox   i18n="Benefits Fish Photos| Paragraph Benefits Fish Photos@@"
                            [checked]="true"
                            disabled>
            Fish Photos
            </mat-checkbox>

            <!-- <mat-checkbox   [(ngModel)]="this.challenge.Premium.isEmailInvitations"
                            disabled
                            i18n="Benefits Email invitations| Paragraph Benefits Email invitations@@"
                            (change)="calculatePrice()">
            Email invitations
            </mat-checkbox> -->

            <mat-checkbox   [(ngModel)]="this.challenge.Premium.isAdvancedFacebook"
                            disabled
                            i18n="Benefits Advanced Facebook| Paragraph Benefits Facebook@@"
                            (change)="calculatePrice()">
            Advanced Facebook posts
            </mat-checkbox>

            <mat-checkbox   [(ngModel)]="this.challenge.Premium.isAdvancedInstagram"
                            disabled
                            i18n="Benefits Advanced Instagram| Paragraph Benefits Instagram@@"
                            (change)="calculatePrice()">
            Advanced Instagram posts
            </mat-checkbox>
          </section>

        </div>

        <ng-template #freeInfo>

          <div fxLayout="column">

            <h2 class="mat-h2" i18n="Premium Free Info Header| Header for Premium Free Info@@">Free Functions</h2>
    
            <ul fxLayout="column">
    
                <li>
                    <p i18n="Benefits Contest Page| Paragraph Benefits Contest Page@@">
                        Contest Page
                    </p>
                </li>
    
                <li>
                    <p i18n="Benefits Partners| Paragraph Benefits Partners@@">
                        Partners Swiper
                    </p>
                </li>
    
                <li>
                    <p i18n="Benefits Facebook| Paragraph Benefits Facebook@@">
                        Facebook & Instagram announcement post
                    </p>
                </li>
    
            </ul>
    
        </div>


        </ng-template>
        

        <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="1vh">

            <h3 class="mat-h3" i18n="Premium Maximum Price Header| Header for Maximum Price@@">Maximum Price</h3>

            <p *ngIf="this.challenge.Fee.feeCurrency === 'PLN'">
                {{this.predictedPricePLN}}&nbsp;{{this.challenge.Fee.feeCurrency}}
            </p>

        </div>

</section>