import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';


@Component({
  selector: 'app-chart-bar-horizontal',
  templateUrl: './chart-bar-horizontal.component.html',
  styleUrls: ['./chart-bar-horizontal.component.css']
})

export class ChartBarHorizontalComponent implements OnInit,OnDestroy {
  
  @Input() chartData$: Observable<any[]>;
  @Input() chartData: any[]

  dataSubs: Subscription

  single: any[]
  preparedData: any[]
  multi: any[];

  dataPerDayPeriod: any[]
  dataPerHalfDate: any[]
  dataPerDay: any[]

  view: any[];
  
  // options
  showXAxis = true;
  showYAxis = true;
  gradient = true;
  showLegend = false;
  showDataLabel = true;
  showXAxisLabel = false;
  xAxisLabel = 'false';
  showYAxisLabel = false
  yAxisLabel = 'Weighted Fish';

  colorScheme = {
    domain: ['#2196F3', '#F9A825','#1B5E20','#ef5350']
  };

  constructor(){
     
   }

  ngOnInit() { 
    this.single = this.chartData
    this.dataSubs = this.chartData$.subscribe(data => {
      this.single = data
    })

  }

  // onSelect(event) {
  //   console.log(event);
  // }

  ngOnDestroy(){
    this.dataSubs.unsubscribe()
  }

}
