import { Injectable } from "@angular/core";
import { FirebaseService } from '../base/firebase.service';
import { RankingEntity } from '../Entity/ranking.model';
import { AngularFireList} from '@angular/fire/compat/database';



@Injectable()
export class RankingService{

    basePathReference: string = null;

    constructor(private firebaseService: FirebaseService, ){

    }

    getRanking(): AngularFireList<RankingEntity | any>{

        return this.firebaseService.getCollection(this.basePathReference)
    }
}
