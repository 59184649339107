import { Injectable } from '@angular/core';
import { FirebaseService } from 'src/app/core-module/base/firebase.service';

import { AngularFireList } from '@angular/fire/compat/database';
import { Referee } from 'src/app/data/models/Manage/referee.model';

@Injectable()

export class RefereeService {


    refereesConfirmedCollection: string;
    refereesReservationPath: string;

    constructor(private firebaseService: FirebaseService){
        // this.refereeCollection = challengeCollection + '/Referees';
    }

    getRefereesConfirmed(): AngularFireList<Referee | any>{
        return this.firebaseService.getCollection(this.refereesConfirmedCollection)
    }

    getRefereesReservation(): AngularFireList<Referee | any>{
        return this.firebaseService.getCollection(this.refereesReservationPath)
    }

    setRefereeReservation(_reservation) {

        let key = this.firebaseService.setObjectCreateHash(this.refereesReservationPath)

        return this.firebaseService.updateObjectByKey(this.refereesReservationPath + '/', _reservation, key )

    }

}
