
import { ActivatedRoute } from '@angular/router';
import { UIService } from 'src/app/shared/service/ui.service';

import { Subscription } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { ProvideDataService } from '../../services/provideDataService';
import { Challenge } from 'src/app/data/models/Manage/challenge.model';

@Injectable({
  providedIn: 'root'
})
export abstract class BaseComponent implements OnDestroy{

    dataSubscription: Subscription

    constructor(private _route: ActivatedRoute, 
                dataService: ProvideDataService,
                UIservice: UIService){

        dataService.setRoute(this._route)

        window.onresize = () => {
         UIservice.getIsMobile()
        };
                
        

        if(!UIservice.challengeAlias || !dataService.challenge.value){
  
          this.dataSubscription = dataService.keyReady.subscribe((bool) => {
            
            if(bool)
            {
              dataService.challenge$.subscribe(challenge => {
                dataService.challenge.next(challenge)
                let dateNow = Date.now();
                let _live =  Date.parse(challenge.startDate) - (24*60*60*1000) < dateNow  ? true : false;
                let _premium = false
  
                if(challenge.Premium)
                {
                  _premium = challenge.Premium.isPremium
                }
                UIservice.isPremium = _premium
                UIservice.isLive = true
                UIservice.isSingUpAvailable = challenge.Fee.isSignUpOpen
                UIservice.challengeName = challenge.title;
                UIservice.rulesLink = challenge.Links.rulesLink;
                UIservice.isTeamsAvailable = challenge.amountTeamsActual > 0 || challenge.amountTeamsReservation > 0 ? true : false
                UIservice.challengeAlias = dataService.challengeAlias
                
                UIservice.organiserLoaded.next(challenge.Organizer)
              })
  
              UIservice.partners$ = dataService.partners$;
              UIservice.partnersLoaded.next(true)
            }
           
          })
        }
        

    }

    prepareUI(challenge: Challenge){
      
    }

    ngOnDestroy(): void{
        if(this.dataSubscription){
            this.dataSubscription.unsubscribe()
        }
       
    }


}