import { Component, OnInit, Input } from '@angular/core';
import { AwardsPlace } from 'src/app/data/models/Manage/challenge.model';

@Component({
  selector: 'app-awards-details',
  templateUrl: './awards-details.component.html',
  styleUrls: ['./awards-details.component.css']
})
export class AwardsDetailsComponent implements OnInit {

  @Input() awardsDetails: AwardsPlace
  @Input() currency: string
  constructor() { }

  ngOnInit() {
  }

}
