import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { environment } from '../../environments/environment';

import { FirebaseService } from './base/firebase.service';
import { FireStoreService } from './base/firestore.service';
import { FirebaseAuthService } from './base/firebase-auth.service';
import { AngularFireDatabaseModule} from '@angular/fire/compat/database';
import { AngularFireStorageModule} from '@angular/fire/compat/storage';
import { AngularFireAuthModule} from '@angular/fire/compat/auth';
import { AngularFirestoreModule} from '@angular/fire/compat/firestore'
import {provideAuth, getAuth} from '@angular/fire/auth'
import { AnglerService } from './entityServices/angler.service';
import { ChallengeService } from './entityServices/challange.service';
import { FishService } from './entityServices/fish.service';
import { PartnerService } from './entityServices/partner.service';
import { RefereeService } from './entityServices/referee.service';
import { TeamService } from './entityServices/team.service';
import { GlobalsService } from './entityServices/serviceGlobals';
import { RankingService } from './entityServices/ranking.service';
import { MessageService } from './entityServices/message.service';
import { ContestService } from 'projects/landingApp/src/app/services/contest.service';
import { AngularFireFunctionsModule, REGION, ORIGIN } from '@angular/fire/compat/functions';
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { initializeAppCheck, provideAppCheck, ReCaptchaV3Provider } from '@angular/fire/app-check';
import { AngularFireModule } from '@angular/fire/compat';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    // provideAppCheck(() => initializeAppCheck(getApp(), {
    //   provider: new ReCaptchaV3Provider(environment.appCheck.recaptcha3SiteKey),
    //   isTokenAutoRefreshEnabled: environment.appCheck.isTokenAutoRefreshEnabled,
    // })),
    provideAuth(() => getAuth()),
    // provideDatabase(() => getDatabase()),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AngularFireStorageModule,
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireFunctionsModule
  ],

  exports: [],
  providers: [
    {provide: REGION, useValue: 'europe-west3'},
    // {provide: ORIGIN, useValue: 'https://carp-contest.pl/'},
    FirebaseService,
    FireStoreService,
    FirebaseAuthService,
    AnglerService,
    ChallengeService,
    FishService,
    PartnerService,
    RefereeService,
    GlobalsService,
    RankingService,
    MessageService,
    ContestService,
    TeamService]
})

export class CoreModule { }
