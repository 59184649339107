<!-- <div *ngIf="!challenge" fxFill fxLayout="column" fxLayoutAlign="center center">
        <mat-spinner color="accent"></mat-spinner>
</div> -->

<div *ngIf="challenge" fxLayout="column" fxFlex="100" fxLayoutGap="1" fxLayoutAlign="space-between stretch">
                <h1 *ngIf="!uiService.isMobileSM" style="padding-top: 2vh;" class="mat-h1" fxFlex="none" fxFlexOrder="1" fxLayoutAlign="center center"><strong>{{this.challenge.title }}</strong></h1>
                
                        <section fxFlex="grow" fxFlexOrder="3" fxLayout="row wrap" [ngClass.gt-xs]="'cardSection'" fxLayout.xs="column"  fxLayoutAlign="center space-around">
                                 
                                <mat-card [ngClass.gt-xs]="'infoCard'" fxLayout="column" fxLayoutAlign.xs="space-between stretch" fxLayoutAlign="start center"  fxFlex="100">
                                        <mat-card-title fxFlex="none"  i18n="Welcome Info Card Header| Header for card with informations@@">Contest Info</mat-card-title>                     
                                                                                                
                                        <mat-card-content fxFlex="nogrow" style="width: 100%;" fxLayout.xs="column" fxLayout.gt-xs="row wrap">
                                                                                              
                                                <article class="articleStyle" fxLayout="column" style="width: 100%;" [ngClass.gt-xs]="'mat-title'">
                                                                
                                                        <p>{{ this.challenge.challengeInfo }}</p>
                                                        
                                                        <div class="articleStyle" fxFlex="none" fxLayoutAlign="center start" fxLayout="row wrap" fxLayoutGap.gt-sm="3vw"> 
                                                    
                                                    
                                                                 <div fxFlex.xs="100" fxFlexOrder="1" fxFlex fxFlex.lt-lg="60" fxLayout="column" fxLayoutAlign.gt-xs="start center">
                                                                        <app-contest-details [challenge]="this.challenge"></app-contest-details>
                                                                </div>   
                                                    
                                                    
                                                                <img    *ngIf="contestInfoImageSRC" 
                                                                        fxShow="{{contestInfoImageState}}" 
                                                                        fxFlexOrder.gt-md="2" fxFlexOrder="5" 
                                                                        fxFlex.gt-md="40" fxFlex.gt-xs="90" 
                                                                        [ngClass.xs]="'cardImage'" [attr.src]="contestInfoImageSRC">

                                                                <div fxFlex.xs="100" fxFlex fxLayout="column" fxFlexOrder="3" fxLayoutAlign.gt-xs="start center">
                                                                        <app-team-details [challenge]="this.challenge"></app-team-details>
                                                                </div>                                                                               
                                                                
                                                                <div fxShow.gt-xs="{{!contestInfoImageState}}" fxFlex.xs="100" fxFlexOrder="4" fxFlex fxLayout="column" fxLayoutAlign.gt-xs="start center">
                                                                        <app-awards [awards]="this.challenge.Awards" [currency]="this.challenge.Fee.feeCurrency"></app-awards>
                                                                </div>
                                                        </div>                                                                                                                                                                                                         
                                                    </article>
                                                                                    
                                        </mat-card-content>
                                        <mat-card-actions fxLayout="row wrap" class="cardActions" fxLayoutAlign="center end">
                                                        
                                                <button *ngIf="this.challenge.Links.posterLink" mat-stroked-button i18n="getPosterButton| Button get poster bottom card@@" (click)="showInfoImage(1)"><strong>Poster</strong></button>
                                                <button *ngIf="this.challenge.Links.bankSpotsMapLink" mat-stroked-button i18n="bankSpotsButton| Button bank spots bottom card@@" (click)="showInfoImage(2)"><strong>Bank spots</strong></button>
                                                <button fxShow={{contestInfoImageState}} mat-stroked-button i18n="ShowAwardsList| Button Awards List bottom card@@" (click)="changeImageState()"><strong>Show Awards</strong></button>
                                                <a *ngIf="this.challenge.Links.rulesLink" mat-stroked-button i18n="getRulesButton| Button Rules Download bottom card@@" target="_blank" [attr.href]="this.challenge.Links.rulesLink"><strong>Get Rules...</strong></a>

                                        </mat-card-actions>
                                </mat-card>
                                                       
                                <mat-card  fxLayout="column" fxLayoutAlign.xs="space-between stretch" fxLayoutAlign="space-between center" fxFlex.gt-xs="40%">
                                                        <mat-card-title fxFlex="none" i18n="Welcome Place&Time Card Header| Header for card with Place&Time@@">                      
                                                                        Place & Time
                                                        </mat-card-title>
                                                        <mat-card-content fxFlex="nogrow" fxLayout="column" fxLayout.gt-xs="row wrap" fxLayoutAlign="center center">
                                                        <iframe fxShow="{{contestPlaceMapState}}" fxFlex.gt-xs="100" [ngClass.xs]="'cardImage'" [src]="this.challenge.Links.challengeAreaLink | safeResource" id="googleFrame" frameborder="0" allowfullscreen=""></iframe>
                                                                
                                                        <div [ngClass.gt-xs]="'mat-title'" fxFlex fxLayoutAlign="center center">
                                                                <article class="articleStyle" >
                                                                        <div fxLayout="column"> 
                                                                                <p fxLayoutAlign="start start" fxFlexOrder="1"><label fxFlex.xs="100px" fxFlex="140px" fxLayoutAlign="start start" class="detailsLabel" title="Where" i18n="Welcome Place&Time Card Label Where| Label for Where information@@">Where:</label>&nbsp;{{ this.challenge.place}}</p>
                                                                                <p fxLayoutAlign="start start" fxFlexOrder="2"><label fxFlex.xs="100px" fxFlex="140px" fxLayoutAlign="start start" class="detailsLabel" title="Place details" i18n="Welcome Place&Time Card Label Area Details| Label for Area Details information@@">Details:</label>&nbsp;{{this.challenge.areaDetails}}</p>
                                                                                <p fxLayoutAlign="start start" fxFlexOrder="3"><label fxFlex.xs="100px" fxFlex="140px" fxLayoutAlign="start start" class="detailsLabel" title="When"i18n="Welcome Place&Time Card Label When| Label for When information@@">When:</label>&nbsp;{{ this.challenge.startDate | date:"dd/MM"}} - {{ this.challenge.endDate | date:"dd/MM/yyyy"}}</p>
                                                                        </div>
                                                                </article>
                                                        </div>
                                                        </mat-card-content>

                                                        <mat-card-actions fxFlex="nogrow" class="cardActions" fxLayoutAlign="center end">
                                                                <a      *ngIf="this.challenge.Links.challengeFisheryLink"
                                                                        i18n="enterFisherySiteButton| Button entry link fishery site@@" 
                                                                        [attr.href]="this.challenge.Links.challengeFisheryLink"
                                                                        mat-stroked-button
                                                                        style="font-weight: bold;" 
                                                                        target="_blank">Fishery Site</a>

                                                                <button *ngIf="this.challenge.Links.challengeAreaLink"
                                                                        style="font-weight: bold;"
                                                                        mat-stroked-button i18n="GetMapButton| Button get map bottom card@@" 
                                                                        (click)="showMap()"><strong>Get map...</strong></button>
                                                                
                                                                <a      *ngIf="this.challenge.Links.challengeRouteMeLink"
                                                                        i18n="RouteMeButton| Button route me bottom card@@"
                                                                        [attr.href]="this.challenge.Links.challengeRouteMeLink"
                                                                        style="font-weight: bold;"
                                                                        mat-stroked-button  
                                                                        target="_blank">Route me...</a>
                                                        </mat-card-actions>
                                </mat-card>
                        
                                 <mat-card fxLayout="column" fxLayoutAlign.xs="space-between stretch" fxLayoutAlign="space-between center"  fxFlex.gt-xs="40%">                                
                                                        <mat-card-title fxFlex="none"  i18n="Welcome Join&Start Card Header| Header for card with Join&Start@@">Join & Start</mat-card-title>                     
                                                       
                                                        <mat-card-content fxFlex="nogrow">
                                                                
                                                                <div [ngClass.gt-xs]="'mat-title'" fxFlex fxLayoutAlign="center center">
                                                                            <app-join-start [fee]="this.challenge.Fee"></app-join-start>
                                                                </div>
                                                                        
                                                        </mat-card-content>
                                                        <mat-card-actions fxFlex="nogrow" class="cardActions" fxLayoutAlign="center end">
                                                                        <button *ngIf="this.uiService.isSingUpAvailable && this.uiService.isPremium"
                                                                        (click)="contestOnButtonClick('/sign-me')"
                                                                         mat-raised-button color="accent"
                                                                         i18n="SignUpContestButton| Button signUp contest bottom card@@"
                                                                         ><strong>Sign up...</strong></button>
                                                                      <!--   <button mat-stroked-button i18n="AskQuestionButton| Button ask question bottom card@@"><strong>Ask us...</strong></button> -->
                                                                </mat-card-actions>
                                </mat-card>

                        </section>
                
                
</div>
