import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ModuleWithProviders } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainViewComponent } from './main-view/main-view.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { WelcomeImageComponent } from './main-view/welcome-image/welcome-image.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ContestListsComponent } from './contest-lists/contest-lists.component';
import { ContestTableComponent } from './contest-lists/contest-table/contest-table.component';
import { CoreModule } from 'src/app/core-module/core.module';
import { FilterRegionsComponent } from './contest-lists/filter-regions/filter-regions.component';
import { CompetitorComponent } from './info/competitor/competitor.component';
import { InfoComponent } from './info/info.component';
import { CompHowItWorksComponent } from './info/competitor/comp-how-it-works/comp-how-it-works.component';
import { CompMyDataComponent } from './info/competitor/comp-my-data/comp-my-data.component';
import { RefereeComponent } from './info/referee/referee.component';
import { RefereeHowItWorksComponent } from './info/referee/referee-how-it-works/referee-how-it-works.component';
import { RefereeRequirementsComponent } from './info/referee/referee-requirements/referee-requirements.component';
import { OrganiserComponent } from './info/organiser/organiser.component';
import { OrganiserHowItWorksComponent } from './info/organiser/organiser-how-it-works/organiser-how-it-works.component';
import { BenefitsComponent } from './info/organiser/benefits/benefits.component';
import { FutureComponent } from './info/organiser/future/future.component';
import { PricingComponent } from './info/organiser/pricing/pricing.component';
import { PrivacyComponent } from './info/privacy/privacy.component';
import { RodoComponent } from './info/rodo/rodo.component';
import { TermsAndConditionsComponent } from './info/terms-and-conditions/terms-and-conditions.component';
import { LoginComponent } from './login/login.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { ChooseAppComponent } from './choose-app/choose-app.component';
import { NotAllowedComponent } from './not-allowed/not-allowed.component';
import { RecaptchaModule, RecaptchaFormsModule, RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';

const providers = [
  {
    provide: RECAPTCHA_SETTINGS,
    useValue: { siteKey: '6Lc2RdAUAAAAAEL6M8tuhmieByhTz-c-DW2vO5It' } as RecaptchaSettings
  }
]

@NgModule({
  declarations: [
    AppComponent,
    MainViewComponent,
    WelcomeImageComponent,
    ContestListsComponent,
    ContestTableComponent,
    FilterRegionsComponent,
    CompetitorComponent,
    InfoComponent,
    CompHowItWorksComponent,
    CompMyDataComponent,
    RefereeComponent,
    RefereeHowItWorksComponent,
    RefereeRequirementsComponent,
    OrganiserComponent,
    OrganiserHowItWorksComponent,
    BenefitsComponent,
    FutureComponent,
    PricingComponent,
    PrivacyComponent,
    RodoComponent,
    TermsAndConditionsComponent,
    LoginComponent,
    SignUpComponent,
    ChooseAppComponent,
    NotAllowedComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    FlexLayoutModule,
    CoreModule,
    RecaptchaModule,
    RecaptchaFormsModule 
  ],
  providers: providers,
  bootstrap: [AppComponent]
})
export class AppModule { }

@NgModule({})
export class LandingAppSharedModule{
  static forRoot(): ModuleWithProviders<AppModule> {
    return {
      ngModule: AppModule,
      providers: providers
    }
  }
}
