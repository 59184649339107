import { Component, OnInit,OnDestroy} from '@angular/core';
import { Partner } from 'src/app/data/models/Manage/partner.model';

import SwiperCore, { SwiperOptions} from 'swiper';
import { Subscription } from 'rxjs';
import { UIService } from 'src/app/shared/service/ui.service';




@Component({
  selector: 'app-partner-swiper',
  templateUrl: './partner-swiper.component.html',
  styleUrls: ['./partner-swiper.component.css']
})


export class PartnerSwiperComponent implements OnInit, OnDestroy {

  dataSubscription: Subscription
  swiperNagivators: boolean = true;

  public config: SwiperOptions = {
    // a11y: true,
    direction: 'horizontal',
    slidesPerView: 3,
    spaceBetween: 10,
    keyboard: true,
    mousewheel: true,
    autoplay: true,
    centeredSlides: false,
    loop: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    }
  };

  Partners: Partner[];
  partnersLoaded: boolean;


  constructor(public UIservice: UIService){

    this.dataSubscription = UIservice.partnersLoaded.subscribe(next => {this.getPartners()})
  }

  getPartners(){
    this.UIservice.partners$.subscribe(res => {
      this.Partners = res.slice()
      this.Partners.sort((a,b) => a.priority - b.priority)
      // this.prepareSlider()
      this.partnersLoaded = true;
    })

  }



  ngOnInit() {
  }


  prepareSlider(){

    let PartnerArrayLen = this.Partners.length

    if(this.UIservice.isMobileXS)
    {
      this.config.slidesPerView = 1
      this.config.centeredSlides = true
    }
    else if (this.UIservice.isMobileSM)
    {
      this.config.slidesPerView = 2
      this.config.centeredSlides = true
    }
    else
    {

      let fitImg = Math.floor(window.innerWidth / 300);

    if(fitImg > 4 && fitImg > PartnerArrayLen){
      this.config.slidesPerView = PartnerArrayLen
    }
    else if(fitImg >= 4){
      this.config.slidesPerView = 4
    }
    else if(fitImg < 4 && fitImg > 1){
      this.config.slidesPerView = fitImg
    }
    else {
      this.config.slidesPerView = 1;
    }

    }


    if(this.config.slidesPerView >= PartnerArrayLen){
      this.swiperNagivators = false;
      this.config.autoplay = false;
      this.config.loop = false;
      }

      console.log(this.config.slidesPerView)


  }

  ngOnDestroy(){
    this.dataSubscription.unsubscribe()
  }
}
