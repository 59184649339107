<section fxFlex="100" fxLayoutAlign="center start" style="margin:2vh">
    <div fxLayout.xs="column" fxLayout="row wrap" fxFlexFill fxLayoutAlign.xs="start center" fxLayoutAlign="center start" style="margin: 2vh; padding-top: 2vh;" fxLayoutGap="2vh">
    
        <label style="width: 100%; text-align: center;" class="mat-h1" i18n="SingUp Team Label| Header for Team sign up@@">Sign Up Team</label>


        <mat-divider style="height: 2vh; width: 80%"></mat-divider>

        <form *ngIf="!(this.signAction)" fxLayout="row wrap" fxLayoutAlign="center start" #f="ngForm" (ngSubmit)="onSubmit()">
           
            <div fxLayout="row wrap" fxLayoutAlign="center center">

                <label fxFlex="100" fxLayoutAlign="center center" class="mat-h2" i18n="SingUp Team Label| Header for Team form sign up@@">Team</label>           
            
            <div fxLayout="column" fxLayoutAlign="start center">    
                
                <label fxFlex="100"  fxLayoutAlign="center center" class="mat-h3" i18n="SingUp Team Label| Header for Team form sign up@@">Team Details</label>

                <mat-form-field>
                    <mat-label i18n="SingUp Team PromoName| label for Team Promo Name form sign up@@">Promo Team name</mat-label>
                    <input  matInput
                        name="PromoTeam"
                        [(ngModel)]="team.promoTeam"
                     >
                    </mat-form-field>
    
                <mat-form-field>
                    <mat-label i18n="SingUp Team email| label for Team email form sign up@@">Leader email</mat-label>
                    <input matInput
                            [formControl]="emailFormControl"                            
                            name="LeaderEmail"
                            [(ngModel)]="team.privateData.leaderEmail"
                            required
                    >
                    <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
                        Please enter a valid email address
                      </mat-error>
                    <mat-error *ngIf="emailFormControl.hasError('required')">
                        Email is <strong>required</strong>
                      </mat-error>
                </mat-form-field>

                <mat-form-field>
                    <mat-label i18n="SingUp Team Phone| label for Team phone form sign up@@">Leader Telephone</mat-label>
                    <input matInput
                            [formControl]="telFormControl"
                            [(ngModel)]="team.privateData.leaderPhone"
                            type="tel"
                            name="Team leaderPhone"
                            required
                    >
                    <mat-error *ngIf="!telFormControl.hasError('minLenght')">
                        Phone number is <strong>incorrect</strong>
                      </mat-error>
                </mat-form-field>

            </div>

            <div *ngIf="!uiService.isMobileXS" fxFlex="100" fxLayoutAlign="center center">
                <button *ngIf="!this.team.anglers[2] && this.teamRunner != 'Not allowed'" 
                        mat-raised-button 
                        i18n="SingUp AddTeamRunnerButton| Button for Runner additon in form sign up@@"
                        (click)="addTeamRunner()">Add Team Runner</button>
            </div>

            </div>
                  
            <div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="3vh">

                <label fxFlex="100"  fxLayoutAlign="center center" class="mat-h2" i18n="SingUp Anglers Label| Header for Anglers form sign up@@">Anglers</label>
        
            <div fxLayout="column" fxLayoutAlign="start center" *ngFor="let angler of team.anglers; let i = index">
            <label class="mat-h3" i18n="SingUp Person| Header for Person form sign up@@">Person&nbsp;{{i + 1}}</label>

            <mat-form-field>
            <mat-label i18n="SingUp Angler name| label for Angler name form sign up@@">Name</mat-label>
            <input matInput
                    [(ngModel)]="angler.name"
                    name="Name"
                    required
            >
            </mat-form-field>

            <mat-form-field>
                <mat-label i18n="SingUp Angler surname| label for Angler surname form sign up@@">Surname</mat-label>
                <input matInput
                        [(ngModel)]="angler.surname"
                        name="Surname"
                        required
                >
            </mat-form-field>

            <mat-form-field>
                <mat-label i18n="SingUp Angler country| label for Angler country form sign up@@">Country</mat-label>
                <mat-select ([ngModel])="angler.country" [(value)]="angler.country">
                    <mat-option value="CZ">Czech Republic</mat-option>
                    <mat-option value="DE">Germany</mat-option>
                    <mat-option value="HU">Hungary</mat-option>
                    <mat-option value="PL">Poland</mat-option>
                    <mat-option value="SK">Slovakia</mat-option>
                    <mat-option value="UA">Ukraine</mat-option>
                    <mat-option value="Other">Other</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field>
                <mat-label i18n="SingUp Angler team Role| label for Angler teamRole sign up@@">Team Role</mat-label>
                <mat-select ([ngModel])="angler.teamRole" [(value)]="angler.teamRole">
                    <mat-option value="Leader" i18n="@@Leader">Team Leader</mat-option>
                    <mat-option value="Member" i18n="@@Member">Team Member</mat-option>
                    <mat-option value="Runner">Team Runner</mat-option>
                </mat-select>
            </mat-form-field>

            </div>

            </div>

            <div *ngIf="uiService.isMobileXS" fxLayoutAlign="center center" style="margin: 2vh;">
                <button *ngIf="!this.team.anglers[2]" 
                        mat-raised-button 
                        i18n="SingUp AddTeamRunnerButton| Button for Runner additon in form sign up@@"
                        (click)="addTeamRunner()" i18n="@@AddTeamRunner">Add Team Runner</button>
            </div>
            
            <div fxFlex="100" fxLayoutAlign="center center" fxLayout="column" fxLayoutGap="2vh">

                <re-captcha
                    [(ngModel)]="captcha"
                    name="captcha"
                    required
                ></re-captcha>

                <div style="max-width: 290px; white-space: normal;" fxLayout="row">
                    <mat-checkbox [(ngModel)]="rulesChecked" name="RulesCheckbox"></mat-checkbox>
                    <p style="padding-left: 5px;"> <span i18n="@@e1a6161863eaea797409506aaf25d00471d12fc7" >
                            I hereby declare acceptance for</span>
                            <a i18n="@@924ada7cae46906deadbf5390c7d6462dc9d576d" target="_blank" [routerLink]="['/main/info/privacy-politics']">Privacy Politics</a>
                            ,
                            <a i18n="@@a5c0f27df0d9b97aee4436b45198a64483fd5aaa" target="_blank" [routerLink]="['/main/info/rodo']">RODO Politics</a> *
                            
                            <!-- <a i18n="@@b973d93d490f66d6b34dc7034885abad7fb78dae" target="_blank" [routerLink]="['/main/info/terms-and-conditions']">Terms and Conditions</a> -->
                    </p>
                    </div>

                <p i18n="@@5d00b283a921189c5443dfb64ac57c4867e46d18">* required fields</p>

                <button type="submit" [disabled]="!f.valid  || !captcha || !rulesChecked"
                    i18n="SingUp SubmitButton| Button for submit form sign up@@"
                    mat-raised-button color="primary">Submit</button>
            </div>
        </form>
        
        <div *ngIf="this.signAction" fxLayout="row wrap" fxLayout.xs="column" fxFlexFill fxLayoutAlign="center center" fxLayoutGap="2vh">
            
            <img fxFlex.xs="50" [attr.src]="resultIcon">

            <div fxLayoutAlign="center center" fxLayout="column">
                <div class="mat-h2" *ngIf="signActionResult === 'success'">
                    <p i18n="ReservationFormSuccess">Team Reservation has been created. Check your email box and confrim it.</p>              
                </div>
                <div class="mat-h2" *ngIf="signActionResult === 'error'">
                    <p i18n="ReservationFormUnavailable">Team Reservation temporarly unavailable. Please try again later.</p>     
                </div>
                <button style="margin-bottom: 2vh;" mat-stroked-button (click)="newFormModel()" i18n="@@NewSignUpForm">New Sign Up Form</button>
            </div>

        </div>
        
    </div>
</section>
