<div class="mat-headline" i18n="Welcome Info Card Constest Details Header| Header for Contest Details@@"><b>Contest Details:</b></div>

        <div fxLayout="column">

            <p fxLayoutAlign="start start" fxFlexOrder="1">
                <label fxFlex.xs="120px" fxFlex="160px" fxLayoutAlign="start start"
                        class="detailsLabel" title="Contest Model"
                        i18n="Welcome Info Card Label Contest Model| Label for Contest Model information@@">
                Contest Model:</label>
            &nbsp;
            <span *ngIf="this.challenge.challengeModel === 'Individual Contest'" i18n="@@Inividual">Individual Contest</span>
            <span *ngIf="this.challenge.challengeModel === 'PZW'">PZW</span>
            <span *ngIf="this.challenge.challengeModel === 'Team Contest'" i18n="@@TeamContest">Team Contest</span>
            <span *ngIf="this.challenge.challengeModel === 'Team Sectors'" i18n="@@TeamsInSectors">Teams in Sectors</span>
            </p>

            <p fxLayoutAlign="start start" fxFlexOrder="3">
                <label fxFlex.xs="120px" fxFlex="160px" fxLayoutAlign="start start"
                        class="detailsLabel" title="Contest Type"
                        i18n="Welcome Info Card Label Contest Type| Label for Contest Type information@@">
                Contest Type:</label>
            &nbsp;{{ this.challenge.challengeType }}</p>

            <p *ngIf="sortTopByIsNumber" fxLayoutAlign="start start" fxFlexOrder="4">
                <label fxFlex.xs="120px" fxFlex="160px" fxLayoutAlign="start start"
                        class="detailsLabel" title="Classification"
                        i18n="@@Classification">
                        Classification:</label>
            &nbsp;<span>Top</span>&nbsp;{{ this.challenge.sortTopBy }}&nbsp;<span i18n="@@Fish">Fish</span></p>

            <p *ngIf="!sortTopByIsNumber" fxLayoutAlign="start start" fxFlexOrder="4">
              <label fxFlex.xs="120px" fxFlex="160px" fxLayoutAlign="start start"
                      class="detailsLabel" title="Classification"
                      i18n="@@Classification">
                      Classification:</label>
          &nbsp;{{ this.challenge.sortTopBy }}</p>

            <p fxLayoutAlign="start start" fxFlexOrder="2">
                <label fxFlex.xs="120px" fxFlex="160px" fxLayoutAlign="start start"
                        class="detailsLabel" title="Team Squad"
                        i18n="Welcome Info Card Label Team Squad| Label for Team Squad information@@">
                Team Squad:</label>
            &nbsp;{{ this.challenge.teamSquad }}&nbsp;<span i18n="@@persons">Person\s</span></p>

            <p fxLayoutAlign="start start" fxFlexOrder="5">
                <label fxFlex.xs="120px" fxFlex="160px" fxLayoutAlign="start start"
                        class="detailsLabel" title="Baiting Method"
                        i18n="@@BaitingMethods">
                Baiting Method:</label>
                &nbsp;
                <ng-container>
                    <ul class="methods-list" >
                        <li *ngIf="this.challenge.fishingMethod === 'Rubber Boat' || this.challenge.fishingMethod === 'All'" class="methods-list-element" i18n="@@RubberBoat">Rubber Boat</li>
                        <li *ngIf="this.challenge.fishingMethod === 'Casting' || this.challenge.fishingMethod === 'All'" class="methods-list-element" i18n="@@Casting">Casting</li>
                        <li *ngIf="this.challenge.fishingMethod === 'RC Models' || this.challenge.fishingMethod === 'All'" class="methods-list-element" i18n="@@RCmodels">RC Models</li>
                    </ul>
                </ng-container>




            </p>

        </div>
