<article id="competitorData" class="full-description-panel" fxFill fxFlex fxLayout="column" fxLayoutAlign="start center">

    <div fxLayout="column" fxLayoutAlign="start center">
        
        <h1 class="mat-display-2" i18n="@@CompetitorData">Competitor Data</h1>
        
    </div>

    <div [ngClass.gt-xs]="'mat-title'" [ngClass.lt-md]="'mobile-font-style'" fxFlex fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap.lt-sm="5vh">

        <div fxLayout.lt-sm="column" fxLayoutAlign="start center" fxFlexOrder="1">
            <div class="workflow-element" [ngClass]="{'icon-mobile-sm':  UIservice.isMobileSM, 'icon-desktop': !UIservice.isMobileSM}" fxLayoutAlign="center center">
                <mat-icon [inline]="true" aria-hidden="true">accessibility</mat-icon>            
            </div>
        
            <div>
                <p i18n="@@CompetitorWeWillStoreYourData">We will store your personal data like:</p>
                        <ul i18n="@@CompetitorDataList">
                            <li>Name and Surname,</li> 
                            <li>Phone Number</li>
                            <li>E-mail adress</li>
                            <li>Photos sent by Referees and Organiser</li>
                            <li>Country (If provided)</li>
                        </ul>
            </div>
        </div>

        <div fxLayout.lt-sm="column" fxLayoutAlign="start center" fxFlexOrder="2">
            <div class="workflow-element" [ngClass]="{'icon-mobile-sm':  UIservice.isMobileSM, 'icon-desktop': !UIservice.isMobileSM}" fxLayoutAlign="center center">
                <mat-icon [inline]="true" aria-hidden="true">policy</mat-icon>            
            </div>
        
            <div>
                <p i18n="@@WeAreProtectingYourData">
                      Carp Contest is <strong>Protecting</strong> your sensitive data.<br>
                      Following persons will have access to your data:</p>
                      <ul i18n="@@CompetitorAcessList">
                        <li>Contest Referees</li> 
                        <li>Contest Organiser</li>
                        <li>Carp Contest Administrator</li>
                    </ul>
            </div>         
        </div>

        <div fxLayout.lt-sm="column" fxLayoutAlign="start center" fxFlexOrder="3">
            <div class="workflow-element" [ngClass]="{'icon-mobile-sm':  UIservice.isMobileSM, 'icon-desktop': !UIservice.isMobileSM}" fxLayoutAlign="center center">
                <mat-icon [inline]="true" aria-hidden="true">delete_forever</mat-icon>            
            </div>
        
            <div>
                <p i18n="@@WeCanClearYourData">
                      If you would like to delete any data then please<br>
                      inform us by sending a request at carp.contests@gmail.com<br>
                       and provide:</p>
                      <ul i18n="@@CompetitorCleanDataList">
                        <li>Contest Name</li> 
                        <li>Your Name and Surname</li>
                        <li>What data you would like to delete</li>
                    </ul>
            </div>         
        </div>
    </div>
</article>