import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { BaseComponent } from '../base.component';
import { Subject, Subscription, Observable, BehaviorSubject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';


import { Team } from 'src/app/data/models/Manage/team.model';
import { RankingItem } from 'src/app/data/models/Manage/rankingItem.model';


import { UIService } from 'src/app/shared/service/ui.service';
import { LiveDataService } from '../../../services/liveDataService';
import { ProvideDataService } from '../../../services/provideDataService';

import { Message } from 'src/app/data/models/Manage/message.model';
import { Challenge } from 'src/app/data/models/Manage/challenge.model';

declare var require: any
@Component({
  selector: 'app-live',
  templateUrl: './live.component.html',
  styleUrls: ['./live.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [LiveDataService]
})
export class LiveComponent extends BaseComponent implements OnInit, OnDestroy {

  public blueArrowIcon = "assets/icons/Arrow_Blue_Right.png"
  public redArrowIcon = "assets/icons/Red_Arrow_Down.png"
  public greenArrowIcon = "assets/icons/Green_Arrow_Up_Darker.png"


  generalDataRequested: boolean;

  awards: Challenge["Awards"]
  challengeType: number
  ranking: RankingItem[]
  messages: Message[]
  teams: Team[];
  currency: string

  private keySubscription: Subscription;
  challengeSub: Subscription;
  rankingSub: Subscription;
  messageSub: Subscription;

  rankingDataLoaded: BehaviorSubject<RankingItem[]> = new BehaviorSubject<RankingItem[]>(<RankingItem[]>{});
  messagesDataLoaded: BehaviorSubject<Message[]> = new BehaviorSubject<Message[]>(<Message[]>{});




  constructor(
    private route: ActivatedRoute,
    private dataService: ProvideDataService,
    private liveDataService: LiveDataService,
    private uiService: UIService)
    {

     super(route,dataService, uiService)

     if(this.dataService.challenge.value)
     {
      var _challenge = this.dataService.challenge.value
      this.awards = _challenge.Awards
      this.challengeType = _challenge.sortTopBy
      this.currency = _challenge.Fee.feeCurrency
     }

     this.keySubscription = this.dataService.keyReady.asObservable().subscribe(bool => {
      if(bool){

        this.getAllData()
       }
     })


  }

  ngOnInit() {
  }

  getAllData(){
    this.liveDataService.getRanking()
    this.liveDataService.getMessages()

    if(!this.generalDataRequested)
     {
       this.challengeSub = this.dataService.challenge$
       .subscribe((challenge: Challenge) => {
         this.awards = challenge.Awards
         this.challengeType = challenge.sortTopBy
         this.currency = challenge.Fee.feeCurrency
       }, error => {
         this.uiService.showSnackBar('Fetching Contest Data Failed, please try again later.',null,3000)
       }
       )

       this.rankingSub = this.liveDataService.ranking$
       .subscribe((rankingArray: RankingItem[]) => {
         let ranking$ = rankingArray;
         this.ranking = this.prepareRankingData(ranking$.slice());
         this.rankingDataLoaded.next(this.ranking)
       }, error => {
         this.uiService.showSnackBar('Fetching Ranking Data Failed, please try again later.',null,3000)
       })

       this.messageSub = this.liveDataService.messages$
       .subscribe((messages: Message[]) => {
         this.messages = messages
         this.messagesDataLoaded.next(this.messages)
       }, error => {
         this.uiService.showSnackBar('Message Service temporarly not available.',null,3000)
       })

       this.generalDataRequested = true;

     }
  }

  prepareRankingData(ranking: RankingItem[]): RankingItem[]{

    const sortedTeamsByScore = this.sortTeamsByPlaceAndAssignClass(ranking)

    return sortedTeamsByScore

  }

  sortTeamsByPlaceAndAssignClass(teamWithRankingCatches: RankingItem[]): RankingItem[]{

    let stayImage = this.blueArrowIcon
    let growImage = this.greenArrowIcon
    let declineImage = this.redArrowIcon

    teamWithRankingCatches
    .sort((a,b) => a.place - b.place)
    .forEach(team => {
      let x = team.place

      team.Stats = {
        displayClass: 'otherPlace',
        progressImage: stayImage,
        kilosToFirst: null,
        kilosToNext: null,
        fishToFirst: null,
        fishToNext: null
      }
      if(team.oldPlace !== team.place){
        team.Stats.progressImage = team.oldPlace > team.place ? growImage : declineImage
      }

      if (!(x > 5))
      {
        team.Stats.displayClass = this.chooseTeamDisplayClass(x);
      }
      x++
    })

    return teamWithRankingCatches
  }

  chooseTeamDisplayClass(x: number): string{

    let displayClass: string = null;

    switch (x){
      case 1:
          displayClass = 'firstPlace';
        break;
      case 2:
          displayClass = 'secondPlace';
        break;
      case 3:
          displayClass = 'thirdPlace';
        break;
      case 4:
          displayClass = 'fourthPlace';
        break;
      case 5:
          displayClass = 'fifthPlace';
        break;
      default:
          displayClass = null;
          break;
    }

    return displayClass
  }

  ngOnDestroy(){
    if(this.challengeSub) {this.challengeSub.unsubscribe();}
    if (this.rankingSub) {this.rankingSub.unsubscribe()};
    if(this.keySubscription) {this.keySubscription.unsubscribe()};
  }
}
