import { Component, OnInit } from '@angular/core';
import { Message } from 'src/app/data/models/Manage/message.model';
import { ContestDataService } from '../../../services/contest-data.service';
import { ActivatedRoute } from '@angular/router';
import { ManageContestDataService } from '../../../services/manage-contest-data';
import { UIService } from 'src/app/shared/service/ui.service';


@Component({
  selector: 'app-message-writer',
  templateUrl: './message-writer.component.html',
  styleUrls: ['./message-writer.component.css']
})
export class MessageWriterComponent implements OnInit {

  newMessage: Message = new Message()
  result: string = null
  public dateTimeNow: number
  photoName: any = null
  originPhoto: any = null
  displayPhotoURL: any = null

  constructor(private manageContest: ManageContestDataService, public UIservice: UIService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.onClear()


  }

  youtubeLinkReview(event){
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
    var match = this.newMessage.extraData.youtubeLink.match(regExp)
    console.log(match)
  }

  onFileSelected(event){

    const _file = event.target.files[0]

    if(_file.size < 6291456)
    {
      this.originPhoto = _file
      this.photoName = _file.name
      this.newMessage.extraData.imgLink = _file

      const reader = new FileReader()
      reader.onload = () => {
        this.displayPhotoURL = reader.result as string
      }
      reader.readAsDataURL(_file)
    }
    else
    {
      //  this.uploadError = "File is to big, max size 6MB"
    }



}

  onClear(){
    this.newMessage.createdBy = "Organiser"
    this.newMessage.text = {
      EN: "",
      PL: "",
      title: ""
    }
    this.newMessage.type = "Text"
    this.newMessage.extraData = {
      imgLink: null,
      youtubeLink: null,
      partner: {
        partnerLink: null,
        partnerName: null,
      }
    }

    this.photoName = null
    this.originPhoto = null
    this.displayPhotoURL = null

    this.dateTimeNow = Date.now();
  }

  async onSave(){

    const spinner = this.UIservice.openSpinnerDialog()

    this.newMessage.createdAt = Date.now();

    if(this.newMessage.type === 'Photo' )
    {
      await this.manageContest.uploadMessagePhoto(this.originPhoto)
        .then((photoLink) => {this.newMessage.extraData.imgLink = photoLink})
        .catch((e) => {this.newMessage.extraData.imgLink= null})
    }

   await this.manageContest.writeContestMessage(this.newMessage)
      .then(() => {this.result = 'Success'})
      .then(() => {this.onClear()})
      .catch((e) => {this.result = e})
      .finally(() => {spinner.close()})

  }
}
